// SharedLoading.js
import React from 'react'

const Loading = ({ message }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50px',
      }}
    >
      <span style={{ marginRight: '8px' }}>{message}</span>
      <div className='spinner-border' role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  )
}

export default Loading
