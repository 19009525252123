import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Checkbox } from '../../Controls/CheckBoxList'
import { GoalService } from '../../GoalComponenet/GoalService'
import { format } from 'date-fns'
import { Datectrl } from '../../Controls/Datectrl'
import formatDate from '../../Utility/Utility'

export const HeaderUI = forwardRef(
  ({ firstName, VisitId, onSaveButtonClick, ClientId }, ref) => {
    const goalService = new GoalService()
    const [goalData, setGoalData] = useState([]) // Initialize as an empty array
    const [selectedRow, setSelectedRow] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      const fetchData = () => {
        setLoading(true)
        goalService
          .getPNGoals(ClientId, VisitId)
          .then((dataFromResponse) => {
            setGoalData(dataFromResponse)
          })
          .catch((error) => {
            console.error('Error fetching data:', error)
          })
          .finally(() => {
            setLoading(false)
          })
      }
      if (VisitId !== null && ClientId != null) {
        fetchData()
      }
    }, [VisitId, ClientId])

    const handleCheckboxChange = (GoalId, checked) => {
      const updatedGoalData = goalData.map((row) =>
        row.GoalId === GoalId
          ? {
              ...row,
              GoalMET: checked,
              GoalMetDate: checked
                ? format(new Date(), 'yyyy-MM-dd HH:mm:ss')
                : null,
            }
          : row,
      )
      //alert(JSON.stringify(updatedGoalData));
      setGoalData(updatedGoalData)
    }

    const handleDateChange = (GoalId, controlName, value) => {
      const updatedGoalData = goalData.map((row) =>
        row.GoalId === GoalId ? { ...row, GoalMetDate: value } : row,
      )
      // alert(JSON.stringify(updatedGoalData));
      setGoalData(updatedGoalData)

      //  // Update the selected row state with the checked row
      //  const selectedRowData = updatedGoalData.find(
      //   (row) => row.GoalId === GoalId
      // );
      // setSelectedRow(selectedRowData);
    }
    // Expose the insertData function through the onSaveButtonClick prop
    const insertData = () => {
      goalService
        .updateGoalMet(selectedRow)
        .then((dataFromResponse) => {
          // Handle the success response, if needed
        })
        .catch((error) => {
          console.error('Header UI error', error)
        })
    }
    // Function to get the selected rows from the child component
    useImperativeHandle(ref, () => ({
      getSelectedRows: () => {
        return goalData //.filter((row) => row.GoalMet);
      },
    }))
    //Call the insertData function when the parent component's button is clicked
    useEffect(() => {
      if (onSaveButtonClick && selectedRow) {
        // alert("save click");
        //insertData();
      }
    }, [onSaveButtonClick, selectedRow])

    const LTgoalCounters = {}
    const STgoalCounters = {}

    const getGoalCounter = (GoalCode) => {
      //alert(JSON.stringify(LTgoalCounters));
      if (GoalCode === 'LTG') {
        // LT goals: Use the index + 1
        const value = (LTgoalCounters[GoalCode] || 0) + 1
        LTgoalCounters[GoalCode] = value
        return value
      } else {
        // Other goals: Increment the counter for the specific goal type
        const value = (STgoalCounters[GoalCode] || 0) + 1
        STgoalCounters[GoalCode] = value
        return value
      }
    }
    return (
      <div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <table>
            <tbody>
              {goalData.map((row, index) => (
                <tr key={row.GoalId}>
                  <td
                    style={{
                      width: '90%',
                      paddingLeft: row.GoalType !== 'LT' ? '0' : '0',
                    }}
                  >
                    <span
                      style={{
                        paddingLeft: row.GoalType !== 'LT' ? '10px' : '0',
                      }}
                    >
                      {row.GoalType === 'LT' ? (
                        <>
                          LTG<span> - {getGoalCounter(row.GoalCode)}: </span>
                          Within{' '}
                          <b>
                            {row.Duration} {row.Units}, {firstName}{' '}
                            {row.GoalDescription} {row.Accuracy}
                          </b>
                          .
                        </>
                      ) : row.GoalType === 'General' ? (
                        <>
                          STG
                          <span>
                            {' '}
                            - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}
                            :{' '}
                          </span>
                          Within{' '}
                          <b>
                            {row.Duration} {row.Units}
                          </b>
                          , Given <b>{row.Given}</b>, {firstName} will{' '}
                          <b>{row.GoalDescription}</b> with{' '}
                          <b>{row.Accuracy}</b> accuracy in{' '}
                          <b>{row.InSessions}</b> sessions.
                        </>
                      ) : row.GoalType === 'Articulation' ? (
                        <>
                          STG
                          <span>
                            {' '}
                            - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}
                            :{' '}
                          </span>
                          Within{' '}
                          <b>
                            {row.Duration} {row.Units}
                          </b>
                          , Given <b>{row.Given}</b>, {firstName}{' '}
                          <b>{row.GoalDescription}</b> in <b>{row.Sound}</b>{' '}
                          sound(s) at the <b>{row.AtLevel}</b> level with{' '}
                          <b>{row.Accuracy}</b> accuracy in{' '}
                          <b>{row.InSessions}</b> sessions.
                        </>
                      ) : row.GoalType === 'Phonology' ? (
                        <>
                          STG
                          <span>
                            {' '}
                            - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}
                            :{' '}
                          </span>
                          Within{' '}
                          <b>
                            {row.Duration} {row.Units}
                          </b>
                          , Given <b>{row.Given}</b>, {firstName}{' '}
                          <b>{row.GoalDescription}</b> with{' '}
                          <b>{row.Accuracy}</b> accuracy in{' '}
                          <b>{row.InSessions}</b> sessions.
                        </>
                      ) : (
                        <>No content found.</>
                      )}
                    </span>
                  </td>
                  <td style={{ width: '2%' }}>
                    {row.GoalType === 'LT' ? (
                      <span>
                        <b>MET</b>
                      </span>
                    ) : (
                      <Checkbox
                        label={row.GoalMET}
                        checked={row.GoalMET}
                        onChange={handleCheckboxChange}
                        columnName={row.GoalId}
                        // disabled={row.GoalMetDate?row.GoalMET || false:false}
                      />
                    )}
                  </td>
                  <td style={{ width: '5%' }}>
                    {row.GoalType === 'LT' ? (
                      <span></span>
                    ) : // Conditional rendering of Datectrl
                    row.GoalMET ? (
                      <Datectrl
                        style={{ paddingLeft: '2px' }}
                        selectedDate={formatDate(row.GoalMetDate, false, false)}
                        onDateChange={(value) =>
                          handleDateChange(row.GoalId, 'GoalMetDate', value)
                        }
                      />
                    ) : (
                      <span></span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  },
)
