import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Variables } from '../../Variables'
import {
  VisitProvider,
  SignLockProvider,
  CreatedByProvider,
} from '../../Provider/Visitprovider'
import { toast } from 'react-toastify'
import { NomsComp } from '../Component/NomsComp'
import { ServiceDeliveryComp } from '../Component/ServiceDeliveryComp'
import { NomsService } from '../NomsService'
import { TreatmentAimTarget } from '../Component/TreatmentAimTarget'
import { useUser } from '../../Provider/UserContext'
import signLockService from '../../POC/SignLock/Service'
import { NoteType } from '../../Enum'
import Accordion from 'react-bootstrap/Accordion'

const underlineStyle = { textDecoration: 'underline' }

export const IntNoms = forwardRef(({ props, AreaofAsses }, ref) => {
  const VisitId = useContext(VisitProvider)
  const { userDetails } = useUser()
  const CreatedBy = useContext(CreatedByProvider)
  const [formData, setFormData] = useState(new NomsService())
  const [data, setData] = useState({
    noms: {
      INT: [],
      SLE: [],
      SLC: [],
      COG: [],
      SWAL: [],
      FEED: [],
      FLU: [],
      AAC: [],
      PRAG: [],
      VOIC: [],
    },
    serviceDel: {
      INT: [],
      SLE: [],
      SLC: [],
      COG: [],
      SWAL: [],
      FEED: [],
      FLU: [],
      AAC: [],
      PRAG: [],
      VOIC: [],
    },
    TAT: {
      INT: [],
      SLE: [],
      SLC: [],
      COG: [],
      SWAL: [],
      FEED: [],
      FLU: [],
      AAC: [],
      PRAG: [],
      VOIC: [],
    },
  })
  const [isSignLock, setIsSignLock] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [shouldReloadData, setShouldReloadData] = useState(false)
  const [clientId, setClientId] = useState(null)
  const [age, setAge] = useState(0)
  const fcmTypesToDisplay = AreaofAsses?.split(',')

  useEffect(() => {
    // alert(AreaofAsses)
    if (userDetails) {
      setAge(userDetails.Age)
      setClientId(userDetails.Clientid)
    }
  }, [userDetails])

  useEffect(() => {
    if (VisitId) FetchingSignAndLock()
  }, [VisitId])

  useEffect(() => {
    if ((VisitId && clientId) || shouldReloadData) {
      formData
        .getDischargeNomsData(VisitId, clientId)
        .then((responseData) => {
          if (responseData) {
            const newData = {
              noms: {
                INT: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'INT',
                ),
                SLE: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'SLE',
                ),
                SLC: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'SLC',
                ),
                COG: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'COG',
                ),
                SWAL: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'SWAL',
                ),
                FEED: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'FEED',
                ),
                FLU: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'FLU',
                ),
                AAC: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'AAC',
                ),
                PRAG: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'PRAG',
                ),
                VOIC: responseData.filter(
                  (item) => item.GroupType === 1 && item.FCMType === 'VOIC',
                ),
              },
              serviceDel: {
                INT: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'INT',
                ),
                SLE: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'SLE',
                ),
                SLC: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'SLC',
                ),
                COG: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'COG',
                ),
                SWAL: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'SWAL',
                ),
                FEED: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'FEED',
                ),
                FLU: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'FLU',
                ),
                AAC: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'AAC',
                ),
                PRAG: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'PRAG',
                ),
                VOIC: responseData.filter(
                  (item) => item.GroupType === 2 && item.FCMType === 'VOIC',
                ),
              },
              TAT: {
                INT: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'INT',
                ),
                SLE: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'SLE',
                ),
                SLC: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'SLC',
                ),
                COG: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'COG',
                ),
                SWAL: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'SWAL',
                ),
                FEED: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'FEED',
                ),
                FLU: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'FLU',
                ),
                AAC: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'AAC',
                ),
                PRAG: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'PRAG',
                ),
                VOIC: responseData.filter(
                  (item) => item.GroupType === 3 && item.FCMType === 'VOIC',
                ),
              },
            }
            setData(newData)
          }
        })
        .catch((error) => console.error('Error fetching data:', error))
        .finally(() => setShouldReloadData(false))
    }
  }, [VisitId, clientId, shouldReloadData, formData])

  useImperativeHandle(ref, () => ({ handleButtonClick }))

  const handleUpdate = (type, fcmType) => (selectedRowData) => {
    setData((prevData) => ({
      ...prevData,
      [type]: {
        ...prevData[type],
        [fcmType]: selectedRowData,
      },
    }))
  }

  const handleButtonClick = (showAlert = true) => {
    const mergedData = [
      ...data.noms.INT,
      ...data.serviceDel.INT,
      ...data.TAT.INT,
      ...data.noms.SLE,
      ...data.serviceDel.SLE,
      ...data.TAT.SLE,
      ...data.noms.SLC,
      ...data.serviceDel.SLC,
      ...data.TAT.SLC,
      ...data.noms.COG,
      ...data.serviceDel.COG,
      ...data.TAT.COG,
      ...data.noms.SWAL,
      ...data.serviceDel.SWAL,
      ...data.TAT.SWAL,
      ...data.noms.FEED,
      ...data.serviceDel.FEED,
      ...data.TAT.FEED,
      ...data.noms.FLU,
      ...data.serviceDel.FLU,
      ...data.TAT.FLU,
      ...data.noms.AAC,
      ...data.serviceDel.AAC,
      ...data.TAT.AAC,
      ...data.noms.PRAG,
      ...data.serviceDel.PRAG,
      ...data.TAT.PRAG,
      ...data.noms.VOIC,
      ...data.serviceDel.VOIC,
      ...data.TAT.VOIC,
    ]
    //alert(JSON.stringify(mergedData))
    if (mergedData && !isSaving) {
      setIsSaving(true)
      const updatedMergedData = mergedData.map((item) => ({
        ...item,
        CreatedBy: CreatedBy,
        UpdatedBy: CreatedBy,
      }))
      formData
        .insertDischanrgeNomsData(updatedMergedData)
        .then((response) => {
          setShouldReloadData(true)
          if (showAlert) toast.success(response.MessageDetail)
        })
        .catch((error) => console.error('Error saving data:', error))
        .finally(() => setIsSaving(false))
    }
  }

  const FetchingSignAndLock = () => {
    signLockService
      .getSignLockDataByNoteType(VisitId, NoteType.DischargeNoms)
      .then((response) => setIsSignLock(response?.IsSignedAndLocked || false))
      .catch((error) => console.error('Error FetchingSignAndLock:', error))
  }

  const handleSignLockClick = () => {
    if (window.confirm('Are you sure you want to sign and lock?')) {
      handleButtonClick(false)
      const signLockData = {
        VisitId,
        IsSignedAndLocked: true,
        ClientId: clientId,
        VisitTypeId: userDetails.VisitTypeId,
        TherepistId: userDetails.TherapistId,
        VisitStartDateTime: userDetails.Scheduleddatefrom,
        VisitendDateTime: userDetails.Scheduleddateto,
        NoteType: NoteType.DischargeNoms,
        SignedBy: userDetails.TherapistId,
      }
      signLockService
        .insertSignLockData(signLockData)
        .then(() => {
          FetchingSignAndLock()
          alert('Sign and lock confirmed!')
        })
        .catch((error) => console.error('Error inserting data:', error))
    } else {
      alert('Sign and lock cancelled.')
    }
  }

  const renderAccordionItem = (title, fcmType) => (
    <Accordion.Item eventKey='0'>
      <Accordion.Header>
        <b style={underlineStyle}>{title}</b>
      </Accordion.Header>
      <Accordion.Body>
        <table style={{ width: '100%', padding: 5 }}>
          <tr>
            <td style={{ verticalAlign: 'top' }}>
              {/* <p style={{ fontSize: '14px' }}>
                <b>Instructions:</b> Based on your assessment, please answer the
                following questions about the individual's speech
                intelligibility. Intelligibility is based on listener
                familiarity and length of utterance. For all questions, consider
                chronological age expectations when rating the individual.{' '}
              </p>
              <p style={{ fontSize: '14px' }}>
                <b>Note:</b>{' '}
                <i>
                  <span style={{ textDecoration: 'underline' }}>
                    Please note that the scale is reversed for the first
                    question only.{' '}
                  </span>
                  For example, a score of 0-25% indicates the highest
                  functioning for the first question only.
                </i>
              </p> */}

              <NomsComp
                visitId={VisitId}
                defaultRow={data.noms[fcmType]}
                onRowSelected={handleUpdate('noms', fcmType)}
                FCMType={fcmType}
                Age={age}
              />
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'top' }}>
              <ServiceDeliveryComp
                visitId={VisitId}
                defaultRow={data.serviceDel[fcmType]}
                onRowSelected={handleUpdate('serviceDel', fcmType)}
                FCMType={fcmType}
                Age={age}
              />
            </td>
          </tr>
          {fcmType !== 'FEED' &&
            fcmType !== 'FLU' &&
            fcmType !== 'AAC' &&
            fcmType !== 'PRAG' && (
              <>
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <TreatmentAimTarget
                      visitId={VisitId}
                      defaultRow={data.TAT[fcmType]}
                      onRowSelected={handleUpdate('TAT', fcmType)}
                      FCMType={fcmType}
                    />
                  </td>
                </tr>
              </>
            )}
        </table>
      </Accordion.Body>
    </Accordion.Item>
  )

  return (
    <div style={{ display: 'block', width: 'max', padding: 10 }}>
      {fcmTypesToDisplay?.includes('INT') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem('Speech Intelligibility', 'INT')}
          </Accordion>
          <br></br>
        </>
      )}
      {fcmTypesToDisplay?.includes('SLE') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem('Spoken Language Expression', 'SLE')}
          </Accordion>
          <br></br>
        </>
      )}
      {fcmTypesToDisplay?.includes('SLC') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem('Spoken Language Comprehension', 'SLC')}
          </Accordion>
          <br></br>
        </>
      )}
      {fcmTypesToDisplay?.includes('COG') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem('Cognition', 'COG')}
          </Accordion>
          <br></br>
        </>
      )}
      {fcmTypesToDisplay?.includes('SWAL') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem('Swallowing', 'SWAL')}
          </Accordion>
          <br></br>
        </>
      )}
      {fcmTypesToDisplay?.includes('FEED') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem('Feeding', 'FEED')}
          </Accordion>
          <br></br>
        </>
      )}
      {fcmTypesToDisplay?.includes('FLU') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem('Fluency', 'FLU')}
          </Accordion>
          <br></br>
        </>
      )}
      {fcmTypesToDisplay?.includes('AAC') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem(
              'Multi Modal Functional Communication(AAC)',
              'AAC',
            )}
          </Accordion>
          <br></br>
        </>
      )}
      {fcmTypesToDisplay?.includes('PRAG') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem('Pragmatics', 'PRAG')}
          </Accordion>
          <br></br>
        </>
      )}
      {fcmTypesToDisplay?.includes('VOIC') && (
        <>
          <Accordion defaultActiveKey={''}>
            {renderAccordionItem('VOICE', 'VOIC')}
          </Accordion>
          <br></br>
        </>
      )}

      {/* <Accordion defaultActiveKey={''}>
            {renderAccordionItem('Speech Intelligibility', 'INT')}
          </Accordion>  <br></br>
      <Accordion defaultActiveKey={''}>
        {renderAccordionItem('Spoken Language Expression', 'SLE')}
      </Accordion>
      <br></br>
      <Accordion defaultActiveKey={''}>
        {renderAccordionItem('Spoken Language Comprehension', 'SLC')}
      </Accordion>
      <br></br>
      <Accordion defaultActiveKey={''}>
        {renderAccordionItem('Cognition', 'COG')}
      </Accordion>
      <br></br>
      <Accordion defaultActiveKey={''}>
        {renderAccordionItem('Swallowing', 'SWAL')}
      </Accordion>
      <br></br>
      <Accordion defaultActiveKey={''}>
        {renderAccordionItem('Feeding', 'FEED')}
      </Accordion>
      <br></br>
      <Accordion defaultActiveKey={''}>
        {renderAccordionItem('Fluency', 'FLU')}
      </Accordion>
      <br></br>
       <Accordion defaultActiveKey={''}>
        {renderAccordionItem('Multi Modal Functional Communication(AAC)', 'AAC')}
      </Accordion>
      <br></br>
      <Accordion defaultActiveKey={''}>
        {renderAccordionItem('Pragmatics', 'PRAG')}
      </Accordion>
      <br></br> 
      <Accordion defaultActiveKey={''}>
        {renderAccordionItem('VOICE', 'VOIC')}
      </Accordion>  */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <button
          style={{ width: '150px', marginLeft: '20px' }}
          disabled={isSignLock || isSaving}
          onClick={handleButtonClick}
          className='btn btn-primary rounded-pill'
        >
          {isSaving ? <b>Saving...</b> : <b>Save</b>}
        </button>
        <button
          onClick={handleSignLockClick}
          disabled={isSignLock}
          style={{ width: '150px', marginLeft: '20px' }}
          className='btn btn-primary rounded-pill'
        >
          <b>Sign and Lock</b>
        </button>
      </div>
    </div>
  )
})
