import React, { Component } from 'react'
import axios from 'axios'
import { Variables } from '../../Variables'
import { AssessSumService } from '../Component/AssessSumService'
import { NomsService } from '../Component/NomsService'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
const nomsService = new NomsService()

export class AACService {
  constructor() {
    this.AacId = 0
    this.Visitid = null
    this.CurrentMethod = null
    this.CurrentMethodSuccess = null
    this.CurrentMethodBarriers = null
    this.OtherAACTrials = null
    this.ConsideredDevice = null
    this.OverallRating = 'Within Normal Limits'
    this.IsSkilledTherapyIndicated = false
    this.Result = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []

      // Assuming serviceAssessment.saveData returns a Promise
      const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)

      // Assuming serviceAssessment.saveData returns a valid axios post configuration
      if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
        reject(new Error('Invalid assessment Promise'))
        return
      }

      // Push the assessment promise to the apiCalls array
      apiCalls.push(assessmentPromise)

      // Push the second axios.post call to the apiCalls array
      apiCalls.push(axios.post(apiUrl + 'AAC/InsertAAC', formData))

      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }

      // Use Promise.all to wait for both promises to resolve
      Promise.all(apiCalls)
        .then((responses) => {
          // alert(JSON.stringify(responses))
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  getGetAssSummary(VisitId) {
    return serviceAssessment
      .getGetAssSummary(VisitId, 'AAC')
      .then((data) => {
       // alert('Data received:' + data)
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getNomsData(VisitId) {
    return nomsService
      .getNomsData(VisitId, 'AAC')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  deleteAssSumData(summId) {
    return serviceAssessment
      .deleteAssSumData(summId)
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  getAACData(VisitId) {
    return axios
      .get(apiUrl + 'AAC/GetAAC/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data) {
          //alert(JSON.stringify(data));
          this.AacId = data.AacId
          this.Visitid = data.Visitid
          this.CurrentMethod = data.CurrentMethod
          this.CurrentMethodSuccess = data.CurrentMethodSuccess
          this.CurrentMethodBarriers = data.CurrentMethodBarriers
          this.OtherAACTrials = data.OtherAACTrials
          this.ConsideredDevice = data.ConsideredDevice
          this.OverallRating = data.OverallRating
          this.IsSkilledTherapyIndicated = data.IsSkilledTherapyIndicated
          this.Result = data.Result
          this.CreatedBy = data.CreatedBy
          this.UpdatedBy = data.UpdatedBy
          this.CreatedDate = data.CreatedDate

          return this
        }
      })
      .catch((error) => console.log('Error getSpeechData data:', error))
  }
}
