import axios from 'axios'
import { Variables } from '../Variables'
import { format } from 'date-fns'

export class GoalService {
  constructor() {
    this.defaultFormData = {
      GoalId: 0,
      RowId: 1,
      VisitId: 0,
      GoalType: 'Articulation',
      Duration: 6,
      FCMType: null,
      GoalCategory: null,
      Units: 'mth(s)',
      GoalCode: 'ST1',
      Given: 'No Cues',
      Sound: '',
      AtLevel: 'Sentence',
      InSessions: '4 of 5',
      Accuracy: '10%',
      GoalDescription: '',
      GoalMET: false,
      GoalMetDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    }
  }

  getPNGoals(clientId, visitId) {
    const apiUrl = Variables.API_URL
    return axios
      .get(`${apiUrl}SpeechInt/GetPNGoal/${clientId}/${visitId}`)
      .then((response) => {
        const dataFromResponse = response.data.Result
        const mappedData = dataFromResponse.map((goalData) => ({
          GoalId: goalData.GoalId,
          VisitId: goalData.VisitId,
          RowId: goalData.RowId,
          PN_Goal_Type: goalData.PN_Goal_Type,
          GoalType: goalData.GoalType,
          GoalCode: goalData.GoalCode,
          FCMType: goalData.FCMType,
          GoalCategory: goalData.GoalCategory,
          GoalDescription: goalData.GoalDescription,
          Duration: goalData.Duration,
          Units: goalData.Units,
          Given: goalData.Given,
          Sound: goalData.Sound,
          AtLevel: goalData.AtLevel,
          Accuracy: goalData.Accuracy,
          InSessions: goalData.InSessions,
          GoalMET: goalData.GoalMET,
          GoalMetDate: goalData.GoalMetDate,
        }))

        //alert(JSON.stringify(mappedData));
        return mappedData
      })
      .catch((error) => {
        console.error('Error fetching SoapNote data:', error)
        throw error
      })
  }

  insertUpdateGoal(formData) {
    const apiUrl = Variables.API_URL
    return axios
      .post(`${apiUrl}SoapNote/InsertSoapNote`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting PocDTO data:', error)
        throw error
      })
  }

  updateGoalMet(metData) {
    const apiUrl = Variables.API_URL
    return axios
      .put(`${apiUrl}SpeechInt/UpdateGoal`, metData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting PocDTO data:', error)
        throw error
      })
  }

  insertGoal(formData) {
    const apiUrl = Variables.API_URL
    return axios
      .post(`${apiUrl}SoapNote/InsertSoapNote`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting PocDTO data:', error)
        throw error
      })
  }

  async getHeaderData(VisitId, clientid, visitdate) {
    const apiUrl = Variables.API_URL

    try {
      const response = await axios.get(
        apiUrl + `ProgressDis/GetHeader/${VisitId}/${clientid}/${visitdate}`,
      )
      const data = response.data.Result
      return data
    } catch (error) {
      console.log('Error fetching user details:', error)
      return null
    }
  }

  getProgressReportData(clientid, goalType,currentDate) {
    const apiUrl = Variables.API_URL
    return axios
      .get(apiUrl + `ProgressDis/GetProgressReportData/${clientid}/${goalType}/${currentDate}`)
      .then((response) => {
        const data = response.data.Result
        return data
      })
      .catch((error) => {
        console.log('Error fetching ProgressDis/GetProgressReportData:', error)
        return null
      })
  }

  getMontlyProgressReportData(clientid, goalType,currentDate) {
    const apiUrl = Variables.API_URL
    return axios
      .get(apiUrl + `ProgressDis/GetProgressReportData/${clientid}/${goalType}/${currentDate}`)
      .then((response) => {
        const data = response.data.Result
        return data
      })
      .catch((error) => {
        console.log('Error fetching ProgressDis/GetProgressReportData:', error)
        return null
      })
  }
}
