import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  StatusDischargeOptions,
  WeekValue,
  FrequencyValue,
  PlanDiscussWithValue,
  PrognosisValue,
  CurrentPlanValue,
  ClassGradeValue,
  LanguageOptions,
  LanguageOptionsValue,
  CommunicatesThroughOptions,
  UnderstandingLevelValue,
} from '../data/options'

export const DDList = forwardRef(
  ({ ddType, onSelect, dropdownName, defaultValue, width, className }, ref) => {
    //export const DDList = ({ddType,onSelect, dropdownName, defaultValue}) => {
    const [selectedOption, setSelectedOption] = useState(defaultValue || '')
    var [options, setOptions] = useState([])
    const selectStyle = width ? { width: `${width}px` } : {}

    useEffect(() => {
      if (defaultValue) {
        setSelectedOption(defaultValue)
      }
    }, [defaultValue])

    if (ddType == '1') {
      options = [
        { value: 'Unremarkable', text: 'Unremarkable' },
        { value: 'Complicated', text: 'Complicated' },
      ]
    } else if (ddType == '2') {
      options = [
        { value: 'falling asleep', text: 'Falling asleep' },
        { value: 'fussy/cranky', text: 'Fussy/Cranky' },
        { value: 'all the time', text: 'All the time' },
      ]
    } else if (ddType == '3') {
      options = [
        { value: 'Passed', text: 'Passed' },
        { value: 'Failed', text: 'Failed' },
      ]
    } else if (ddType == '4') {
      options = [
        { value: 'mth(s)', text: 'mth(s)' },
        { value: 'wk(s)', text: 'wk(s)' },
        { value: 'yr(s)', text: 'yr(s)' },
      ]
    } else if (ddType === '5') {
      options = [
        { value: 'no cues', text: 'No Cues' },
        { value: 'minimum cues', text: 'Minimum Cues' },
        { value: 'moderate cues', text: 'Moderate Cues' },
        { value: 'maximum cues', text: 'Maximum Cues' },
      ]
    } else if (ddType == '6') {
      options = [
        { value: 'sentence', text: 'Sentence' },
        { value: 'isolation', text: 'Isolation' },
        { value: 'syllables', text: 'Syllables' },
        { value: 'word', text: 'Word' },
        { value: 'phrase', text: 'Phrase' },
        { value: 'conversation', text: 'Conversation' },
      ]
    } else if (ddType == '7') {
      options = [
        { value: '4 of 5', text: '4 of 5' },
        { value: '5 of 5', text: '5 of 5' },
      ]
    } else if (ddType == '8') {
      options = [
        { value: '0%', text: '0%' },
        { value: '5%', text: '5%' },
        { value: '10%', text: '10%' },
        { value: '15%', text: '15%' },
        { value: '20%', text: '20%' },
        { value: '25%', text: '25%' },
        { value: '30%', text: '30%' },
        { value: '35%', text: '35%' },
        { value: '40%', text: '40%' },
        { value: '45%', text: '45%' },
        { value: '50%', text: '50%' },
        { value: '55%', text: '55%' },
        { value: '60%', text: '60%' },
        { value: '65%', text: '65%' },
        { value: '70%', text: '70%' },
        { value: '75%', text: '75%' },
        { value: '80%', text: '80%' },
        { value: '85%', text: '85%' },
        { value: '90%', text: '90%' },
        { value: '95%', text: '95%' },
        { value: '100%', text: '100%' },
      ]
    } else if (ddType == '9') {
      options = [
        { value: 'Articulation', text: 'Articulation' },
        { value: 'Phonology', text: 'Phonology' },
        { value: 'Phonology', text: 'Phonology' },
      ]
    } else if (ddType == '11' || ddType == '13') {
      options = [
        { value: 'Within Normal Limits', text: 'Within Normal Limits' },
        { value: 'Mild', text: 'Mild' },
        { value: 'Moderate', text: 'Moderate' },
        { value: 'Severe', text: 'Severe' },
        { value: 'Profound', text: 'Profound' },
      ]
    } else if (ddType === 12) {
      options = [
        { value: 'Good', text: 'Good' },
        { value: 'Fair', text: 'Fair' },
        { value: 'Poor', text: 'Poor' },
      ]
    } else if (ddType == '13') {
      options = [
        { value: 'Within Normal Limits', text: 'Within Normal Limits' },
        { value: 'Mild', text: 'Mild' },
        { value: 'Moderate', text: 'Moderate' },
        { value: 'Severe', text: 'Severe' },
        { value: 'Profound', text: 'Profound' },
      ]
    } else if (ddType == '14') {
      options = [
        { value: 'Complex', text: 'Complex' },
        { value: 'Basic', text: 'Basic' },
        { value: 'Simple', text: 'Simple' },
        { value: 'None', text: 'None' },
      ]
    } else if (ddType == '15') {
      options = [
        { value: 'Conversation', text: 'Conversation' },
        { value: 'Sentence', text: 'Sentence' },
        { value: 'Phrase', text: 'Phrase' },
        { value: 'Word', text: 'Word' },
        { value: 'None', text: 'None' },
      ]
    } else if (ddType == '16') {
      options = [
        { value: 'Multi-Step', text: 'Multi-Step' },
        { value: '1 Step', text: '1 Step' },
        { value: '2 Step', text: '2 Step' },
        { value: '3 Step', text: '3 Step' },
        { value: 'None', text: 'None' },
      ]
    } else if (ddType == '17') {
      options = [
        { value: '1-2x yr', text: '1-2x yr' },
        { value: '2-3x 6mo', text: '2-3x 6mo' },
        { value: '>3x yr', text: '>3x yr' },
      ]
    } else if (ddType == '18') {
      options = LanguageOptionsValue
    } else if (ddType == '19') {
      options = CommunicatesThroughOptions
    } else if (ddType === 20) {
      options = UnderstandingLevelValue
    } else if (ddType === 21) {
      options = ClassGradeValue
    } else if (ddType === 22) {
      options = CurrentPlanValue
    } else if (ddType === 23) {
      options = WeekValue
    } else if (ddType === 24) {
      options = FrequencyValue
    } else if (ddType === 25) {
      options = PlanDiscussWithValue
    } else if (ddType === 26) {
      options = PrognosisValue
    } else if (ddType === 27) {
      options = StatusDischargeOptions
    } else if (ddType === 28) {
      options = StatusDischargeOptions
    } else if (ddType === 29) {
      options = [
        { value: 'Elementary', text: 'Elementary' },
        { value: 'High School', text: 'High School' },
        { value: 'Associates', text: 'Associates' },
        { value: 'Bachelors', text: 'Bachelors' },
        { value: 'Graduate', text: 'Graduate' },
        { value: 'Vocational', text: 'Vocational' },
        { value: 'Other', text: 'Other' },
      ]
    } else if (ddType === 30) {
      options = [
        { value: 'Unintelligible', text: 'Unintelligible' },
        { value: 'Syllables', text: 'Syllables' },
        { value: 'Words', text: 'Words' },
        { value: 'Phrases', text: 'Phrases' },
        { value: 'Sentences', text: 'Sentences' },
        { value: 'Conversation', text: 'Conversation' },
      ]
    } //This is goal category for SLC
    else if (ddType === 31) {
      options = [
        { value: 'vocabulary', text: 'Vocabulary' },
        { value: 'following directions', text: 'Following Directions' },
        { value: 'answering questions', text: 'Answering Questions' },
      ]
    } else if (ddType === 32) {
      options = [
        { value: 'morphology', text: 'Morphology' },
        { value: '1-word', text: '1-Word' },
        { value: '2-words', text: '2-Words' },
        { value: 'expansion', text: 'Expansion' },
        { value: 'sequencing', text: 'Sequencing' },
        { value: 'gestures/signs', text: 'Gestures/Signs' },
        { value: 'labeling', text: 'Labeling' },
        { value: 'function', text: 'Function' },
        { value: 'categorizations', text: 'Categorizations' },
        { value: 'similarities', text: 'Similarities' },
        { value: 'differences', text: 'Differences' },
        { value: 'comparisons', text: 'Comparisons' },
        { value: 'multiple meanings', text: 'Multiple Meanings' },
        { value: 'attributes', text: 'Attributes' },
        { value: 'grammar structure', text: 'Grammar Structure' },
        { value: 'idioms', text: 'Idioms' },
        { value: 'vocabulary definitions', text: 'Vocabulary Definitions' },
      ]
    } else if (ddType === 33) {
      options = [
        { value: '1 word', text: '1 Word' },
        { value: '2 words', text: '2 Words' },
        { value: '3 words', text: '3 Words' },
        { value: '4+ words', text: '4+ Words' },
      ]
    } else if (ddType === 34) {
      options = [
        { value: 'non-verbal', text: 'Non-verbal' },
        { value: 'basic', text: 'Basic' },
        { value: 'simple', text: 'Simple' },
        { value: 'complex', text: 'Complex' },
      ]
    } else if (ddType === 35) {
      options = [
        { value: '1', text: '1 (8-22 minutes)' },
        { value: '2', text: '2 (23-37 minutes)' },
        { value: '3', text: '3 (38-52 minutes)' },
        { value: '4', text: '4 (53-67 minutes)' },
        { value: '5', text: '5 (68-82 minutes)' },
        { value: '6', text: '6 (82 or more minutes)' },
      ]
    } else if (ddType === 36) {
      options = [
        { value: 'alert', text: 'Alert' },
        { value: 'responsive', text: 'Responsive' },
        { value: 'cooperative', text: 'Cooperative' },
        { value: 'confused', text: 'Confused' },
        { value: 'lethargic', text: 'Lethargic' },
        { value: 'impulsive', text: 'Impulsive' },
        { value: 'uncooperative', text: 'Uncooperative' },
        { value: 'unresponsive', text: 'Unresponsive' },
      ]
    } else if (ddType === 37) {
      options = [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' },
        { value: 'Inconsistent ', text: 'inconsistent' },
      ]
    } else if (ddType === 38) {
      options = [
        { value: 'Words', text: 'Words' },
        { value: 'Sentences', text: 'Sentences' },
        { value: 'Discourse ', text: 'Discourse' },
      ]
    } else if (ddType === 39) {
      options = [
        { value: 'orientation', text: 'Orientation' },
        { value: 'awareness', text: 'Awareness' },
        { value: 'attention', text: 'Attention' },
        { value: 'memory', text: 'Memory' },
        { value: 'executive functioning', text: 'Executive Functioning' },
      ]
    } else if (ddType === 40) {
      options = [
        {
          value: '8 or more glaseses (>57oz)',
          text: '8 or more glaseses (>57oz)',
        },
        { value: '5-7 glasses (33-56 oz)', text: '5-7 glasses (33-56 oz)' },
        { value: '3-4 glasses (17-32oz)', text: '3-4 glasses (17-32oz)' },
        { value: '<2 glasses (16 oz)', text: '<2 glasses (16 oz)' },
        { value: 'NPO', text: 'NPO' },
      ]
    } else if (ddType === 41) {
      options = [
        { value: 'None', text: 'None' },
        { value: 'Extremely thick - L4', text: 'Extremely thick - L4' },
        { value: 'Moderately thick - L3', text: 'Moderately thick - L3' },
        { value: 'Mildly thick - L2', text: 'Mildly thick - L2' },
        { value: 'Slightly thick - L1', text: 'Slightly thick - L1' },
        { value: 'Thin - L0', text: 'Thin - L0' },
      ]
    } else if (ddType === 42) {
      options = [
        { value: 'None', text: 'None' },
        { value: 'Regular - L7', text: 'Regular - L7' },
        { value: 'Easy to chew - L7', text: 'Easy to chew - L7' },
        { value: 'Soft/bite sized - L6', text: 'Soft/bite sized - L6' },
        { value: 'Minced/Moist - L5', text: 'Minced/Moist - L5' },
        { value: 'Puree - L4', text: 'Puree - L4' },
        { value: 'Liquidized - L3', text: 'Liquidized - L3' },
      ]
    } else if (ddType === 43) {
      options = [
        { value: 'None', text: 'None' },
        { value: 'Whole', text: 'Whole' },
        { value: 'Whole w/ puree', text: 'Whole w/ puree' },
        { value: 'Crushed', text: 'Crushed' },
        { value: 'Tube', text: 'Tube' },
      ]
    } else if (ddType === 44) {
      options = [
        { value: 'Good', text: 'Good' },
        { value: 'Fair', text: 'Fair' },
        { value: 'Poor', text: 'Poor' },
        { value: 'Variable', text: 'Variable' },
      ]
    } else if (ddType === 45) {
      options = [
        { value: 'Alert', text: 'Alert' },
        { value: 'Responsive', text: 'Responsive' },
        { value: 'Cooperative', text: 'cooperative' },
        { value: 'Confused', text: 'Confused' },
        { value: 'Lethargic', text: 'Lethargic' },
        { value: 'Impulsive', text: 'Impulsive' },
        { value: 'Uncooperative', text: 'Uncooperative' },
        { value: 'Combative', text: 'Combative' },
        { value: 'Unresponsive', text: 'Unresponsive' },
      ]
    } else if (ddType === 46) {
      options = [
        { value: 'WNL', text: 'WNL' },
        { value: 'Missing teeth', text: 'Missing teeth' },
        { value: 'Decay', text: 'Decay' },
        { value: 'Both dentures', text: 'Both dentures' },
        { value: 'Upper dentures', text: 'Upper dentures' },
        { value: 'Lower dentures', text: 'Lower dentures' },
        { value: 'Partials', text: 'Partials' },
      ]
    } else if (ddType === 47) {
      options = [
        { value: 'Room Air', text: 'Room Air' },
        { value: 'O2 Dependent', text: 'O2 Dependent' },
      ]
    } else if (ddType === 48) {
      options = [
        { value: '90 degrees', text: '90 degrees' },
        { value: 'Tilted back', text: 'Tilted back' },
        { value: 'Tilted right', text: 'Tilted right' },
        { value: 'Tilted left', text: 'Tilted left' },
        { value: 'Tilted forward', text: 'Tilted forward' },
      ]
    } else if (ddType === 49) {
      options = [
        { value: 'Regular - L7', text: 'Regular - L7' },
        { value: 'Easy to chew - L7', text: 'Easy to chew - L7' },
        { value: 'Minced/Moist - L5', text: 'Minced/Moist - L5' },
        { value: 'Puree - L4', text: 'Puree - L4' },
        { value: 'Extremely thick - L4', text: 'Extremely thick - L4' },
        { value: 'Liquidized - L3', text: 'Liquidized - L3' },
        { value: 'Moderately thick - L3', text: 'Moderately thick - L3' },
        { value: 'Mildly thick - L2', text: 'Mildly thick - L2' },
        { value: 'Slightly thick - L1', text: 'Slightly thick - L1' },
        { value: 'Thin - L0', text: 'Thin - L0' },
        { value: 'Saliva Swallow', text: 'Saliva Swallow' },
      ]
    } else if (ddType === 50) {
      options = [
        { value: '1/2 tsp', text: '1/2 tsp' },
        { value: '1 tsp', text: '1 tsp' },
        { value: 'sip', text: 'sip' },
        { value: 'gulp', text: 'gulp' },
        { value: '5 oz', text: '5 oz' },
        { value: '10 oz', text: '10 oz' },
      ]
    } else if (ddType === 51) {
      options = [
        { value: 'Self-fed', text: 'Self-fed' },
        { value: 'Fed by others', text: 'Fed by others' },
        { value: 'Spoon/fork', text: 'Spoon/fork' },
        { value: 'Hand', text: 'Hand' },
        { value: 'Open cup', text: 'Open cup' },
        { value: 'Straw', text: 'Straw' },
        { value: 'Other', text: 'Other' },
      ]
    } else if (ddType === 52) {
      options = [
        { value: 'Desensitization', text: 'Desensitization' },
        {
          value: 'Stuttering Modifications Techniques',
          text: 'Stuttering Modifications Techniques',
        },
        {
          value: 'Fluency Shaping Techniques',
          text: 'Fluency Shaping Techniques',
        },
        { value: 'Secondary Behaviors', text: 'Secondary Behaviors' },
      ]
    } else if (ddType === 53) {
      options = [
        {
          value: 'low tech communication device',
          text: 'low tech communication device',
        },
        {
          value: 'mid tech communication device',
          text: 'mid tech communication device',
        },
        {
          value: 'high tech communication device',
          text: 'high tech communication device',
        },
      ]
    } else if (ddType === 54) {
      options = [
        {
          value: '15',
          text: '15',
        },
        {
          value: '30',
          text: '30',
        },
        {
          value: '45',
          text: '45',
        },
        {
          value: '60',
          text: '60',
        },
        {
          value: '75',
          text: '75',
        },
        {
          value: '90',
          text: '90',
        },
      ]
    }
   
    const getFirstSelectedText = () => {
      // Find the option with the matching value of the defaultValue
      const selectedOption = options.find(
        (option) => option.value === defaultValue,
      )
      // Return the text of the selected option if found, otherwise return an empty string
      return selectedOption ? selectedOption.text : ''
    }

    // Use useImperativeHandle to expose the getFirstSelectedText function
    useImperativeHandle(ref, () => ({
      getFirstSelectedText,
    }))

    const handleSelectChange = (event) => {
      //
      //const selectedValue = event.target.value;
      //alert(selectedText);
      const selectedOption = event.target.options[event.target.selectedIndex]
      // Get the text content of the selected option
      const selectedText = selectedOption.value //.textContent;

      setSelectedOption(selectedText)
      onSelect(selectedText, dropdownName)
    }
    // const handleSelectChange = (event) => {
    //   const selectedValue =event.target.value;
    //   setSelectedOption(selectedValue);
    //   onSelect(selectedValue);
    // };

    return (
      <div>
        <select
          className={className || 'form-select form-select-sm'}
          //style={selectStyle}
          value={selectedOption}
          onChange={handleSelectChange}
          style={{
            ...selectStyle,
            //borderColor: 'darkgrey', // Darker color for the border
           // borderWidth: '1px', // Optional: increase the border width for better visibility
           // borderStyle: 'solid' // Ensure the border is solid
          }}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    )
  },
)
