import React, { useState, useEffect, useContext } from 'react'
import jsPDF from 'jspdf'
import {
  Document,
  Page,
  Text,
  Image,
  View,
  PDFViewer,
  Svg,
  Polygon,
  Rect,
  StyleSheet,
  Line,
  Path,
  G,
  Circle,
} from '@react-pdf/renderer'
import Modal from 'react-modal'
import { useUser } from '../../Provider/UserContext'
import axios from 'axios'
import { Variables } from '../../Variables'
import { VisitProvider } from '../../Provider/Visitprovider'
import ReportHeader from '../ReportHeader'
import ReportFooter from '../ReportFooter'
import ProgressNTSection2 from './ProgressNTSection2'
import ProgressNTSection1 from './ProgressNTSection1'
import ContentSoapNote from './ContentSoapNote'
import { useSelector } from 'react-redux'
const customModalStyles = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    padding: '20px',
    maxWidth: '1200px',
    width: '100%',
    maxHeight: '1000px',
    height: '80%',
    overflow: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}
const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },
  image: {
    width: 400, // Adjust the width as needed
    height: 300, // Adjust the height as needed
    marginTop: 10,
  },
  body: {
    paddingTop: 1,
    paddingBottom: 40,
    // paddingHorizontal: 15,
  },
})

const apiCoreUrl = Variables.USER_API_URL
const ProgressNoteMain = ({ data, isShowButton = true, newVisitId }) => {
  const supervisorDetail = useSelector((state) => state.supervisorDetail)
  const [modalOpen, setModalOpen] = useState(false)
  const [generatedPDF, setGeneratedPDF] = useState(null)
  const [pdfFilename, setPdfFilename] = useState('ProgressNote.pdf')
  const { userDetails } = useUser()
  const [clientdata, setClientdata] = useState(null)
  const [FullName, setFullName] = useState(null)
  const VisitId = useContext(VisitProvider)
  const [diagnosis2, setDiagnosis2] = useState(null)

  const [FirstName, setFirstName] = useState('')
  const [isYes] = useState('Yes')
  const [HeOrShe, setHeOrShe] = useState('')
  const [hisHer, sethisHer] = useState('')
  const [dynamicVisitId, setDynamicVisitId] = useState(null)

  useEffect(() => {
    //alert(JSON.stringify(supervisorDetail))
    if (userDetails) {
      setClientdata(userDetails)
      setHeOrShe(userDetails.Sex === 1 ? 'He' : 'She')
      sethisHer(userDetails.Sex === 1 ? 'his' : 'her')
      setFullName(userDetails.LastName + ', ' + userDetails.FirstName)
      setFirstName(userDetails.FirstName)
      setDynamicVisitId(VisitId)
    }
  }, [userDetails])

  const fetchData = async (visitId) => {
    if (visitId) {
      try {
        //alert(VisitId);
        const response = await axios.get(
          apiCoreUrl + 'Client/GetVisitDetailForClinicalNotes?visitId=' + visitId,
        ) // Replace with the correct visitId or get it from somewhere else
        const data = response.data

        setClientdata(data)
        setHeOrShe(data.Sex === 1 ? 'He' : 'She')
        sethisHer(data.Sex === 1 ? 'his' : 'her')

        setFullName(data.LastName + ', ' + data.FirstName)
        setFirstName(data.FirstName)
      } catch (error) {
        console.log('Error fetching user details:', error)
      }
    }
  }

  useEffect(() => {
    if (!isShowButton) {
      fetchData(newVisitId)
      setDynamicVisitId(newVisitId)
      generatePDF()
    }
  }, [isShowButton, newVisitId])

  const generatePDF = () => {
    const doc = new jsPDF()

    //setGeneratedPDF(doc.output("datauristring"));
    setModalOpen(true)
  }

  const handleDownload = () => {
    // Set the desired filename for download
    setPdfFilename('ProgressNote.pdf')
  }
  const updateDiagnosis2 = (value) => {
    setDiagnosis2(value)
  }

  // //--------------Char Start--------------
  // const chartData = {
  //   // Define your chart dataset here
  //   labels: ["January", "February", "March", "April", "May", "June", "July"],
  //   datasets: [
  //     {
  //       label: "ST1",
  //       data: [10, 20, 15, 18, 25, 30, 22],
  //       borderColor: "rgb(255, 132, 22)",
  //       backgroundColor: "rgba(255, 99, 132, 0.5)",
  //     },
  //     // Add other datasets as needed
  //   ],
  // };
  const [chartImageDataUrl, setChartImageDataUrl] = useState(null)

  const handleChartImageGenerated = (imageDataUrl) => {
    //alert(imageDataUrl);
    setChartImageDataUrl(imageDataUrl)
  }
  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 50px)',
  }

  const chartImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1, // Position the chart image behind the PDF viewer
  }
  //--------------Char End--------------
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  return (
    <div>
      {isShowButton && (
        <button
          className='btn btn-primary rounded-pill'
          style={{ width: '150px', marginRight: '10px' }}
          onClick={generatePDF}
        >
          <b>Preview</b>
        </button>
      )}
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customModalStyles}
      >
        {/* <App></App> 
        <HTMLToPDF/> */}

        <PDFViewer style={{ width: '100%', height: 'calc(100% - 50px)' }}>
          <Document title={pdfFilename}>
            {/* <Page size="A4">
             
            </Page> */}
            <Page size='A4' style={styles.body}>
              <ReportHeader
                isShowSubTitle={false}
                clientdata={clientdata}
                headerText={'Speech & Language Progress Report'}
              ></ReportHeader>

              <ProgressNTSection1
                clientdata={clientdata}
                FirstName={capitalizeFirstLetter(FirstName)}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={VisitId}
                FullName={FullName}
                mainData={data}
                chartImageUrl={chartImageDataUrl}
              ></ProgressNTSection1>

              <ReportFooter></ReportFooter>
            </Page>
            <Page size='A4'>
              <ReportHeader
                isShowSubTitle={false}
                clientdata={clientdata}
                headerText={'Speech & Language Progress Report'}
              ></ReportHeader>
              <ProgressNTSection2
                clientdata={clientdata}
                FirstName={capitalizeFirstLetter(FirstName)}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={VisitId}
                FullName={FullName}
                mainData={data}
                licenceData={supervisorDetail}
              ></ProgressNTSection2>
              <ReportFooter></ReportFooter>
            </Page>{' '}
            <Page size='A4'>
              <ReportHeader
                isShowSubTitle={false}
                clientdata={clientdata}
                headerText={'Speech & Language Progress Report'}
              ></ReportHeader>
              <ContentSoapNote
                clientdata={clientdata}
                FirstName={capitalizeFirstLetter(FirstName)}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={VisitId}
                FullName={FullName}
                pnData={data}
              ></ContentSoapNote>
              <ReportFooter></ReportFooter>
            </Page>
          </Document>
        </PDFViewer>

        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            padding: '10px',
            textAlign: 'center',
            backgroundColor: '#f0f0f0',
          }}
        >
          {/* <a
            href={generatedPDF}
            download={pdfFilename}
            onClick={handleDownload}
          >
            Download PDF
          </a> */}
        </div>
      </Modal>
    </div>
  )
}

export default ProgressNoteMain
