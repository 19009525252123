import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
import formatDate from '../Utility/Utility'

const HeaderTextContent = ({ FullName, clientdata, PocData, Diagnosis2 }) => {
  return (
    // <View style={reportStyle.pageSpace}>
    <View style={reportStyle.pageSpace} fixed>
      <View style={reportStyle.gridContainer}>
        <View style={reportStyle.gridRow}>
          <View style={reportStyle.gridCell}>
            <Text style={{ fontWeight: 'bold' }}>{FullName}</Text>
          </View>
          <View style={reportStyle.gridCell}>
            <Text>
              Referred by:{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {clientdata && clientdata.ReferringPhysician.toUpperCase()}
              </Text>
            </Text>
          </View>
          <View style={reportStyle.gridCell}>
            <Text>
              Therapist:{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {clientdata && clientdata.TherapistName.toUpperCase()}
              </Text>
            </Text>
          </View>
        </View>
        <View style={reportStyle.gridRow}>
          <View style={reportStyle.gridCell}>
            <Text>
              DOB:{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {clientdata && formatDate(clientdata.DOB, false, true)}{' '}
              </Text>
              {'   '} Age:{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {clientdata && clientdata.Age}
              </Text>
            </Text>
          </View>
          <View style={reportStyle.gridCell}>
            <Text>
              Primary:{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {clientdata && clientdata.Diagnosisid1}
              </Text>
            </Text>
          </View>
          <View style={reportStyle.gridCell}>
            <Text>
              Treatment:{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {PocData && PocData.PrimaryDiagnosis}
              </Text>
            </Text>
          </View>
        </View>
        <View style={reportStyle.gridRow}>
          <View style={reportStyle.gridCell}>
            <Text>
              Gender:{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {clientdata && clientdata.Gender}
              </Text>
            </Text>
          </View>
          <View style={reportStyle.gridCell}>
            <Text>Secondary: {Diagnosis2}</Text>
          </View>
          <View style={reportStyle.gridCell}>
            <Text>
              Billing:{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {PocData && PocData.CPTCode}
              </Text>
            </Text>
          </View>
        </View>
      </View>
    </View>
    // </View>
  )
}

export default HeaderTextContent
