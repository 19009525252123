import React, { useState, useEffect, useContext } from 'react'
import { View, Text, Font, Path, Svg } from '@react-pdf/renderer'
import { PNNoteStyle, SoapNoteStyle, reportStyle } from '../../CSS/reportStyle'
import formatDate, { formatTime } from '../../Utility/Utility'
import { GoalService } from '../../GoalComponenet/GoalService'
import LineChart from '../ChartExample/LineChart'
import axios from 'axios'
import { Variables } from '../../Variables'
import HeaderText from './HeaderText'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
const dataGraph = [
  [10, 30, 10, 30, 10, 30, 10],
  [20, 40, 60, 80, 60, 40, 20],
  [40, 60, 80, 100, 80, 60, 40],
  // [40, 50, 55, 60, 70,80,80],
  // [40, 30, 20, 10, 5],
  // [70, 100, 70, 100, 70],
]
const DSContentSection1 = ({
  FirstName,
  HeOrShe,
  hisHer,
  VisitId,
  FullName,
  clientdata,
  mainData,
}) => {
  const [isYes] = useState('Yes')
  const [goalData, setGoalData] = useState([])
  const [graphData, setGraphData] = useState([])

  const goalService = new GoalService()

  useEffect(() => {
    //alert(JSON.stringify(clientdata));
    const fetchData = () => {
      goalService
        .getPNGoals(clientdata.Clientid, VisitId)
        .then((dataFromResponse) => {
        // alert(JSON.stringify(dataFromResponse));
          setGoalData(dataFromResponse)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    }
    //alert(JSON.stringify(VisitId));
    if (VisitId && clientdata) {
      fetchData()
    }

    // const fetchGraphData = () => {
    //   //alert("fetchGraphData");
    //   goalService
    //     .getProgressReportData(clientdata.Clientid, 'DS')
    //     .then((dataFromResponse) => {
    //       //alert("GraphData:"+JSON.stringify(dataFromResponse));
    //       setGraphData(dataFromResponse)
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching data:', error)
    //     })
    // }
    // fetchGraphData()
  }, [clientdata, VisitId])
  
  const LTgoalCounters = {}
  const STgoalCounters = {}

  const getGoalCounter = (GoalCode) => {
    //alert(JSON.stringify(LTgoalCounters));
    if (GoalCode === 'LTG') {
      // LT goals: Use the index + 1
      const value = (LTgoalCounters[GoalCode] || 0) + 1
      LTgoalCounters[GoalCode] = value
      return value
    } else {
      // Other goals: Increment the counter for the specific goal type
      const value = (STgoalCounters[GoalCode] || 0) + 1
      STgoalCounters[GoalCode] = value
      return value
    }
  }


  const currentDate = new Date()
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`
  return (
    <>
      <View style={reportStyle.pageSpace}>
        {/*  <View style={SoapNoteStyle.headerTextContainerText}>
          <Text style={SoapNoteStyle.headerText}>
            Date of Follow Up:{' '}
            {clientdata &&
              formatDate(clientdata.Scheduleddatefrom, false, true)}
            {'   '}
            <Text style={SoapNoteStyle.headerTextSpace}> </Text>
            Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
            {'   '}
            <Text style={SoapNoteStyle.headerTextSpace}> </Text>
            End:{clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
            <Text style={SoapNoteStyle.headerTextSpace}> </Text>
            Visit: {clientdata && clientdata.VisitStatus}
          </Text>
        </View> */}

        <HeaderText
          clientdata={clientdata}
          VisitId={VisitId}
          FullName={FullName}
        ></HeaderText>

        <View style={SoapNoteStyle.TextHeader}>
          {' '}
          <Text style={SoapNoteStyle.TextHeaderText}>
            {FirstName}'s Therapy Goals
          </Text>
        </View>

        <View style={PNNoteStyle.paragraph}>
        {goalData &&
            goalData.map((row, index) => (
              <View id={row.SI_GoalsId} style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }} id={index}>
                  {row.SI_GoalsId}
                  <Text
                    style={{
                      fontSize: '8px',
                      width:
                        row.GoalType !== 'LT' ? '100%' : 'calc(100% - 10px)',
                      paddingLeft: row.GoalType !== 'LT' ? '10px' : '0',
                    }}
                  >
                    {row.GoalType === 'LT' ? (
                      <>
                        <Text style={{ fontSize: '8px' }}>
                          LTG - {getGoalCounter(row.GoalCode)}: Within{' '}
                          {row.Duration} {row.Units}, {FirstName}{' '}
                          {row.GoalDescription} {row.Accuracy}
                        </Text>
                        .
                      </>
                    ) : row.GoalType === 'Articulation' ? (
                      <>
                        <Text style={{ fontSize: '8px' }}>
                          STG - {LTgoalCounters.LTG}.
                          {getGoalCounter(row.FCMType)}: Within {row.Duration}{' '}
                          {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        the <Text style={{ fontSize: '8px' }}>{row.Sound}</Text>{' '}
                        sound(s) at the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.AtLevel}</Text>{' '}
                        level with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    ) : row.GoalType === 'Phonology' ? (
                      <>
                        STG - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}
                        : Within{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.Duration} {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    ) : row.GoalType === 'General' ? (
                      <>
                        STG - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}
                        : Within{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.Duration} {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    ) : (
                      <>
                        {' '}
                        <>
                          STG - {LTgoalCounters.LTG}.
                          {getGoalCounter(row.FCMType)}: Within{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.Duration} {row.Units}
                          </Text>
                          , given{' '}
                          <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                          {FirstName} will{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.GoalDescription}
                          </Text>{' '}
                          with{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.Accuracy}
                          </Text>{' '}
                          accuracy in{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.InSessions}
                          </Text>{' '}
                          sessions.
                        </>
                      </>
                    )}
                  </Text>
                </View>
              </View>
            ))}
          {/* {goalData.map((row, index) => (
            <View key={row.SI_GoalsId} style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1, fontSize: '8px' }}>
                <Text
                  style={{
                    width: row.GoalType !== 'LT' ? '100%' : 'calc(100% - 10px)',
                    paddingLeft: row.GoalType !== 'LT' ? '10px' : '0',
                  }}
                >
                  {row.GoalType === 'LT' ? (
                    <>
                      <Text>
                        {FirstName} was able to achieve {hisHer} long-term
                        therapy goal for Speach Inteligibility and{' '}
                        {row.GoalDescription} with {row.Accuracy} accuracy
                        within {row.Duration} {row.Units}.
                      </Text>
                    </>
                  ) : row.GoalType === 'Articulation' ? (
                    <>
                      {HeOrShe} {row.GoalMET ? 'MET' : 'did not MET'} {hisHer}{' '}
                      short-term goal to, given {row.Given},{' '}
                      {row.GoalDescription} the {row.Sound} sound(s) at the{' '}
                      {row.AtLevel} with {row.Accuracy} accuracy in{' '}
                      {row.InSessions} sessions within {row.Duration}{' '}
                      {row.Units}{' '}
                      {row.GoalMET
                        ? 'on ' + formatDate(row.GoalMetdate, false, true)
                        : ''}
                      .
                    </>
                  ) : row.GoalType === 'Phonology' ? (
                    <>
                      {HeOrShe} {row.GoalMET ? 'MET' : 'did not MET'} {hisHer}{' '}
                      short-term goal to, given {row.Given},{' '}
                      {row.GoalDescription} at the {row.AtLevel} with{' '}
                      {row.Accuracy} accuracy in {row.InSessions} sessions
                      within {row.Duration} {row.Units}{' '}
                      {row.GoalMET
                        ? 'on ' + formatDate(row.GoalMetdate, false, true)
                        : ''}
                      .
                    </>
                  ) : row.GoalType === 'General' ? (
                    <>
                      {HeOrShe} {row.GoalMET ? 'MET' : 'did not MET'} {hisHer}{' '}
                      short-term goal to, given {row.Given},{' '}
                      {row.GoalDescription} with {row.Accuracy} accuracy in{' '}
                      {row.InSessions} sessions within {row.Duration}{' '}
                      {row.Units}{' '}
                      {row.GoalMET
                        ? 'on ' + formatDate(row.GoalMetdate, false, true)
                        : ''}
                      .
                    </>
                  ) : (
                    <>No content found.</>
                  )}
                </Text>
              </View>
            </View>
          ))} */}
        </View>
        {/* <View style={SoapNoteStyle.TextHeader}>
          {' '}
          <Text style={SoapNoteStyle.TextHeaderText}>
            Progress on Goals During Therapy
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text style={{ fontSize: '8px' }}>
            The pace at which {HeOrShe} achieve {hisHer} goals is highlighted in
            the graph below:
          </Text>
        </View> */}

        <View style={SoapNoteStyle.TextHeader}>
          {' '}
          <Text style={SoapNoteStyle.TextHeaderText}>
            Final Level of Function
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text style={{ fontSize: '8px' }}>
            {mainData && mainData.ProgressReport && (
              <>
                {FirstName}'s final level of function on {hisHer} unmet goals
                was: {mainData.ProgressReport}.
              </>
            )}
          </Text>
        </View>
        {/* <View style={{ alignContent: "center",marginTop:"30px" }}>
        <LineChart dataGraph={graphData}></LineChart>
      </View> */}
      </View>
    </>
  )
}

export default DSContentSection1
