import React, { useState, useEffect, useContext, useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { TextareaComponent } from '../../Controls/TextareaComponent '
import {
  VisitProvider,
  SignLockProvider,
  CreatedByProvider,
} from '../../Provider/Visitprovider'
import { useUser } from '../../Provider/UserContext'
import { CHAGeneralService } from './CHAGeneralService'
import { SelectYesNo } from '../../Controls/DDLYesNo'
import { DDList } from '../../Controls/DDList'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { TextBox } from '../../Controls/TextareaComponent '
import { MultiSelectDropdown } from '../../Controls/MultiSelectDropdown'
import Loading from '../../Controls/Loading'
import Form from 'react-bootstrap/Form'

const underlineStyle = {
  textDecoration: 'underline',
}
export const CHAGeneralUI = React.forwardRef(({ onDataFetched }, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const { userFirstName, userDetails } = useUser()
  const VisitId = useContext(VisitProvider)
  const CreatedBy = useContext(CreatedByProvider)
  const IsSignLock = useContext(SignLockProvider)
  var generalService = new CHAGeneralService()
  const [formData, setFormData] = useState(new CHAGeneralService())
  const [refreshForm, setRefreshForm] = useState(false)
  const [familyDefictDescriptionError, setfamilyDefictDescriptionError] =
    useState(false)
  const [HasDifficultExpressionError, setHasDifficultExpressionError] =
    useState(false)
  const [HasDifficultFindingError, setHasDifficultFindingError] =
    useState(false)
  const [HasDifficultUnderstandError, setHasDifficultUnderstandError] =
    useState(false)
  const [DifficultAreaError, setDifficultAreaError] = useState(false)
  const [DifficultDailyActivityError, setDifficultDailyActivityError] =
    useState(false)
  const [HasSpeechTherapyPastError, setHasSpeechTherapyPastError] =
    useState(false)
  const [
    IsReceivingOutsideAgencyServiceError,
    setIsReceivingOutsideAgencyServiceError,
  ] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    if (userDetails) {
      //const initialFormData = { ...formData };
      formData.VisitId = VisitId
      formData.CreatedBy = CreatedBy
      formData.UpdatedBy = CreatedBy
      //alert(JSON.stringify(formData));
      //setFormData(initialFormData);
    }
  }, [formData, userDetails, VisitId])

  React.useImperativeHandle(ref, () => ({
    SaveButtonClick,
  }))

  const fetchData = (visitId) => {
    //alert(refreshForm)
    //setFormData(new CHAGeneralService())
    generalService
      .getCHAData(visitId)
      .then((dataFromResponse) => {
        if (dataFromResponse) {
          setFormData(dataFromResponse)
        } else {
          //alert('aa')
          // alert('generalService:' + JSON.stringify(generalService))
          setFormData(generalService)
        }
      })
      .catch((error) => {
        console.error('Error in CHA general:', error)
      })
      .finally(() => {
        setIsLoading(false)
        onDataFetched()
      })
  }
  useEffect(() => {
    //alert(VisitId)
    if (VisitId !== null) {
      fetchData(VisitId)
    }
  }, [VisitId, refreshForm])

  const handleTextValueChange = (controlName, value) => {
    const valueControl = controlName
    const updatedFormData = {
      ...formData,
      [valueControl]: value,
    }
    setFormData(updatedFormData)
    //setIsDirty(true)
  }

  const ValidationObject = () => {
    let isValid = true
    if (
      formData.HasFamilyDefict === 'Yes' &&
      (formData.FamilyDefictDescription === null ||
        formData.FamilyDefictDescription.trim() === '')
    ) {
      setfamilyDefictDescriptionError(true)
      isValid = false
    }

    if (
      formData.HasDifficultExpression === 'Yes' &&
      (formData.DifficultExpressionDescription === null ||
        formData.DifficultExpressionDescription.trim() === '')
    ) {
      setHasDifficultExpressionError(true)
      isValid = false
    }

    if (
      formData.HasDifficultFinding === 'Yes' &&
      (formData.DifficultFindingDescription === null ||
        formData.DifficultFindingDescription.trim() === '')
    ) {
      setHasDifficultFindingError(true)
      isValid = false
    }

    if (
      formData.HasDifficultUnderstand === 'Yes' &&
      (formData.DifficultUnderstandDescription === null ||
        formData.DifficultUnderstandDescription.trim() === '')
    ) {
      setHasDifficultUnderstandError(true)
      isValid = false
    }

    if (formData.DifficultArea && formData.DifficultArea.length > 0) {
      if (
        formData.DifficultAreaDescription === null ||
        formData.DifficultAreaDescription.trim() === ''
      ) {
        setDifficultAreaError(true)
        isValid = false
      }
    }

    if (
      formData.DifficultDailyActivity &&
      formData.DifficultDailyActivity.length > 0
    ) {
      if (
        formData.DifficultDailyActivityDescription === null ||
        formData.DifficultDailyActivityDescription.trim() === ''
      ) {
        setDifficultDailyActivityError(true)
        isValid = false
      }
    }

    if (
      formData.HasSpeechTherapyPast === 'Yes' &&
      (formData.HasSpeechTherapyPastDescription === null ||
        formData.HasSpeechTherapyPastDescription.trim() === '')
    ) {
      setHasSpeechTherapyPastError(true)
      isValid = false
    }

    if (
      formData.IsReceivingOutsideAgencyService === 'Yes' &&
      (formData.ReceivingOutsideAgencyWhere === null ||
        formData.ReceivingOutsideAgencyWhere.trim() === '')
    ) {
      setIsReceivingOutsideAgencyServiceError(true)
      isValid = false
    }

    //alert(isValid);
    return isValid
  }

  const SaveButtonClick = (showMsg = true) => {
    // const isValid = ValidationObject();
    // //alert(isValid);
    // if (!isValid) {
    //   toast.error("Kindly fill the all required field(s).");
    //   return;
    // }
    if (IsSignLock || !isDirty) {
      return
    }
    setRefreshForm(false)
    formData.CreatedBy = CreatedBy
    formData.UpdatedBy = CreatedBy

    if (
      Array.isArray(formData.AdditionalLanguages) &&
      formData.AdditionalLanguages.length > 0
    ) {
      formData.AdditionalLanguages = formData.AdditionalLanguages.join(',')
    } else {
      formData.AdditionalLanguages = null
    }

    if (
      Array.isArray(formData.DifficultArea) &&
      formData.DifficultArea.length > 0
    ) {
      formData.DifficultArea = formData.DifficultArea.join(',')
    } else {
      formData.DifficultArea = null
    }

    if (
      Array.isArray(formData.DifficultDailyActivity) &&
      formData.DifficultDailyActivity.length > 0
    ) {
      formData.DifficultDailyActivity =
        formData.DifficultDailyActivity.join(',')
    } else {
      formData.DifficultDailyActivity = null
    }

    if (
      Array.isArray(formData.OtherSpecialistsWorkedWith) &&
      formData.OtherSpecialistsWorkedWith.length > 0
    ) {
      formData.OtherSpecialistsWorkedWith =
        formData.OtherSpecialistsWorkedWith.join(',')
    } else {
      formData.OtherSpecialistsWorkedWith = null
    }

    // alert(JSON.stringify(formData))
    //return;
    generalService
      .insertCHAData(formData)
      .then((response) => {
        setFormData(formData)
        //formData.ProgressDischargeId = response.Result;
        if (showMsg) {
          toast.success(response.MessageDetail)
        }
        //alert(JSON.stringify(formData))
        //setFormData(formData)
        setRefreshForm(true)
      })
      .catch((error) => {
        toast.error('Error in API call: ' + error.message)
      })
      .finally(() => {
        setIsDirty(false)
        // setRefreshForm(false)
      })
  }

  const ddlChange = (controlName, selectedValue) => {
    const updatedFormData = {
      ...formData,
      [controlName]: selectedValue,
    }
    setFormData(updatedFormData)
    setIsDirty(true)
  }

  const handleTextBoxValueChange = (controlName, value) => {
    const valueControl = controlName
    const updatedFormData = {
      ...formData,
      [valueControl]: value,
    }
    setFormData(updatedFormData)
    //setIsDirty(true)
  }
  const handleMultiSelectChange = (controlName, selectedValues) => {
    const selectedText = selectedValues.map((option) => option.label)
    setFormData((prevData) => ({ ...prevData, [controlName]: selectedText }))
    setIsDirty(true)
  }

  useEffect(() => {
    //alert("form data");
    ValidationObject()
  }, [formData])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Container fluid>
      <Row className='mt-2'>
        <Col md={6}>
          <Row>
            <Col style={underlineStyle} md={12}>
              <b>Personal Profile</b>
              {/* <MonthYearPicker defaultDate={'1/2025'} onDateChange={handleDateChange} /> */}
            </Col>
          </Row>
          <Row className='mt-2' md={12}>
            <Col md={8}>
              <b> {userFirstName}'s</b> dominant language?
            </Col>
            <Col>
              <DDList
                dropdownName='DominantLanguage'
                defaultValue={formData.DominantLanguage}
                onSelect={(selectedValue) =>
                  ddlChange('DominantLanguage', selectedValue)
                }
                ddType={18}
              />
            </Col>

            {formData.DominantLanguage === 'Other' && (
              <Col md={2}>
                {' '}
                <TextBox
                  IsChangeTracking={setIsDirty}
                  maxLength={20}
                  controlid='DominantLanguageOther'
                  defaultValue={formData.DominantLanguageOther}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('DominantLanguageOther', value)
                  }
                  placeholder={'other...'}
                />{' '}
              </Col>
            )}
          </Row>
          <Row className='mt-2'>
            <Col md={6}>Additional language(s) spoken at home?</Col>
            <Col md={6}>
              <MultiSelectDropdown
                closeMenuOnSelect={false}
                optionType={4}
                isMultiSelect={true}
                setDefaultValues={formData.AdditionalLanguages}
                selectedOptions={formData.AdditionalLanguages}
                onChange={(values) =>
                  handleMultiSelectChange('AdditionalLanguages', values)
                }
              ></MultiSelectDropdown>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col md={6}>How does your speech deficit affect your life?</Col>
            <Col md={6}>Describe your current living situation?</Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                cols={40}
                rows={2}
                maxLength={5000}
                controlid='DeficitEffect'
                defaultValue={formData.DeficitEffect}
                onTextChange={(value) =>
                  handleTextValueChange('DeficitEffect', value)
                }
                placeholder={'Please describe...'}
              />
            </Col>
            <Col md={6}>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                cols={40}
                rows={2}
                maxLength={5000}
                controlid='CurrentLivingDescription'
                defaultValue={formData.CurrentLivingDescription}
                onTextChange={(value) =>
                  handleTextValueChange('CurrentLivingDescription', value)
                }
                placeholder={'Please describe...'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              {' '}
              Is there a family history of speech and language deficits or
              learning difficulties?
            </Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.HasFamilyDefict}
                onSelect={(selectedValue) =>
                  ddlChange('HasFamilyDefict', selectedValue)
                }
              />
            </Col>
          </Row>
          {formData.HasFamilyDefict === 'Yes' && (
            <>
              <Row className='mt-1'>
                <Col md={12}>
                  <TextareaComponent
                    IsChangeTracking={setIsDirty}
                    showError={familyDefictDescriptionError}
                    controlid='FamilyDefictDescription'
                    cols={40}
                    rows={2}
                    maxLength={5000}
                    defaultValue={formData.FamilyDefictDescription}
                    onTextChange={(value) =>
                      handleTextValueChange('FamilyDefictDescription', value)
                    }
                    placeholder={'Please describe...'}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row className='mt-1'>
            <Col md={8}>
              Do you have difficulty expressing your general wants/needs?
            </Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.HasDifficultExpression}
                onSelect={(selectedValue) =>
                  ddlChange('HasDifficultExpression', selectedValue)
                }
              />
            </Col>
          </Row>{' '}
          {formData.HasDifficultExpression === 'Yes' && (
            <>
              <Row className='mt-1'>
                <Col md={12}>
                  <TextareaComponent
                    IsChangeTracking={setIsDirty}
                    showError={HasDifficultExpressionError}
                    cols={40}
                    rows={2}
                    maxLength={5000}
                    controlid='DifficultExpressionDescription'
                    defaultValue={formData.DifficultExpressionDescription}
                    onTextChange={(value) =>
                      handleTextValueChange(
                        'DifficultExpressionDescription',
                        value,
                      )
                    }
                    placeholder={'Please describe...'}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row className='mt-1'>
            <Col md={7}>
              Do you have difficulty finding the right words when speaking?
            </Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.HasDifficultFinding}
                onSelect={(selectedValue) =>
                  ddlChange('HasDifficultFinding', selectedValue)
                }
              />
            </Col>
          </Row>
          {formData.HasDifficultFinding === 'Yes' && (
            <>
              <Row className='mt-1'>
                <Col md={12}>
                  <TextareaComponent
                    IsChangeTracking={setIsDirty}
                    showError={HasDifficultFindingError}
                    cols={40}
                    rows={2}
                    maxLength={5000}
                    controlid='DifficultFindingDescription'
                    defaultValue={formData.DifficultFindingDescription}
                    onTextChange={(value) =>
                      handleTextValueChange(
                        'DifficultFindingDescription',
                        value,
                      )
                    }
                    placeholder={'Please describe...'}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row className='mt-1'>
            <Col md={6}>Do others find it difficult to understand you?</Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.HasDifficultUnderstand}
                onSelect={(selectedValue) =>
                  ddlChange('HasDifficultUnderstand', selectedValue)
                }
              />
            </Col>
          </Row>
          {formData.HasDifficultUnderstand === 'Yes' && (
            <>
              <Row className='mt-1'>
                <Col md={12}>
                  {' '}
                  <TextareaComponent
                    IsChangeTracking={setIsDirty}
                    showError={HasDifficultUnderstandError}
                    cols={40}
                    rows={2}
                    maxLength={5000}
                    controlid='DifficultUnderstandDescription'
                    defaultValue={formData.DifficultUnderstandDescription}
                    onTextChange={(value) =>
                      handleTextValueChange(
                        'DifficultUnderstandDescription',
                        value,
                      )
                    }
                    placeholder={'Please describe...'}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col md={12}>
              Do you have difficulty in any of the following areas?
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <MultiSelectDropdown
                closeMenuOnSelect={false}
                selectedOptions={formData.DifficultArea}
                setDefaultValues={formData.DifficultArea}
                onChange={(values) =>
                  handleMultiSelectChange('DifficultArea', values)
                }
                optionType={8}
                isMultiSelect={true}
              ></MultiSelectDropdown>
            </Col>
          </Row>
          {formData.DifficultArea && formData.DifficultArea.length > 0 && (
            <>
              <Row className='mt-1'>
                <Col md={12}>
                  {' '}
                  <TextareaComponent
                    IsChangeTracking={setIsDirty}
                    showError={DifficultAreaError}
                    cols={40}
                    rows={2}
                    maxLength={5000}
                    controlid='DifficultAreaDescription'
                    defaultValue={formData.DifficultAreaDescription}
                    onTextChange={(value) =>
                      handleTextValueChange('DifficultAreaDescription', value)
                    }
                    placeholder={'Please describe...'}
                  />
                </Col>
              </Row>{' '}
            </>
          )}
          <Row>
            <Col md={12}>
              Do you have difficulty with any of the following daily activities?
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <MultiSelectDropdown
                closeMenuOnSelect={false}
                selectedOptions={formData.DifficultDailyActivity}
                setDefaultValues={formData.DifficultDailyActivity}
                onChange={(values) =>
                  handleMultiSelectChange('DifficultDailyActivity', values)
                }
                optionType={9}
                isMultiSelect={true}
              ></MultiSelectDropdown>
            </Col>
          </Row>
          {formData.DifficultDailyActivity &&
            formData.DifficultDailyActivity.length > 0 && (
              <>
                <Row className='mt-1'>
                  <Col md={12}>
                    <TextareaComponent
                      IsChangeTracking={setIsDirty}
                      showError={DifficultDailyActivityError}
                      cols={40}
                      rows={2}
                      maxLength={5000}
                      controlid='DifficultDailyActivityDescription'
                      defaultValue={formData.DifficultDailyActivityDescription}
                      onTextChange={(value) =>
                        handleTextValueChange(
                          'DifficultDailyActivityDescription',
                          value,
                        )
                      }
                      placeholder={'Please describe...'}
                    />
                  </Col>
                </Row>
              </>
            )}
        </Col>
        <Col md={6}>
          <Row>
            <Col style={underlineStyle} md={12}>
              <b>Therapy Profile</b>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col md={6}>Why are you seeking speech therapy?</Col>
            <Col md={6}>What is your expected outcome?</Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                cols={40}
                rows={2}
                maxLength={5000}
                defaultValue={formData.SeekingSpeachTherapy}
                controlid='SeekingSpeachTherapy'
                onTextChange={(value) =>
                  handleTextValueChange('SeekingSpeachTherapy', value)
                }
                placeholder={'Please describe...'}
              />
            </Col>
            <Col md={6}>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                cols={40}
                rows={2}
                maxLength={5000}
                controlid='SeekingSpeachTherapyOutcome'
                defaultValue={formData.SeekingSpeachTherapyOutcome}
                onTextChange={(value) =>
                  handleTextValueChange('SeekingSpeachTherapyOutcome', value)
                }
                placeholder={'Please describe...'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>Have you had speech therapy in the past?</Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.HasSpeechTherapyPast}
                onSelect={(selectedValue) =>
                  ddlChange('HasSpeechTherapyPast', selectedValue)
                }
              />
            </Col>
            {formData.HasSpeechTherapyPast === 'Yes' && (
              <Col md={6}>
                <TextBox
                  IsChangeTracking={setIsDirty}
                  showError={HasSpeechTherapyPastError}
                  controlid='HasSpeechTherapyPastDescription'
                  maxLength={50}
                  // showError={hasPastTherapyError}
                  defaultValue={formData.HasSpeechTherapyPastDescription}
                  onTextChange={(value) =>
                    handleTextBoxValueChange(
                      'HasSpeechTherapyPastDescription',
                      value,
                    )
                  }
                  placeholder={'(Required) where...'}
                />
              </Col>
            )}
          </Row>

          {formData.HasSpeechTherapyPast === 'Yes' && (
            <>
              <Row>
                <Col md={4}>Have you received a report?</Col>
                <Col md={2}>
                  <SelectYesNo
                    defaultValue={formData.HasReceivedReport}
                    onSelect={(selectedValue) =>
                      ddlChange('HasReceivedReport', selectedValue)
                    }
                  />
                </Col>
              </Row>
              <Row className='mt-1'>
                <Col md={5}>
                  Are you currently receiving speech therapy services through an
                  outside agency?
                </Col>
                <Col md={2}>
                  <SelectYesNo
                    defaultValue={formData.IsReceivingOutsideAgencyService}
                    onSelect={(selectedValue) =>
                      ddlChange(
                        'IsReceivingOutsideAgencyService',
                        selectedValue,
                      )
                    }
                  />
                </Col>
                {formData.IsReceivingOutsideAgencyService === 'Yes' && (
                  <Col md={5}>
                    <TextBox
                      IsChangeTracking={setIsDirty}
                      maxLength={50}
                      showError={IsReceivingOutsideAgencyServiceError}
                      defaultValue={formData.ReceivingOutsideAgencyWhere}
                      controlid='ReceivingOutsideAgencyWhere'
                      onTextChange={(value) =>
                        handleTextBoxValueChange(
                          'ReceivingOutsideAgencyWhere',
                          value,
                        )
                      }
                      placeholder={'where...'}
                    />{' '}
                  </Col>
                )}
              </Row>
              <Row className='mt-1'>
                <Col md={6}></Col>
              </Row>
            </>
          )}
          <Row className='mt-1'>
            <Col md={6}>Have you worked with any other specialists?</Col>
            <Col md={6}>
              <MultiSelectDropdown
                closeMenuOnSelect={false}
                selectedOptions={formData.OtherSpecialistsWorkedWith}
                setDefaultValues={formData.OtherSpecialistsWorkedWith}
                onChange={(values) =>
                  handleMultiSelectChange('OtherSpecialistsWorkedWith', values)
                }
                optionType={7}
                isMultiSelect={true}
              ></MultiSelectDropdown>
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md={4}>Have you received a report?</Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.IsReceivedReportOther}
                onSelect={(selectedValue) =>
                  ddlChange('IsReceivedReportOther', selectedValue)
                }
              />
            </Col>
            <Col md={4}>Do you have a swallow study or ENT report?</Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.IsEntReport}
                onSelect={(selectedValue) =>
                  ddlChange('IsEntReport', selectedValue)
                }
              />
            </Col>
          </Row>
          <Row className='mt-1'></Row>
          <Row>
            <Col style={underlineStyle} md={12}>
              <b>Social / Education</b>
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md={4}>Highest level of education?</Col>
            <Col md={2} className='text-left'>
              <DDList
                dropdownName='HighestEducation'
                defaultValue={formData.HighestEducation}
                onSelect={(selectedValue) =>
                  ddlChange('HighestEducation', selectedValue)
                }
                ddType={29}
              />
            </Col>
            {formData.HighestEducation === 'Other' && (
              <>
                <Col md={6}>
                  <TextBox
                    IsChangeTracking={setIsDirty}
                    maxLength={50}
                    controlid='HighestEducationOther'
                    defaultValue={formData.HighestEducationOther}
                    onTextChange={(value) =>
                      handleTextBoxValueChange('HighestEducationOther', value)
                    }
                    placeholder={'other...'}
                  />
                </Col>
              </>
            )}
          </Row>
          <Row className='mt-1'>
            <Col md={3}>Currently working? </Col>
            <Col md={2} className='text-left'>
              {' '}
              <SelectYesNo
                defaultValue={formData.IsCurrentlyWorking}
                onSelect={(selectedValue) =>
                  ddlChange('IsCurrentlyWorking', selectedValue)
                }
              />
            </Col>
            <Col md={2} className='text-start'>
              Profession?
            </Col>
            <Col md={5} className='text-left'>
              <TextBox
                IsChangeTracking={setIsDirty}
                maxLength={200}
                // showError={hasPastTherapyError}
                controlid='Profession'
                defaultValue={formData.Profession}
                onTextChange={(value) =>
                  handleTextBoxValueChange('Profession', value)
                }
                placeholder={'other...'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={2}>Hobbies?</Col>
            <Col md={10} className='text-left'>
              {/* <Form.Control
                size='sm'
                type='text'
                controlid='Hobbies11'
                value={formData.Hobbies}
                placeholder='Small text'
                onChange={(e) =>
                  handleTextBoxValueChange('Hobbies', e.target.value)
                }
              /> */}
              <TextBox
                IsChangeTracking={setIsDirty}
                maxLength={200}
                // showError={hasPastTherapyError}
                controlid='Hobbies'
                defaultValue={formData.Hobbies}
                onTextChange={(value) =>
                  handleTextBoxValueChange('Hobbies', value)
                }
                placeholder={'Hobbies...'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <AlertifyMessage /> */}
      <Row className='mt-3'>
        <Col md={12} className='text-right'>
          <Button
            type='button'
            onClick={SaveButtonClick}
            disabled={IsSignLock || !isDirty}
            style={{ width: '150px', marginRight: '20px' }}
            className='btn btn-primary rounded-pill'
          >
            <b> Save</b>
          </Button>
        </Col>
      </Row>
    </Container>
  )
})
