import React, { Component } from 'react'
import axios from 'axios'
import { Variables } from '../../Variables'
import { AssessSumService } from '../Component/AssessSumService'
import { NomsService } from '../Component/NomsService'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
const nomsService = new NomsService()

export class FeedingService {
  constructor() {
    this.FeedingId = 0
    this.VisitId = null
    this.History = null
    this.HistoryDescription = null
    this.FoodAllergies = null
    this.CurrentDiet = null
    this.MealTimeObservation = null
    this.FoodPreferences = null
    this.FoodDislikes = null
    this.PreferredTemperature = null
    this.PreferredTexture = null
    this.PreferredTaste = null
    this.FoodPresentation = null
    this.ResponseFood = null
    this.CaregiverConcerns = null
    this.AdditionalFinding = null
    this.OverallRating = 'Within Normal Limits'
    this.IsSkilledTherapyIndicated = false
    //this.Result = 'Complex'
    this.Result = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []

      // Assuming serviceAssessment.saveData returns a Promise
      const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)

      // Assuming serviceAssessment.saveData returns a valid axios post configuration
      if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
        reject(new Error('Invalid assessment Promise'))
        return
      }

      // Push the assessment promise to the apiCalls array
      apiCalls.push(assessmentPromise)

      // Push the second axios.post call to the apiCalls array
      apiCalls.push(axios.post(apiUrl + 'FEED/InsertFEED', formData))

      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }

      // Use Promise.all to wait for both promises to resolve
      Promise.all(apiCalls)
        .then((responses) => {
          // alert(JSON.stringify(responses))
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  
  getGetAssSummary(VisitId) {
    return serviceAssessment
      .getGetAssSummary(VisitId, 'FEED')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getNomsData(VisitId) {
    return nomsService
      .getNomsData(VisitId, 'FEED')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  deleteAssSumData(summId) {
    return serviceAssessment
      .deleteAssSumData(summId)
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  getFEEDData(VisitId) {
    return axios
      .get(apiUrl + 'FEED/GetFEED/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data) {
          //alert(JSON.stringify(data));
          this.FeedingId = data.FeedingId
          this.VisitId = data.VisitId
          this.History = data.History
          this.HistoryDescription = data.HistoryDescription
          this.FoodAllergies = data.FoodAllergies
          this.CurrentDiet = data.CurrentDiet
          this.MealTimeObservation = data.MealTimeObservation
          this.FoodPreferences = data.FoodPreferences
          this.FoodDislikes = data.FoodDislikes
          this.PreferredTemperature = data.PreferredTemperature
          this.PreferredTexture = data.PreferredTexture
          this.PreferredTaste = data.PreferredTaste
          this.FoodPresentation = data.FoodPresentation
          this.ResponseFood = data.ResponseFood
          this.CaregiverConcerns = data.CaregiverConcerns
          this.AdditionalFinding = data.AdditionalFinding

          this.OverallRating = data.OverallRating
          this.IsSkilledTherapyIndicated = data.IsSkilledTherapyIndicated
          this.Result = data.Result
          this.CreatedBy = data.CreatedBy
          this.UpdatedBy = data.UpdatedBy
          this.CreatedDate = data.CreatedDate

          return this
        }
      })
      .catch((error) => console.log('Error getSpeechData data:', error))
  }
}
