import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pocData: null,  // Initialize the POC data
};

const pocSlice = createSlice({
  name: 'poc',
  initialState,
  reducers: {
    setPocData(state, action) {
      state.pocData = action.payload;  // Set the POC data when action is dispatched
    },
    clearPocData(state) {
      state.pocData = null;  // Clear POC data when action is dispatched
    },
  },
});

export const { setPocData, clearPocData } = pocSlice.actions;  // Export the actions
export default pocSlice.reducer;  // Export the reducer
