import React, { createContext, useState } from 'react'

// Create a new context
export const AppContext = createContext()
export const AppProvider = ({ children }) => {
  ///const [globalVariable, setGlobalVariable] = useState('Ashish');
  //const globalVariable = useState('JACOB');
  const [firstName, setfirstName] = useState('aa')

  return (
    <AppContext.Provider value={{ firstName, setfirstName }}>
      {children}
    </AppContext.Provider>
  )
}
