import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'

export function TextareaComponent({
  rows,
  cols,
  placeholder,
  onTextChange,
  defaultValue,
  controlid,
  maxLength,
  requiredCondition,
  isInvalid,
  autoFocus,
  showError,
  required,
  IsChangeTracking = false,
}) {
  const [text, setText] = useState('')

  useEffect(() => {
    if (defaultValue !== null) {
      setText(defaultValue)
      onTextChange(defaultValue)
    }
  }, [defaultValue])

  const handleChange = (event) => {
    const newText = event.target.value
    setText(newText)
    // Call the callback function with the updated text
    IsChangeTracking(true)
    onTextChange(newText)
  }
  const redBorderStyle = isInvalid ? { borderColor: 'red' } : {}
  const currentCharCount = text?.length ?? 0
  const characterCountStyle = {
    fontSize: '0.8rem',
    color: currentCharCount <= maxLength ? 'grey' : 'red',
    position: 'absolute',
    bottom: '0px', // Position at the bottom
    right: '10px', // Position to the right
  }
  const characterCountBlock =
    maxLength >= 100 ? (
      <div style={characterCountStyle}>
        {currentCharCount}/{maxLength}
      </div>
    ) : null

  return (
    <div className={`form-group ${showError && !text ? 'has-error' : ''}`}>
      <Form.Control
        as='textarea'
        rows={rows}
        cols={cols}
        value={text || ''}
        name={controlid}
        className={`form-control form-control-sm ${
          showError && !text ? 'is-invalid' : ''
        }`}
        onChange={handleChange}
        placeholder={placeholder}
        maxLength={maxLength || 500}
        required={requiredCondition || false}
        autoFocus={autoFocus || false}
        style={{ fontFamily: 'Noto Sans, sans-serif', ...redBorderStyle }} 
        isInvalid={isInvalid || false}
       
      />
      {characterCountBlock}
      {/* </div> */}
      {showError && !text && (
        <div style={{ width: '100px' }} className='invalid-feedback'>
          *Required
        </div>
      )}
    </div>
  )
}

export function TextBox({
  placeholder,
  onTextChange,
  defaultValue,
  controlid,
  maxLength,
  readOnly,
  showError,
  numericOnly,
  required,
  IsChangeTracking = false,
}) {
  // input.red-placeholder::placeholder {
  //   color: red;
  // }
  const [text, setText] = useState(defaultValue || '')

  useEffect(() => {
    if (defaultValue !== null) {
      setText(defaultValue)
    }
  }, [defaultValue])

  const handleChange = (event) => {
    let newText = event.target.value
    if (numericOnly) {
      newText = newText.replace(/[^0-9]/g, '')
    }
    setText(newText)
    onTextChange(newText)
     IsChangeTracking(true)
  }
  const currentCharCount = text?.length ?? 0
  const characterCountStyle = {
    fontSize: '0.8rem',
    color: currentCharCount <= maxLength ? 'black' : 'red',
    position: 'absolute',
    bottom: '0px', // Position at the bottom
    right: '10px', // Position to the right
  }
  const characterCountBlock =
    maxLength >= 100 ? (
      <div style={characterCountStyle}>
        {currentCharCount}/{maxLength}
      </div>
    ) : null

  return (
    <div
      className={`form-group ${
        (required || showError) && !text ? 'has-error' : ''
      }`}
    >
      <input
        type='text'
        size='sm'
        className={`form-control form-control-sm ${
          (required || showError) && !text ? 'is-invalid' : ''
        }`}
        value={text}
        name={controlid}
        onChange={handleChange}
        placeholder={placeholder}
        maxLength={maxLength}
        readOnly={readOnly || false} // If readOnly prop is not provided, default to false
      />
      {characterCountBlock}
      {/* </div> */}
      {(showError || required) && !text && (
        <div className='invalid-feedback'>*Required</div>
      )}
    </div>
  )
}
