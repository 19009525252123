import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const supervisorSlice = createSlice({
  name: 'supervisorDetail',
  initialState,
  reducers: {
    supervisorDetail(state, action) {
      return {
        ...state,
        ...action.payload,
      }
      //state.headerTabData = action.payload;  // Set the POC data when action is dispatched
    },
    clearData(state) {
      return null
      //state.pocData = null;  // Clear POC data when action is dispatched
    },
  },
})

export const { supervisorDetail, clearData } = supervisorSlice.actions // Export the actions
export default supervisorSlice.reducer // Export the reducer