import React, { useState, useEffect } from 'react'
import {
  Document,
  Page,
  Text,
  View,
  Svg,
  Line,
  Path,
  G,
  Circle,
  Rect,
  Polygon,
} from '@react-pdf/renderer'

const LineChartProgOneData = ({ dataGraph, FCMName }) => {
  const [data, setData] = useState([])
  const [xLabels, setXLabels] = useState([])
  const [legendLabels, setLegendLabels] = useState([])
  const chartWidth = 400
  const chartHeight = 200
  const axisOffset = 40 // Increased to add margin
  const yMax = 100 // Fixed y-axis maximum value

  useEffect(() => {
    if (dataGraph) {
      const uniqueVisitDates = [
        ...new Set(dataGraph.map((item) => item.VisitDate)),
      ]
      const xLabels = uniqueVisitDates.map((visitDate) => visitDate)
      setXLabels(xLabels)

      const uniqueGoalCodes = [
        ...new Set(dataGraph.map((item) => item.GoalCode)),
      ]
      const legendLabels = uniqueGoalCodes.map((goalCode) => goalCode)
      setLegendLabels(legendLabels)

      const data = new Array(legendLabels.length).fill([]).map((_) => [])

      dataGraph.forEach((item) => {
        const xIndex = uniqueVisitDates.indexOf(item.VisitDate)
        const legendIndex = uniqueGoalCodes.indexOf(item.GoalCode)

        if (xIndex !== -1 && legendIndex !== -1) {
          data[legendIndex][xIndex] = item.AvgAccuracy
        }
      })

      setData(data)
    }
  }, [dataGraph])

  const yLabels = Array.from({ length: 11 }, (_, index) => `${10 * index}`)

  const xLabelMarginBottom = 10
  const yAxisExtraWidth = 30
  const svgHeight = chartHeight + axisOffset + 50
  const padding = 15

  const symbols = [
    'circle',
    'rect',
    'triangle',
    'pentagon',
    'hexagon',
    'star',
    'diamond',
    'cross',
    'plus',
    'arrow',
  ]
  const colors = [
    'red',
    'blue',
    'green',
    'orange',
    'purple',
    'brown',
    'pink',
    'cyan',
    'magenta',
    'lime',
  ]

  const renderSymbol = (symbol, cx, cy, color) => {
    const size = 8
    const sizephs = 12
    switch (symbol) {
      case 'circle':
        return <Circle cx={cx} cy={cy} r={size / 2} fill={color} />
      case 'rect':
        return (
          <Rect
            x={cx - size / 2}
            y={cy - size / 2}
            width={size}
            height={size}
            fill={color}
          />
        )
      case 'triangle':
        return (
          <Polygon
            points={`${cx},${cy - size / 2} ${cx - size / 2},${cy + size / 2} ${
              cx + size / 2
            },${cy + size / 2}`}
            fill={color}
          />
        )
      case 'pentagon':
        return (
          <Polygon
            points={`${cx},${cy - sizephs / 2} ${cx - sizephs / 3},${
              cy - sizephs / 6
            } ${cx - sizephs / 4},${cy + sizephs / 2} ${cx + sizephs / 4},${
              cy + sizephs / 2
            } ${cx + sizephs / 3},${cy - sizephs / 6}`}
            fill={color}
          />
        )
      case 'hexagon':
        return (
          <Polygon
            points={`${cx},${cy - sizephs / 2} ${cx - sizephs / 4},${
              cy - sizephs / 4
            } ${cx - sizephs / 4},${cy + sizephs / 4} ${cx},${
              cy + sizephs / 2
            } ${cx + sizephs / 4},${cy + sizephs / 4} ${cx + sizephs / 4},${
              cy - sizephs / 4
            }`}
            fill={color}
          />
        )
      case 'star':
        return (
          <Polygon
            points={`${cx},${cy - sizephs / 2} ${cx - sizephs / 5},${
              cy - sizephs / 5
            } ${cx - sizephs / 2},${cy} ${cx - sizephs / 5},${
              cy + sizephs / 5
            } ${cx},${cy + sizephs / 2} ${cx + sizephs / 5},${
              cy + sizephs / 5
            } ${cx + sizephs / 2},${cy} ${cx + sizephs / 5},${
              cy - sizephs / 5
            }`}
            fill={color}
          />
        )
      case 'diamond':
        return (
          <Polygon
            points={`${cx},${cy - size / 2} ${cx - size / 2},${cy} ${cx},${
              cy + size / 2
            } ${cx + size / 2},${cy}`}
            fill={color}
          />
        )
      case 'cross':
        return (
          <G fill={color}>
            <Rect
              x={cx - size / 6}
              y={cy - size / 2}
              width={size / 3}
              height={size}
            />
            <Rect
              x={cx - size / 2}
              y={cy - size / 6}
              width={size}
              height={size / 3}
            />
          </G>
        )
      case 'plus':
        return (
          <G fill={color}>
            <Rect
              x={cx - size / 6}
              y={cy - size / 2}
              width={size / 3}
              height={size}
            />
            <Rect
              x={cx - size / 2}
              y={cy - size / 6}
              width={size}
              height={size / 3}
            />
          </G>
        )
      case 'arrow':
        return (
          <Polygon
            points={`${cx},${cy - size / 2} ${cx - size / 4},${cy} ${cx},${
              cy + size / 2
            } ${cx + size / 4},${cy}`}
            fill={color}
          />
        )
      default:
        return null
    }
  }

  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Svg width={chartWidth + axisOffset + 50} height={svgHeight}>
        <G transform={`translate(${padding}, ${padding})`}>
          {yLabels.map((_, index) => (
            <Line
              key={index}
              x1={axisOffset}
              y1={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
              x2={Math.min(
                axisOffset + chartWidth + yAxisExtraWidth,
                chartWidth + axisOffset + 10,
              )}
              y2={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
              stroke='#E5E5E5'
              style={{ strokeDasharray: '1, 1' }}
            />
          ))}

          {xLabels.map((_, index) => (
            <Line
              key={index}
              x1={axisOffset + (chartWidth * index) / (xLabels.length - 1)}
              y1={0}
              x2={axisOffset + (chartWidth * index) / (xLabels.length - 1)}
              y2={chartHeight}
              stroke='gray'
              style={{ strokeDasharray: '1, 1' }}
            />
          ))}

          <Line
            x1={axisOffset}
            y1={chartHeight}
            x2={axisOffset + chartWidth + 10}
            y2={chartHeight}
            stroke='black'
            strokeWidth={2} // Increased line width
          />
          <Line
            x1={axisOffset}
            y1={0}
            x2={axisOffset}
            y2={chartHeight}
            stroke='black'
            strokeWidth={2} // Increased line width
          />

          {xLabels.map((label, index) => (
            <Text
              style={{ fontSize: '8px' }}
              key={index}
              x={axisOffset + (chartWidth * index) / (xLabels.length - 1)}
              y={chartHeight + xLabelMarginBottom}
              textAnchor='middle'
            >
              {label}
            </Text>
          ))}

          {yLabels.map((label, index) => (
            <Text
              style={{ fontSize: '8px' }}
              key={index}
              x={axisOffset - 5}
              y={chartHeight - chartHeight * (index / (yLabels.length - 1))}
              textAnchor='end'
              dominantBaseline='central'
            >
              {label}
            </Text>
          ))}

          {yLabels.map((_, index) => (
            <Line
              key={index}
              x1={axisOffset}
              y1={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
              x2={axisOffset - 5}
              y2={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
              stroke='black'
              strokeWidth={1} // Increased line width
            />
          ))}

          <Text
            style={{ fontSize: '10px', fontWeight: 'bold' }}
            x={axisOffset + chartWidth / 2}
            y={chartHeight + 30}
            textAnchor='middle'
          >
            Encounter Date
          </Text>
          <Text
            style={{ fontSize: '10px', fontWeight: 'bold' }}
            x={-chartHeight / 2}
            y={axisOffset - 25}
            transform='rotate(-90)'
            textAnchor='middle'
          >
            %Accuracy
          </Text>

          {data.map((dataset, datasetIndex) => (
            <G key={datasetIndex}>
              {dataset.map((value, index) => {
                if (index < dataset.length - 1) {
                  const x1 =
                    axisOffset + (chartWidth * index) / (xLabels.length - 1)
                  const y1 = chartHeight - chartHeight * (value / yMax)
                  const x2 =
                    axisOffset +
                    (chartWidth * (index + 1)) / (xLabels.length - 1)
                  const y2 =
                    chartHeight - chartHeight * (dataset[index + 1] / yMax)
                  const color = colors[datasetIndex % colors.length]

                  return (
                    <Line
                      key={index}
                      x1={x1}
                      y1={y1}
                      x2={x2}
                      y2={y2}
                      stroke={color}
                      strokeWidth={2} // Increased line width
                    />
                  )
                }
                return null
              })}

              {dataset.map((value, index) => {
                const cx =
                  axisOffset + (chartWidth * index) / (xLabels.length - 1)
                const cy = chartHeight - chartHeight * (value / yMax)
                const symbol = symbols[datasetIndex % symbols.length]
                const color = colors[datasetIndex % colors.length]

                return renderSymbol(symbol, cx, cy, color)
              })}
            </G>
          ))}
        </G>
        {/* Title */}
        <Text
          x={(chartWidth + axisOffset) / 2}
          y={10}
          fontSize={10}
          fontWeight='bold'
          textAnchor='middle'
          fill='black'
          style={{ paddingBottom: 5,marginBottom:5}}
        >
          {FCMName} 
        </Text>
      </Svg>

      <View
        style={{
          marginTop: -40,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {legendLabels.map((label, index) => (
          <View
            key={index}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginHorizontal: 1,
              marginVertical: -5,
              paddingVertical: -5,
            }}
          >
            <Svg height='22' width='22'>
              {renderSymbol(
                symbols[index % symbols.length],
                12,
                12,
                colors[index % colors.length],
              )}
            </Svg>
            <Text style={{ fontSize: '9px', marginLeft: 1 }}>{label}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default LineChartProgOneData

// import React, { useState, useEffect } from 'react';
// import {
//   Document,
//   Page,
//   Text,
//   View,
//   Svg,
//   Line,
//   Path,
//   G,
//   Circle,
//   Rect,
//   Polygon,
// } from '@react-pdf/renderer';

// const LineChartProgOneData = ({ dataGraph }) => {
//   const [data, setData] = useState([]);
//   const [xLabels, setXLabels] = useState([]);
//   const [legendLabels, setLegendLabels] = useState([]);
//   const chartWidth = 400;
//   const chartHeight = 200;
//   const axisOffset = 20;
//   const [maxData, setMaxData] = useState(100);

//   useEffect(() => {
//     if (dataGraph) {
//       const uniqueVisitDates = [...new Set(dataGraph.map((item) => item.VisitDate))];
//       const xLabels = uniqueVisitDates.map((visitDate) => visitDate);
//       setXLabels(xLabels);

//       const uniqueGoalCodes = [...new Set(dataGraph.map((item) => item.GoalCode))];
//       const legendLabels = uniqueGoalCodes.map((goalCode) => goalCode);
//       setLegendLabels(legendLabels);

//       const data = new Array(legendLabels.length).fill([]).map((_) => []);

//       dataGraph.forEach((item) => {
//         const xIndex = uniqueVisitDates.indexOf(item.VisitDate);
//         const legendIndex = uniqueGoalCodes.indexOf(item.GoalCode);

//         if (xIndex !== -1 && legendIndex !== -1) {
//           data[legendIndex][xIndex] = item.AvgAccuracy;
//         }
//       });

//       setData(data);
//       setMaxData(Math.max(...data.flat()));
//     }
//   }, [dataGraph]);

//   const yLabels = Array.from(
//     { length: 11 },
//     (_, index) => `${(maxData / 10) * index}`
//   );

//   const xLabelMarginBottom = 10;
//   const yAxisExtraWidth = 30;
//   const svgHeight = chartHeight + axisOffset + 50;
//   const padding = 15;

//   const symbols = ['circle', 'rect', 'triangle', 'pentagon', 'hexagon', 'star', 'diamond', 'cross', 'plus', 'arrow'];
//   const colors = ['red', 'blue', 'green', 'orange', 'purple', 'brown', 'pink', 'cyan', 'magenta', 'lime'];

//   const renderSymbol = (symbol, cx, cy, color) => {
//     const size = 10;
//     switch (symbol) {
//       case 'circle':
//         return <Circle cx={cx} cy={cy} r={size / 2} fill={color} />;
//       case 'rect':
//         return <Rect x={cx - size / 2} y={cy - size / 2} width={size} height={size} fill={color} />;
//       case 'triangle':
//         return (
//           <Polygon
//             points={`${cx},${cy - size / 2} ${cx - size / 2},${cy + size / 2} ${cx + size / 2},${cy + size / 2}`}
//             fill={color}
//           />
//         );
//       case 'pentagon':
//         return (
//           <Polygon
//             points={`${cx},${cy - size / 2} ${cx - size / 3},${cy - size / 6} ${cx - size / 4},${cy + size / 2} ${cx + size / 4},${cy + size / 2} ${cx + size / 3},${cy - size / 6}`}
//             fill={color}
//           />
//         );
//       case 'hexagon':
//         return (
//           <Polygon
//             points={`${cx},${cy - size / 2} ${cx - size / 4},${cy - size / 4} ${cx - size / 4},${cy + size / 4} ${cx},${cy + size / 2} ${cx + size / 4},${cy + size / 4} ${cx + size / 4},${cy - size / 4}`}
//             fill={color}
//           />
//         );
//       case 'star':
//         return (
//           <Polygon
//             points={`${cx},${cy - size / 2} ${cx - size / 5},${cy - size / 5} ${cx - size / 2},${cy} ${cx - size / 5},${cy + size / 5} ${cx},${cy + size / 2} ${cx + size / 5},${cy + size / 5} ${cx + size / 2},${cy} ${cx + size / 5},${cy - size / 5}`}
//             fill={color}
//           />
//         );
//       case 'diamond':
//         return (
//           <Polygon
//             points={`${cx},${cy - size / 2} ${cx - size / 2},${cy} ${cx},${cy + size / 2} ${cx + size / 2},${cy}`}
//             fill={color}
//           />
//         );
//       case 'cross':
//         return (
//           <G fill={color}>
//             <Rect x={cx - size / 6} y={cy - size / 2} width={size / 3} height={size} />
//             <Rect x={cx - size / 2} y={cy - size / 6} width={size} height={size / 3} />
//           </G>
//         );
//       case 'plus':
//         return (
//           <G fill={color}>
//             <Rect x={cx - size / 6} y={cy - size / 2} width={size / 3} height={size} />
//             <Rect x={cx - size / 2} y={cy - size / 6} width={size} height={size / 3} />
//           </G>
//         );
//       case 'arrow':
//         return (
//           <Polygon
//             points={`${cx},${cy - size / 2} ${cx - size / 4},${cy} ${cx},${cy + size / 2} ${cx + size / 4},${cy}`}
//             fill={color}
//           />
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <View
//       style={{
//         flexDirection: 'column',
//         alignItems: 'center',
//       }}
//     >
//       <Svg width={chartWidth + axisOffset + 50} height={svgHeight}>
//         <G transform={`translate(${padding}, ${padding})`}>
//           {yLabels.map((_, index) => (
//             <Line
//               key={index}
//               x1={axisOffset}
//               y1={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
//               x2={Math.min(
//                 axisOffset + chartWidth + yAxisExtraWidth,
//                 chartWidth + axisOffset + 10
//               )}
//               y2={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
//               stroke='#E5E5E5'
//               style={{ strokeDasharray: '1, 1' }}
//             />
//           ))}

//           {xLabels.map((_, index) => (
//             <Line
//               key={index}
//               x1={axisOffset + (chartWidth * index) / (xLabels.length - 1)}
//               y1={0}
//               x2={axisOffset + (chartWidth * index) / (xLabels.length - 1)}
//               y2={chartHeight}
//               stroke='gray'
//               style={{ strokeDasharray: '1, 1' }}
//             />
//           ))}

//           <Line
//             x1={axisOffset}
//             y1={chartHeight}
//             x2={axisOffset + chartWidth + 10}
//             y2={chartHeight}
//             stroke='black'
//           />
//           <Line
//             x1={axisOffset}
//             y1={0}
//             x2={axisOffset}
//             y2={chartHeight}
//             stroke='black'
//           />

//           {xLabels.map((label, index) => (
//             <Text
//               style={{ fontSize: '8px' }}
//               key={index}
//               x={axisOffset + (chartWidth * index) / (xLabels.length - 1)}
//               y={chartHeight + xLabelMarginBottom}
//               textAnchor='middle'
//             >
//               {label}
//             </Text>
//           ))}

//           {yLabels.map((label, index) => (
//             <Text
//               style={{ fontSize: '8px' }}
//               key={index}
//               x={axisOffset - 5}
//               y={chartHeight - chartHeight * (index / 10)}
//               textAnchor='end'
//               dominantBaseline='central'
//             >
//               {label}
//             </Text>
//           ))}

//           {yLabels.map((_, index) => (
//             <Line
//               key={index}
//               x1={axisOffset}
//               y1={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
//               x2={axisOffset - 5}
//               y2={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
//               stroke='black'
//             />
//           ))}

//           <Text
//             style={{ fontSize: '10px', fontWeight: 'bold' }}
//             x={axisOffset + chartWidth / 2}
//             y={chartHeight + 30}
//             textAnchor='middle'
//           >
//             Visit Date
//           </Text>
//           {/* <Text
//             style={{ fontSize: '10px', fontWeight: 'bold' }}
//             x={axisOffset - 40}
//             y={chartHeight / 2}
//             textAnchor='middle'
//             transform={`rotate(-90, ${axisOffset - 40}, ${chartHeight / 2})`}
//           >
//             Avg Accuracy
//           </Text> */}

//           {data.map((dataset, datasetIndex) => (
//             <G key={datasetIndex}>
//               {dataset.map((value, index) => {
//                 if (index < dataset.length - 1) {
//                   const x1 = axisOffset + (chartWidth * index) / (xLabels.length - 1);
//                   const y1 = chartHeight - (chartHeight * dataset[index]) / maxData;
//                   const x2 = axisOffset + (chartWidth * (index + 1)) / (xLabels.length - 1);
//                   const y2 = chartHeight - (chartHeight * dataset[index + 1]) / maxData;

//                   return (
//                     <Line
//                       key={index}
//                       x1={x1}
//                       y1={y1}
//                       x2={x2}
//                       y2={y2}
//                       stroke='black'
//                     />
//                   );
//                 }
//                 return null;
//               })}

//               {dataset.map((value, index) => {
//                 const cx = axisOffset + (chartWidth * index) / (xLabels.length - 1);
//                 const cy = chartHeight - (chartHeight * value) / maxData;
//                 const symbol = symbols[datasetIndex % symbols.length];
//                 const color = colors[datasetIndex % colors.length];

//                 return renderSymbol(symbol, cx, cy, color);
//               })}
//             </G>
//           ))}
//         </G>
//       </Svg>

//       <View
//         style={{
//           marginTop: -20,
//         //   flexDirection: 'row',
//         //   flexWrap: 'wrap',
//         //   justifyContent: 'center',
//          flexDirection: 'column',
//          alignItems: 'flex-start',
//         }}
//       >
//         {legendLabels.map((label, index) => (
//           <View
//             key={index}
//             style={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               marginHorizontal: 1, // Adjusted to reduce space
//               marginVertical:-3, // Further reduced to eliminate vertical space
//               paddingVertical: -5,
//             }}
//           >
//              <Svg height='18' width='18'>
//               {renderSymbol(
//                 symbols[index % symbols.length],
//                 8,
//                 8,
//                 colors[index % colors.length],
//               )}
//             </Svg>
//             <Text style={{ fontSize: '9px', marginLeft: 1 }}>{label}</Text>
//           </View>
//         ))}
//       </View>
//     </View>
//   );
// };

// export default LineChartProgOneData;

// import React, { useState, useEffect } from 'react';
// import {
//   Document,
//   Page,
//   Text,
//   View,
//   Svg,
//   Line,
//   Path,
//   G,
//   Circle,
// } from '@react-pdf/renderer';

// const LineChartProgOneData = ({ dataGraph }) => {
//   const [data, setData] = useState([]);
//   const [xLabels, setXLabels] = useState([]);
//   const [legendLabels, setLegendLabels] = useState([]);
//   const chartWidth = 400;
//   const chartHeight = 200;
//   const axisOffset = 20;
//   const [maxData, setMaxData] = useState(100);

//   useEffect(() => {
//     if (dataGraph) {
//       const uniqueVisitDates = [...new Set(dataGraph.map((item) => item.VisitDate))];
//       const xLabels = uniqueVisitDates.map((visitDate) => visitDate);
//       setXLabels(xLabels);

//       const uniqueGoalCodes = [...new Set(dataGraph.map((item) => item.GoalCode))];
//       const legendLabels = uniqueGoalCodes.map((goalCode) => goalCode);
//       setLegendLabels(legendLabels);

//       const data = new Array(legendLabels.length).fill([]).map((_) => []);

//       dataGraph.forEach((item) => {
//         const xIndex = uniqueVisitDates.indexOf(item.VisitDate);
//         const legendIndex = uniqueGoalCodes.indexOf(item.GoalCode);

//         if (xIndex !== -1 && legendIndex !== -1) {
//           data[legendIndex][xIndex] = item.AvgAccuracy;
//         }
//       });

//       setData(data);
//       setMaxData(Math.max(...data.flat()));
//     }
//   }, [dataGraph]);

//   const yLabels = Array.from(
//     { length: 11 },
//     (_, index) => `${(maxData / 10) * index}`
//   );

//   const xLabelMarginBottom = 10;
//   const yAxisExtraWidth = 30;
//   const svgHeight = chartHeight + axisOffset + 50;
//   const padding = 15;

//   return (
//     <View
//       style={{
//         flexDirection: 'column',
//         alignItems: 'center',
//       }}
//     >
//       <Svg width={chartWidth + axisOffset + 50} height={svgHeight}>
//         <G transform={`translate(${padding}, ${padding})`}>
//           {yLabels.map((_, index) => (
//             <Line
//               key={index}
//               x1={axisOffset}
//               y1={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
//               x2={Math.min(
//                 axisOffset + chartWidth + yAxisExtraWidth,
//                 chartWidth + axisOffset + 10
//               )}
//               y2={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
//               stroke='#E5E5E5'
//               style={{ strokeDasharray: '1, 1' }}
//             />
//           ))}

//           {xLabels.map((_, index) => (
//             <Line
//               key={index}
//               x1={axisOffset + (chartWidth * index) / (xLabels.length - 1)}
//               y1={0}
//               x2={axisOffset + (chartWidth * index) / (xLabels.length - 1)}
//               y2={chartHeight}
//               stroke='gray'
//               style={{ strokeDasharray: '1, 1' }}
//             />
//           ))}

//           <Line
//             x1={axisOffset}
//             y1={chartHeight}
//             x2={axisOffset + chartWidth + 10}
//             y2={chartHeight}
//             stroke='black'
//           />
//           <Line
//             x1={axisOffset}
//             y1={0}
//             x2={axisOffset}
//             y2={chartHeight}
//             stroke='black'
//           />

//           {xLabels.map((label, index) => (
//             <Text
//               style={{ fontSize: '8px' }}
//               key={index}
//               x={axisOffset + (chartWidth * index) / (xLabels.length - 1)}
//               y={chartHeight + xLabelMarginBottom}
//               textAnchor='middle'
//             >
//               {label}
//             </Text>
//           ))}

//           {yLabels.map((label, index) => (
//             <Text
//               style={{ fontSize: '8px' }}
//               key={index}
//               x={axisOffset - 5}
//               y={chartHeight - chartHeight * (index / 10)}
//               textAnchor='end'
//               dominantBaseline='central'
//             >
//               {label}
//             </Text>
//           ))}

//           {yLabels.map((_, index) => (
//             <Line
//               key={index}
//               x1={axisOffset}
//               y1={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
//               x2={axisOffset - 5}
//               y2={chartHeight - (chartHeight * index) / (yLabels.length - 1)}
//               stroke='black'
//             />
//           ))}

//           <Text
//             style={{ fontSize: '10px' }}
//             x={axisOffset + chartWidth / 2}
//             y={chartHeight + axisOffset + 10}
//             textAnchor='middle'
//           >
//             Encounter Date
//           </Text>

//           <Text
//             style={{ fontSize: '10px' }}
//             x={-chartHeight / 2}
//             y={axisOffset - 25}
//             textAnchor='middle'
//             transform={`rotate(-90, ${-chartHeight / 2}, ${axisOffset - 10})`}
//           >
//             % Accuracy
//           </Text>

//           {data.map((lineData, lineIndex) => (
//             <G key={lineIndex}>
//               <Path
//                 d={`M${axisOffset},${
//                   chartHeight - (lineData[0] / maxData) * chartHeight
//                 } ${lineData
//                   .map(
//                     (value, index) =>
//                       `L${
//                         axisOffset +
//                         (chartWidth * index) / (lineData.length - 1)
//                       },${chartHeight - (value / maxData) * chartHeight}`
//                   )
//                   .join(' ')}`}
//                 stroke={`hsl(${(lineIndex * 60) % 360}, 70%, 50%)`}
//                 strokeWidth={2}
//                 fill='none'
//               />
//               {lineData.map((value, dataIndex) => (
//                 <Circle
//                   key={dataIndex}
//                   cx={
//                     axisOffset +
//                     (chartWidth * dataIndex) / (lineData.length - 1)
//                   }
//                   cy={chartHeight - (value / maxData) * chartHeight}
//                   r={3}
//                   fill={`hsl(${(lineIndex * 60) % 360}, 70%, 50%)`}
//                 />
//               ))}
//             </G>
//           ))}
//         </G>
//       </Svg>

//       <View
//         style={{
//           flexDirection: 'column',
//           alignItems: 'flex-start',
//         }}
//       >
//         {legendLabels.map((label, index) => (
//           <View
//             key={index}
//             style={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               marginRight: 10,
//               marginBottom: 5,
//             }}
//           >
//             <View
//               style={{
//                 width: 12,
//                 height: 12,
//                 backgroundColor: `hsl(${(index * 60) % 360}, 70%, 50%)`,
//                 marginRight: 3,
//                 borderRadius: 6,
//               }}
//             />
//             <Text style={{ fontSize: 11, fontFamily: 'Helvetica' }}>
//               {label}
//             </Text>
//           </View>
//         ))}
//       </View>
//     </View>
//   );
// };

// export default LineChartProgOneData;
