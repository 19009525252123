import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import formatDate from '../Utility/Utility'

const MonthYearPicker = ({ defaultDate, onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState(null)

  useEffect(() => {
    //alert(defaultDate);
    if (defaultDate && typeof defaultDate === 'string') {
      // Split the defaultDate string into month and year parts
      // Check if the defaultDate matches 'yyyy-MM-dd' format
      const fullDateMatch = defaultDate.match(/^\d{4}-\d{2}-\d{2}$/)

      if (fullDateMatch) {
        // Parse the full date
        const dateParts = defaultDate.split('-')
        const year = parseInt(dateParts[0])
        const month = parseInt(dateParts[1])

        // Check if the parsed values are valid
        if (!isNaN(year) && !isNaN(month) && month >= 1 && month <= 12) {
          const date = new Date(year, month - 1, 1) // Set day to 1 for first day of the month
          setSelectedDate(date)
          return
        }
      }
      onDateChange(formatDate(fullDateMatch, false))
    } else {
      // If defaultDate is not provided or invalid, set the default to the current month and year
      const currentDate = new Date()
      currentDate.setDate(1) // Set the day to 1 to represent the first day of the month
      setSelectedDate(currentDate)
      //const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
      onDateChange(formatDate(currentDate, false))
    }
  }, [defaultDate])

  const handleDateChange = (date) => {
    setSelectedDate(date)

    if (date) {
      // Format the selected Date object as 'yyyy-MM-dd'
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      onDateChange(formatDate(date, false))
    } else {
      onDateChange(null)
    }
  }

  // Calculate the maximum selectable date as the current month and year
  const currentDate = new Date()
  currentDate.setDate(1) // Set the day to 1 to represent the first day of the month

  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat='MMM/yyyy' // Use 'MM' for numeric month format
        showMonthYearPicker
        className='form-select form-select-sm'
        maxDate={currentDate}
      />
    </div>
  )
}

export default MonthYearPicker
