import axios from 'axios'
import { Variables } from '../../Variables'
import PNGoal from '../PNGoalClass'
import { ProgressNotes } from '../../Enum'

export class ProgressNoteService {
  constructor() {
    this.ProgressDischargeId = 0
    this.VisitId = 0
    this.ClientId = 0
    this.NoteType = 'PN'
    this.ProgramEstablish = 'Yes'
    this.ProgramEstablishDesc = ''
    this.Caregiver = 'Yes'
    this.CaregiverDesc = ''
    this.Prognosis = 'Good'
    this.PrognosisDesc = ''
    this.ClientParticipation = '76-100%'
    this.ClientAttendance = '76-100%'
    this.Frequency = 1
    this.Duration = ''
    this.IsReceivingServicesOutside = 'No'
    this.ReceivingServicesOutsideDesc = ''
    this.IsReadyDischargeAuthorization = false
    this.IsFollowUpClient = null
    this.StatusDischarge = ''
    this.ProgressReport = ''
    this.JustificationContinuedTherapy = ''
    this.ReasonDischarge = ''
    this.DischargeInstructions = ''
    this.ReferralNeeded = ''
    this.ReferralDescription = ''
    this.PNGoalListItems = []
    this.CreatedBy=0;
    this.supervisorDetail = null
    this.UpdatedBy=0;
  }

  getProgressNotes(VisitId, ClientId) {
    const apiUrl = Variables.API_URL
    return axios
      .get(
        `${apiUrl}/ProgressDis/GetNote/${VisitId}/${ProgressNotes.ProgressNote}/${ClientId}`,
      )
      .then((response) => {
        const dataFromResponse = response.data.Result
        // Map response data to the properties defined in defaultFormData
        const mappedData = {
          ProgressDischargeId: dataFromResponse.ProgressDischargeId,
          VisitId: dataFromResponse.VisitId,
          ClientId: dataFromResponse.ClientId,
          NoteType: dataFromResponse.NoteType,
          ProgramEstablish: dataFromResponse.ProgramEstablish,
          ProgramEstablishDesc: dataFromResponse.ProgramEstablishDesc,
          Caregiver: dataFromResponse.Caregiver,
          CaregiverDesc: dataFromResponse.CaregiverDesc,

          Prognosis: dataFromResponse.Prognosis,
          PrognosisDesc: dataFromResponse.PrognosisDesc,
          ClientParticipation: dataFromResponse.ClientParticipation,
          ClientAttendance: dataFromResponse.ClientAttendance,
          Frequency: dataFromResponse.Frequency,
          Duration: dataFromResponse.Duration,
          IsReceivingServicesOutside:
            dataFromResponse.IsReceivingServicesOutside,
          ReceivingServicesOutsideDesc:
            dataFromResponse.ReceivingServicesOutsideDesc,
          IsReadyDischargeAuthorization:
            dataFromResponse.IsReadyDischargeAuthorization,
          IsFollowUpClient: dataFromResponse.IsFollowUpClient,
          StatusDischarge: dataFromResponse.StatusDischarge,
          ProgressReport: dataFromResponse.ProgressReport,
          JustificationContinuedTherapy:
            dataFromResponse.JustificationContinuedTherapy,
          ReasonDischarge: dataFromResponse.ReasonDischarge,
          DischargeInstructions: dataFromResponse.DischargeInstructions,
          ReferralNeeded: dataFromResponse.ReferralNeeded,
          ReferralDescription: dataFromResponse.ReferralDescription,
          CreatedBy: dataFromResponse.CreatedBy,
          UpdatedBy: dataFromResponse.UpdatedBy,
        
          PNGoalListItems: dataFromResponse.PNGoalListItems.map((goalData) => {
            const goal = new PNGoal()
            goal.PN_GoalsId = goalData.PN_GoalsId
            goal.GoalId = goalData.GoalId
            goal.VisitId = VisitId
            goal.ClientId = ClientId
            goal.RowId = goalData.RowId
            goal.PN_Goal_Type = ProgressNotes.ProgressNote
            goal.GoalType = goalData.GoalType
            goal.GoalCode = goalData.GoalCode
            goal.FCMType = goalData.FCMType
            goal.GoalCategory = goalData.GoalCategory 
            goal.GoalDescription = goalData.GoalDescription
            goal.Duration = goalData.Duration
            goal.Units = goalData.Units
            goal.Given = goalData.Given
            goal.Sound = goalData.Sound
            goal.AtLevel = goalData.AtLevel
            goal.Accuracy = goalData.Accuracy
            goal.InSessions = goalData.InSessions
            goal.GoalMET = goalData.GoalMET
            goal.GoalMetDate = goalData.GoalMetDate
            return goal
          }),
        }
        return mappedData
      })
      .catch((error) => {
        console.error('Error fetching SoapNote data:', error)
        throw error
      })
  }

  insertProgressNote(formData) {
    const apiUrl = Variables.API_URL
    return axios
      .post(`${apiUrl}/ProgressDis/InsertNote`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting ProgressDis data:', error)
        throw error
      })
  }
}
