import React, { useState, useContext, useEffect } from 'react'
import { GoalsSLCGeneral } from '../Component/GoalsSLCGeneral'
import { GTDefault } from '../../GoalComponenet/GTDefault'
import { Variables } from '../../Variables'
import axios from 'axios'
import {
  VisitProvider,
  SignLockProvider,
  CreatedByProvider,
} from '../../Provider/Visitprovider'
import { useLanguage } from '../../Provider/LanguageContext'
import { GoalsService } from '../Component/GoalsService'
import { toast } from 'react-toastify'

const apiUrl = Variables.API_URL
export const GoalsUI = React.forwardRef(({ refreshGoal, IsAdult }, ref) => {
  const VisitId = useContext(VisitProvider)
  const CreatedBy = useContext(CreatedByProvider)

  const [generalData, setGeneralData] = useState()
  const [selectedRowLT, setSelectedRowLT] = useState([])
  const [reFreshGenGrid, setReFreshGenGrid] = useState(false)
  const IsSignLock = useContext(SignLockProvider)
  const language = useLanguage()
  const [userLanguage, setuserLanguage] = useState(false)
  const goalService = new GoalsService()
  const [isDirty, setIsDirty] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  React.useImperativeHandle(ref, () => ({
    handleButtonClick,
  }))
  useEffect(() => {
    setuserLanguage(language.language)
  }, [VisitId])

  const handleRowSelected = (selectedRowData) => {
    setSelectedRowLT(selectedRowData)
  }

  const handleGeneralChange = (newData) => {
    setGeneralData(newData)
  }
  const validateLTGoalDescriptions = () => {
    const isEmpty = selectedRowLT?.some(
      (row) => !row.GoalDescription || row.GoalDescription.trim() === '',
    )
    return isEmpty
  }

  const handleButtonClick = (showMsg = true) => {
    //alert(isDirty)
    if (IsSignLock || !isDirty) {
      //alert(isDirty)
      return
    }

    if (validateLTGoalDescriptions() && showMsg) {
      toast.error('Please select the NOMS first to save the Goal(s).',{style: { width: '500px' },})
      return
    }

    const allData = []

    if (selectedRowLT != null) {
      allData.push(...selectedRowLT)
    }

    if (generalData && generalData.length > 0) {
      allData.push(...generalData)
    }
    const apiCalls = []
    // Make API call with the merged data
    if (allData.length > 0) {
      apiCalls.push(goalService.saveData(allData))
    }

    // alert('updatedsave : ' + JSON.stringify(allData))
    //return;
    // if (selectedRowLT != null) {
    //   apiCalls.push(goalService.saveData(selectedRowLT))
    // }
    // if (generalData && generalData.length > 0) {
    //   apiCalls.push(goalService.saveData(generalData))

    // }

    Promise.all([apiCalls])
      .then((responses) => {
        if (showMsg) {
          toast.success('Data saved successfully!')
        }
        setReFreshGenGrid(true)
      })
      .catch((error) => {
        console.log('Failed to save data. Please try again.')
      })
      .finally(() => {
        setIsDirty(false)
      })
  }

  return (
    <div style={{ display: 'block', width: '100%', padding: 10 }}>
      <table style={{ width: '100%', padding: 5 }}>
        <tr>
          <td>
            <GTDefault
              VisitId={VisitId}
              IsrefreshGoal={refreshGoal}
              onRowSelected={handleRowSelected}
              fcmType='SLC'
              CreatedBy={CreatedBy}
              IsAdult={IsAdult}
              IsChangeTracking={setIsDirty}
            />
          </td>
        </tr>

        <tr>
          <td>
            <GoalsSLCGeneral
              onTableDataChange={handleGeneralChange}
              reFreshGrid={true}
              VisitId={VisitId}
              IsAdult={IsAdult}
              fcmType={'SLC'}
              CreatedBy={CreatedBy}
              IsChangeTracking={setIsDirty}
            />
          </td>
        </tr>
        <tr>
          <td colspan={2} style={{ textAlign: 'right' }}>
            <div style={{ marginTop: '10px' }}>
              <button
                disabled={IsSignLock || !isDirty}
                onClick={handleButtonClick}
                style={{ width: '150px' }}
                className='btn btn-primary rounded-pill'
              >
                <b> Save</b>{' '}
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  )
})
