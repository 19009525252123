import React, { useState, useEffect, useContext, useRef } from 'react'
import { Variables } from '../Variables'
import 'bootstrap/dist/css/bootstrap.css'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { DDList } from '../Controls/DDList'
import Container from 'react-bootstrap/Container'
import { CheckboxListInColumn, Checkbox } from '../Controls/CheckBoxList'
import { TextareaComponent, TextBox } from '../Controls/TextareaComponent '
import { SPSSumm } from '../SpeechInt/SPSumm'
import { SoundGrid } from '../SpeechInt/SoundGrid'
import SpeechIntelligibilityDTO from '../Model/SpeechIntelligibility'
import axios from 'axios'
import { VisitProvider, SignLockProvider } from '../Provider/Visitprovider'
import { useLanguage } from '../Provider/LanguageContext'
import { AssessSumService } from '../FCM/Component/AssessSumService'
import Loading from '../Controls/Loading'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
export const SpeechIntAss = React.forwardRef(
  ({ onDataFetched, triggerRefresh }, ref) => {
    const VisitId = useContext(VisitProvider)
    const [selectedRows, setSelectedRows] = useState([])
    const [formData, setFormData] = useState(new SpeechIntelligibilityDTO())
    const [ppTypeOther, setPpTypeOther] = useState(false)
    const [gridData, setGridData] = useState([])
    const [gridData2, setGridData2] = useState([])
    const [apiData, setApiData] = useState([])
    const [consolidatedRows, setConsolidatedRows] = useState([])
    const IsSignLock = useContext(SignLockProvider)
    const [AssessmentDescriptionError, setAssessmentDescriptionError] =
      useState(false)
    const language = useLanguage()
    const [userLanguage, setuserLanguage] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSound, setLoadingSound] = useState(true)
    const [isDirty, setIsDirty] = useState(false)

    React.useImperativeHandle(ref, () => ({
      handleFormSubmit,
    }))
    const handleSelectedRows = (selectedRows) => {
      setSelectedRows(selectedRows)
    }

    const handleCheckboxListChange = (controlName, value) => {
      const valueControl = controlName

      const updatedFormData = {
        ...formData,
        [valueControl]: value,
      }

      setFormData(updatedFormData)
      if (formData.PP_Type?.includes('Other')) {
        setPpTypeOther(true)
      } else {
        setPpTypeOther(false)
      }
      setIsDirty(true)
    }
    const handleTextBoxValueChange = (controlName, value) => {
      const valueControl = controlName
      const updatedFormData = {
        ...formData,
        [valueControl]: value,
      }
      setFormData(updatedFormData)
      if (formData.PP_Type?.includes('Other') && controlName === 'PP_Other') {
      }
      //setIsDirty(true)
    }
    const handleSelect = (selectedValue, dropdownName) => {
      const valueControl = dropdownName
      const updatedFormData = {
        ...formData,
        [valueControl]: selectedValue,
      }

      setFormData(updatedFormData)
      setIsDirty(true)
    }

    const handleCheckboxChange = (columnName, checked) => {
      //alert(columnName);alert(checked);
      const updatedFormData = { ...formData, [columnName]: checked }
      setFormData(updatedFormData)
      triggerRefresh(checked)
      setIsDirty(true)
    }

    const ValidationObject = () => {
      let isValid = true
      if (
        formData.AssessmentDescription === null ||
        formData.AssessmentDescription.trim() === ''
      ) {
        setAssessmentDescriptionError(true)
        isValid = false
      }
      return isValid
    }

    useEffect(() => {
      setuserLanguage(language.language)
      //alert(JSON.stringify(language.language));
      ValidationObject()
    }, [formData])

    const handleFormSubmit = (showMsg = true) => {
      // alert(isDirty)
      if (IsSignLock || !isDirty) {
        //alert(isDirty)
        return
      }

      const apiCalls = []
      formData.VisitId = VisitId
      // alert(formData.IsSkilledSpeechTherapy);
      //console.log('Submitting data:',JSON.stringify(consolidatedRows));
      // return ;
      const updateAssmentSummary = consolidatedRows.map((item) => {
        if (item.hasOwnProperty('VisitId')) {
          if (item.VisitId === 0) {
            item.VisitId = VisitId
          }
        }
        return item
      })

      const soundData = [...gridData, ...gridData2]
      const updatedSoundData = soundData.map((item) => {
        if (item.hasOwnProperty('VisitId')) {
          if (item.VisitId === 0) {
            item.VisitId = VisitId
          }
        }
        return item
      })
      // alert(JSON.stringify(updateAssmentSummary));
      // return;
      if (updateAssmentSummary.length > 0) {
        serviceAssessment.saveData(updateAssmentSummary)
        // apiCalls.push(
        //   axios.post(apiUrl + "SpeechInt/CreateAssSummary", updateAssmentSummary)
        // );
      }
      apiCalls.push(
        axios.post(apiUrl + 'SpeechInt/CreateSoundAss', updatedSoundData),
      )

      if (formData.SpeechIntelligibilityId === 0) {
        apiCalls.push(
          axios.post(apiUrl + 'SpeechInt/CreateSpeechInt', formData),
        )
      } else {
        apiCalls.push(
          axios.put(Variables.API_URL + 'SpeechInt/UpdateSpeechInt', formData),
        )
      }
      Promise.all([apiCalls])
        .then((responses) => {
          if (showMsg) {
            alert('Data saved successfully!') //('Data saved successfully!');
          }
          triggerRefresh(formData.IsSkilledSpeechTherapy)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.')
        })
        .finally(() => {
          setIsDirty(false)
        })
    }

    useEffect(() => {
      if (!VisitId) return // Exit early if VisitId is falsy

      setLoading(true) // Set loading state

      axios
        .get(apiUrl + 'SpeechInt/GetSpeechInt/' + VisitId)
        .then((response) => {
          const data = response.data.Result
          if (data) {
            triggerRefresh(data.IsSkilledSpeechTherapy)
            const {
              SpeechIntelligibilityId,
              SPP_Level,
              SPP_Type,
              SPP_Description,
              PP_Level,
              PP_Type,
              PP_Other,
              Stimulability_Rating,
              IsSkilledSpeechTherapy,
              AssessmentType,
              AssessmentDescription,
              OverallIntRating,
            } = data
            setFormData({
              SpeechIntelligibilityId,
              SPP_Level,
              SPP_Type,
              SPP_Description,
              PP_Level,
              PP_Type,
              PP_Other,
              Stimulability_Rating,
              IsSkilledSpeechTherapy,
              AssessmentType,
              AssessmentDescription,
              OverallIntRating,
            })
          }
        })
        .catch((error) => console.log('Error fetching data:', error))
        .finally(() => {
          setLoading(false) // Reset loading state
          onDataFetched() // Call onDataFetched regardless of success or failure
        })
    }, [VisitId])

    useEffect(() => {
      setLoadingSound(true)
      axios
        //alert(apiUrl+'SpeechInt/GetSpeechInt/'+VisitId)
        .get(apiUrl + 'SpeechInt/GetSoundAss/' + VisitId)
        .then((response) => {
          const data = response.data.Result
          setApiData(data)
        })
        .catch((error) => console.log('Error fetching data:', error))
        .finally(() => {
          setLoadingSound(false)
          ///onDataFetched()
        })
    }, [VisitId])

    useEffect(() => {
      //console.log("trig1------------");
      //alert(VisitId);
      serviceAssessment
        .getGetAssSummary(VisitId, 'INT')
        .then((data) => {
          //console.log("Data received:", data);
          setConsolidatedRows(data)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          throw error
        })
      // axios
      //   .get(apiUrl + "SpeechInt/GetAssSummary/" + VisitId)
      //   .then((response) => {
      //     const data = response.data.Result;
      //     setConsolidatedRows(data);
      //   })
      //   .catch((error) => console.log("Error fetching data:", error));
    }, [VisitId])

    const handleGridDataChange = (data) => {
      setGridData(data)
    }
    const handleGridDataChange2 = (data) => {
      setGridData2(data)
    }

    const handleRowAdded = (newRow) => {
      // Do something with the added row, if needed
      console.log('Row Added:', newRow)
      setConsolidatedRows(newRow)
    }

    const handleRowDeleted = (deletedRow) => {
      if (!deletedRow || typeof deletedRow.RowId === 'undefined') {
        console.error('Invalid deletedRow:', deletedRow)
        return
      }

      if (deletedRow.AssessmentSummaryId > 0) {
        return serviceAssessment
          .deleteAssSumData(deletedRow.AssessmentSummaryId)
          .then((data) => {
            //console.log("Data received:", data);
            return data
          })
          .catch((error) => {
            console.error('Error fetching data:', error)
            throw error
          })
        // axios.delete(
        //   apiUrl +
        //     "SpeechInt/DeleteAssSummary/" +
        //     deletedRow.SI_AssessmentSummaryId
        // );
      }
      // setConsolidatedRows((prevRows) =>
      //   prevRows.filter((row) => row.RowId !== deletedRow.RowId)
      // );

      //console.log('Row Deleted:', deletedRow);
      //}
    }

    const handleUpdatedRows = (updatedRows) => {
      setConsolidatedRows(updatedRows)
    }
    if (loading) {
      return <Loading />
    }
    return (
      <Container fluid>
        <Row>
          <Col xs={6} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            <b>Instructions:</b> Mark the sounds in error.
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Row>
              <Col xs={6}>
                {loadingSound ? (
                  'Loading...'
                ) : (
                  <SoundGrid
                    SoundGroup='1'
                    data={apiData}
                    onDataChange={handleGridDataChange}
                    isChangeTracked={setIsDirty}
                  />
                )}
              </Col>
              <Col xs={6}>
                {loadingSound ? (
                  'Loading...'
                ) : (
                  <SoundGrid
                    SoundGroup='2'
                    userLanguage={userLanguage}
                    data={apiData}
                    onDataChange={handleGridDataChange2}
                    isChangeTracked={setIsDirty}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={6} style={{ paddingTop: '10px' }}>
            <Row>
              <Col xs={6}>
                <b>Sound Production Patterns</b>
              </Col>
              <Col xs={6}>
                <DDList
                  onSelect={(selectedValue) =>
                    handleSelect(selectedValue, 'SPP_Level')
                  }
                  defaultValue={formData.SPP_Level}
                  ddType={11}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col xs={2}>
                <CheckboxListInColumn
                  values={formData.SPP_Type}
                  onChange={(selectedValues) =>
                    handleCheckboxListChange('SPP_Type', selectedValues)
                  }
                  typeOption={2}
                  columns={1}
                  rows={3}
                ></CheckboxListInColumn>
              </Col>
              <Col xs={10} style={{ marginTop: '5px' }}>
                {/* {formData.SPP_Description} */}
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={3}
                  maxLength={5000}
                  controlid='SPP_Description'
                  defaultValue={loading ? '' : formData.SPP_Description}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('SPP_Description', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={6}>
                    <b>Phonological Processes</b>
                  </Col>
                  <Col xs={6}>
                    <DDList
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'PP_Level')
                      }
                      defaultValue={formData.PP_Level}
                      ddType={11}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <CheckboxListInColumn
                      values={formData.PP_Type}
                      onChange={(selectedValues) =>
                        handleCheckboxListChange('PP_Type', selectedValues)
                      }
                      typeOption={1}
                      columns={3}
                      rows={2}
                    ></CheckboxListInColumn>
                  </Col>
                </Row>
                {formData.PP_Type==="Other" && (
                  <Row style={{ marginTop: '5px', paddingBottom: '5px' }}>
                    <Col xs={12}>
                      <TextBox
                        IsChangeTracking={setIsDirty}
                        controlid='PP_Other'
                        defaultValue={formData.PP_Other}
                        maxLength={50}
                        onTextChange={(value) =>
                          handleTextBoxValueChange('PP_Other', value)
                        }
                        placeholder={'Other...'}
                      />{' '}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={6}>
                    <b>Stimulability</b>
                  </Col>
                  <Col xs={6}>
                    <DDList
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'Stimulability_Rating')
                      }
                      defaultValue={formData.Stimulability_Rating}
                      ddType={12}
                    />
                  </Col>
                </Row>
                <Row>
                  <b>Assessment Summary</b>
                </Row>
                <Row className='mt-1 mb-1'>
                  <Col xs={2}>
                    <b>Overall Rating</b>
                  </Col>
                  <Col xs={3}>
                    <DDList
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'OverallIntRating')
                      }
                      defaultValue={formData.OverallIntRating}
                      ddType={11}
                    />
                  </Col>
                  <Col xs={7}>
                    <Checkbox
                      label='Skilled Therapy NOT Indicated for INT'
                      checked={formData.IsSkilledSpeechTherapy}
                      onChange={handleCheckboxChange}
                      columnName='IsSkilledSpeechTherapy'
                    />
                  </Col>
                </Row>

                <Row>
                  <SPSSumm
                    apiData={consolidatedRows}
                    onUpdatedRows={handleUpdatedRows}
                    onRowAdded={handleUpdatedRows}
                    onRowDeleted={handleRowDeleted}
                    FCMType={'INT'}
                    isChangeTracked={setIsDirty}
                  />
                </Row>
                <Row>
                  <CheckboxListInColumn
                    values={formData.AssessmentType}
                    onChange={(selectedValues) =>
                      handleCheckboxListChange('AssessmentType', selectedValues)
                    }
                    typeOption={3}
                    columns={3}
                    rows={1}
                  ></CheckboxListInColumn>
                </Row>
                <Row>
                  <Col xs={12}>
                    <TextareaComponent
                      IsChangeTracking={setIsDirty}
                      cols={40}
                      showError={AssessmentDescriptionError}
                      rows={2}
                      maxLength={5000}
                      controlid='AssessmentDescription'
                      defaultValue={formData.AssessmentDescription}
                      onTextChange={(value) =>
                        handleTextBoxValueChange('AssessmentDescription', value)
                      }
                      placeholder={'Please describe...'}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ textAlign: 'right' }}>
          <Col xs={12}>
            {/* <Button type="submit" className="btn btn-primary rounded-pill" >Preview</Button> */}
            <Button
              disabled={IsSignLock || !isDirty}
              type='submit'
              onClick={handleFormSubmit}
              style={{
                width: '150px',
                marginTop: '10px',
                marginLeft: '10px',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
              className='btn btn-primary rounded-pill'
            >
              <b>Save</b>
            </Button>
            {/* <Button type="submit" style={{ marginLeft: '10px' }} className="btn btn-primary rounded-pill">Sign/Lock</Button>
             */}{' '}
          </Col>
        </Row>
      </Container>
    )
  },
)
