class SpeechIntelligibilityDTO {
  constructor() {
    this.SpeechIntelligibilityId = 0
    this.VisitId = 0
    this.SPP_Level = 'Within Normal Limits'
    this.SPP_Type = ''
    this.SPP_Description = ''
    this.PP_Level = 'Within Normal Limits'
    this.PP_Type = ''
    this.Stimulability_Rating = 'Good'
    this.IsSkilledSpeechTherapy = false
    this.AssessmentType = ''
    this.AssessmentDescription = ''
    this.PP_Other = ''
    this.OverallIntRating = 'Within Normal Limits'
  }
}

export default SpeechIntelligibilityDTO
