import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faAdd, faCode } from '@fortawesome/free-solid-svg-icons'

export const Delete = () => {
  return <FontAwesomeIcon icon={faTrash} style={{ color: 'red' }} />
}

export const Add = () => {
  return <FontAwesomeIcon icon={faAdd} style={{ color: 'blue' }} />
}

export const Braces = () => {
  return <FontAwesomeIcon icon={faCode} style={{ color: 'black' }} />
}
