import React, { Component } from 'react'
import axios from 'axios'
import { Variables } from '../../Variables'
import { AssessSumService } from '../Component/AssessSumService'
import { NomsService } from '../Component/NomsService'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
const nomsService = new NomsService()

export class VoiceService {
  constructor() {
    this.VoiceId = 0
    this.VisitId = null
    this.Smoke = 'No'
    this.SmokeDescription = null
    this.Caffeine = 'No'
    this.CaffeineDescription = null
    this.Alcohol = 'No'
    this.AlcoholDescription = null
    this.DailyWaterIntake = '3-4 glasses (17-32oz)'
    this.QualityResonance = null
    this.QualityResonanceDescription = null
    this.Pitch = null
    this.Loudness = null
    this.Resporatory = null
    this.Posture = null
    this.Tension = null
    this.VocalAbuse = null
    this.ImpactDailyActivities = null
    this.AssessmentObservations = null

    this.OverallRating = 'Within Normal Limits'
    this.IsSkilledTherapyIndicated = false
    this.Result = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []

      // Assuming serviceAssessment.saveData returns a Promise
      const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)

      // Assuming serviceAssessment.saveData returns a valid axios post configuration
      if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
        reject(new Error('Invalid assessment Promise'))
        return
      }

      // Push the assessment promise to the apiCalls array
      apiCalls.push(assessmentPromise)

      // Push the second axios.post call to the apiCalls array
      apiCalls.push(axios.post(apiUrl + 'VOIC/InsertVOIC', formData))

      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }

      // Use Promise.all to wait for both promises to resolve
      Promise.all(apiCalls)
        .then((responses) => {
          // alert(JSON.stringify(responses))
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  getGetAssSummary(VisitId) {
    return serviceAssessment
      .getGetAssSummary(VisitId, 'VOIC')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getNomsData(VisitId) {
    return nomsService
      .getNomsData(VisitId, 'VOIC')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  deleteAssSumData(summId) {
    return serviceAssessment
      .deleteAssSumData(summId)
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  getVOICData(VisitId) {
    return axios
      .get(apiUrl + 'VOIC/GetVOIC/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data) {
          //alert(JSON.stringify(data));
          this.VoiceId = data.VoiceId
          this.VisitId = data.VisitId
          this.Smoke = data.Smoke
          this.SmokeDescription = data.SmokeDescription
          this.Caffeine = data.Caffeine
          this.CaffeineDescription = data.CaffeineDescription
          this.Alcohol = data.Alcohol
          this.AlcoholDescription = data.AlcoholDescription
          this.DailyWaterIntake = data.DailyWaterIntake
          this.QualityResonance = data.QualityResonance
          this.QualityResonanceDescription = data.QualityResonanceDescription
          this.Pitch = data.Pitch
          this.Loudness = data.Loudness
          this.Resporatory = data.Resporatory
          this.Posture = data.Posture
          this.Tension = data.Tension
          this.VocalAbuse = data.VocalAbuse
          this.ImpactDailyActivities = data.ImpactDailyActivities
          this.AssessmentObservations = data.AssessmentObservations
          this.OverallRating = data.OverallRating
          this.IsSkilledTherapyIndicated = data.IsSkilledTherapyIndicated
          this.Result = data.Result
          this.CreatedBy = data.CreatedBy
          this.UpdatedBy = data.UpdatedBy
          this.CreatedDate = data.CreatedDate

          return this
        }
      })
      .catch((error) => console.log('Error getSpeechData data:', error))
  }
}
