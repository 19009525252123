import React from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';

const SpeechImage = ({ sound }) => {
  const getImageSource = (sound) => {
    switch (sound) {
      case 'ʤ':
        return '/logoImage/SPImage/d3.png';
      case 'ʃ':
        return '/logoImage/SPImage/f.png';
      case 'tʃ':
        return '/logoImage/SPImage/tf.png';
      default:
        return '';
    }
  };

  const imageSrc = getImageSource(sound);

  return (
    <View style={styles.imageContainer}>
      {imageSrc && <Image src={imageSrc} style={styles.imageStyle} />}
    </View>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    // Add your container styles here
  },
  imageStyle: {
    width: 7,
    height: 7,
    // Add any other styles you need for the image
  },
});

export default SpeechImage;
