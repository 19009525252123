import axios from "axios";
import { Variables } from "../../Variables";

const apiUrl = Variables.API_URL;

export class NomsService {
  

  saveData(data) {
    return new Promise((resolve, reject) => {
      const apiCalls = [];
      //alert(formData.SpeechelligibilityId);
      //alert(JSON.stringify(data));
      apiCalls.push(
        axios.post(apiUrl + "SpeechInt/CreateNoms", data)
      );
      if (apiCalls.length === 0) {
        console.error("No promises to resolve."); 
        reject(new Error("No promises to resolve.")); 
      }
      Promise.all(apiCalls)
        .then((responses) => {
          resolve(responses); 
        })
        .catch((error) => {
          console.log("Failed to save data. Please try again.", error);
          reject(error); 
        });
    });
  }

  getNomsData(VisitId,fcmType) {
    return axios
      .get(`${apiUrl}SpeechInt/GetNoms/${VisitId}/${fcmType}`)
      .then((response) => {
        const data = response.data.Result;
        return data;
      })
      .catch((error) => console.log("Error fetching data:", error));
  }
 
}
