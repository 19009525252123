import React from 'react'

const HorizontalLine = () => {
  const hrStyle = {
    borderTop: '2px solid black', // #f0f0f0
    //margin-Top: '5px',
  }

  return <hr style={hrStyle} />
}

export default HorizontalLine
