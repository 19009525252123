import React, { useState, useEffect, useContext, useRef } from 'react'
import { PDFViewer, pdf } from '@react-pdf/renderer'
import { useUser } from '../Provider/UserContext'
import { VisitProvider } from '../Provider/Visitprovider'
import { Variables } from '../Variables'
import EvalPDFDocs from './EvalPDFDocs'
import { PocService } from '../POC/Service/PocService'
import { CreatedByProvider } from '../Provider/Visitprovider'
//import { CreatedByProvider } from '../../Provider/Visitprovider'

const apiUrl = Variables.API_URL
const EvalPDFDownlaod = ({ onPdfGenerationComplete }) => {
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)
  const [dbPOCdata, setDBPOCDate] = useState(null)
  const pocService = new PocService()
  const CreatedBy = useContext(CreatedByProvider)
  const { userDetails } = useUser()
  const [clientdata, setClientdata] = useState(null)
  const [FullName, setFullName] = useState(null)
  const VisitId = useContext(VisitProvider)
  const [diagnosis2, setDiagnosis2] = useState(null)
  const [dynamicVisitId, setDynamicVisitId] = useState(null)
  const [isAdult, setIsAdult] = useState(false)
  const [FirstName, setFirstName] = useState('')
  const [HeOrShe, setHeOrShe] = useState('')
  const [hisHer, sethisHer] = useState('')
  const [pdfContent, setPdfContent] = useState(null)
  const [pdfFilename, setPdfFilename] = useState(`${VisitId}.pdf`)
  const [loadingRepo, setLoadingRepo] = useState(false)
  const ClinicalReport = useRef({
    ServiceLine: 0,
    VisitId: 0,
    CompanyId: 0,
    CreatedBy: 0,
  })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!VisitId) return
    if (!userDetails) return

    if (userDetails) {
      //alert(JSON.stringify("its initialize"))
      setClientdata(userDetails)
      ClinicalReport.current.CompanyId = userDetails.CompanyId
      ClinicalReport.current.VisitId = VisitId
      ClinicalReport.current.CreatedBy = CreatedBy
      ClinicalReport.current.ServiceLine = 1

      setHeOrShe(userDetails.Sex === 1 ? 'He' : 'She')
      sethisHer(userDetails.Sex === 1 ? 'his' : 'her')
      setFullName(
        `${userDetails.LastName.toUpperCase()}, ${userDetails.FirstName.toUpperCase()}`,
      )
      const formattedFirstName =
        userDetails.FirstName.charAt(0).toUpperCase() +
        userDetails.FirstName.slice(1).toLowerCase()
      setFirstName(formattedFirstName)
      setDynamicVisitId(VisitId)

      if (userDetails.Age >= 18) {
        setIsAdult(true)
      }
    }

    setIsLoading(true)
    pocService
      .getPocData(VisitId)
      .then((data) => {
        setDBPOCDate(data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching POC data:', error)
        setIsLoading(false)
      })
  }, [VisitId, userDetails])

  // useEffect(() => {

  // }, [userDetails, reportGeneratedVisitId])

  useEffect(() => {
    if (dbPOCdata) {
      //alert(JSON.stringify(dbPOCdata))
      GeneratePDF()
    }
  }, [dbPOCdata, VisitId])

  const GeneratePDF = () => {
    if (dbPOCdata) {
      const doc = (
        <EvalPDFDocs
          pdfFilename={pdfFilename}
          POCdata={dbPOCdata}
          clientdata={clientdata}
          FullName={FullName}
          diagnosis2={diagnosis2}
          FirstName={FirstName}
          HeOrShe={HeOrShe}
          hisHer={hisHer}
          userDetails={userDetails}
          dynamicVisitId={VisitId}
          isAdult={isAdult}
        />
      )
      setPdfContent(pdf(doc))
    }
  }

  const uploadPDF = async () => {
    try {
      setIsGeneratingPDF(true)
      const blob = await pdfContent.toBlob()
      const formData = new FormData()
      formData.append('pdfFile', blob, pdfFilename)
      formData.append('clinicalreport', JSON.stringify(ClinicalReport.current))

      const response = await fetch(`${apiUrl}/POC/UploadPDF`, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        //alert('The PDF file has been saved to the drive successfully!')
        console.log('The PDF file has been saved to the drive successfully!')
        onPdfGenerationComplete(true)
      } else {
        throw new Error('Failed to upload PDF.')
      }
    } catch (error) {
      console.error('Error uploading PDF:', error)
      //alert('An error occurred while uploading the PDF.')
    } finally {
      setIsGeneratingPDF(false)
    }
  }

  const handleButtonClick = () => {
    setTimeout(uploadPDF, 10000) // Delayed execution after 12 seconds
  }
  useEffect(() => {
    //alert("it start")
    if (pdfContent) {
      setTimeout(() => {
        if (!isGeneratingPDF) {
          uploadPDF()
        }
      }, 12000) // 12 seconds delay
    }
  }, [pdfContent, userDetails])

  if (loadingRepo) return <div>Report is being generated</div>

  return (
    <>
      {/* <button onClick={handleButtonClick} disabled={isGeneratingPDF}>
        {isGeneratingPDF ? 'Uploading PDF...' : 'Upload PDF'}
      </button>
      {isGeneratingPDF && <p>Generating PDF...</p>} */}
    </>
  )
}

export default EvalPDFDownlaod
