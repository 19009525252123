import React, { useState, useEffect } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
//import { PragmaticsService } from '../FCM/Pragmatics/PragmaticsService'
import { PragmaticService } from '../FCM/Pragmatic/PragmaticService'
import CheckedBoxIcon from './Component/CheckBoxIcon'
import { UnCheckedBoxIcon } from './Component/CheckBoxIcon'
import {
  VerbalCommOptions,
  NonVerbalCommOptions,
  SocialInteractionOptions,
  PragVoiceOptions,
} from '../data/options'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const ContentPRAG = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  HeaderFcm,
}) => {
  const [assSumdata, setAssSumdata] = useState([])
  const [rptData, setRptData] = useState(null)
  const [nomsData, setNomsData] = useState([])
  const service = new PragmaticService()

  useEffect(() => {
    if (!VisitId) return
    service
      .getGetAssSummary(VisitId)
      .then((response) => {
        setAssSumdata(response)
        //alert(JSON.stringify(data));
      })
      .catch((error) => console.log('Error fetching data:', error))

    service
      .getPRAGData(VisitId)
      .then((response) => {
        //const data = response.data.Result
        //alert(JSON.stringify(response))
        if (response) {
          setRptData(response)
        }
      })
      .catch((error) => console.log('Error fetching data:', error))

    service
      .getNomsData(VisitId)
      .then((response) => {
        setNomsData(response)
      })
      .catch((error) => console.log('Error fetching data:', error))
  }, [VisitId])

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <>
      <View style={reportStyle.pageSpace}>
        {HeaderFcm === true && (
          <>
            <View style={reportStyle.TextHeaderTop}>
              <Text style={reportStyle.TextHeaderText}>
                Clinical Assessment
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName} was assessed against the following AHSA Functional
                Communication Measures (FCM):
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextHeaderText}>Pragmatics</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Based on the assessment & observations outlined herein, I found{' '}
            {FirstName}'s overall deficit to be{' '}
            {rptData && rptData.OverallRating}, and I believe Skilled Speech
            Therapy{' '}
            {rptData && rptData.IsSkilledTherapyIndicated ? 'is not' : 'is'}{' '}
            indicated for Pragmatics.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I scored the following assessments when evaluating {hisHer} current
            Pragmatics proficiency:
          </Text>
        </View>
        <View style={reportStyle.container}>
          <View style={reportStyle.tblheaderRow}>
            <Text style={reportStyle.tbldataCellAssessment}>Assessment</Text>
            <Text style={reportStyle.tblheaderCell}>Raw Score</Text>
            <Text style={reportStyle.tblheaderCell}>Std Score</Text>
            <Text style={reportStyle.tblheaderCell}>%ile Rank</Text>
          </View>
          {assSumdata.map((row, index) => (
            <View style={reportStyle.tbldataRow} key={row.RowId}>
              <Text style={reportStyle.tbldataCellAssessment}>
                {row.AssessmentSummaryDesc}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Score}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Code}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Rank}
              </Text>
            </View>
          ))}
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {FirstName}{' '}
            {rptData && rptData.FamilyHistory === 'Yes'
              ? 'has a family history of Pragmatics deficits'
              : 'does not have a family history of Pragmatics deficits'}{' '}
            {hisHer}{' '}
            {rptData && rptData.FamilyHistoryDesc
              ? rptData.FamilyHistoryDesc
              : ''}
            .
          </Text>
        </View>

        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Areas of Concern</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <View style={{ paddingLeft: 5 }}>
            <Text style={{ paddingBottom: 3 }}>Verbal Communication: </Text>
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {VerbalCommOptions.map((row, index) => (
                <View style={{ width: '20%', marginBottom: 4 }} key={row.id}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {rptData &&
                    rptData.VerbalComm &&
                    rptData.VerbalComm.toLowerCase()
                      .split(',')
                      .includes(row.label.toLowerCase()) ? (
                      <CheckedBoxIcon />
                    ) : (
                      <UnCheckedBoxIcon />
                    )}

                    <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                      {row.label}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <Text>{rptData && rptData.VerbalCommDesc}</Text>
            <Text style={{ paddingBottom: 3 }}>Non-Verbal Communication:</Text>
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {NonVerbalCommOptions.map((row, index) => (
                <View style={{ width: '20%', marginBottom: 4 }} key={row.id}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {rptData &&
                    rptData.NonVerbalComm &&
                    rptData.NonVerbalComm.toLowerCase()
                      .split(',')
                      .includes(row.label.toLowerCase()) ? (
                      <CheckedBoxIcon />
                    ) : (
                      <UnCheckedBoxIcon />
                    )}
                    <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                      {row.label}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <Text>{rptData && rptData.NonVerbalCommDesc}</Text>
            <Text style={{ paddingBottom: 3 }}>Social Interaction: </Text>
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {SocialInteractionOptions.map((row, index) => (
                <View style={{ width: '25%', marginBottom: 4 }} key={row.id}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {rptData &&
                    rptData.SocialInteraction &&
                    rptData.SocialInteraction.toLowerCase()
                      .split(',')
                      .includes(row.label.toLowerCase()) ? (
                      <CheckedBoxIcon />
                    ) : (
                      <UnCheckedBoxIcon />
                    )}

                    <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                      {row.label}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <Text>{rptData && rptData.SocialInteractionDesc}</Text>
            <Text style={{ paddingBottom: 3 }}>Voice: </Text>
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {PragVoiceOptions.map((row, index) => (
                <View style={{ width: '20%', marginBottom: 4 }} key={row.id}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {rptData &&
                    rptData.Voice &&
                    rptData.Voice.toLowerCase()
                      .split(',')
                      .includes(row.label.toLowerCase()) ? (
                      <CheckedBoxIcon />
                    ) : (
                      <UnCheckedBoxIcon />
                    )}

                    <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                      {row.label}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <Text>{rptData && rptData.VoiceDesc}</Text>
          </View>
        </View>

        {rptData && rptData.ImpactDailyActivities && (
          <>
            <View style={reportStyle.TextHeader}>
              <Text style={reportStyle.TextSubHeader}>
                Impact on Daily Activities :{' '}
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>{rptData && rptData.ImpactDailyActivities}</Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Observations</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Overall, I found {FirstName}'s {rptData && rptData.Result}
          </Text>
        </View>
        {rptData && rptData.IsSkilledTherapyIndicated === false && (
          <>
            {nomsData && nomsData.length > 0 && (
              <>
                <View style={reportStyle.paragraph}>
                  <Text>{FirstName} was able to:</Text>
                  <View style={[reportStyle.tblnoms, { paddingLeft: 10 }]}>
                    {nomsData.map((row, index) => (
                      <View style={reportStyle.dataRownoms} key={row.RowId}>
                        <Text style={[reportStyle.indexCell]}>
                          {index + 1})
                        </Text>
                        <Text style={[reportStyle.questionCell97]}>
                          {`${
                            row.Question &&
                            row.Question.replace(/\?/g, '')
                              .charAt(0)
                              .toUpperCase()
                          }${
                            row.Question.endsWith('?')
                              ? row.Question.slice(1, -1)
                              : row.Question.slice(1)
                          } ${row.Answer}.`}
                        </Text>
                      </View>
                    ))}
                  </View>{' '}
                </View>
              </>
            )}
          </>
        )}
      </View>
    </>
  )
}

export default ContentPRAG
