import React, { Component } from 'react'
import axios from 'axios'
import { Variables } from '../../Variables'
import { AssessSumService } from '../Component/AssessSumService'
import { NomsService } from '../Component/NomsService'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
const nomsService = new NomsService()
export class SwallowingService {
  constructor() {
    this.SwalId = 0
    this.VisitId = null
    this.History = null
    this.Symptoms = null
    this.SymptomsDescription = null
    this.StrategiesResult = null
    this.CurrentDietLiquid = 'Thin - L0'
    this.CurrentDietSolid = 'Regular - L7'
    this.CurrentDietMeds = 'Whole'
    this.Endurance = 'Good'
    this.MentalStatus = 'Alert'
    this.OralStatus = 'WNL'
    this.Respiratory = 'Room Air'
    this.Posture = '90 degrees'
    this.CranialNerveAssessment = null
    this.SafetyConcernsPrecaution = null
    this.CaregiverPatientEducated = 'Yes'
    this.Observations = null
    this.SwalTrailSummary = []
    this.OverallRating = 'Within Normal Limits'
    this.IsSkilledTherapyIndicated = false
    this.Result = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []

      // Assuming serviceAssessment.saveData returns a Promise
      const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)

      // Assuming serviceAssessment.saveData returns a valid axios post configuration
      if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
        reject(new Error('Invalid assessment Promise'))
        return
      }

      // Push the assessment promise to the apiCalls array
      apiCalls.push(assessmentPromise)

      // Push the second axios.post call to the apiCalls array
      apiCalls.push(axios.post(apiUrl + 'SWAL/InsertSWAL', formData))

      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }

      // Use Promise.all to wait for both promises to resolve
      Promise.all(apiCalls)
        .then((responses) => {
          // alert(JSON.stringify(responses))
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  getGetAssSummary(VisitId) {
    return serviceAssessment
      .getGetAssSummary(VisitId, 'SWAL')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getNomsData(VisitId) {
    return nomsService
      .getNomsData(VisitId, 'SWAL')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  deleteAssSumData(summId) {
    return serviceAssessment
      .deleteAssSumData(summId)
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  deleteSwallTrial(trialId) {
    return axios
      .delete(apiUrl + 'SWAL/DeleteSWAL/' + trialId)
      .then((response) => {
        return response.data // Return the data if needed
      })
      .catch((error) => {
        console.log('Error deleting data:', error)
      })
  }

  getSWALData(VisitId) {
    const axiosConfig = {
      timeout: 10000, // Timeout in milliseconds (adjust as needed)
    }
    return axios
      .get(apiUrl + 'SWAL/GetSWAL/' + VisitId, axiosConfig)
      .then((response) => {
        const data = response.data.Result
        if (data) {
          //alert(JSON.stringify(data));

          this.SwalId = data.SwalId
          this.VisitId = data.VisitId
          this.History = data.History
          this.Symptoms = data.Symptoms
          this.SymptomsDescription = data.SymptomsDescription
          this.StrategiesResult = data.StrategiesResult
          this.CurrentDietLiquid = data.CurrentDietLiquid
          this.CurrentDietSolid = data.CurrentDietSolid
          this.CurrentDietMeds = data.CurrentDietMeds
          this.Endurance = data.Endurance
          this.MentalStatus = data.MentalStatus
          this.OralStatus = data.OralStatus
          this.Respiratory = data.Respiratory
          this.Posture = data.Posture
          this.CranialNerveAssessment = data.CranialNerveAssessment
          this.SafetyConcernsPrecaution = data.SafetyConcernsPrecaution
          this.CaregiverPatientEducated = data.CaregiverPatientEducated
          this.Observations = data.Observations
          this.SwalTrailSummary = data.SwalTrailSummary
          this.OverallRating = data.OverallRating
          this.IsSkilledTherapyIndicated = data.IsSkilledTherapyIndicated
          this.Result = data.Result
          this.CreatedBy = data.CreatedBy
          this.UpdatedBy = data.UpdatedBy
          this.CreatedDate = data.CreatedDate

          return this
        }
      })
      .catch((error) => console.log('Error getSpeechData data:', error))
  }
}
