import React, { useState, useEffect, useRef } from 'react'
import { Form, FormControl, ListGroup } from 'react-bootstrap'

export const AutocompleteDropdown = ({ options, onSelect, initialValue }) => {
  const [searchValue, setSearchValue] = useState(initialValue || '')
  const [isOpen, setIsOpen] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState([])
  const dropdownRef = useRef(null)
  const [activeOptionIndex, setActiveOptionIndex] = useState(-1)
  const MAX_DISPLAYED_ITEMS = 10

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    // Update the searchValue when initialValue changes (e.g., when data is fetched from API)
    setSearchValue(initialValue || '')
  }, [initialValue])

  const handleInputChange = (event) => {
    const { value } = event.target
    setSearchValue(value)

    if (value.length >= 3) {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase()),
      )
      setFilteredOptions(filtered.slice(0, MAX_DISPLAYED_ITEMS))
      setIsOpen(true)
    } else {
      setFilteredOptions([])
      setIsOpen(false)
    }
    setActiveOptionIndex(-1) // Reset the active option index when input value changes
  }

  const handleKeyDown = (event) => {
    if (!isOpen) return

    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        setActiveOptionIndex((prevIndex) => Math.max(prevIndex - 1, 0))
        break
      case 'ArrowDown':
        event.preventDefault()
        setActiveOptionIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredOptions.length - 1),
        )
        break
      case 'Enter':
        event.preventDefault()
        if (activeOptionIndex !== -1 && filteredOptions[activeOptionIndex]) {
          handleOptionSelect(filteredOptions[activeOptionIndex].label)
        } else {
          onSelect(searchValue)
          //handleOptionSelect(searchValue);
        }
        setIsOpen(false)
        break
      case 'Escape':
        setIsOpen(false)
        break
      default:
        break
    }
  }

  const handleOptionSelect = (option) => {
    setSearchValue(option)
    onSelect(option)
    setIsOpen(false)
  }
  const handleInputBlur = () => {
    const value = searchValue
    //alert(value);
    if (value.length > 0) {
      // handleOptionSelect(value);
      onSelect(value)
    }
  }
  return (
    <div style={{ position: 'relative' }}>
      <FormControl
        className='form-control form-control-sm'
        type='text'
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        placeholder='Type to search...'
      />
      {isOpen && (
        <ListGroup
          ref={dropdownRef}
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            zIndex: 1,
          }}
        >
          {filteredOptions.map((option, index) => (
            <ListGroup.Item
              key={option.label}
              data-option-index={index}
              onClick={() => handleOptionSelect(option.label)}
              action
              tabIndex='0' // Make the option focusable
              active={index === activeOptionIndex} // Apply active style to the focused option
            >
              {option.label}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  )
}

// Usage example in DynamicGrid component remains the same...
