import React, { useState, useEffect, useContext } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
import formatDate from '../Utility/Utility'
import axios from 'axios'
import { Variables } from '../Variables'
import HeaderTextContent from './HeaderTextContent'
import { formatTime } from '../Utility/Utility'
import { AssessSumService } from '../FCM/Component/AssessSumService'
import { NomsService } from '../FCM/Component/NomsService'
import { LanguageComService } from '../FCM/LanguageComprehension/LanguageComService'
import CheckedBoxIcon from './Component/CheckBoxIcon'
import { UnCheckedBoxIcon } from './Component/CheckBoxIcon'
import { ComprehendsOptions, AnswerQuestionOptions } from '../data/options'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const fcmType = 'INT'
const ContentSLC = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  clientdata,
  pocData,
  Diagnosis2,
  HeaderFcm,
}) => {
  const [isYes] = useState('Yes')
  const [assSumdata, setAssSumdata] = useState([])
  const [sounData, setSoundData] = useState([])
  const [rptData, setRptData] = useState(null)
  const [nomsData, setNomsData] = useState([])
  const assessSumService = new AssessSumService()
  const nomsService = new NomsService()
  const languageComService = new LanguageComService()

  useEffect(() => {
    if (!VisitId) return
    //alert(Variables.API_URL + 'SpeechInt/GetAssSummary/' + VisitId+'/INT');
    languageComService
      .getGetAssSummary(VisitId)
      .then((response) => {
        setAssSumdata(response)
        //alert(JSON.stringify(data));
      })
      .catch((error) => console.log('Error fetching data:', error))

    languageComService
      .getLanComData(VisitId)
      .then((response) => {
        //const data = response.data.Result
        //alert(JSON.stringify(response))
        setRptData(response)
      })
      .catch((error) => console.log('Error fetching data:', error))

    languageComService
      .getNomsData(VisitId)
      .then((response) => {
        setNomsData(response)
      })
      .catch((error) => console.log('Error fetching data:', error))
  }, [VisitId, userDetails])

  return (
    <>
      <View style={reportStyle.pageSpace}>
        {/* <View style={reportStyle.headerTextContainerText}>
        <Text style={reportStyle.headerText}>
          Date of Evaluation:{' '}
          {rptData && formatDate(rptData.CreatedDate, false, true)}
          {'   '}
          <Text style={reportStyle.headerTextSpace}> </Text>
          Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
          {'   '}
          <Text style={reportStyle.headerTextSpace}> </Text>
          End: {clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
        </Text>
      </View> 
      <HeaderTextContent
        clientdata={clientdata}
        FullName={FullName}
        PocData={pocData}
        Diagnosis2={Diagnosis2}
      ></HeaderTextContent>*/}
        {HeaderFcm === true && (
          <>
            <View style={reportStyle.TextHeaderTop}>
              <Text style={reportStyle.TextHeaderText}>
                Clinical Assessment
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName} was assessed against the following AHSA Functional
                Communication Measures (FCM):
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          {' '}
          <Text style={reportStyle.TextHeaderText}>Language Comprehension</Text>
        </View>

        <View style={reportStyle.paragraph}>
          <Text>
            Based on the assessment & observations outlined herein, I found{' '}
            {FirstName}'s overall deficit to be{' '}
            {rptData && rptData.OverallRating}, and I believe Skilled Speech
            Therapy{' '}
            {rptData && rptData.SkilledTherapyIndicated ? 'is not' : 'is'}{' '}
            indicated for Language Comprehension.
          </Text>
        </View>
        {rptData && rptData.NonVerbal && (
          <>
            <View style={reportStyle.paragraph}>
              <Text>{FirstName} is currently Non-verbal.</Text>
            </View>
          </>
        )}
        <View style={reportStyle.paragraph}>
          <Text>
            I examined {FirstName}'s {rptData && rptData.LanguageTested}{' '}
            Language Comprehension{' '}
            {rptData && rptData.AACUse && <>using {rptData.AACUse} </>}
            and scored the following assessments when evaluating {hisHer}{' '}
            current speech & language proficiency:
          </Text>
        </View>
        <View style={reportStyle.container}>
          <View style={reportStyle.tblheaderRow}>
            <Text style={reportStyle.tbldataCellAssessment}>Assessment</Text>
            <Text style={reportStyle.tblheaderCell}>Raw Score</Text>
            <Text style={reportStyle.tblheaderCell}>Std Score</Text>
            <Text style={reportStyle.tblheaderCell}>%ile Rank</Text>
          </View>
          {assSumdata.map((row, index) => (
            <View style={reportStyle.tbldataRow} key={row.RowId}>
              <Text style={reportStyle.tbldataCellAssessment}>
                {row.AssessmentSummaryDesc}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Score}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Code}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Rank}
              </Text>
            </View>
          ))}
        </View>
        {/* <View style={reportStyle.paragraph}>
        <Text>
          Overall, I found {FirstName}{' '}
          {rptData && rptData.AssessmentDescription}.
        </Text>
      </View>
      <View style={reportStyle.paragraph}>
        <Text>
          {rptData && rptData.AssessmentType && (
            <>Indication of {rptData.AssessmentType} of Prosody is suspected.</>
          )}
        </Text>
      </View> */}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>
            Language Characteristics:
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {FirstName}'s comprehends the following language characteristics:
          </Text>
          {/* <CheckedBoxIcon /> */}
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {ComprehendsOptions.map((row, index) => (
              <View style={{ width: '16.666%', marginBottom: 4 }} key={row.id}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {rptData &&
                  rptData.Comprehend &&
                  rptData.Comprehend.split(',').includes(row.label) ? (
                    <CheckedBoxIcon />
                  ) : (
                    <UnCheckedBoxIcon />
                  )}

                  <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                    {row.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <Text>
            {HeOrShe} is also able to answer the following type(s) of questions:
          </Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {AnswerQuestionOptions.map((row, index) => (
              <View style={{ width: '16.666%', marginBottom: 4 }} key={row.id}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {rptData &&
                  rptData.AnswersQuestions &&
                  rptData.AnswersQuestions.split(',').includes(row.label) ? (
                    <CheckedBoxIcon />
                  ) : (
                    <UnCheckedBoxIcon />
                  )}

                  <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                    {row.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <Text>
            {capitalizeFirstChar(hisHer)} highest levels of proficiency are:
          </Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View style={{ width: '25%', marginBottom: 3 }}>
              <Text>
                Overall Complexity: {rptData && rptData.OverallComplexity}
              </Text>
            </View>
            <View style={{ width: '25%', marginBottom: 3 }}>
              <Text>Repeating Words: {rptData && rptData.RepeatingWord}</Text>
            </View>

            <View style={{ width: '25%', marginBottom: 3 }}>
              <Text>
                Repeating Sentences: {rptData && rptData.RepeatingSentence}
              </Text>
            </View>

            <View style={{ width: '25%', marginBottom: 3 }}>
              <Text>
                Following Directions: {rptData && rptData.FollowingDirection}
              </Text>
            </View>

            <View style={{ width: '25%', marginBottom: 3 }}>
              <Text>Sequencing: {rptData && rptData.Sequencing}</Text>
            </View>

            <View style={{ width: '25%', marginBottom: 3 }}>
              <Text>
                Speech Understanding: {rptData && rptData.SpeechUnderstanding}
              </Text>
            </View>
          </View>
        </View>
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Observations:</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Overall, I found {FirstName} {rptData && rptData.Result}
          </Text>
          <Text style={{ paddingLeft: 10 }}>
            1) {capitalizeFirstChar(hisHer)} verbal comprehension can be
            described as: {rptData && rptData.VerbalComprehension}
          </Text>
          <Text style={{ paddingLeft: 10 }}>
            2) {capitalizeFirstChar(hisHer)} general reading comprehension can
            be described as: {rptData && rptData.ReadingComprehension}
          </Text>
          <Text style={{ paddingLeft: 10 }}>
            3) {capitalizeFirstChar(hisHer)} ability to retell information can
            be described as as: {rptData && rptData.RetellingInformation}
          </Text>
          <Text style={{ paddingLeft: 10 }}>
            4) {capitalizeFirstChar(hisHer)} overall comprehension is described
            as: {rptData && rptData.ComprehensionDetails}
          </Text>
          <Text style={{ paddingLeft: 10 }}>
            5) {capitalizeFirstChar(hisHer)} general strengths & weaknesses are:{' '}
            {rptData && rptData.StrengthsWeaknesses}
          </Text>
        </View>
        {rptData && rptData.SkilledTherapyIndicated === false && (
          <>
            {nomsData && nomsData.length > 0 && (
              <>
                <View style={reportStyle.paragraph}>
                  <Text>{FirstName} was able to:</Text>
                  <View style={[reportStyle.tblnoms, { paddingLeft: 10 }]}>
                    {nomsData.map((row, index) => (
                      <View style={reportStyle.dataRownoms} key={row.RowId}>
                        <Text style={[reportStyle.indexCell]}>
                          {index + 1})
                        </Text>
                        <Text style={[reportStyle.questionCell97]}>
                          {`${
                            row.Question &&
                            row.Question.replace(/\?/g, '')
                              .charAt(0)
                              .toUpperCase()
                          }${
                            row.Question.endsWith('?')
                              ? row.Question.slice(1, -1)
                              : row.Question.slice(1)
                          } ${row.Answer}.`}

                          {/* {row.Question.endsWith('?')
                        ? row.Question.replace(/\?/g, '')
                            .charAt(0)
                            .toUpperCase() + row.Question.slice(0, -1)
                        : row.Question.replace(/\?/g, '')
                            .charAt(0)
                            .toUpperCase() + row.Question.slice(1)}{' '}
                      {row.Answer}. */}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              </>
            )}
          </>
        )}
      </View>
    </>
  )
}

export default ContentSLC
