import React, { useEffect } from 'react'
import { View, Image, Text } from '@react-pdf/renderer'
import { reportStyle, SoapNoteStyle } from '../CSS/reportStyle'
import formatDate from '../Utility/Utility'
import { formatTime } from '../Utility/Utility'
//import { useUser } from '../Provider/UserContext'
//import { useUser } from '../Provider/UserContext'

const ReportHeader = ({
  headerText,
  POCData,
  clientdata,
  isShowSubTitle = true,
}) => {
  // const { userDetails } = useUser()
  // useEffect(() => {
  //   //alert(userTypeId)
  //   if (userDetails) {

  //   }
  // })
  //alert(JSON.stringify(clientdata))
  return (
    <>
      {/* <View style={reportStyle.pageSpace}> */}
      <View style={reportStyle.header} fixed>
        {/* style=
          {[
            reportStyle.image,
            {
              height: 'column',
              marginBottom: '100pt',
            },
          ]} 
          { clientdata?.BrandLogo}*/}
        <Image
          src={
            clientdata?.BrandLogo
              ? './logoImage/' + clientdata?.BrandLogo
              : './logoImage/logo.png'
          }
          style={reportStyle.image}
        />
        <View style={reportStyle.headerTextContainer}>
          <Text>
            {headerText ? (
              <>{headerText}</>
            ) : (
              <>Speech & Language Evaluation Report</>
            )}
          </Text>
        </View>
      </View>
      {isShowSubTitle ? (
        <View style={reportStyle.headerTextContainerText} fixed>
          <Text style={reportStyle.headerText}>
            Date of Evaluation:{' '}
            {clientdata &&
              formatDate(clientdata.Scheduleddatefrom, false, true)}
            {'   '}
            <Text style={reportStyle.headerTextSpace}> </Text>
            Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
            {'   '}
            <Text style={reportStyle.headerTextSpace}> </Text>
            End: {clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
          </Text>
        </View>
      ) : (
        <View style={reportStyle.paragraph} fixed>
          <View style={SoapNoteStyle.headerTextContainerText}>
            <Text style={SoapNoteStyle.headerText}>
              Date of Follow Up:{' '}
              {clientdata &&
                formatDate(clientdata.Scheduleddatefrom, false, true)}
              {'   '}
              <Text style={SoapNoteStyle.headerTextSpace}> </Text>
              Start: {clientdata &&
                formatTime(clientdata.Scheduleddatefrom)}{' '}
              {'   '}
              <Text style={SoapNoteStyle.headerTextSpace}> </Text>
              End: {clientdata && formatTime(clientdata.Scheduleddateto)}{' '}
              {'   '}
              <Text style={SoapNoteStyle.headerTextSpace}> </Text>
              Visit: {clientdata && clientdata.VisitStatus}
            </Text>
          </View>
        </View>
      )}
      {/* </View> */}
    </>
  )
}

export default ReportHeader
