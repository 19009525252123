import React, { useState, useEffect } from 'react'
//import { TextBox } from '../Controls/TextBoxComponent'
//import { MultiSelectDDL } from '../Controls/MultiSelectDDL'

import MultiSelectDDL from './MultiSelectDDL'
export const NomsComp = ({
  defaultRow,
  onRowSelected,
  visitId,
  FCMType,
  Age,
}) => {
  const [gridData, setGridData] = useState([])
  useEffect(() => {
    var dataFile = null
    if (FCMType === 'INT') {
      dataFile = 'data/disINTNomsTo18.json'
    } else if (FCMType === 'SLE') {
      if (Age >= 6) {
        dataFile = 'data/disSLENoms6.json'
      } else if (Age < 6) {
        dataFile = 'data/disSLENoms35.json'
      }
    } else if (FCMType === 'SLC') {
      if (Age >= 6) {
        dataFile = 'data/disSLCNoms6.json'
      } else if (Age < 6) {
        dataFile = 'data/disSLCNoms35.json'
      }
    } else if (FCMType === 'COG') {
      if (Age >= 6) {
        dataFile = 'data/disCOGNoms6.json'
      } else if (Age < 6) {
        dataFile = 'data/disCOGNoms35.json'
      }
    } else if (FCMType === 'SWAL') {
      dataFile = 'data/disSWALNoms.json'
    }
    else if (FCMType === 'FEED') {
      dataFile = 'data/disFEEDNoms.json'
    }
    else if (FCMType === 'FLU') {
      dataFile = 'data/disFLUNoms.json'
    }
    else if (FCMType === 'AAC') {
      dataFile = 'data/disAACNoms.json'
    }
    else if (FCMType === 'PRAG') {
      if (Age >= 6) {
        dataFile = 'data/disPRAGNoms6.json'
      } else if (Age < 6) {
        dataFile = 'data/disPRAGNoms35.json'
      }
    }
    else if (FCMType === 'VOIC') {
      dataFile = 'data/disVOICNoms.json'
    }
    

    fetch(dataFile)
      .then((response) => response.json())
      .then((data) => {
        const updatedData = data.map((item) => ({
          ...item,
          VisitId: visitId,
          FCMType: FCMType,
        }))
        //alert(JSON.stringify(updatedData));
        setGridData(updatedData)
      })
      .catch((error) => {
        console.error('Error reading data:', error)
      })
  }, [FCMType, visitId])

  const [selectedRow, setSelectedRow] = useState(null)
  const [defaultRowProcessed, setDefaultRowProcessed] = useState(false)
  const [dropdownOptions, setDropdownOptions] = useState([
    { value: '0-25% of the time', text: '0-25% of the time' },
    { value: '26-49% of the time', text: '26-49% of the time' },
    { value: '50-75% of the time', text: '50-75% of the time' },
    { value: '76-90% of the time', text: '76-90% of the time' },
    { value: '91-100% of the time', text: '91-100% of the time' },
  ])

  useEffect(() => {
    if (!defaultRowProcessed && defaultRow && defaultRow.length > 0) {
      const updatedData = gridData.map((row) => {
        const matchingDefaultRow = defaultRow.find(
          (defaultRowItem) =>
            String(defaultRowItem.RowId) === String(row.RowId),
        )

        if (matchingDefaultRow) {
          return {
            ...row,
            Discharge_NomsId: matchingDefaultRow.Discharge_NomsId,
            Answer: matchingDefaultRow.Answer,
            QuestionCode: matchingDefaultRow.QuestionCode,
            //IsLongTermGoal:matchingDefaultRow.IsLongTermGoal
          }
        }

        return row
      })
      //console.log(JSON.stringify(updatedData));
      setGridData(updatedData)
      setSelectedRow(null) // Clear the selected row, as multiple rows might have changed
      onRowSelected(updatedData) // Notify parent about the updated rows
      setDefaultRowProcessed(true)
    }
  }, [defaultRow, defaultRowProcessed])

  const handleDropdownChange = (id, value) => {
    const selectedValue = value.trim() === '' ? dropdownOptions[0].value : value
    //alert(JSON.stringify(selectedValue))
    const updatedData = gridData.map((row) => {
      if (row.RowId === id) {
        return { ...row, Answer: selectedValue }
      }
      return row
    })
    //alert(JSON.stringify(updatedData))
    setGridData(updatedData)
    setSelectedRow(updatedData.find((row) => row.RowId === id))
    onRowSelected(updatedData)
  }

  // const getDropdownOptionsForRow = () => {
  //   //alert(JSON.stringify(dropdownOptions));
  //   return dropdownOptions
  // }
  const QSet1 = [
    { value: 'Regular', text: 'Regular' },
    { value: 'Soft & bite sized', text: 'Soft & bite sized' },
    { value: 'Minced & moist', text: 'Minced & moist' },
    { value: 'Pureed', text: 'Pureed' },
    { value: 'Liquidized', text: 'Liquidized' },
    { value: 'No solids', text: 'No solids' },
  ]

  const QSet2 = [
    { value: 'Thin liquid', text: 'Thin liquid' },
    { value: 'Slightly thick', text: 'Slightly thick' },
    { value: 'Mildly thick', text: 'Mildly thick' },
    { value: 'Moderately thick', text: 'Moderately thick' },
    { value: 'Extremely thick', text: 'Extremely thick' },
    { value: 'No liquids', text: 'No liquids' },
  ]
  const QSet3 = [
    { value: 'None', text: 'None' },
    { value: '<=25% of calorie intake', text: '<=25% of calorie intake' },
    { value: '26-49% of calorie intake', text: '26-49% of calorie intake' },
    { value: '50-75% of calorie intake', text: '50-75% of calorie intake' },
    { value: '>75% of calorie intake', text: '>75% of calorie intake' },
  ]

  const defaultOptions = [
    { value: '0-25% of the time', text: '0-25% of the time' },
    { value: '26-49% of the time', text: '26-49% of the time' },
    { value: '50-75% of the time', text: '50-75% of the time' },
    { value: '76-90% of the time', text: '76-90% of the time' },
    { value: '91-100% of the time', text: '91-100% of the time' },
  ]
  const getDropdownOptionsForRow = (qType) => {
    //alert(qType)
    switch (qType) {
      case 3:
        return QSet1
      case 4:
        return QSet2
      case 5:
        return QSet3
      default:
        return defaultOptions
    }
  }

  return (
    <table className='table table-striped'>
      <thead>
        <tr>
          <th style={{ width: '2%' }}>#</th>
          <th style={{ width: 'auto' }}>How often does the individual… </th>
          <th style={{ width: 'auto' }}>Response values</th>
        </tr>
      </thead>
      <tbody>
        {gridData.map((row) => (
          <tr key={row.RowId}>
            <td>{row.RowId}</td>
            <td>{row.Question}</td>
            <td>
              {(row.ResponseType === 0 ||
                row.ResponseType === 3 ||
                row.ResponseType === 4 ||
                row.ResponseType === 5) && (
                <>
                  {/* {row.ResponseType} */}
                  <select
                    className='form-select form-select-sm'
                    value={row.Answer}
                    onChange={(event) =>
                      handleDropdownChange(row.RowId, event.target.value)
                    }
                  >
                    {getDropdownOptionsForRow(row.ResponseType).map(
                      (option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ),
                    )}
                    {/* {getDropdownOptionsForRow().map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))} */}
                  </select>
                </>
              )}
              {/* {(row.ResponseType === '0' ||
                row.ResponseType === '3' ||
                row.ResponseType === '4' ||
                row.ResponseType === '5') && (
                <>{row.ResponseType}
                  <select
                    className='form-select form-select-sm'
                    value={row.Answer}
                    onChange={(event) =>
                      handleDropdownChange(row.RowId, event.target.value)
                    }
                  >
                    {getDropdownOptionsForRow(row.ResponseType).map(
                      (option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ),
                    )}
                   
                  </select>
                </>
              )} */}
              {row.ResponseType === 1 && (
                <>
                  <input
                    type='text'
                    size='sm'
                    value={row.Answer}
                    name={row.RowId}
                    className={`form-control form-control-sm`}
                    onChange={(event) =>
                      handleDropdownChange(row.RowId, event.target.value)
                    }
                    placeholder={row.Question}
                    maxLength={100}
                  />
                </>
              )}
              {row.ResponseType === 2 && (
                <>
                  <MultiSelectDDL
                    type={row.RowId}
                    closeMenuOnSelect={false}
                    value={row.Answer}
                    onChange={(e) => handleDropdownChange(row.RowId, e)}
                  />
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
