import { configureStore } from '@reduxjs/toolkit';
import pocReducer from './pocSlice';  // Import your reducer for POC data
import headerDataReducer from './headerTabDataSlice';  // Import your reducer for header data
import supervisorReducer from './supervisorSlice';  // Import your reducer for supervisor data

const store = configureStore({
  reducer: {
    poc: pocReducer,  // Register the POC reducer
    headerData: headerDataReducer,  // Register the header data reducer
    supervisorDetail: supervisorReducer,  // Register the supervisor data reducer
  },
});

export default store;
