import React, { Component } from 'react'
import axios from 'axios'
import { Variables } from '../../Variables'
import jsPDF from 'jspdf'
import { AssessSumService } from '../../FCM/Component/AssessSumService'

const apiUrl = Variables.API_URL
const assessSumService = new AssessSumService()
export class AssessIntService {
  constructor() {
    this.SpeechIntelligibilityId = 0
    this.VisitId = 0
    this.SPP_Level = 'Within Normal Limits'
    this.SPP_Type = null
    this.SPP_Description = null
    this.PP_Level = 'Within Normal Limits'
    this.PP_Type = null
    this.Stimulability_Rating = 'Good'
    this.IsSkilledSpeechTherapy = false
    this.AssessmentType = null
    this.AssessmentDescription = null
    this.PP_Other = null
    this.OverallIntRating = 'Within Normal Limits'
    this.LanguageTested = 'English'
    this.LanguageOther = null
    this.HighestLevel = 'Conversation'
    this.Percentage = '100'
    this.OralLingual = 'Within Normal Limits'
    this.OralLabial = 'Within Normal Limits'
    this.OralBuccal = 'Within Normal Limits'
    this.OralFacial = 'Within Normal Limits'
    this.OralDescription = null
    this.DiadochokineticRate = 'Within Normal Limits'
    this.FluencySpeech = 'Within Normal Limits'
    this.AwareDeficit = 'Yes'
    this.Prosody = 'Within Normal Limits'
    this.DysarthriaType = null
    this.ImpactOnDailyLife = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []
      //alert(formData.SpeechelligibilityId);
      apiCalls.push(
        assessSumService.saveData(updateAssmentSummary),
        // axios.post(
        //   apiUrl + 'AssessmentSummary/CreateAssSummary',
        //   updateAssmentSummary,
        // ),
      )
      //console.log(JSON.stringify(formData));
      if (
        formData.SpeechelligibilityId === undefined ||
        formData.SpeechelligibilityId === 0
      ) {
        apiCalls.push(
          axios.post(apiUrl + 'SpeechInt/CreateSpeechInt', formData),
        )
      } else {
        apiCalls.push(axios.put(apiUrl + 'SpeechInt/UpdateSpeechInt', formData))
      }

      if (apiCalls.length === 0) {
        console.error('No promises to resolve.') // Add an error message for debugging
        reject(new Error('No promises to resolve.')) // Reject the promise
      }

      Promise.all(apiCalls)
        .then((responses) => {
          //alert("Data saved successfully!");
          // triggerRefresh(!formData.IsSkilledSpeechTherapy);
          resolve(responses) // Resolve the promise with the responses
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          // Handle the error here
          reject(error) // Reject the promise with the error
        })
    })
  }

  getGetAssSummary(VisitId) {
    // alert(VisitId)
    return assessSumService
      .getGetAssSummary(VisitId, 'INT')
      .then((data) => {
        // console.log("Data received:", data);
        //alert(JSON.stringify(data))
        return data;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error to propagate it further
      });
  }

  deleteAssSumData(summId) {
    return axios
      .delete(apiUrl + 'SpeechInt/DeleteAssSummary/' + summId)
      .then((response) => {
        return response.data // Return the data if needed
      })
      .catch((error) => {
        console.log('Error deleting data:', error)
      })
  }

  getSpeechData(VisitId) {
    return axios
      .get(apiUrl + 'SpeechInt/GetSpeechInt/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data) {
          // alert(JSON.stringify(data));

          this.SpeechIntelligibilityId = data.SpeechIntelligibilityId
          this.VisitId = data.VisitId
          this.SPP_Level = data.SPP_Level
          this.SPP_Type = data.SPP_Type
          this.SPP_Description = data.SPP_Description
          this.PP_Level = data.PP_Level
          this.PP_Type = data.PP_Type
          this.PP_Other = data.PP_Other
          this.OverallIntRating = data.OverallIntRating
          this.Stimulability_Rating = data.Stimulability_Rating
          this.AssessmentType = data.AssessmentType
          this.AssessmentDescription = data.AssessmentDescription
          this.LanguageTested = data.LanguageTested
          this.IsSkilledSpeechTherapy = data.IsSkilledSpeechTherapy
          this.LanguageTested = data.LanguageTested
          this.LanguageOther = data.LanguageOther
          this.HighestLevel = data.HighestLevel
          this.Percentage = data.Percentage
          this.OralLingual = data.OralLingual
          this.OralLabial = data.OralLabial
          this.OralBuccal = data.OralBuccal
          this.OralFacial = data.OralFacial
          this.OralDescription = data.OralDescription
          this.DiadochokineticRate = data.DiadochokineticRate
          this.FluencySpeech = data.FluencySpeech
          this.AwareDeficit = data.AwareDeficit
          this.Prosody = data.Prosody
          this.DysarthriaType = data.DysarthriaType
          this.ImpactOnDailyLife = data.ImpactOnDailyLife
          this.CreatedDate = data.CreatedDate

          return this
        }
      })
      .catch((error) => console.log('Error getSpeechData data:', error))
  }
}
