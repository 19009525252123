import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { Checkbox } from '../../Controls/CheckBoxList'
import { TextareaComponent } from '../../Controls/TextareaComponent '
import { VisitProvider, SignLockProvider } from '../../Provider/Visitprovider'
import { useUser } from '../../Provider/UserContext'
import { SoapNoteService } from './SoapNoteService'
import { HeaderUI } from './HeaderUI'
import { CProgressReportUI } from '../CProgressReportUI'
import HorizontalLine from '../../Controls/HorizontalLine'
import signLockService from '../../POC/SignLock/Service'
import { toast } from 'react-toastify'
import format from 'date-fns/format'
import SupervisorContext from '../../Provider/Visitprovider'
import SoapNoteMain from '../../PDFGenerator/NotesPDF/SoapNoteMain'
import { VisitTypeId, NoteType } from '../../Enum'
import { RadioButtonlist } from '../../Controls/RadioButtonlist'
import { CPTCodeBillingUI } from '../../FCM/Component/CPTCodeBillingUI'
import { CPTBillingCodeService } from '../../FCM/Component/CPTBillingCodeService'
import { PocService } from '../../POC/Service/PocService'
import {
  CreatedByProvider,
  UserTypeIdProvider,
} from '../../Provider/Visitprovider'
import Modal from 'react-modal'

import SoapNotesPDFDownload from '../../PDFGenerator/NotesPDF/SoapNotesPDFDownload'
import { ProgressNoteService } from '../ProgressNotes/ProgressNoteService'
//import ProgNotesPDFDownload from '../../PDFGenerator/NotesPDF/ProgNotesPDFDownload'
import ProgNotesPDFDownload from '../../PDFGenerator/NotesPDF/ProgNotesPDFDownload'

import { useDispatch, useSelector } from 'react-redux'
import { supervisorDetail, clearData } from '../../store/supervisorSlice'

const underlineStyle = {
  textDecoration: 'underline',
}

export const SoapNoteUI = forwardRef(({ triggerDisplayNote }, ref) => {
  const { userFirstName } = useUser()
  const { userDetails } = useUser()
  const CreatedBy = useContext(CreatedByProvider)
  const userTypeId = useContext(UserTypeIdProvider)

  const dispatch = useDispatch()
  const supervisorSLPDetail = useSelector((state) => state.supervisorDetail)

  const [clientId, setClientId] = useState(null)
  const [visitTypeId, setVisitTypeId] = useState(0)
  const VisitId = useContext(VisitProvider)
  const [IsSignLock, setIsSignLock] = useState(false)
  const { supervisorValue } = useContext(SupervisorContext)
  const soapNoteService = new SoapNoteService()
  const [loadingHeader, setLoadingHeader] = useState(true)
  const [loadingObjectiveFinding, setLoadingObjectiveFinding] = useState(true)
  const [stGoalData, setStGoalData] = useState([])
  const [refreshKey, setRefreshKey] = useState(0)
  const [cptCodeList, setCPTCodeList] = useState([])
  const [cptBillingCodeList, setCptBillingCodeList] = useState([])
  const cptBillingCode = new CPTBillingCodeService()
  const pocService = new PocService()
  const [IsCPTCodeBase, setIsCPTCodeBase] = useState(false)
  const [RateTypeId, setRateTypeId] = useState(0)
  const [TherapistId, setTherapistId] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [refreshTriggerDisplayNote, setRefreshTriggerDisplayNote] =
    useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [visitDuration, setVisitDuration] = useState(0)
  const [modelMessage, setmodelMessage] = useState('')
  const [cpCodeList, setcpCodeList] = useState('')
  const [ResultErrorPlanNextSession, setResultErrorPlanNextSession] =
    useState(false)
  const [ResultErrorSubject, setResultErrorSubject] = useState(false)
  const [ResultErrorAssessment, setResultErrorAssessment] = useState(false)
  const [supervisorName, setSupervisorname] = useState([])
  const [IsTreatmentCodeBasedPayor, setIsTreatmentCodeBasedPayor] = useState()

  const [formData, setFormData] = useState({
    SoapNoteId: 0,
    VisitId: 0,
    ClientId: 0,
    TherapistId: null,
    SubjectiveObservation: null,
    AssessmentofProgress: null,
    PlanNextSession: null,
    IsRequiresObservation: false,
    PNGoalListItems: [],
    SupervisorName: null,
    DisplayNoteTab: '0',
    CreatedBy: CreatedBy,
    UpdatedBy: CreatedBy,
    StopRequestingAuth: false,
  })

  const calculateMinutesDifference = (dateFrom, dateTo) => {
    const startDate = new Date(dateFrom)
    const endDate = new Date(dateTo)
    const diffInMilliseconds = endDate - startDate
    const diffInMinutes = diffInMilliseconds / (1000 * 60)
    return diffInMinutes
  }
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (userDetails) {
      //alert(JSON.stringify(VisitId))
      signLockService.GetVisitByID(VisitId).then((response) => {
        if (response) {
          var IsTreatmentCodeBasedPayor =
            response.Result.IsTreatmentCodeBasedPayor
          //alert(JSON.stringify(response.Result.IsTreatmentCodeBasedPayor))
          setIsTreatmentCodeBasedPayor(IsTreatmentCodeBasedPayor)

          if (IsTreatmentCodeBasedPayor === true) {
            setIsLoading(true)
            signLockService
              .getAuthorizedSLPBillingSoapNoteCPTCodes(VisitId)
              .then((data) => {
                const transformedCptCode = data.map((item) => ({
                  value: item.CPTCode,
                  label: item.Description,
                  remainingUnit: item.RemainingUnits,
                }))
                
                if (IsSignLock === true) {
                  setCptBillingCodeList(transformedCptCode)
                } else {
                  var filteredCptCode = transformedCptCode.filter(
                    (item) => item.remainingUnit > 0,
                  )
                  setCPTCodeList(filteredCptCode)
                }
                //alert(JSON.stringify(transformedCptCode))
                //setCPTCodeList(transformedCptCode)
              })
              .catch((error) => {
                console.error('Error fetching CPT Code data:', error)
              })
              .finally(() => {
                setIsLoading(false)
              })
            //alert("if "+IsTreatmentCodeBasedPayor)
          } else {
            signLockService
              .GetSLPBilling(VisitId)
              .then((data) => {
                const transformedCptCode = data.map((item) => ({
                  value: item.Value,
                  label: item.Text,
                }))
                //alert(JSON.stringify(transformedCptCode))
                //alert("else "+IsTreatmentCodeBasedPayor)
                setCPTCodeList(transformedCptCode)
              })
              .catch((error) => {
                console.error('Error fetching CPT Code data:', error)
              })
              .finally(() => {
                setIsLoading(false)
              })
          }
        }
      })
      //alert(JSON.stringify(userDetails))
      // console.log(JSON.stringify(userDetails));
      //Scheduleddatefrom":"2024-03-18T14:30:00","Scheduleddateto":"2024-03-18T15:00:00"
      var visitDurationData = calculateMinutesDifference(
        userDetails.Scheduleddatefrom,
        userDetails.Scheduleddateto,
      )
      setVisitDuration(visitDurationData)
      //alert(JSON.stringify(userDetails.Scheduleddatefrom))
      setClientId(userDetails.Clientid)
      setVisitTypeId(userDetails.VisitTypeId)
      //formData.ClientId = userDetails.Clientid
      formData.VisitId = VisitId
      formData.ClientId = userDetails.Clientid
      formData.TherapistId = userDetails.TherapistId
      setTherapistId(userDetails.TherapistId)
      formData.CreatedBy = userDetails.TherapistId
      setIsCPTCodeBase(userDetails.IsCPTCodeBase) //RateTypeId
      setRateTypeId(userDetails.RateTypeId)
    }
  }, [userDetails, VisitId])

  useEffect(() => {
    if (refreshTriggerDisplayNote) {
      const displayNoteTabNumber = parseInt(formData.DisplayNoteTab)
      // alert(displayNoteTabNumber) // Verify the parsed number
      triggerDisplayNote(displayNoteTabNumber)
    }
  }, [refreshTriggerDisplayNote])

  const FetchingSignAndLock = () => {
    signLockService
      .getSignLockDataByNoteType(VisitId, NoteType.SoapNote)
      .then((response) => {
        if (response) {
          let isSignedAndLocked = response.IsSignedAndLocked
          isSignedAndLocked = isSignedAndLocked || false
          //alert(JSON.stringify(response));
          setIsSignLock(isSignedAndLocked)
        }
        // alert("hiih");
      })
      .catch((error) => {
        console.error('Error FetchingSignAndLock:', error)
      })
  }
  useEffect(() => {
    if (VisitId) {
      FetchingSignAndLock()
    }
  }, [VisitId])

  const handleSTDataChange = (newData) => {
    const updatedData = newData.map((item) => ({
      ...item,
      CreatedBy: userDetails.TherapistId,
      UpdatedBy: userDetails.TherapistId,
    }))
    setStGoalData(updatedData)
    //console.log('Goaldata: ' + JSON.stringify(updatedData))
  }

  // const handleSTDataChange = (newData) => {
  //   setStGoalData(newData)
  //   console.log('Goaldata: ' + JSON.stringify(newData))
  // }
  const fetchSupervisors = (supervisorList, SupervisorName) => {
    try {
      if (supervisorList && supervisorList.length > 0) {
        if (!SupervisorName) {
          setFormData((prevData) => ({
            ...prevData,
            SupervisorName: supervisorList[0].SupervisorName,
          }))

          soapNoteService
            .getLicenseNoByClient(supervisorList[0].SupervisorId, clientId)
            .then((licienseData) => {
              licienseData.SupervisorName = supervisorList[0].SupervisorName
              dispatch(supervisorDetail(licienseData))
            })
            .catch((error) => {
              console.error('Error fetching License No:', error)
            })
        }

        if (SupervisorName) {
          const filteredSupervisors = supervisorList.filter((option) =>
            option.SupervisorName.toLowerCase().includes(
              SupervisorName.toLowerCase(),
            ),
          )

          if (filteredSupervisors.length > 0) {
            soapNoteService
              .getLicenseNoByClient(
                filteredSupervisors[0].SupervisorId,
                clientId,
              )
              .then((licienseData) => {
                licienseData.SupervisorName =
                  filteredSupervisors[0].SupervisorName
                dispatch(supervisorDetail(licienseData))
              })
              .catch((error) => {
                console.error('Error fetching License No:', error)
              })
          }
        }
      }
    } catch (error) {
      console.error('Failed to fetch supervisors', error)
    }
  }

  useEffect(() => {
    //alert(VisitId)
    if (clientId === null || VisitId === null) return
    setLoadingHeader(true)
    // Add this check to ensure clientId is not null
    const fetchData = async () => {
      //alert(userDetails)

      cptBillingCode.getCPTBillingCode(VisitId).then((data) => {
        setCptBillingCodeList(data)
      })

      // Fetch Supervisor List
      const supervisorList = await soapNoteService.getSupervisors(
        userDetails.TherapistId,
      )
      //alert('supervisorList',JSON.stringify(supervisorList))
      setSupervisorname(supervisorList)
      soapNoteService
        .getSoapNoteData(VisitId, clientId, visitTypeId)
        .then((dataFromResponse) => {
          if (dataFromResponse.SoapNoteId === 0) {
            if (dataFromResponse.PNGoalListItems.length !== 0) {
              formData.PNGoalListItems = dataFromResponse.PNGoalListItems
            } else {
              alert(
                'Their is no Eval/ReEval data. Please fill the Eval/ReEval data first.',
              )
            }
            setFormData(formData)
          } else {
            //setAssigsupervisorName(dataFromResponse.SupervisorName)
            setFormData(dataFromResponse)
            //alert(JSON.stringify(dataFromResponse.SupervisorName))
            fetchSupervisors(supervisorList, dataFromResponse.SupervisorName)

            // alert(JSON.stringify(dataFromResponse))
          }
          //alert(JSON.stringify(dataFromResponse.DisplayNoteTab));
          triggerDisplayNote(dataFromResponse.DisplayNoteTab)
        })
        .catch((error) => {
          console.error('Error fetching Soap Note data:', error)
          alert(
            'Their is no Eval/ReEval data. Please fill the Eval/ReEval data first.',
          )
        })
        .finally(() => {
          setLoadingHeader(false)
          setLoadingObjectiveFinding(false)
        })
    }
    fetchData()
  }, [clientId, VisitId, refreshKey])

  const handleCheckboxChange = (columnName, checked) => {
    const updatedFormData = { ...formData, [columnName]: checked }
    setFormData(updatedFormData)
    setIsDirty(true)
  }

  const handleTextValueChange = (controlName, value) => {
    const valueControl = controlName
    const updatedFormData = {
      ...formData,
      [valueControl]: value,
    }
    setFormData(updatedFormData)
  }
  const handleSupervisorChange = (fieldName) => async (event) => {
    // Update the form data with the selected value
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: event.target.value,
    }))

    // Filter the supervisors based on the entered value
    const filteredSupervisors = supervisorName.filter((option) =>
      option.SupervisorName.toLowerCase().includes(
        event.target.value.toLowerCase(),
      ),
    )

    // Check if there's at least one filtered supervisor
    if (filteredSupervisors && filteredSupervisors.length > 0) {
      try {
        // Get license data for the first filtered supervisor
        const supervisor = filteredSupervisors[0] // Take the first matched supervisor
        const licienseData = await soapNoteService.getLicenseNoByClient(
          supervisor.SupervisorId,
          clientId,
        )

        // Add the SupervisorName to the license data
        licienseData.SupervisorName = supervisor.SupervisorName

        // Dispatch the license data to Redux
        dispatch(supervisorDetail(licienseData))
      } catch (error) {
        console.error('Error fetching license data:', error)
      }
    }

    // Mark the form as dirty (modified)
    setIsDirty(true)
  }
  //   const handleSupervisorChange = (fieldName) => (event) => {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [fieldName]: event.target.value,
  //     }))

  //     const filteredSupervisors = supervisorName.filter((option) =>
  //       option.SupervisorName.toLowerCase().includes(event.target.value.toLowerCase()),
  //     )
  // if(filteredSupervisors && filteredSupervisors.length>0){

  //       var licienseData = soapNoteService.getLicenseNoByClient(
  //         filteredSupervisors.SupervisorId,
  //         clientId,
  //       )
  //       licienseData.SupervisorName = supervisorList[0].SupervisorName
  //       //alert(JSON.stringify(licienseData))
  //       dispatch(supervisorDetail(licienseData))
  //     }
  //     //alert(JSON.stringify(filteredSupervisors))

  //     setIsDirty(true)
  //   }

  useImperativeHandle(ref, () => ({
    handleButtonClick,
  }))
  const getCPTCodeLabel = (CPTCode) => {
    const cptCode = cptCodeList?.find(
      (cptCodeItem) => cptCodeItem.value === CPTCode.toString(),
    )
    //alert("filter : "+JSON.stringify(cptCodeList))
    return cptCode ? cptCode.label : ''
  }

  const handleButtonClick = (showAlert = true) => {
    //alert(JSON.stringify(formData.SupervisorName))
    //return;
    if (showAlert === false) {
      FetchingSignAndLock()
    }
    if (IsSignLock === true || !isDirty) {
      return
    }

    const isValid = ValidationObject()
    if (!isValid) {
      toast.error('Kindly fill the all required field(s).')
      return
    }
    const validRows = cptBillingCodeList.filter(
      (row) => row.Units > 0 && row.CPTCode > 0,
    )
    // alert('IsTreatmentCodeBasedPayor:' + JSON.stringify(validRows))
    // Check if CPT Codes are required and the list is empty
    if (
      (IsTreatmentCodeBasedPayor === true || IsCPTCodeBase === true) &&
      validRows.length === 0
    ) {
      toast.error('Please select CPT Code.')
      return
    }
    //return;
    setIsSaving(true)

    if (cptBillingCodeList && cptBillingCodeList.length > 0) {
      //alert('cptBillingCodeList:' + JSON.stringify(cptBillingCodeList))

      const updatedRowsDesc = validRows.map((row) => ({
        ...row,
        CPTCodeDescription:
          row.CPTCodeDescription || getCPTCodeLabel(row.CPTCode),
      }))

      //   alert('CPT Codes: ' + cptCodesString);
      // return;
      cptBillingCode.saveData(updatedRowsDesc)
    }
    if (formData.PNGoalListItems.length === 0) {
      alert(
        'Their is no Eval/ReEval data. Please fill the Eval/ReEval data first.',
      )
      return
    }
    if (stGoalData) {
      formData.PNGoalListItems = stGoalData.filter(
        (item) => item.GoalType !== 'LT',
      )
    }

    const saveDOS = {
      VisitId: VisitId,
      ClientId: clientId,
      VisitDateFrom: userDetails.Scheduleddatefrom,
      VisitDateTo: userDetails.Scheduleddateto,
      UpdateBy: userDetails.TherapistId,
      UpdateDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      IsObservation: formData.IsRequiresObservation,
      ...(supervisorValue !== null && {
        SupervisingTherapistId: supervisorValue,
      }),
    }
    // formData.VisitId = VisitId
    // formData.ClientId = clientId
    formData.UpdatedBy = userDetails.TherapistId
    const promise1 = soapNoteService.insertSoapnoteData(formData)
    const promise2 = soapNoteService.insertSaveDOS(saveDOS)

    Promise.all([promise1, promise2])
      .then(([response1, response2]) => {
        formData.SoapNoteId = response1.Result
        //alert(formData.DisplayNoteTab)
        setRefreshTriggerDisplayNote(true)
        //triggerDisplayNote(formData.DisplayNoteTab)
        // setRefreshKey((prevKey) => prevKey + 1)//i dont know why i have added this--ashish 17 may 2024
        if (showAlert) {
          toast.success(response1.MessageDetail)
          //toast.success(response2.MessageDetail);
        }
      })
      .catch((error) => {
        console.error('Error inserting Soap Note data:', error)
      })
      .finally(() => {
        setIsSaving(false)
        setIsDirty(false)
      })
  }
  const ValidationObject = () => {
    let isValid = true
    if (formData.PlanNextSession === null || formData.PlanNextSession === '') {
      setResultErrorPlanNextSession(true)
      isValid = false
    }
    if (
      formData.SubjectiveObservation === null ||
      formData.SubjectiveObservation === ''
    ) {
      setResultErrorSubject(true)
      isValid = false
    }
    if (
      formData.AssessmentofProgress === null ||
      formData.AssessmentofProgress === ''
    ) {
      setResultErrorAssessment(true)
      isValid = false
    }

    return isValid
  }
  const [pdfIsReadyforUpload, setPdfIsReadyforUpload] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [confirmSignLock, setConfirmSignLock] = useState(false)
  const [buttonText, setButtonText] = useState('Sign and Lock')
  const [isProcessing, setIsProcessing] = useState(false)

  const [regenerateReportButton, setRegenerateReportButton] =
    useState('Generate Report')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handlePdfReGenerationComplete = () => {
    const tabPnote =
      document.querySelector('[data-rr-ui-event-key="PN"]') !== null
    //alert('tabPnote:' + tabPnote)
    if (tabPnote === true) {
      setIsProgressSummaryData(true)
    }
    setRegenerateReportButton('Generating Report...')
    setIsButtonDisabled(true)

    setTimeout(() => {
      setPdfIsReadyforUpload(true)
      setRegenerateReportButton('Regenerate Report')
      setIsButtonDisabled(false)
      alert('Report generated successfully!') // Move alert inside setTimeout
    }, 12000)
  }

  const handlePdfGenerationComplete = (success) => {
    // alert(success)
    setIsSuccessful(success)
    if (!success) {
      setIsProcessing(false)
      setPdfIsReadyforUpload(false)
      setButtonText('Sign and Lock')
      toast.error(
        'An error occurred with the sign and lock process. Please try again.',
        {
          className: 'custom-toast',
          style: { width: '550px' },
        },
      )
    }
  }
  //To check progree summary data
  const progressNoteService = new ProgressNoteService()
  const [ProgressSummaryData, setProgressSummaryData] = useState(null)
  const [isProgressSummaryData, setIsProgressSummaryData] = useState(false)
  useEffect(() => {
    if (clientId !== null && VisitId !== null) {
      const fetchData = () => {
        progressNoteService
          .getProgressNotes(VisitId, clientId)
          .then((dataFromResponse) => {
            //alert('progress data:' + JSON.stringify(dataFromResponse))
            setProgressSummaryData(dataFromResponse)
          })
          .catch((error) => {
            console.error('Error fetching PocDTO data:', error)
          })
      }

      fetchData()
    }
    // }
  }, [clientId, VisitId])

  const handleSignLockModelClick = () => {
    const validRows = cptBillingCodeList.filter(
      (row) => row.Units > 0 && row.CPTCode > 0,
    )
    // alert('IsTreatmentCodeBasedPayor:' + JSON.stringify(validRows))
    // Check if CPT Codes are required and the list is empty
    if (
      (IsTreatmentCodeBasedPayor === true || IsCPTCodeBase === true) &&
      validRows.length === 0
    ) {
      toast.error('Please select CPT Code.')
      return
    }
    //to check the element exists or not for data validation
    const tabPnote =
      document.querySelector('[data-rr-ui-event-key="PN"]') !== null
    //alert('tabPnote:' + tabPnote)
    if (tabPnote === true) {
      setIsProgressSummaryData(true)
      //alert(tabPnote)
      //alert('ProgressSummaryData:' + JSON.stringify(ProgressSummaryData))
      if (
        ProgressSummaryData &&
        ProgressSummaryData.ProgressDischargeId === 0
      ) {
        alert(
          'Kindly submit/save the Progress summary data first then do the sign and lock.',
        )
        return
      }
    }

    //alert('return ho gaya')
    //return
    if (formData.PNGoalListItems.length === 0) {
      alert(
        'Their is no Eval/ReEval data. Please fill the Eval/ReEval data first.',
      )
      return
    }
    //alert(JSON.stringify(cptCodeList))
    const isValid = ValidationObject()
    if (!isValid) {
      toast.error('Kindly fill the all required field(s).')
      return
    }
    const today = new Date()
    const scheduledDate = new Date(userDetails.Scheduleddatefrom)

    if (scheduledDate > today) {
      alert('Future visit cannot be signed and locked.')
      return
    }

    const cptCodesString = cptCodeList
      ?.map((row) => row.value) // or row.CPTCodeDescription if needed
      .join(', ')

    let confirmed
    if (IsCPTCodeBase === true) {
      if (visitDuration === 30 && cptBillingCodeList.length < 2) {
        confirmed =
          //window.confirm( )
          `Please verify that you have billed all of the correct codes: ${cptCodesString}. More than one (1) code should be used if necessary. Continue with sign/lock?`

        // return
      } else if (visitDuration === 45 && cptBillingCodeList.length < 3) {
        confirmed =
          //window.confirm()
          `Please verify that you have billed all of the correct codes: ${cptCodesString}. More than one (2) code should be used if necessary. Continue with sign/lock?`

        //return
      } else if (visitDuration === 60 && cptBillingCodeList.length < 4) {
        confirmed =
          //window.confirm( )
          `Please verify that you have billed all of the correct codes: ${cptCodesString}. More than one (3) code should be used if necessary. Continue with sign/lock?`
        // return
      }
    } else {
      confirmed = 'Are you sure you want to sign and lock?' //window.confirm('Are you sure you want to sign and lock?')
    }
    //alert(confirmed)
    //return;
    if (confirmed) {
      setmodelMessage(confirmed)
      setModalOpen(true)
    } else {
      setmodelMessage('Are you sure you want to sign and lock?')
      setModalOpen(true)
    }
  }
  const handleSignLockConfirmClick = () => {
    setPdfIsReadyforUpload(true)
    setPdfIsReadyforUpload(true)
    setIsProcessing(true)
    setModalOpen(false)
    setButtonText('in process...')
  }

  const handleSignLockClick = () => {
    //if(IsTreatmentCodeBasedPayor)
    //return;

    const signLockData = {
      VisitId: VisitId,
      IsSignedAndLocked: true,
      ClientId: clientId,
      VisitTypeId: userDetails.VisitTypeId, // VisitTypeId.SoapNote,
      TherepistId: userDetails.TherapistId,
      VisitStartDateTime: userDetails.Scheduleddatefrom,
      VisitendDateTime: userDetails.Scheduleddateto,
      NoteType: NoteType.SoapNote,
      //SignAndLockDate:true,
      SignedBy: userDetails.TherapistId,
    }
    const saveSlpCoreSignLock = {
      VisitId: VisitId,
      SignedBy: userDetails.TherapistId,
      DateAndTimeSigned: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      VisitDateFrom: userDetails.Scheduleddatefrom,
      VisitDateTo: userDetails.Scheduleddateto,
      ...(supervisorValue !== null && {
        SupervisingTherapistId: supervisorValue,
      }),
      //poc_duration
      //poc_frequency
      HasProgressNote: 0,
      UpdateBy: userDetails.TherapistId,
      UpdateDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      IsObservation: formData.IsRequiresObservation,
      HasNomsBasedNotes: true,
    }
    //alert("signlock:"+JSON.stringify(signLockData));
    // return;
    try {
      // Call handleButtonClick, but ignore alerts and messages from it
      handleButtonClick(false)
    } catch (error) {
      // Handle any potential errors from handleButtonClick here
      console.error('Error calling handleButtonClick:', error)
    }

    //insertSignLockData for Progress Note
    const signLockProgressNoteData = {
      VisitId: VisitId,
      IsSignedAndLocked: true,
      ClientId: clientId,
      VisitTypeId: userDetails.VisitTypeId, // VisitTypeId.SoapNote,
      TherepistId: userDetails.TherapistId,
      VisitStartDateTime: userDetails.Scheduleddatefrom,
      VisitendDateTime: userDetails.Scheduleddateto,
      IsProgressNote: true,
      SignedBy: userDetails.TherapistId,
      NoteType: NoteType.ProgressNote,
    }

    const saveSlpCoreProgressNoteSignLock = {
      VisitId: VisitId,
      SignedBy: userDetails.TherapistId,
      DateAndTimeSigned: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      VisitDateFrom: userDetails.Scheduleddatefrom,
      VisitDateTo: userDetails.Scheduleddateto,
      HasProgressNote: 1,
      UpdateBy: userDetails.TherapistId,
      UpdateDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      HasNomsBasedNotes: true,
    }

    signLockService
      .insertSignLockDataSLPCore(saveSlpCoreSignLock)
      .then((response) => {
        if (response.Result === true) {
          if (isProgressSummaryData === true) {
            signLockService
              .insertSignLockDataSLPCore(saveSlpCoreProgressNoteSignLock)
              .then((response) => {
                if (response.Result === true) {
                  signLockService
                    .insertSignLockData(signLockProgressNoteData)
                    .then((response) => {})
                    .catch((error) => {
                      console.error(
                        'Error inserting data for Progress Note:',
                        error,
                      )
                    })
                }
                //alert("Sign and lock confirmed!");
              })
              .catch((error) => {
                //console.error("Error inserting data:", error);
              })
          }
          signLockService
            .insertSignLockData(signLockData)
            .then((response) => {
              FetchingSignAndLock()
              //if (cptBillingCodeList && cptBillingCodeList.length > 0) {
              cptBillingCode.insertCodeBillingtoCore(
                cptBillingCodeList,
                VisitId,
                TherapistId,
                IsCPTCodeBase,
              )
              // }
              if (formData.StopRequestingAuth) {
                const EpisodeDTO = {
                  NoMoreAuthRequest: VisitId,
                  SignedBy: userDetails.TherapistId,
                  DateAndTimeSigned: formData.StopRequestingAuth,
                  EpisodeId: 0,
                }
                // alert(JSON.stringify(EpisodeDTO))
                cptBillingCode.updateNoMoreAuthRequest(EpisodeDTO, clientId)
              }
              alert('Sign and lock confirmed!')
            })
            .catch((error) => {
              console.error('Error inserting data:', error)
            })
        } else {
          alert('Sign and lock failed!. Please try again.')
        }
      })
      .catch((error) => {
        console.error('signLockService.insertSignLockDataSLPCore', error)
      })
      .finally(() => {
        setModalOpen(false)
        setIsProcessing(false)
        setPdfIsReadyforUpload(false)
        setButtonText('Sign and Lock')
      })
  }
  useEffect(() => {
    if (isSuccessful) {
      //alert(isSuccessful)
      if (!IsSignLock) {
        setIsProcessing(true)
        handleSignLockClick()
        // alert('done')
        setPdfIsReadyforUpload(false)
      }
    }
  }, [isSuccessful])

  const RadioButtonListChange = (controlName, value) => {
    const valueControl = controlName
    const updatedFormData = {
      ...formData,
      [valueControl]: value,
    }
    if (!IsSignLock) {
      //alert(JSON.stringify(value));
      triggerDisplayNote(value)
    }
    setFormData(updatedFormData)
    setIsDirty(true)
  }
  const handleUpdatedRows = (updatedRows) => {
    // alert('cptCode:' + JSON.stringify(updatedRows))

    if (Array.isArray(updatedRows)) {
      const updatedData = updatedRows.map((row) => {
        //alert('cptCode:' + JSON.stringify(row.CPTCodeDescription))
        if (row.CPTCodeDescription === '') {
          const cptCode = cptCodeList?.find(
            (cptCode) => cptCode.value === row.CPTCode.toString(),
          )

          if (cptCode) {
            return {
              ...row,
              CPTCodeDescription: cptCode.label,
              isDisabled: row.CPTCode === 0 ? false : true,
            }
          } else {
            // Handle the case when cptCode is not found
            return row
          }
        } else {
          return row
        }
      })
      //alert('updatedData vvv:' + JSON.stringify(updatedData))
      // console.log('Updated rows with descriptions: ' + JSON.stringify(updatedData));
      setCptBillingCodeList(updatedData)
    } else {
      console.log('updatedRows is not an array')
    }
  }
  const handleRowDeleted = (deletedRow) => {
    if (!deletedRow || typeof deletedRow.RowId === 'undefined') {
      console.error('Invalid deletedRow:', deletedRow)
      return
    }
    if (deletedRow.CPTBillingCodeId > 0) {
      cptBillingCode.deleteCode(deletedRow.CPTBillingCodeId)
    }
  }
  const isMobileDevice =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
      navigator.userAgent,
    )

  // if (isMobileDevice) {
  //   alert('This is a mobile browser.');
  // } else {
  //   alert(isMobileDevice);
  // }
  useEffect(() => {
    //alert("form data");
    ValidationObject()
  }, [formData])

  return (
    <Container fluid>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentLabel='Example Modal'
        style={{
          content: {
            width: '800px',
            height: '180px',
            margin: 'auto',
            padding: '20px',
            textAlign: 'center',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            borderRadius: '5px',
            backgroundColor: '#fff',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <div style={{ marginBottom: '20px', fontSize: '25px' }}>
          <h5>{modelMessage}</h5>
        </div>
        <div>
          <button
            onClick={() => handleSignLockConfirmClick()}
            style={{ marginRight: '10px', width: '150px' }}
            className='btn btn-primary'
          >
            Yes
          </button>
          <button
            style={{ width: '150px', paddingBottom: '5px' }}
            onClick={() => setModalOpen(false)}
            className='btn btn-secondary'
          >
            No
          </button>
        </div>
      </Modal>

      <Row className='mt-1'>
        {/* <Row>
          <Col md={12}>isMobileDevice
            <b style={underlineStyle}>{userFirstName}'s Therapy Goals</b>
          </Col>
        </Row> */}

        <Col md={12}>
          {loadingHeader ? (
            <p>Loading...</p>
          ) : (
            <>
              <Accordion defaultActiveKey={isMobileDevice ? '' : '0'}>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    <b style={underlineStyle}>
                      {userFirstName}'s Therapy Goals
                    </b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <HeaderUI
                      ClientId={clientId}
                      firstName={userFirstName}
                      VisitId={VisitId}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <HorizontalLine></HorizontalLine>{' '}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <b style={underlineStyle}>Subjective Observations:</b>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col md={12}>
          <TextareaComponent
            showError={ResultErrorSubject}
            IsChangeTracking={setIsDirty}
            cols={40}
            rows={2}
            maxLength={5000}
            controlid='SubjectiveObservation'
            defaultValue={formData.SubjectiveObservation}
            onTextChange={(value) =>
              handleTextValueChange('SubjectiveObservation', value)
            }
            placeholder={'Please describe...'}
          />
        </Col>
      </Row>
      {/* <Row>
        <b style={underlineStyle}>Objective Findings</b>
      </Row> */}
      <Row>
        <Col md={12}>
          {loadingObjectiveFinding ? (
            <p>Loading Objective Finding...</p>
          ) : (
            <Accordion defaultActiveKey={isMobileDevice ? '' : '0'}>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>
                  <b style={underlineStyle}>Objective Findings</b>
                </Accordion.Header>
                <Accordion.Body>
                  <CProgressReportUI
                    ClientId={clientId}
                    onTableDataChange={handleSTDataChange}
                    firstName={userFirstName}
                    dataSource={formData.PNGoalListItems}
                    showIsIndicate={true}
                    IsChangeTracking={setIsDirty}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}{' '}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <HorizontalLine></HorizontalLine>{' '}
        </Col>
      </Row>
      <Row className='mt-1'>
        <b style={underlineStyle}> Assessment of Progress</b>
      </Row>
      <Row className='mt-1'>
        {' '}
        <Col md={12}>
          <TextareaComponent
            showError={ResultErrorAssessment}
            IsChangeTracking={setIsDirty}
            cols={40}
            rows={2}
            maxLength={5000}
            defaultValue={formData.AssessmentofProgress}
            onTextChange={(value) =>
              handleTextValueChange('AssessmentofProgress', value)
            }
            placeholder={'Please describe...'}
          />
        </Col>
      </Row>
      <Row className='mt-3'>
        <b style={underlineStyle}>Plans for Next Session</b>
      </Row>
      <Row className='mt-1'>
        <Col md={12}>
          <TextareaComponent
            showError={ResultErrorPlanNextSession}
            IsChangeTracking={setIsDirty}
            cols={40}
            rows={2}
            maxLength={5000}
            defaultValue={formData.PlanNextSession}
            onTextChange={(value) =>
              handleTextValueChange('PlanNextSession', value)
            }
            placeholder={'Please describe...'}
          />
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col md={4}>
          <RadioButtonlist
            selectedValue={formData.DisplayNoteTab}
            onChange={(selectedValues) =>
              RadioButtonListChange('DisplayNoteTab', selectedValues)
            }
            typeOption={1}
            columns={3}
            rows={1}
          ></RadioButtonlist>
        </Col>
        <Col md={3}>
          <Checkbox
            label='Requires Clinical Observation?'
            checked={formData.IsRequiresObservation}
            onChange={handleCheckboxChange}
            columnName='IsRequiresObservation'
          />
        </Col>
        <Col md={2}>
          <Checkbox
            label='Stop Requesting Authorization'
            checked={formData.StopRequestingAuth}
            onChange={handleCheckboxChange}
            columnName='StopRequestingAuth'
          />
        </Col>
        {userDetails && userDetails.IsSLPA === 1 && (
          <>
            <Col md={3} style={{ display: 'flex', alignItems: 'center' }}>
              <label
                htmlFor='dropdown'
                style={{ marginRight: '10px', fontWeight: 'bold' }}
              >
                Supervisor:
              </label>
              <select
                className={'form-select form-select-sm'}
                //style={selectStyle}
                value={formData.SupervisorName}
                onChange={handleSupervisorChange('SupervisorName')}
                style={{
                  width: '300px',
                  borderColor: 'darkgrey', // Darker color for the border
                  borderWidth: '1px', // Optional: increase the border width for better visibility
                  borderStyle: 'solid', // Ensure the border is solid
                }}
              >
                {supervisorName &&
                  supervisorName.map((option, index) => (
                    <option key={index} value={option.SupervisorName}>
                      {option.SupervisorName}
                    </option>
                  ))}
              </select>
            </Col>
          </>
        )}
      </Row>
      {/* <Row className='mt-1'>
        <Col md={6}>
          <CPTCodeBillingUI
            IsCPTCodeBase={IsCPTCodeBase}
            RateTypeId={RateTypeId}
            cptCodeList={cptCodeList}
            apiData={cptBillingCodeList}
            onUpdatedRows={handleUpdatedRows}
            onRowAdded={handleUpdatedRows}
            onRowDeleted={handleRowDeleted}
            VisitType={2}
            VisitId={VisitId}
            defaultBilingCode={'92507'}
            IsChangeTracking={setIsDirty}
          />
        </Col>
      </Row> */}
      {/* {IsCPTCodeBase === true && ( */}
      <Row className='mt-1'>
        <Col md={6}>
          {IsCPTCodeBase === false && (
            <b>
              Billing Code(s):
              <i>
                {' '}
                {userDetails && userDetails.PayorName} has contracted a standard
                rate for all therapeutic services.
              </i>
            </b>
          )}
          {isLoading ? (
            <p>Billing Code Loading...</p>
          ) : cptCodeList === null ? (
            <p>No Code Found</p>
          ) : (
            <>
              {IsCPTCodeBase === true &&
                IsTreatmentCodeBasedPayor !== undefined && (
                  <CPTCodeBillingUI
                    IsCPTCodeBase={IsCPTCodeBase}
                    RateTypeId={RateTypeId}
                    cptCodeList={cptCodeList}
                    apiData={cptBillingCodeList}
                    onUpdatedRows={handleUpdatedRows}
                    onRowAdded={handleUpdatedRows}
                    onRowDeleted={handleRowDeleted}
                    VisitType={2}
                    VisitId={VisitId}
                    defaultBilingCode={IsTreatmentCodeBasedPayor ? 0 : '92507'}
                    IsChangeTracking={setIsDirty}
                    IsSignLock={IsSignLock}
                    IsTreatmentCodeBasedPayor={IsTreatmentCodeBasedPayor}
                  />
                )}
            </>
          )}
        </Col>
      </Row>
      {/* )} */}
      <Row className='mt-4'>
        <Col md={12} className='text-right'>
          <button
            onClick={handleButtonClick}
            disabled={IsSignLock || isSaving || !isDirty}
            style={{
              marginRight: '20px',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
            className='btn btn-primary rounded-pill'
          >
            {isSaving ? <b>Saving...</b> : <b>Save</b>}
          </button>
          <button
            onClick={handleSignLockModelClick}
            disabled={IsSignLock || isProcessing}
            style={{ marginRight: '20px' }}
            className='btn btn-primary rounded-pill'
          >
            <b>{buttonText}</b>
          </button>

          <div
            style={{
              marginTop: '2px',
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          >
            <SoapNoteMain data={formData} />

            {pdfIsReadyforUpload && (
              <>
                {isProgressSummaryData && ProgressSummaryData ? (
                  <ProgNotesPDFDownload
                    onPdfGenerationComplete={handlePdfGenerationComplete}
                    pSummaryData={ProgressSummaryData}
                    soapNoteData={formData}
                  />
                ) : (
                  <SoapNotesPDFDownload
                    onPdfGenerationComplete={handlePdfGenerationComplete}
                    soapNoteData={formData}
                  />
                )}
              </>
            )}
            {/* {formData&&(<PdfGenerateNotes data={formData}/>)}  */}
          </div>
          {IsSignLock && userTypeId === 3 && (
            <button
              onClick={handlePdfReGenerationComplete}
              style={{ width: '180px', marginLeft: '10px' }}
              className='btn btn-primary rounded-pill'
              disabled={isButtonDisabled}
            >
              <b>{regenerateReportButton}</b>
            </button>
          )}
        </Col>
      </Row>
    </Container>
  )
})
