import React, { Component } from 'react'
import axios from 'axios'
import { Variables } from '../../Variables'
import { AssessSumService } from '../Component/AssessSumService'
import { NomsService } from '../Component/NomsService'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
const nomsService = new NomsService()

export class LanguageComService {
  constructor() {
    this.LanguageCompId = 0
    this.VisitId = null
    this.LanguageTested = 'English'
    this.LanguageOther = null
    this.NonVerbal = false
    this.AACUse = false
    this.Comprehend = null
    this.RepeatingWord = 'Complex'
    this.FollowingDirection = 'Multi-Step'
    this.RepeatingSentence = 'Complex'
    this.Sequencing = 'Multi-Step'
    this.SpeechUnderstanding = 'Multi-Step'
    this.VerbalComprehension = null
    this.ReadingComprehension = null
    this.RetellingInformation = null
    this.AnswersQuestions = null
    this.ComprehensionDetails = null
    this.StrengthsWeaknesses = null
    this.OverallRating = 'Within Normal Limits'
    this.SkilledTherapyIndicated = false
    this.OverallComplexity = 'Complex'
    this.Result = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  // saveData(updateAssmentSummary, formData) {

  //   return new Promise((resolve, reject) => {
  //     const apiCalls = [];
  //     //alert(formData.SpeechelligibilityId);
  //     apiCalls.push(
  //       axios.post(serviceAssessment.saveData(updateAssmentSummary))
  //     );
  //     apiCalls.push(axios.post(apiUrl + "SLC/InsertSLC", formData));

  //     if (apiCalls.length === 0) {
  //       console.error("No promises to resolve."); // Add an error message for debugging
  //       reject(new Error("No promises to resolve.")); // Reject the promise
  //     }
  //     Promise.all(apiCalls)
  //       .then((responses) => {
  //         resolve(responses); // Resolve the promise with the responses
  //       })
  //       .catch((error) => {
  //         console.log("Failed to save data. Please try again.", error);
  //         reject(error); // Reject the promise with the error
  //       });
  //   });
  // }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []

      // Assuming serviceAssessment.saveData returns a Promise
      const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)

      // Assuming serviceAssessment.saveData returns a valid axios post configuration
      if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
        reject(new Error('Invalid assessment Promise'))
        return
      }

      // Push the assessment promise to the apiCalls array
      apiCalls.push(assessmentPromise)

      // Push the second axios.post call to the apiCalls array
      apiCalls.push(axios.post(apiUrl + 'SLC/InsertSLC', formData))

      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }

      // Use Promise.all to wait for both promises to resolve
      Promise.all(apiCalls)
        .then((responses) => {
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  getGetAssSummary(VisitId) {
    return serviceAssessment
      .getGetAssSummary(VisitId, 'SLC')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getNomsData(VisitId) {
    return nomsService
      .getNomsData(VisitId, 'SLC')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  deleteAssSumData(summId) {
    return serviceAssessment
      .deleteAssSumData(summId)
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  getLanComData(VisitId) {
    return axios
      .get(apiUrl + 'SLC/GetSLC/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data) {
         //alert(JSON.stringify(data));
          this.LanguageCompId = data.LanguageCompId
          this.VisitId = data.VisitId
          this.LanguageTested = data.LanguageTested
          this.LanguageOther = data.LanguageOther
          this.NonVerbal = data.NonVerbal
          this.AACUse = data.AACUse
          this.Comprehend = data.Comprehend
          this.RepeatingWord = data.RepeatingWord
          this.FollowingDirection = data.FollowingDirection
          this.RepeatingSentence = data.RepeatingSentence
          this.Sequencing = data.Sequencing
          this.SpeechUnderstanding = data.SpeechUnderstanding
          this.VerbalComprehension = data.VerbalComprehension
          this.ReadingComprehension = data.ReadingComprehension
          this.RetellingInformation = data.RetellingInformation
          this.AnswersQuestions = data.AnswersQuestions
          this.ComprehensionDetails = data.ComprehensionDetails
          this.StrengthsWeaknesses = data.StrengthsWeaknesses
          this.OverallRating = data.OverallRating
          this.SkilledTherapyIndicated = data.SkilledTherapyIndicated
          this.OverallComplexity = data.OverallComplexity
          this.Result = data.Result
          this.CreatedBy = data.CreatedBy
          this.UpdatedBy = data.UpdatedBy
          this.CreatedDate = data.CreatedDate
          return this
        }
      })
      .catch((error) => console.log('Error getSpeechData data:', error))
  }
}
