import React, { useState, useEffect } from 'react'
import { DDList } from '../Controls/DDList'
import { Table } from 'react-bootstrap'

export const CProgressReportUI = ({
  firstName,
  dataSource,
  onTableDataChange,
  ClientId,
  showIsIndicate = false,
  IsChangeTracking = false,
}) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (dataSource) {
      // When dataSource prop changes, update the rows state
      setRows(dataSource)
      IsChangeTracking(false)
    }
  }, [dataSource])

  const handleOptionSelect = (rowId, columnName, value) => {
    //alert(rowId)
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.GoalId === rowId ? { ...row, [columnName]: value } : row,
      ),
    )
    IsChangeTracking(true)
  }

  useEffect(() => {
    // Call onTableDataChange whenever the rows state is updated
    onTableDataChange(rows)
  }, [rows])

  return (
    // <div  style={{
    //   width: '100%',
    //   overflowX: 'auto'
    // }}>
    //   <div
    //    style={{
    //     display: 'block',
    //     minWidth: '1000px', // Minimum width to ensure horizontal scrolling
    //     overflowX: 'auto',
    //     WebkitOverflowScrolling: 'touch' // for smooth scrolling on iOS
    //   }}
    //   >
    //     <Table
    //       size='sm'
    //       responsive
    //       style={{ width: '98%', marginLeft: showIsIndicate ? '20px' : '0px' }}
    //     >
    //       <tbody>
    //         {rows.map((row, index) =>
    //           !row.GoalMET ? (
    //             <tr key={row.GoalId}>
    //               {showIsIndicate && (
    //                 <td style={{ width: '1%' }}>
    //                   <input
    //                     className='form-check-input'
    //                     type='checkbox'
    //                     style={{ border: '1px solid blue' }}
    //                     checked={row.IsIndicated}
    //                     onChange={(e) =>
    //                       handleOptionSelect(
    //                         row.GoalId,
    //                         'IsIndicated',
    //                         e.target.checked,
    //                       )
    //                     }
    //                   />{' '}
    //                   <span style={{ marginLeft: '2px' }}>Given</span>
    //                 </td>
    //               )}
    //               <td style={{ width: '1%' }}>
    //                 <DDList
    //                   ddType='5'
    //                   width={150}
    //                   defaultValue={row.Given}
    //                   onSelect={(e) =>
    //                     handleOptionSelect(row.GoalId, 'Given', e)
    //                   }
    //                 />
    //               </td>
    //               <td style={{ width: '98%' }}>
    //                 {firstName} <b>{row.GoalDescription}</b>
    //               </td>
    //               {row.GoalType === 'Articulation' && (
    //                 <>
    //                   <td style={{ width: '15%' }}>
    //                     in&nbsp;<b>{row.Sound}</b> &nbsp;sound(s) at the&nbsp;
    //                   </td>
    //                   <td style={{ width: '15%' }}>
    //                     <DDList
    //                       width={100}
    //                       ddType={6}
    //                       defaultValue={row.AtLevel}
    //                       onSelect={(e) =>
    //                         handleOptionSelect(row.GoalId, 'AtLevel', e)
    //                       }
    //                     />
    //                   </td>
    //                 </>
    //               )}
    //               {row.GoalType === 'General' ||
    //               row.GoalType === 'Phonology' ? (
    //                 <td style={{ width: '25%' }}>
    //                   <DDList
    //                     width={80}
    //                     ddType={8}
    //                     defaultValue={row.Accuracy}
    //                     onSelect={(e) =>
    //                       handleOptionSelect(row.GoalId, 'Accuracy', e)
    //                     }
    //                   />
    //                 </td>
    //               ) : (
    //                 <td style={{ width: '25%' }}>
    //                   <DDList
    //                     width={80}
    //                     ddType={8}
    //                     defaultValue={row.Accuracy}
    //                     onSelect={(e) =>
    //                       handleOptionSelect(row.GoalId, 'Accuracy', e)
    //                     }
    //                   />
    //                 </td>
    //               )}
    //             </tr>
    //           ) : null,
    //         )}
    //       </tbody>
    //     </Table>
    //   </div>
    // </div>
    <div
      style={{
        width: '100%',
        overflowX: 'auto',
      }}
    >
      <div
        style={{
          display: 'block',
          minWidth: '1000px', // Minimum width to ensure horizontal scrolling
          overflowX: 'auto',
          WebkitOverflowScrolling: 'touch', // for smooth scrolling on iOS
        }}
      >
        <Table
          size='sm'
          responsive
          style={{ width: '98%', marginLeft: showIsIndicate ? '20px' : '0px' }}
        >
          <tbody>
            {rows.map(
              (row, index) =>
                !row.GoalMET ? (
                  <tr key={row.GoalId}>
                    <td
                      style={{
                        width: '100%',
                        whiteSpace: 'normal',
                        wordBreak: 'break-all',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {row.GoalType === 'General' ? (
                          <>
                            {showIsIndicate && (
                              <>
                                <label
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <input
                                    className='form-check-input mr-2'
                                    type='checkbox'
                                    style={{ border: '1px solid blue' }}
                                    checked={row.IsIndicated}
                                    onChange={(e) =>
                                      handleOptionSelect(
                                        row.GoalId,
                                        'IsIndicated',
                                        e.target.checked,
                                      )
                                    }
                                  />
                                  <span
                                    style={{ marginLeft: '2px', width: '50px' }}
                                  >
                                    Given
                                  </span>
                                </label>
                              </>
                            )}
                            <DDList
                              ddType='5'
                              width={150}
                              defaultValue={row.Given}
                              onSelect={(e) =>
                                handleOptionSelect(row.GoalId, 'Given', e)
                              }
                            />{' '}
                            &nbsp;
                            <b>
                              {' '}
                              {firstName} {row.GoalDescription}
                            </b>
                            <DDList
                              width={80}
                              ddType={8}
                              defaultValue={row.Accuracy}
                              onSelect={(e) =>
                                handleOptionSelect(row.GoalId, 'Accuracy', e)
                              }
                            />
                          </>
                        ) : row.GoalType === 'Articulation' ? (
                          <>
                            {showIsIndicate && (
                              <>
                                <label
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <input
                                    className='form-check-input mr-2'
                                    type='checkbox'
                                    style={{ border: '1px solid blue' }}
                                    checked={row.IsIndicated}
                                    onChange={(e) =>
                                      handleOptionSelect(
                                        row.GoalId,
                                        'IsIndicated',
                                        e.target.checked,
                                      )
                                    }
                                  />
                                  <span
                                    style={{ marginLeft: '2px', width: '50px' }}
                                  >
                                    Given
                                  </span>
                                </label>
                              </>
                            )}
                            <DDList
                              ddType='5'
                              width={150}
                              defaultValue={row.Given}
                              onSelect={(e) =>
                                handleOptionSelect(row.GoalId, 'Given', e)
                              }
                            />
                            ,&nbsp;
                            <b>
                              {' '}
                              {firstName} {row.GoalDescription}
                            </b>
                            &nbsp;in&nbsp;<b>{row.Sound}</b> &nbsp;sound(s) at
                            the&nbsp;
                            <DDList
                              width={100}
                              ddType={6}
                              defaultValue={row.AtLevel}
                              onSelect={(e) =>
                                handleOptionSelect(row.GoalId, 'AtLevel', e)
                              }
                            />
                            &nbsp;level with&nbsp;
                            <DDList
                              width={80}
                              ddType={8}
                              defaultValue={row.Accuracy}
                              onSelect={(e) =>
                                handleOptionSelect(row.GoalId, 'Accuracy', e)
                              }
                            />
                          </>
                        ) : row.GoalType === 'Phonology' ? (
                          <>
                            {showIsIndicate && (
                              <>
                                <label
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <input
                                    className='form-check-input mr-2'
                                    type='checkbox'
                                    checked={row.IsIndicated}
                                    style={{ border: '1px solid blue' }}
                                    onChange={(e) =>
                                      handleOptionSelect(
                                        row.GoalId,
                                        'IsIndicated',
                                        e.target.checked,
                                      )
                                    }
                                  />{' '}
                                  <span
                                    style={{ marginLeft: '2px', width: '50px' }}
                                  >
                                    Given
                                  </span>
                                </label>
                              </>
                            )}
                            <DDList
                              ddType='5'
                              width={150}
                              defaultValue={row.Given}
                              onSelect={(e) =>
                                handleOptionSelect(row.GoalId, 'Given', e)
                              }
                            />
                            ,&nbsp;
                            <b>
                              {firstName}&nbsp;{row.GoalDescription}
                            </b>
                            &nbsp;
                            <DDList
                              width={80}
                              ddType={8}
                              defaultValue={row.Accuracy}
                              onSelect={(e) =>
                                handleOptionSelect(row.GoalId, 'Accuracy', e)
                              }
                            />
                          </>
                        ) : (
                          <>
                            {/* Given&nbsp;
                        <DDList
                          ddType='5'
                          width={150}
                          defaultValue={row.Given}
                          onSelect={(e) =>
                            handleOptionSelect(row.GoalId, 'Given', e)
                          }
                        />
                        ,&nbsp;
                        {firstName}&nbsp;
                        <b>{row.GoalDescription}</b>&nbsp;
                        <DDList
                          width={80}
                          ddType={8}
                          defaultValue={row.Accuracy}
                          onSelect={(e) =>
                            handleOptionSelect(row.GoalId, 'Accuracy', e)
                          }
                        /> */}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : null, // If GoalMET is true, render nothing (null)
            )}
          </tbody>
        </Table>
      </div>{' '}
    </div>
  )
}
