import React, { useState, useEffect, useContext } from 'react'
import {
  Document,
  Page,
  View,
  PDFViewer,
  StyleSheet,
  ReactPDF,
} from '@react-pdf/renderer'
import Modal from 'react-modal'
import { useUser } from '../Provider/UserContext'
import { VisitProvider } from '../Provider/Visitprovider'
import ReportHeader from './ReportHeader'
import ReportFooter from './ReportFooter'
import ContentSI from './ContentSI'
import ContentCHC from './ContentCHC'
import ContentPOC from './ContentPOC'
import ContentSIA from './ContentSIA'
import axios from 'axios'
import { Variables } from '../Variables'
import ContentCHA from './ContentCHA'
import ContentSLC from './ContentSLC'
import HeaderTextContent from './HeaderTextContent'
import ContentSLE from './ContentSLE'
import { PocService } from '../POC/Service/PocService'
import ContentCOG from './ContentCOG'
import jsPDF from 'jspdf'
import EvalPDFDocs from './EvalPDFDocs'
const styles = StyleSheet.create({
  body: {
    paddingTop: 1,
    paddingBottom: 40,
    // paddingHorizontal: 15,
  },
})
const customModalStyles = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    padding: '20px',
    maxWidth: '1200px',
    width: '100%',
    maxHeight: '1000px',
    height: '80%',
    overflow: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}

const apiCoreUrl = Variables.USER_API_URL
const CHCMedicalPDF = ({
  PocData,
  isShowButton = true,
  newVisitId,
  isSignLock = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [dbPOCdata, setDBPOCDate] = useState(PocData)
  const pocService = new PocService()
  const [generatedPDF, setGeneratedPDF] = useState(null)
  const [pdfFilename, setPdfFilename] = useState('ChildCasehistory.pdf')
  const { userDetails } = useUser()
  const [clientdata, setClientdata] = useState(null)
  const [FullName, setFullName] = useState(null)
  const VisitId = useContext(VisitProvider)
  const [diagnosis2, setDiagnosis2] = useState(null)
  const [dynamicVisitId, setDynamicVisitId] = useState(null)
  const [isAdult, setIsAdult] = useState(false)

  const [FirstName, setFirstName] = useState('')
  const [HeOrShe, setHeOrShe] = useState('')
  const [hisHer, sethisHer] = useState('')
  const [fcmList, setfcmList] = useState(null)
  const [fcmVisibility, setFcmVisibility] = useState({})

  const [INT, setINT] = useState(false)
  const [SLE, setSLE] = useState(false)
  const [SLC, setSLC] = useState(false)
  const [ELIT, setELIT] = useState(false)
  const [SWAL, setSWAL] = useState(false)
  const [WRIT, setWRIT] = useState(false)
  const [VOIC, setVOIC] = useState(false)
  const [PRC, setPRC] = useState(false)
  const [READ, setREAD] = useState(false)
  const [FLU, setFLU] = useState(false)
  const [FEED, setFEED] = useState(false)
  const [COG, setCOG] = useState(false)
  const [PRAG, setPRAG] = useState(false)

  function getReportHeaderFcmItem() {
    //alert('ghgh : ' + JSON.stringify(fcmList))
    if (fcmList.includes('INT')) {
      setINT(true)
      return
    } else if (fcmList.includes('SLC')) {
      setSLC(true)
      return
    } else if (fcmList.includes('SLE')) {
      setSLE(true)
      return
    } else if (fcmList.includes('COG')) {
      setCOG(true)
      return
    }
    //alert('sdsf')
  }

  useEffect(() => {
    if (userDetails) {
      //alert('ghgh : ' + JSON.stringify(PocData))
      //fetchCPTCodeListData()
      setClientdata(userDetails)
      setHeOrShe(userDetails.Sex === 1 ? 'He' : 'She')
      sethisHer(userDetails.Sex === 1 ? 'his' : 'her')

      setFullName(
        userDetails.LastName.toUpperCase() +
          ', ' +
          userDetails.FirstName.toUpperCase(),
      )
      const formattedFirstName =
        userDetails.FirstName.charAt(0).toUpperCase() +
        userDetails.FirstName.slice(1).toLowerCase()
      setFirstName(formattedFirstName)
      setDynamicVisitId(VisitId)

      if (userDetails.Age >= 18) {
        setIsAdult(true)
      }
    }
  }, [userDetails])

  const fetchData = async (visitId) => {
    if (visitId) {
      try {
        //alert(VisitId);
        const response = await axios.get(
          apiCoreUrl + 'Client/GetVisitDetailForClinicalNotes?visitId=' + visitId,
        )
        const data = response.data
        setClientdata(data)
        setHeOrShe(data.Sex === 1 ? 'He' : 'She')
        sethisHer(data.Sex === 1 ? 'his' : 'her')

        //setFullName(data.LastName + ', ' + data.FirstName)
        //setFirstName(data.FirstName)
      } catch (error) {
        console.log('Error fetching user details:', error)
      }
    }
  }

  useEffect(() => {
    if (!isShowButton) {
      //alert(isShowButton);
      //fetchCPTCodeListData(newVisitId)
      fetchData(newVisitId)
      setDynamicVisitId(newVisitId)
      generatePDF()
    }
  }, [isShowButton, newVisitId])

  useEffect(() => {
    //alert(JSON.stringify(fcmList))
    if (Array.isArray(fcmList)) {
      getReportHeaderFcmItem()
      //const visibilityArray = fcmList.split(',')
      const tabVisibilityData = {
        PRAG: fcmList.includes('PRAG'),
        COG: fcmList.includes('COG'),
        FEED: fcmList.includes('FEED'),
        FLU: fcmList.includes('FLU'),
        READ: fcmList.includes('READ'),
        PRC: fcmList.includes('PRC'),
        VOIC: fcmList.includes('VOIC'),
        WRIT: fcmList.includes('WRIT'),
        SWAL: fcmList.includes('SWAL'),
        SLE: fcmList.includes('SLE'),
        SLC: fcmList.includes('SLC'),
        INT: fcmList.includes('INT'),
        ELIT: fcmList.includes('ELIT'),
      }
      //alert(JSON.stringify(tabVisibilityData))
      setFcmVisibility(tabVisibilityData)
    }
  }, [fcmList])

  const generatePDF = () => {
    //const doc = new jsPDF()
    if (isSignLock) {
      if (VisitId) {
        pocService
          .getPocData(VisitId)
          .then((data) => {
            setDBPOCDate(data)
          })
          .catch((error) => {})
      }
    }
    //alert('POC Report adta: ' + JSON.stringify(PocData))
    //setGeneratedPDF(doc.output('datauristring'))
    setModalOpen(true)
  }

  const handleDownload = () => {
    setPdfFilename('generated_pdf.pdf')
  }
  const updateDiagnosis2 = (value) => {
    setDiagnosis2(value)
  }
 
  return (
    <>
      {isShowButton && (
        <button
          className='btn btn-primary rounded-pill'
          style={{ width: '150px', marginRight: '10px' }}
          onClick={generatePDF}
        >
          <b>Preview</b>
        </button>
      )}
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customModalStyles}
      >
       
      
          <PDFViewer
            style={{ width: '100%', height: 'calc(100% - 50px)' }}
          >
            <EvalPDFDocs
              pdfFilename={pdfFilename}
              POCdata={dbPOCdata}
              clientdata={clientdata}
              FullName={FullName}
              //diagnosis2={diagnosis2}
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              dynamicVisitId={dynamicVisitId}
              //updateDiagnosis2={updateDiagnosis2}
              isAdult={isAdult}
            ></EvalPDFDocs>
            {/* <Document title={pdfFilename}>
            <Page size='A4' style={styles.body}>
              <ReportHeader POCData={dbPOCdata} clientdata={clientdata} />
              <HeaderTextContent
                clientdata={clientdata}
                FullName={FullName}
                PocData={dbPOCdata}
                Diagnosis2={diagnosis2}
              ></HeaderTextContent>
              <ContentPOC
                FirstName={FirstName}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={dynamicVisitId}
                clientdata={clientdata}
                FullName={FullName}
                POCData={dbPOCdata}
                Diagnosis2={diagnosis2}
                fcmList={setfcmList}
              ></ContentPOC>
              <ReportFooter></ReportFooter>
            </Page>

            {isAdult !== true ? (
              <Page size='A4' style={styles.body}>
                <ReportHeader POCData={dbPOCdata} clientdata={clientdata} />
                <HeaderTextContent
                  clientdata={clientdata}
                  FullName={FullName}
                  PocData={dbPOCdata}
                  Diagnosis2={diagnosis2}
                ></HeaderTextContent>
                <View>
                  <ContentCHC
                    FirstName={FirstName}
                    HeOrShe={HeOrShe}
                    hisHer={hisHer}
                    userDetails={userDetails}
                    VisitId={dynamicVisitId}
                    clientdata={clientdata}
                    FullName={FullName}
                    pocData={dbPOCdata}
                    updateDiagnosis2={updateDiagnosis2}
                  />
                </View>
                <ReportFooter />
              </Page>
            ) : (
              <Page size='A4' style={styles.body}>
                <ReportHeader POCData={dbPOCdata} clientdata={clientdata} />
                <HeaderTextContent
                  clientdata={clientdata}
                  FullName={FullName}
                  PocData={dbPOCdata}
                  Diagnosis2={diagnosis2}
                ></HeaderTextContent>
                <View>
                  <ContentCHA
                    FirstName={FirstName}
                    HeOrShe={HeOrShe}
                    hisHer={hisHer}
                    userDetails={userDetails}
                    VisitId={dynamicVisitId}
                    clientdata={clientdata}
                    FullName={FullName}
                    pocData={dbPOCdata}
                    updateDiagnosis2={updateDiagnosis2}
                  />
                </View>
                <ReportFooter />
              </Page>
            )}
            {fcmVisibility && fcmVisibility.INT && (
              <>
                {isAdult === true ? (
                  <Page size='A4' style={styles.body}>
                    <ReportHeader
                      POCData={dbPOCdata}
                      clientdata={clientdata}
                    ></ReportHeader>
                    <HeaderTextContent
                      clientdata={clientdata}
                      FullName={FullName}
                      PocData={dbPOCdata}
                      Diagnosis2={diagnosis2}
                    ></HeaderTextContent>
                    <ContentSIA
                      HeaderFcm={INT}
                      FirstName={FirstName}
                      HeOrShe={HeOrShe}
                      hisHer={hisHer}
                      userDetails={userDetails}
                      VisitId={dynamicVisitId}
                      clientdata={clientdata}
                      FullName={FullName}
                      pocData={dbPOCdata}
                      Diagnosis2={diagnosis2}
                    ></ContentSIA>
                    <ReportFooter></ReportFooter>
                  </Page>
                ) : (
                  <Page size='A4' style={styles.body}>
                    <ReportHeader
                      POCData={dbPOCdata}
                      clientdata={clientdata}
                    ></ReportHeader>
                    <HeaderTextContent
                      clientdata={clientdata}
                      FullName={FullName}
                      PocData={dbPOCdata}
                      Diagnosis2={diagnosis2}
                    ></HeaderTextContent>
                    <ContentSI
                      HeaderFcm={INT}
                      FirstName={FirstName}
                      HeOrShe={HeOrShe}
                      hisHer={hisHer}
                      userDetails={userDetails}
                      VisitId={dynamicVisitId}
                      clientdata={clientdata}
                      FullName={FullName}
                      pocData={dbPOCdata}
                      Diagnosis2={diagnosis2}
                    ></ContentSI>
                    <ReportFooter></ReportFooter>
                  </Page>
                )}
              </>
            )}
            {fcmVisibility && fcmVisibility.SLC && (
              <>
                <Page size='A4' style={styles.body}>
                  <ReportHeader
                    POCData={dbPOCdata}
                    clientdata={clientdata}
                  ></ReportHeader>
                  <HeaderTextContent
                    clientdata={clientdata}
                    FullName={FullName}
                    PocData={dbPOCdata}
                    Diagnosis2={diagnosis2}
                  ></HeaderTextContent>
                  <ContentSLC
                    HeaderFcm={SLC}
                    FirstName={FirstName}
                    HeOrShe={HeOrShe}
                    hisHer={hisHer}
                    userDetails={userDetails}
                    VisitId={dynamicVisitId}
                    clientdata={clientdata}
                    FullName={FullName}
                    pocData={dbPOCdata}
                    Diagnosis2={diagnosis2}
                  ></ContentSLC>
                  <ReportFooter></ReportFooter>
                </Page>
              </>
            )}
            {fcmVisibility && fcmVisibility.SLE && (
              <>
                <Page size='A4' style={styles.body}>
                  <ReportHeader
                    POCData={dbPOCdata}
                    clientdata={clientdata}
                  ></ReportHeader>
                  <HeaderTextContent
                    clientdata={clientdata}
                    FullName={FullName}
                    PocData={dbPOCdata}
                    Diagnosis2={diagnosis2}
                  ></HeaderTextContent>
                  <ContentSLE
                    HeaderFcm={SLE}
                    FirstName={FirstName}
                    HeOrShe={HeOrShe}
                    hisHer={hisHer}
                    userDetails={userDetails}
                    VisitId={dynamicVisitId}
                    clientdata={clientdata}
                    FullName={FullName}
                    pocData={dbPOCdata}
                    Diagnosis2={diagnosis2}
                  ></ContentSLE>
                  <ReportFooter></ReportFooter>
                </Page>
              </>
            )}
            {fcmVisibility && fcmVisibility.COG && (
              <>
                <Page size='A4' style={styles.body}>
                  <ReportHeader
                    POCData={dbPOCdata}
                    clientdata={clientdata}
                  ></ReportHeader>
                  <HeaderTextContent
                    clientdata={clientdata}
                    FullName={FullName}
                    PocData={dbPOCdata}
                    Diagnosis2={diagnosis2}
                  ></HeaderTextContent>
                  <ContentCOG
                    FirstName={FirstName}
                    HeOrShe={HeOrShe}
                    hisHer={hisHer}
                    userDetails={userDetails}
                    VisitId={dynamicVisitId}
                    FullName={FullName}
                    HeaderFcm={COG}

                    // clientdata={clientdata}

                    // pocData={dbPOCdata}
                    // Diagnosis2={diagnosis2}
                  ></ContentCOG>
                  <ReportFooter></ReportFooter>
                </Page>
              </>
            )}
          </Document> */}
          </PDFViewer>
       
        {/* <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            padding: '10px',
            textAlign: 'center',
            backgroundColor: '#f0f0f0',
          }}
        >
          <a
            href={generatedPDF}
            download={pdfFilename}
            onClick={handleDownload}
          >
            Download PDF
          </a>
        </div> */}
      </Modal>
    </>
  )
}

export default CHCMedicalPDF
