import React, { Component } from 'react'
import axios from 'axios'
import { Variables } from '../../Variables'
import { AssessSumService } from '../Component/AssessSumService'
import { NomsService } from '../Component/NomsService'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
const nomsService = new NomsService()

export class CognitionService {
  constructor() {
    this.CognitionId = 0
    this.VisitId = null
    this.MentalStatus = 'Alert'
    this.AwarenessDeficits = 'Yes'
    this.Orientation = null
    this.Memory = null
    this.MemoryDescription = null
    this.Attention = null
    this.AttentionDescription = null
    this.ExecutiveFunctioning = null
    this.ExecutiveFunctioningDesc = null
    this.Pragmatics = null
    this.PragmaticsDesc = null
    this.WordComprehension = 'Words'
    this.FollowingDirections = 'Complex'
    this.YesNoQuestions = 'Complex'
    this.SpeechCharacteristics = null
    this.ImpactDailyActivities = null
    this.OverallRating = 'Within Normal Limits'
    this.IsSkilledTherapyIndicated = false
    //this.Result = 'Complex'
    this.Result = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []

      // Assuming serviceAssessment.saveData returns a Promise
      const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)

      // Assuming serviceAssessment.saveData returns a valid axios post configuration
      if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
        reject(new Error('Invalid assessment Promise'))
        return
      }

      // Push the assessment promise to the apiCalls array
      apiCalls.push(assessmentPromise)

      // Push the second axios.post call to the apiCalls array
      apiCalls.push(axios.post(apiUrl + 'COG/InsertCOG', formData))

      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }

      // Use Promise.all to wait for both promises to resolve
      Promise.all(apiCalls)
        .then((responses) => {
          // alert(JSON.stringify(responses))
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  // async saveData(updateAssmentSummary, formData) {
  //   try {
  //     const apiCalls = []

  //     const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)

  //     if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
  //       throw new Error('Invalid assessment Promise')
  //     }

  //     apiCalls.push(assessmentPromise)

  //     apiCalls.push(axios.post(apiUrl + 'COG/InsertCOG', formData))

  //     if (apiCalls.length === 0) {
  //       throw new Error('No promises to resolve.')
  //     }

  //     // Await for both promises to resolve
  //     const responses = await Promise.all(apiCalls)

  //     // Extract message from the first response
  //     const message = responses[0].data.MessageDetail

  //     // Optionally, you can log or process responses and messages here
  //     console.log(responses)
  //     console.log(message)

  //     // Return the message
  //     return message
  //   } catch (error) {
  //     console.error('Failed to save data. Please try again.', error)
  //     throw error // Re-throwing the error for the caller to handle
  //   }
  // }

  getGetAssSummary(VisitId) {
    return serviceAssessment
      .getGetAssSummary(VisitId, 'COG')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getNomsData(VisitId) {
    return nomsService
      .getNomsData(VisitId, 'COG')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  deleteAssSumData(summId) {
    return serviceAssessment
      .deleteAssSumData(summId)
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  getCOGData(VisitId) {
    return axios
      .get(apiUrl + 'COG/GetCOG/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data) {
          //alert(JSON.stringify(data));
          this.CognitionId = data.CognitionId
          this.VisitId = data.VisitId
          this.MentalStatus = data.MentalStatus
          this.AwarenessDeficits = data.AwarenessDeficits
          this.Orientation = data.Orientation
          this.Memory = data.Memory
          this.MemoryDescription = data.MemoryDescription
          this.Attention = data.Attention
          this.AttentionDescription = data.AttentionDescription
          this.ExecutiveFunctioning = data.ExecutiveFunctioning
          this.ExecutiveFunctioningDesc = data.ExecutiveFunctioningDesc
          this.Pragmatics = data.Pragmatics
          this.PragmaticsDesc = data.PragmaticsDesc
          this.WordComprehension = data.WordComprehension
          this.FollowingDirections = data.FollowingDirections
          this.YesNoQuestions = data.YesNoQuestions
          this.SpeechCharacteristics = data.SpeechCharacteristics
          this.ImpactDailyActivities = data.ImpactDailyActivities
          this.OverallRating = data.OverallRating
          this.IsSkilledTherapyIndicated = data.IsSkilledTherapyIndicated
          this.Result = data.Result
          this.CreatedBy = data.CreatedBy
          this.UpdatedBy = data.UpdatedBy
          this.CreatedDate = data.CreatedDate

          return this
        }
      })
      .catch((error) => console.log('Error getSpeechData data:', error))
  }
}
