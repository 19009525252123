import React from 'react'
export const VisitProvider = React.createContext()

export const CreatedByProvider = React.createContext()

export const UserTypeIdProvider = React.createContext()

export const SignLockProvider = React.createContext()

const SupervisorContext = React.createContext()

export default SupervisorContext
