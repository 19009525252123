import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
  Suspense,
  lazy,
} from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { SignLockProvider } from '../../Provider/Visitprovider'
import { useUser } from '../../Provider/UserContext'
// import { PragmaticUI } from './PragmaticUI'
// import { NomsUI } from './NomsUI'
// import { GoalsUI } from './GoalsUI'

const PragmaticUI = lazy(() => import('./PragmaticUI'))
const NomsUI = lazy(() => import('./NomsUI'))
const GoalsUI = lazy(() => import('./GoalsUI'))

export const PragmaticTabUI = forwardRef(({ isAdult, onTabChange }, ref) => {
  const { userDetails } = useUser()
  const [refreshData, setRefreshData] = useState(false)
  const [activeTab, setActiveTab] = useState('AS')
  const [isAdultCHA, setIsAdultCHA] = useState(false)
  const IsSignLock = useContext(SignLockProvider)
  const [refreshGoalTab, setRefreshGoalTab] = useState(false)
  const AssessmentRef = useRef()
  const NOMSRef = useRef()
  const GoalsRef = useRef()
  const [age, setAge] = useState(0)
  const [activeGoalTab, setActiveGoalTab] = useState(false)

  const triggerRefreshGoalTab = () => {
    //alert("Data saved in noms");
    setRefreshGoalTab(true)
  }

  useEffect(() => {
    if (userDetails) {
      setAge(userDetails.Age)
    }
  }, [userDetails])

  useEffect(() => {
    setActiveTab('AS')
    onTabChange('AS')
    //alert(JSON.stringify(isAdult));
    setIsAdultCHA(isAdult)
  }, [isAdult, refreshData])

  const triggerRefresh = (refresh) => {
    //alert(refresh);
    setRefreshData(refresh)
  }

  const handleTabChange = (eventKey) => {
    if (IsSignLock === false) {
      if (eventKey === 'AS' && activeTab === 'NM') {
        NOMSRef.current.handleButtonClick(false)
        //triggerRefreshGoalTab();
      } else if (eventKey === 'NM' && activeTab === 'AS') {
        //alert("Active Key: "+ isAdult);
        AssessmentRef.current.handleFormSubmit(false)
      } else if (eventKey === 'GL' && activeTab === 'NM') {
        NOMSRef.current.handleButtonClick(false)
        triggerRefreshGoalTab()
      } else if (eventKey === 'NM' && activeTab === 'GL') {
        GoalsRef.current.handleButtonClick(false)
      }
      else if (eventKey === 'GL' && activeTab === 'AS') {
        // alert('else if (eventKey === GL && activeTab === AS) ' + activeTab)
        AssessmentRef.current.handleFormSubmit(false)
      } else if (eventKey === 'AS' && activeTab === 'GL') {
        // alert('GOAL SAVE' + activeTab)
        GoalsRef.current.handleButtonClick(false)
      }
    }
    // if (eventKey === "GL") {
    //   triggerRefreshGoalTab();
    // }
    setActiveTab(eventKey)
    onTabChange(eventKey)
  }

  const SaveAss = () => {
    if (IsSignLock === false) {
      AssessmentRef.current.handleFormSubmit(false)
    }
  }
  const SaveNOMS = () => {
    if (IsSignLock === false) {
      NOMSRef.current.handleButtonClick(false)
    }
  }
  const SaveGOAL = () => {
    if (IsSignLock === false) {
      //alert('Goalcall')
      GoalsRef.current.handleButtonClick(false)
    }
  }
  useImperativeHandle(ref, () => ({
    SaveAss,
    SaveNOMS,
    SaveGOAL,
  }))
  return (
    <div style={{ display: 'block', width: 'max', padding: '10px' }}>
      <Tabs defaultActiveKey={activeTab} onSelect={handleTabChange}>
        <Tab eventKey='AS' title='Assessment'>
          {activeTab === 'AS' && (
            <Suspense fallback={<div>Loading...</div>}>
              <PragmaticUI
                ref={AssessmentRef}
                triggerRefresh={triggerRefresh}
                onDataFetched={() => setActiveTab('AS')}
              ></PragmaticUI>
            </Suspense>
          )}
        </Tab>
        {refreshData === false && (
          <Tab eventKey='NM' title='NOMS'>
            {activeTab === 'NM' && (
              <Suspense fallback={<div>Loading...</div>}>
                {' '}
                <NomsUI
                  age={age}
                  triggerGoalRefresh={triggerRefreshGoalTab}
                  ref={NOMSRef}
                  onDataFetched={() => setActiveTab('NM')}
                  isActiveGoal={setActiveGoalTab}
                />
              </Suspense>
            )}
          </Tab>
        )}

        {refreshData === false && (
          <Tab eventKey='GL' title={isAdult ? 'Goal' : 'Goal'}  disabled={!activeGoalTab}>
            {activeTab === 'GL' && (
              <Suspense fallback={<div>Loading...</div>}>
                {' '}
                <GoalsUI
                  IsAdult={isAdultCHA}
                  refreshGoal={refreshGoalTab}
                  ref={GoalsRef}
                  onDataFetched={() => setActiveTab('GL')}
                />
              </Suspense>
            )}
          </Tab>
        )}
      </Tabs>
    </div>
  )
})
