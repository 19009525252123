import axios from 'axios'
import { Variables } from '../Variables'
const apiUrl = Variables.API_URL
export class NomsService {
  getDischargeNomsData(VisitId, Clientid) {
    return axios
      .get(`${apiUrl}DischargeNoms/GetNoms/${VisitId}/${Clientid}`)
      .then((response) => {
        const data = response.data.Result
        //alert(JSON.stringify(response));
        return data
      })
      .catch((error) => {
        console.error('Error fetching PocDTO data:', error)
        throw error
      })
  }

  insertDischanrgeNomsData(formData) {
    return axios
      .post(`${apiUrl}DischargeNoms/CreateNoms`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting DischargeNoms/CreateNoms data:', error)
        throw error
      })
  }
}
