import React from 'react'
import Select from 'react-select'

const MultiSelectDDL = ({
  type,
  value,
  closeMenuOnSelect,
  onChange,
  FCMType,
}) => {
  const generateOptions = () => {
    switch (type) {
      case 1:
        if (FCMType === 'INT') {
          return [
            {
              value: 'Increased respiratory support for speech',
              label: 'Increased respiratory support for speech',
            },
            {
              value: 'Increased strength of articulators',
              label: 'Increased strength of articulators',
            },
            {
              value: 'Increased accuracy of articulation',
              label: 'Increased accuracy of articulation',
            },
            {
              value: 'Increased accuracy of error perception',
              label: 'Increased accuracy of error perception',
            },
            { value: 'Increased loudness', label: 'Increased loudness' },
            { value: 'Decreased rate', label: 'Decreased rate' },
            {
              value: 'Improved velopharyngeal function for speech',
              label: 'Improved velopharyngeal function for speech',
            },
            {
              value: 'Improved posture for speech',
              label: 'Improved posture for speech',
            },
            {
              value: 'Improved caregiver listening skills',
              label: 'Improved caregiver listening skills',
            },
            {
              value:
                'Increased intelligibility in adverse listening/speaking situations',
              label:
                'Increased intelligibility in adverse listening/speaking situations',
            },
            {
              value:
                'Increased use of compensatory or speech-facilitation strategies',
              label:
                'Increased use of compensatory or speech-facilitation strategies',
            },
            {
              value: 'Increased use of self-monitoring skills',
              label: 'Increased use of self-monitoring skills',
            },
          ]
        } else if (FCMType === 'SLE') {
          return [
            {
              value: 'Improved use of semantic concepts and lexical retrieval',
              label: 'Improved use of semantic concepts and lexical retrieval',
            },
            {
              value: 'Improved use of syntactic sentence structures',
              label: 'Improved use of syntactic sentence structures',
            },
            {
              value: 'Improved use of grammatical morphemes',
              label: 'Improved use of grammatical morphemes',
            },
            { value: 'Improved phonology', label: 'Improved phonology' },
            {
              value: 'Increased quantity of output',
              label: 'Increased quantity of output',
            },
            {
              value: 'Improved conversation/discourse abilities',
              label: 'Improved conversation/discourse abilities',
            },
            // other options specific to FCMType B and type 1
          ]
        } else if (FCMType === 'SLC') {
          return [
            {
              value:
                'Increased use of written modality to facilitate auditory comprehension',
              label:
                'Increased use of written modality to facilitate auditory comprehension',
            },
            {
              value:
                'Increased comprehension and inferencing of implicit information',
              label:
                'Increased comprehension and inferencing of implicit information',
            },
            {
              value: 'Increased ability to make semantic associations',
              label: 'Increased ability to make semantic associations',
            },
            {
              value: 'Increased use of visual stimuli to support comprehension',
              label: 'Increased use of visual stimuli to support comprehension',
            },
            {
              value:
                'Increased use of semantic associations to support comprehension',
              label:
                'Increased use of semantic associations to support comprehension',
            },
            {
              value:
                'Increased comprehension of syntax in subject-verb-object sentences',
              label:
                'Increased comprehension of syntax in subject-verb-object sentences',
            },
            {
              value: 'Increased comprehension of syntax in passive sentences',
              label: 'Increased comprehension of syntax in passive sentences',
            },
            {
              value:
                'Increased comprehension of syntax in complex sentences (e.g. object-cleft)',
              label:
                'Increased comprehension of syntax in complex sentences (e.g. object-cleft)',
            },
            {
              value: 'Increased comprehension of narrative discourse',
              label: 'Increased comprehension of narrative discourse',
            },
            {
              value:
                'Increased comprehension of prosodic intent and emotional tone',
              label:
                'Increased comprehension of prosodic intent and emotional tone',
            },
          ]
        } else if (FCMType === 'COG') {
          return [
            {
              value: 'Establishing orientation to self',
              label: 'Establishing orientation to self',
            },
            {
              value: 'Establishing orientation to others',
              label: 'Establishing orientation to others',
            },
            {
              value: 'Establishing orientation to location/date/time',
              label: 'Establishing orientation to location/date/time',
            },
          ]
        } else if (FCMType === 'SWAL') {
          return [
            {
              value: 'Increased area of vocal fold contact',
              label: 'Increased area of vocal fold contact',
            },
            {
              value:
                'Increased tightness of vocal fold adduction closure during swallow',
              label:
                'Increased tightness of vocal fold adduction closure during swallow',
            },
            {
              value:
                'Increased duration of laryngeal closure during swallowing',
              label:
                'Increased duration of laryngeal closure during swallowing',
            },
            {
              value:
                'Earlier onset of upper airway closure in relation to bolus position',
              label:
                'Earlier onset of upper airway closure in relation to bolus position',
            },
            {
              value:
                'Earlier initiation of pharyngeal response in relation to bolus entry into pharynx',
              label:
                'Earlier initiation of pharyngeal response in relation to bolus entry into pharynx',
            },
            {
              value:
                'Increased percentage of swallows followed by post-swallow expiration',
              label:
                'Increased percentage of swallows followed by post-swallow expiration',
            },
            {
              value: 'Increased epiglottic inversion',
              label: 'Increased epiglottic inversion',
            },
            {
              value: 'Improved oral hygiene',
              label: 'Improved oral hygiene',
            },
          ]
        } else if (FCMType === 'VOIC') {
          return [
           
            {
              label: 'Improved chin posture',
              value: 'improved chin posture',
            },
            {
              label: 'Improved atlanto-occipital alignment',
              value: 'improved atlanto occipital alignment',
            },
          ]
        } else {
          return []
        }

      case 2:
        if (FCMType === 'INT') {
          return [
            {
              value: 'Increased comprehensible words/messages per minute',
              label: 'Increased comprehensible words/messages per minute',
            },
            {
              value: 'Increased use of supplementary strategies',
              label: 'Increased use of supplementary strategies',
            },
            {
              value:
                'Increased comprehensibility in adverse listening/speaking situations',
              label:
                'Increased comprehensibility in adverse listening/speaking situations',
            },
          ]
        } else if (FCMType === 'SLE') {
          return [
            { value: 'Increased rate', label: 'Increased rate' },
            {
              value:
                'Increased content over time (information units per minute)',
              label:
                'Increased content over time (information units per minute)',
            },
            {
              value: 'Decreased perseverations',
              label: 'Decreased perseverations',
            },
          ]
        } else if (FCMType === 'SLC') {
          return [
            {
              value:
                'Increased recognition of the internal signs or feeling of comprehension breakdowns',
              label:
                'Increased recognition of the internal signs or feeling of comprehension breakdowns',
            },
            {
              value:
                'Increased recognition of nonverbal signals that there is a communication breakdown',
              label:
                'Increased recognition of nonverbal signals that there is a communication breakdown',
            },
            {
              value:
                'Increased requesting of clarifications or modifications (e.g. slow rate and speak up)',
              label:
                'Increased requesting of clarifications or modifications (e.g. slow rate and speak up)',
            },
          ]
        } else if (FCMType === 'COG') {
          return [
            {
              value: 'Establishing attention',
              label: 'Establishing attention',
            },
            {
              value: 'Increased capacity for maintaining attention',
              label: 'Increased capacity for maintaining attention',
            },
            {
              value: 'Increased capacity for selective attention',
              label: 'Increased capacity for selective attention',
            },
            {
              value: 'Increased capacity for divided attention',
              label: 'Increased capacity for divided attention',
            },
            {
              value: 'Increased capacity for alternating attention',
              label: 'Increased capacity for alternating attention',
            },
            {
              value:
                'Increased frequency of self-regulated use of external stimuli for attention',
              label:
                'Increased frequency of self-regulated use of external stimuli for attention',
            },
            {
              value:
                'Increased frequency of self-regulated use of internal stimuli/strategies for attention',
              label:
                'Increased frequency of self-regulated use of internal stimuli/strategies for attention',
            },
          ]
        } else if (FCMType === 'SWAL') {
          return [
            {
              value: 'Prolonged duration of UES opening during swallow',
              label: 'Prolonged duration of UES opening during swallow',
            },
            {
              value:
                'Increased traction forces on anterior UES during pharyngeal phase of swallow',
              label:
                'Increased traction forces on anterior UES during pharyngeal phase of swallow',
            },
            {
              value:
                'Increased diameter of UES opening during the pharyngeal swallow',
              label:
                'Increased diameter of UES opening during the pharyngeal swallow',
            },
            {
              value: 'Increased compliance of UES during swallowing',
              label: 'Increased compliance of UES during swallowing',
            },
            {
              value: 'Decreased pharyngeal residue',
              label: 'Decreased pharyngeal residue',
            },
          ]
        } else if (FCMType === 'VOIC') {
          return [
          
            {
              label: 'Increased lateral cartilage movement at rest',
              value: 'increased lateral cartilage movement at rest',
            },
            {
              label: 'Increased lateral hyoid bone movement at rest',
              value: 'increased lateral hyoid bone movement at rest',
            },
            {
              label: 'Lowered thyroid cartilage at rest',
              value: 'lowered thyroid cartilage at rest',
            },
            {
              label: 'Lowered position of hyoid bone at rest',
              value: 'lowered position of hyoid bone at rest',
            },
            {
              label:
                'Decreased resting tension of neck, tongue, orofacial or postural muscles',
              value:
                'decreased resting tension neck tongue orofacial postural muscles',
            },
            {
              label:
                'Increased strength of true vocal fold abduction/adduction',
              value: 'increased strength true vocal fold abduction adduction',
            },
            {
              label:
                'Increased range of motion of true vocal fold abduction/adduction',
              value:
                'increased range of motion true vocal fold abduction adduction',
            },
            {
              label: 'Decreased fatigue over exercise',
              value: 'decreased fatigue over exercise',
            },
            {
              label: 'Maintain widened thyrohyoid space during voicing',
              value: 'maintain widened thyrohyoid space during voicing',
            },
            {
              label:
                'Maintain lower thyroid cartilage or hyoid bone during voicing',
              value:
                'maintain lower thyroid cartilage hyoid bone during voicing',
            },
            {
              label: 'Decreased strained vocal quality',
              value: 'decreased strained vocal quality',
            },
            {
              label: 'Increased loudness range',
              value: 'increased loudness range',
            },
            {
              label: 'Increased pitch range',
              value: 'increased pitch range',
            },
            {
              label: 'Modify habitual pitch',
              value: 'modify habitual pitch',
            },
            {
              label: 'Modify habitual loudness',
              value: 'modify habitual loudness',
            },
            {
              label: 'Increased duration of voicing',
              value: 'increased duration of voicing',
            },
          ]
        } else {
          return []
        }
      case 3:
        if (FCMType === 'INT') {
          return [
            {
              value: 'Maximized breath groups',
              label: 'Maximized breath groups',
            },
            {
              value: 'Increased intelligible words/units of meaning per minute',
              label: 'Increased intelligible words/units of meaning per minute',
            },
            {
              value: 'Improved communication repair strategies',
              label: 'Improved communication repair strategies',
            },
            {
              value: 'Increased use of alerting signals',
              label: 'Increased use of alerting signals',
            },
            {
              value: 'Increased speech fluency',
              label: 'Increased speech fluency',
            },
            {
              value: 'Increased speech initiations',
              label: 'Increased speech initiations',
            },
          ]
        } else if (FCMType === 'COG') {
          return [
            {
              value: 'Increased capacity for encoding/retrieval',
              label: 'Increased capacity for encoding/retrieval',
            },
            {
              value: 'Increased working memory',
              label: 'Increased working memory',
            },
            {
              value: 'Increased immediate memory',
              label: 'Increased immediate memory',
            },
            {
              value: 'Increased short-term memory',
              label: 'Increased short-term memory',
            },
            {
              value: 'Increased long-term memory',
              label: 'Increased long-term memory',
            },
            {
              value: 'Increased prospective memory',
              label: 'Increased prospective memory',
            },
            {
              value:
                'Increased frequency of self-regulated use of external stimuli for memory',
              label:
                'Increased frequency of self-regulated use of external stimuli for memory',
            },
            {
              value:
                'Increased frequency of self-regulated use of internal stimuli/strategies for memory',
              label:
                'Increased frequency of self-regulated use of internal stimuli/strategies for memory',
            },
          ]
        } else if (FCMType === 'SWAL') {
          return [
            {
              value: 'Increased contractile strength of tongue',
              label: 'Increased contractile strength of tongue',
            },
            {
              value: 'Increased lingual propulsive force during swallow',
              label: 'Increased lingual propulsive force during swallow',
            },
            {
              value: 'Increased propulsion force of pharyngeal constrictors',
              label: 'Increased propulsion force of pharyngeal constrictors',
            },
            {
              value:
                'Decreased loss of intrabolus pressure to velopharyngeal incompetence',
              label:
                'Decreased loss of intrabolus pressure to velopharyngeal incompetence',
            },
          ]
        } else if (FCMType === 'VOIC') {
          return [
           
            {
              label: 'Decreased roughness',
              value: 'decreased roughness',
            },
            {
              label: 'Decreased strain',
              value: 'decreased strain',
            },
            {
              label: 'Modify breathiness in volitional voicing tasks',
              value: 'modify breathiness volitional tasks',
            },
            {
              label: 'Modify roughness in volitional voicing tasks',
              value: 'modify roughness volitional tasks',
            },
            {
              label: 'Modify strain in volitional voicing tasks',
              value: 'modify strain volitional tasks',
            },
            {
              label:
                'Decreased roughness or breathiness in skilled voicing tasks',
              value: 'decreased roughness breathiness skilled tasks',
            },
          ]
        } else {
          return []
        }
      case 4:
        if (FCMType === 'INT') {
          return [
            {
              value: 'Improved loudness control and loudness variation',
              label: 'Improved loudness control and loudness variation',
            },
            {
              value: 'Increased pitch variability',
              label: 'Increased pitch variability',
            },
            {
              value: 'Improved use/control of prosody',
              label: 'Improved use/control of prosody',
            },
            { value: 'Improved rate control', label: 'Improved rate control' },
            {
              value: 'Improved velopharyngeal function',
              label: 'Improved velopharyngeal function',
            },
          ]
        } else if (FCMType === 'COG') {
          return [
            {
              value: 'Increased capacity for goal setting',
              label: 'Increased capacity for goal setting',
            },
            {
              value: 'Increased capacity for planning',
              label: 'Increased capacity for planning',
            },
            {
              value: 'Increased capacity for implementation of plan',
              label: 'Increased capacity for implementation of plan',
            },
            {
              value: 'Increased capacity for monitoring',
              label: 'Increased capacity for monitoring',
            },
            {
              value: 'Increased capacity for problem solving/reasoning',
              label: 'Increased capacity for problem solving/reasoning',
            },
            {
              value: 'Increased capacity for organization/sequencing',
              label: 'Increased capacity for organization/sequencing',
            },
            {
              value: 'Increased capacity for self-regulation',
              label: 'Increased capacity for self-regulation',
            },
          ]
        } else if (FCMType === 'SWAL') {
          return [
            {
              value: 'Increased anterior oral containment',
              label: 'Increased anterior oral containment',
            },
            {
              value: 'Increased posterior oral containment',
              label: 'Increased posterior oral containment',
            },
            {
              value: 'Increased strength of lip closure',
              label: 'Increased strength of lip closure',
            },
            {
              value:
                'Increased integrity of posterior oral linguavelar/linguapalatal valve during oral preparatory stage',
              label:
                'Increased integrity of posterior oral linguavelar/linguapalatal valve during oral preparatory stage',
            },
            {
              value: 'Decreased oral residue',
              label: 'Decreased oral residue',
            },
            {
              value:
                'Exploitation of gravity to increase control of oral contents',
              label:
                'Exploitation of gravity to increase control of oral contents',
            },
          ]
        } else if (FCMType === 'VOIC') {
          return [
           
            {
              label: 'Modify nasality',
              value: 'modify nasality',
            },
            {
              label: 'Modify placement',
              value: 'modify placement',
            },
          ]
        } else {
          return []
        }
      case 5:
        if (FCMType === 'COG') {
          return [
            {
              value: 'Increased safety awareness/insight',
              label: 'Increased safety awareness/insight',
            },
            {
              value: 'Increased deficit awareness',
              label: 'Increased deficit awareness',
            },
            {
              value: 'Increased processing speed',
              label: 'Increased processing speed',
            },
            {
              value: 'Increased cognitive endurance',
              label: 'Increased cognitive endurance',
            },
          ]
        } else if (FCMType === 'SWAL') {
          return [
            {
              value: 'Increased lung volume present at swallow onset',
              label: 'Increased lung volume present at swallow onset',
            },
            {
              value: 'Increased post-swallow expiratory/cough pressure',
              label: 'Increased post-swallow expiratory/cough pressure',
            },
            {
              value: 'Increased speed of onset of post-swallow cough',
              label: 'Increased speed of onset of post-swallow cough',
            },
          ]
        } else if (FCMType === 'VOIC') {
          return [
           
            {
              label: 'Increased respiratory control',
              value: 'increased respiratory control',
            },
            {
              label: 'Modify voicing onset',
              value: 'modify voicing onset',
            },
            {
              label: 'Modify respiratory support',
              value: 'modify respiratory support',
            },
            {
              label: 'Modify breathing pattern',
              value: 'modify breathing pattern',
            },
          ]
        } else {
          return []
        }
      case 6:
        if (FCMType === 'SWAL') {
          return [
            {
              value: 'Increased oral intake',
              label: 'Increased oral intake',
            },
          ]
        } else if (FCMType === 'VOIC') {
          return [
           
            {
              label:
                'Improved rhythm of speech-related activities (stress and breathing patterns)',
              value: 'improved rhythm of speech',
            },
            {
              label: 'Improved rate (syllables or repetitions per time period)',
              value: 'improved rate',
            },
          ]
        } else {
          return []
        }
      case 7:
        if (FCMType === 'VOIC') {
          return [
            
            {
              label:
                'Modify lifestyle (e.g. decrease smoking and manage reflux and manage/increase hydration)',
              value: 'modify lifestyle',
            },
            {
              label: 'Reduced pain, soreness, or discomfort at rest',
              value: 'reduced pain at rest',
            },
            {
              label: 'Reduced pain, discomfort, soreness during voicing',
              value: 'reduced pain during voicing',
            },
            {
              label:
                'Increased knowledge and/or affect related to vocal health',
              value: 'increased knowledge vocal health',
            },
          ]
        } else {
          return []
        }
      default:
        return []
    }
  }

  const selectedOptions = value?.split(',').map((val) => {
    if (val && val !== '') {
      const option = generateOptions().find((opt) => opt.value === val)
      return option ? option : { value: val, label: val }
    }
  })

  // Handle change in selected options
  const handleChange = (selectedOptions) => {
    // Convert array of selected options to comma-separated string
    const selectedValues = selectedOptions
      .map((option) => option.value)
      .join(',')
    //alert(selectedValues)
    onChange(selectedValues)
  }

  return (
    <Select
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti
      options={generateOptions()}
      value={selectedOptions || []}
      onChange={handleChange}
    />
  )
}

export default MultiSelectDDL
