const OptionsforLanguage = [
  { value: 'English' },
  { value: 'Spanish' },
  { value: 'Arabic' },
  { value: 'Armenian' },
  { value: 'Cantonese' },
  { value: 'Gujarati' },
  { value: 'Hindi' },
  { value: 'Indonesian' },
  { value: 'Kannada' },
  { value: 'Korean' },
  { value: 'Lao' },
  { value: 'Malay' },
  { value: 'Mandarin' },
  { value: 'Marathi' },
  { value: 'Persian' },
  { value: 'Russian' },
  { value: 'Tagalog' },
  { value: 'Telugu' },
  { value: 'Thai' },
  { value: 'Ukrainian' },
  { value: 'Vietnamese' },
  { value: 'Other' },
]

const LanguageOptions = OptionsforLanguage.map((item) => (
  <option key={item.value} value={item.value} label={item.value}></option>
))

const LanguageOptionsValue = OptionsforLanguage.map((item) => ({
  value: item.value,
  text: item.value,
}))

const CommunicatesThrough = [
  { value: 'Single words' },
  { value: 'Pointing' },
  { value: 'Vocalizing' },
  { value: 'Grunting' },
  { value: 'Phrases' },
  { value: 'Sentences' },
  { value: 'Conversation' },
]

const CommunicatesThroughOptions = CommunicatesThrough.map((item) => ({
  value: item.value,
  text: item.value,
}))

const UnderstandingLevel = [
  { value: '0-25%' },
  { value: '26-50%' },
  { value: '51-75%' },
  { value: '76-100%' },
]

const UnderstandingLevelValue = UnderstandingLevel.map((item) => ({
  value: item.value,
  text: item.value,
}))

const ClassGrade = [
  { value: '' },
  { value: 'PreK' },
  { value: 'K' },
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
  { value: '7' },
  { value: '8' },
  { value: '9' },
  { value: '10' },
  { value: '11' },
  { value: '12' },
]

const ClassGradeValue = ClassGrade.map((item) => ({
  value: item.value,
  text: item.value,
}))

const CurrentPlan = [
  { value: 'IEP' },
  { value: 'IFSP' },
  { value: 'None' },
  { value: '504' },
]

const CurrentPlanValue = CurrentPlan.map((item) => ({
  value: item.value,
  text: item.value,
}))

const Prognosis = [{ value: 'Good' }, { value: 'Fair' }, { value: 'Guarded' }]

const PrognosisValue = Prognosis.map((item) => ({
  value: item.value,
  text: item.value,
}))

const PlanDiscussWith = [
  { value: 'Client' },
  { value: 'Parent' },
  { value: 'Caretaker' },
]

const PlanDiscussWithValue = PlanDiscussWith.map((item) => ({
  value: item.value,
  text: item.value,
}))

const Frequency = [
  { value: '1', defaultSelected: true },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
]

const FrequencyValue = Frequency.map((item) => ({
  value: item.value,
  text: item.value,
}))

const Week = [
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
  { value: '7' },
  { value: '8' },
  { value: '9' },
  { value: '10' },
  { value: '11' },
  { value: '12' },
  { value: '24' },
  { value: '26', defaultSelected: true },
]

const WeekValue = Week.map((item) => ({
  value: item.value,
  text: item.value,
}))

const FcmItems = [
  { id: 'COG', label: 'Cognition' },
  { id: 'ELIT', label: 'Emergent Literacy' },
  { id: 'FEED', label: 'Feeding' },
  { id: 'FLU', label: 'Fluency' },
  { id: 'READ', label: 'Functional Reading' },
  { id: 'AAC', label: 'AAC' },
  { id: 'PRC', label: 'Pediatric Reading Comprehension' },
  { id: 'PRAG', label: 'Pragmatics' },
  { id: 'INT', label: 'Speech Intelligibility' },
  { id: 'SLC', label: 'Spoken Language Comprehension' },
  { id: 'SLE', label: 'Spoken Language Expression' },
  { id: 'SWAL', label: 'Swallowing' },
  { id: 'VOIC', label: 'Voice' },
  { id: 'WRIT', label: 'Writting' },
]
const StatusDischarge = [
  { value: 'Met Goals' },
  { value: 'Plateaued with functional status ' },
  { value: 'Receiving therapy at another clinic' },
  { value: 'Transferred to another clinic' },
  { value: 'Receiving School based therapy' },
  { value: 'Excessive Absenteeism' },
  { value: 'Nonclompliance' },
  { value: 'Lack of Functional progress' },
  { value: 'Refused teletherapy' },
  { value: 'Refused Speech Therapy' },
  { value: 'Hospital Admission' },
  { value: 'Change of Insurance' },
  { value: 'Duplicate client' },
  { value: 'Inactive insurance' },
  { value: 'Need in person services' },
  { value: 'Technology not compatible' },
  { value: 'No sessions. Eval only' },
  { value: 'No sessions Contact made' },
  { value: 'Unable to Reach' },
  { value: 'Auth Expired' },
  { value: 'ST not indicated' },
  { value: 'High Co-pay/deductible' },
]
const StatusDischargeOptions = StatusDischarge.map((item) => ({
  value: item.value,
  text: item.value,
}))

const Comprehends = [
  { id: 'Words', label: 'Words' },
  { id: 'Phrases', label: 'Phrases' },
  { id: 'Sentences', label: 'Sentences' },
  { id: 'Body Parts', label: 'Body Parts' },
  { id: 'Objects', label: 'Objects' },
  { id: 'Objects Function', label: 'Objects Function' },
  { id: 'Prepositions', label: 'Prepositions' },
  { id: 'Categories', label: 'Categories' },
  { id: 'Differences', label: 'Differences' },
  { id: 'More/Less', label: 'More/Less' },
  { id: 'Similarities', label: 'Similarities' },
  { id: 'Time', label: 'Time' },
  { id: 'Emotions', label: 'Emotions' },
  { id: 'Quality', label: 'Quality' },
  { id: 'Quantity', label: 'Quantity' },
  { id: 'Attributes', label: 'Attributes' },
  { id: 'Actions', label: 'Actions' },
  { id: 'Sequencing', label: 'Sequencing' },
  { id: 'Pronouns', label: 'Pronouns' },
  { id: 'Idioms', label: 'Idioms' },
  { id: 'Spatial Concepts', label: 'Spatial Concepts' },
  { id: 'Names', label: 'Names' },
  { id: 'Gestures', label: 'Gestures' },
  { id: 'Nonverbal Cues', label: 'Nonverbal Cues' },
]
const ComprehendsOptions = Comprehends.map((item) => ({
  id: item.id,
  label: item.label,
}))

const WordUse = [
  { id: 'syllables', label: 'Syllables' },
  { id: 'words', label: 'Words' },
  { id: 'phrases', label: 'Phrases' },
  { id: 'sentences', label: 'Sentences' },
  { id: 'gestures', label: 'Gestures' },
  { id: 'nouns', label: 'Nouns' },
  { id: 'verbs', label: 'Verbs' },
  { id: 'adjectives', label: 'Adjectives' },
  { id: 'adverbs', label: 'Adverbs' },
  { id: 'pronouns', label: 'Pronouns' },
  { id: 'conjunctions', label: 'Conjunctions' },
]
const WordUseOptions = WordUse.map((item) => ({
  id: item.id,
  label: item.label,
}))

const AnswerQuestions = [
  { id: 'Who', label: 'Who' },
  { id: 'What', label: 'What' },
  { id: 'Where', label: 'Where' },
  { id: 'When', label: 'When' },
  { id: 'Why', label: 'Why' },
  { id: 'How', label: 'How' },
  { id: 'Yes/No', label: 'Yes/No' },
  { id: 'Open Ended', label: 'Open Ended' },
]
const AnswerQuestionOptions = AnswerQuestions.map((item) => ({
  id: item.id,
  label: item.label,
}))
const NotedLanguage = [
  { id: 'requesting', label: 'Requesting' },
  { id: 'rhyming', label: 'Rhyming' },
  { id: 'paraphrasing', label: 'Paraphrasing' },
  { id: 'asks yes/no', label: 'Asks Yes/No' },
  { id: 'asks how', label: 'Asks How' },
  { id: 'asks who', label: 'Asks Who' },
  { id: 'asks what', label: 'Asks What' },
  { id: 'asks when', label: 'Asks When' },
  { id: 'asks where', label: 'Asks Where' },
  { id: 'asks why', label: 'Asks Why' },
  { id: 'attributes', label: 'Attributes' },
  { id: 'idioms', label: 'Idioms' },
  { id: 'synonyms', label: 'Synonyms' },
  { id: 'antonyms', label: 'Antonyms' },
  // { id: 'opposites', label: 'Opposites' },
]
const NotedLanguageOptions = NotedLanguage.map((item) => ({
  id: item.id,
  label: item.label,
}))

const PragmaticLanguage = [
  { id: 'attends to objects', label: 'Attends to Objects' },
  { id: 'uses words functionally', label: 'Uses Words Functionally' },
  { id: 'uses automatic speech', label: 'Uses Automatic Speech' },
  { id: 'makes needs known', label: 'Makes Needs Known' },
  { id: 'attends to speaker', label: 'Attends to Speaker' },
  { id: 'abstract vocabulary', label: 'Abstract Vocabulary' },
  { id: 'figurative language', label: 'Figurative Language' },
  { id: 'self corrects', label: 'Self Corrects' },
  { id: 'imitates movements', label: 'Imitates Movements' },
  { id: 'imitates words', label: 'Imitates Words' },
  { id: 'imitates play', label: 'Imitates Play' },
]
const PragmaticLanguageOptions = PragmaticLanguage.map((item) => ({
  id: item.id,
  label: item.label,
}))

const LanguageComplexity = [
  { id: 'request', label: 'Request' },
  { id: 'eye contact', label: 'Eye Contact' },
  { id: 'initiating conversation', label: 'Initiating Conversation' },
  { id: 'topic maintenance', label: 'Topic Maintenance' },
  { id: 'basic social language', label: 'Basic Social Language' },
  { id: 'other', label: 'Other' },
  { id: 'turn taking', label: 'Turn Taking' },
]
const LanguageComplexityOptions = LanguageComplexity.map((item) => ({
  id: item.id,
  label: item.label,
}))

const Orientation = [
  { id: 'Person', label: 'Person' },
  { id: 'Place', label: 'Place' },
  { id: 'Time', label: 'Time' },
  { id: 'Situation', label: 'Situation' },
]
const OrientationOptions = Orientation.map((item) => ({
  id: item.id,
  label: item.label,
}))
const Memory = [
  { id: 'Immediate', label: 'Immediate' },
  { id: 'LTM', label: 'LTM' },
  { id: 'STM', label: 'STM' },
  { id: 'Procedural', label: 'Procedural' },
  { id: 'Working', label: 'Working' },
  { id: 'Prospective', label: 'Prospective' },
]
const MemoryOptions = Memory.map((item) => ({
  id: item.id,
  label: item.label,
}))

const Attention = [
  { id: 'Sustained', label: 'Sustained' },
  { id: 'Selective', label: 'Selective' },
  { id: 'Alternating', label: 'Alternating' },
  { id: 'Divided', label: 'Divided' },
]
const AttentionOptions = Attention.map((item) => ({
  id: item.id,
  label: item.label,
}))

const ExecutiveFunctioning = [
  { id: 'Initiation', label: 'Initiation' },
  { id: 'Organization', label: 'Organization' },
  { id: 'Problem Solving', label: 'Problem Solving' },
  { id: 'Sequencing', label: 'Sequencing' },
]
const ExecutiveFunctioningOptions = ExecutiveFunctioning.map((item) => ({
  id: item.id,
  label: item.label,
}))
const Pragmatics = [
  { id: 'vocal tone', label: 'Vocal Tone' },
  { id: 'turn taking', label: 'Turn Taking' },
  { id: 'interaction', label: 'Interaction' },
  { id: 'topic maintainance', label: 'Topic Maintainance' },
  { id: 'expresses intent', label: 'Expresses Intent' },
  { id: 'code switching', label: 'Code Switching' },
  { id: 'body language', label: 'Body Language' },
  { id: 'facial expressions', label: 'Facial Expressions' },
]
const PragmaticsOptions = Pragmatics.map((item) => ({
  id: item.id,
  label: item.label,
}))
const Symptoms = [
  { id: 'Coughing', label: 'Coughing' },
  { id: 'Choking', label: 'Choking' },
  { id: 'Drooling', label: 'Drooling' },
  { id: 'Globus', label: 'Globus' },
  { id: 'Pain', label: 'Pain' },
  { id: 'Weight loss', label: 'Weight loss' },
  { id: 'Pneumonia', label: 'Pneumonia' },
  { id: 'Aspiration', label: 'Aspiration' },
  { id: 'Stuck bolus', label: 'Stuck bolus' },
  { id: 'Other', label: 'Other' },
]
const SymptomsOptions = Symptoms.map((item) => ({
  id: item.id,
  label: item.label,
}))

const History = [
  { id: 'Weight loss', label: 'Weight loss' },
  { id: 'Weight gain', label: 'Weight gain' },
  { id: 'Failure to thrive', label: 'Failure to thrive' },
  { id: 'Reflux', label: 'Reflux' },
  { id: 'Food allergies', label: 'Food allergies' },
  { id: 'Lip tie', label: 'Lip tie' },
  { id: 'Pacifier use', label: 'Pacifier use' },
  { id: 'Bottle use', label: 'Bottle use' },
  { id: 'Biting', label: 'Biting' },
  { id: 'Dental issues', label: 'Dental issues' },
  { id: 'Tube feeding', label: 'Tube feeding' },
  { id: 'Refuses food', label: 'Refuses food' },
  { id: 'Licking', label: 'Licking' },
  { id: 'Dooling', label: 'Drooling' },
  { id: 'Pneumonia', label: 'Pneumonia' },
  { id: 'Choking', label: 'Choking' },
  { id: 'Spitting up', label: 'Spitting up' },
  { id: 'Pain', label: 'Pain' },
  { id: 'Consecutive', label: 'Consecutive' },
  { id: 'Thumb/finger sucking', label: 'Thumb/finger sucking' },
]
const HistoryOptions = History.map((item) => ({
  id: item.id,
  label: item.label,
}))

const QualityResonance = [
  { id: 'Smooth', label: 'Smooth' },
  { id: 'Harsh', label: 'Harsh' },
  { id: 'Tense', label: 'Tense' },
  { id: 'Breathy', label: 'Breathy' },
  { id: 'Hoarse', label: 'Hoarse' },
  { id: 'Fry', label: 'Fry' },
  { id: 'Strained', label: 'Strained' },
  { id: 'Rough', label: 'Rough' },
  { id: 'Wet', label: 'Wet' },
  { id: 'Tremor', label: 'Tremor' },
  { id: 'Gurgly', label: 'Gurgly' },
  { id: 'Aphonia', label: 'Aphonia' },
]
const QualityResonanceOptions = QualityResonance.map((item) => ({
  id: item.id,
  label: item.label,
}))

const Dysfluency = [
  { id: 'Repetitions', label: 'Repetitions' },
  { id: 'Prolongations', label: 'Prolongations' },
  { id: 'Blocks', label: 'Blocks' },
  { id: 'Fillers', label: 'Fillers' },
]
const DysfluencyOptions = Dysfluency.map((item) => ({
  id: item.id,
  label: item.label,
}))

const PhysicalPresentations = [
  { id: 'Tension', label: 'Tension' },
  { id: 'Frustration', label: 'Frustration' },
  { id: 'Complaints', label: 'Complaints' },
  { id: 'Avoidances', label: 'Avoidances' },
  { id: 'Secondary behaviors', label: 'Secondary behaviors' },
]
const PhysicalPresentationsOptions = PhysicalPresentations.map((item) => ({
  id: item.id,
  label: item.label,
}))

const Cluttering = [
  { id: 'Atypical pauses', label: 'Atypical pauses' },
  { id: 'Deletion of syllables', label: 'Deletion of syllables' },
  { id: 'Interjections', label: 'Interjections' },
  { id: 'Revisions', label: 'Revisions' },
  { id: 'Topic shifting', label: 'Topic shifting' },
  { id: 'Ommissions', label: 'Ommissions' },
]
const ClutteringOptions = Cluttering.map((item) => ({
  id: item.id,
  label: item.label,
}))

const VerbalComm = [
  { id: 'Turn taking', label: 'Turn taking' },
  { id: 'Topic maintenance', label: 'Topic maintenance' },
  { id: 'Asking for clarification', label: 'Asking for clarification' },
  { id: 'Giving feedback', label: 'Giving feedback' },
  { id: 'Receiving Feedback', label: 'Receiving Feedback' },
  { id: 'Greets others', label: 'Greets others' },
  { id: 'Responds to question', label: 'Responds to question' },
  { id: 'Displays manners', label: 'Displays manners' },
  { id: 'Prediction of outcome', label: 'Prediction of outcome' },
  { id: 'Offers solutions', label: 'Offers solutions' },
  { id: 'Tells stories', label: 'Tells stories' },
]
const VerbalCommOptions = VerbalComm.map((item) => ({
  id: item.id,
  label: item.label,
}))

const NonVerbalComm = [
  { id: 'Eye contact', label: 'Eye contact' },
  { id: 'Facial Expressions', label: 'Facial Expressions' },
  { id: 'Body Language', label: 'Body Language' },
  { id: 'Gestures', label: 'Gestures' },
  { id: 'Helps others', label: 'Helps others' },
  { id: 'Faces speaker', label: 'Faces speaker' },
  { id: 'Reads facial cues', label: 'Reads facial cues' },
  { id: 'Shows interest', label: 'Shows interest' },
]
const NonVerbalCommOptions = NonVerbalComm.map((item) => ({
  id: item.id,
  label: item.label,
}))
const SocialInteraction = [
  { id: 'Initiating conversation', label: 'Initiating conversation' },
  { id: 'Respecting personal space', label: 'Respecting personal space' },
  { id: 'Waits for turn', label: 'Waits for turn' },
  { id: 'Understanding social cues', label: 'Understanding social cues' },
  { id: 'Responding to others', label: 'Responding to others' },
  { id: 'Plays in groups', label: 'Plays in groups' },
  { id: 'Expresses empathy', label: 'Expresses empathy' },
  { id: 'Displays Humor', label: 'Displays Humor' },
]
const SocialInteractionOptions = SocialInteraction.map((item) => ({
  id: item.id,
  label: item.label,
}))

const pragVoice = [
  { id: 'Volume', label: 'Volume' },
  { id: 'Inflection', label: 'Inflection' },
  { id: 'Speed', label: 'Speed' },
  { id: 'Tone', label: 'Tone' },
]
const PragVoiceOptions = pragVoice.map((item) => ({
  id: item.id,
  label: item.label,
}))

export {
  StatusDischargeOptions,
  FcmItems,
  WeekValue,
  FrequencyValue,
  PlanDiscussWithValue,
  PrognosisValue,
  CurrentPlanValue,
  ClassGradeValue,
  OptionsforLanguage,
  LanguageOptions,
  LanguageOptionsValue,
  CommunicatesThroughOptions,
  UnderstandingLevelValue,
  ComprehendsOptions,
  AnswerQuestionOptions,
  WordUseOptions,
  NotedLanguageOptions,
  PragmaticLanguageOptions,
  LanguageComplexityOptions,
  OrientationOptions,
  MemoryOptions,
  AttentionOptions,
  ExecutiveFunctioningOptions,
  PragmaticsOptions,
  SymptomsOptions,
  HistoryOptions,
  QualityResonanceOptions,
  DysfluencyOptions,
  PhysicalPresentationsOptions,
  ClutteringOptions,
  VerbalCommOptions,
  NonVerbalCommOptions,
  SocialInteractionOptions,PragVoiceOptions
}
