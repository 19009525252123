import React, { useState, useEffect, useContext, useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { TextareaComponent } from '../../Controls/TextareaComponent '
import {
  VisitProvider,
  SignLockProvider,
  CreatedByProvider,
} from '../../Provider/Visitprovider'
import { useUser } from '../../Provider/UserContext'
import { CHAMedicalService } from './CHAMedicalService'
import { SelectYesNo } from '../../Controls/DDLYesNo'
import { DDList } from '../../Controls/DDList'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { TextBox } from '../../Controls/TextareaComponent '
import { MultiSelectDropdown } from '../../Controls/MultiSelectDropdown'
import MonthYearPicker from '../../Controls/MonthYearPicker'
import { Datectrl } from '../../Controls/Datectrl'
import { CheckboxListInColumn } from '../../Controls/CheckBoxList'
import { AsyncAutoComplete } from '../../Controls/MultiSelectDropdown'
import { PocService } from '../../POC/Service/PocService'
import { Variables } from '../../Variables'
import axios from 'axios'
import Loading from '../../Controls/Loading'
const underlineStyle = {
  textDecoration: 'underline',
}
const apiUrlSLPCore = Variables.USER_API_URL
export const CHAMedicalUI = React.forwardRef(
  ({ onDataFetched, triggerRefresh }, ref) => {
    const { userFirstName, userDetails } = useUser()
    const VisitId = useContext(VisitProvider)
    const CreatedBy = useContext(CreatedByProvider)
    const IsSignLock = useContext(SignLockProvider)
    const medicallService = new CHAMedicalService()
    const [formData, setFormData] = useState(new CHAMedicalService())
    const [refreshForm, setRefreshForm] = useState(false)
    const [noticeaChangeError, setnoticeaChangeErrorError] = useState(false)
    const [HeadInjuryDescriptionError, setHeadInjuryDescriptionError] =
      useState(false)
    const [AllergyDescriptionError, setAllergyDescriptionError] =
      useState(false)
    const [MedicationDescriptionError, setMedicationDescriptionError] =
      useState(false)
    const [icdCode, setIcdCode] = useState()
    const pocService = new PocService()
    const [isRequiredMessageShown, setIsRequiredMessageShown] = useState(false)
    const [primaryDiagnosisDetail, setPrimaryDiagnosisDetail] = useState('')
    const [loading, setLoading] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [isDirty, setIsDirty] = useState(false)

    React.useImperativeHandle(ref, () => ({
      SaveButtonClick,
    }))

    useEffect(() => {
      if (userDetails) {
        formData.VisitId = VisitId
        formData.CreatedBy = CreatedBy
        formData.UpdatedBy = CreatedBy
        //alert(JSON.stringify(formData));
      }
    }, [userDetails, VisitId, CreatedBy])
    const icdCodedata = () => {
      pocService
        .getPrimaryDiagnosisCode()
        .then((dataFromResponse) => {
          //const first100Items = dataFromResponse.slice(0,1000);
          const transformedData = dataFromResponse.map((item) => ({
            value: item.Value,
            label: item.Text,
          }))
          //alert(JSON.stringify(dataFromResponse));
          setIcdCode(transformedData)
          //setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching diagnosis data:', error)
        })
    }
    const getDiagnosisFromSLPCore = () => {
      axios
        .get(
          apiUrlSLPCore +
            'Episode/GetEpisodeByServiceLine?clientId=' +
            userDetails.Clientid +
            '&treatmentTypeId=1',
        )
        .then((response) => {
          const data = response.data
          if (response.status === 200) {
            //alert(JSON.stringify(data));
            if (data) {
              setFormData((prevFormData) => {
                const newData = {
                  ...prevFormData,
                  EpisodeId: data.episodeid,
                }
                if (data.diagnosisid1) {
                  newData.Diagnosis1 = data.diagnosisid1
                }
                if (data.diagnosisid2 !== null && data.diagnosisid2 !== '') {
                  newData.Diagnosis2 = data.diagnosisid2
                }
                if (data.diagnosisid3) {
                  newData.Diagnosis3 = data.diagnosisid3
                }
                if (data.diagnosisid4) {
                  newData.Diagnosis4 = data.diagnosisid4
                }
                //alert("aaaaaaaaaa"+JSON.stringify(updatedFormData));
                // setFormData(newData);
                //alert(JSON.stringify(newData));
                return newData
              })
            }

            //console.log(JSON.stringify(data));
          }
        })
        .catch((error) => {
          console.error(
            'Error fetching data CaseHistory/DiagnosisCode/:',
            error,
          )
        })
    }
    const fetchCHAData = () => {
      medicallService
        .getCHAData(VisitId)
        .then((dataFromResponse) => {
          //console.log("data Medical:"+JSON.stringify(dataFromResponse));
          if (dataFromResponse !== undefined) {
            setFormData(dataFromResponse)
          } else {
          }
        })
        .catch((error) => {
          console.error('Error in CHA general:', error)
        })
    }
    useEffect(() => {
      const fetchData = async () => {
        try {
          // Your API calls here...
          await Promise.all([
            icdCodedata(),
            getDiagnosisFromSLPCore(),
            fetchCHAData(),
          ])
        } catch (error) {
          console.error('Error:', error)
        } finally {
          //setLoading(false)
          setTimeout(() => {
            setLoading(false)
          }, 500)
          onDataFetched()
        }
      }

      fetchData()
      // icdCodedata()
      // if (userDetails) {
      //   if (userDetails.Clientid) {
      //     getDiagnosisFromSLPCore()
      //   }
      // }
      // if (VisitId !== null) {
      //   fetchData()
      // }
    }, [refreshForm, userDetails, VisitId])

    useEffect(() => {
      if (icdCode && icdCode.length > 0) {
        const valuesWithinParentheses = icdCode
          .filter((item) => item.value === formData.Diagnosis1)
          .map((item) => {
            const regex = /\(([^)]+)\)/
            const matches = item.label.match(regex)
            if (matches) {
              setPrimaryDiagnosisDetail(matches[1])
              //return matches[1];
            }
          })

        //alert(JSON.stringify(valuesWithinParentheses));
        //console.log(valuesWithinParentheses);
      }
    }, [icdCode, formData.Diagnosis1])

    const handleTextValueChange = (controlName, value) => {
      const valueControl = controlName
      const updatedFormData = {
        ...formData,
        [valueControl]: value,
      }
      setFormData(updatedFormData)
      //setIsDirty(true)
    }

    const ValidationObject = () => {
      let isValid = true

      if (formData.HadHeadInjury === 'Yes') {
        if (
          formData.HeadInjuryDescription === null ||
          formData.HeadInjuryDescription.trim() === ''
        ) {
          setHeadInjuryDescriptionError(true)
          //alert("hi" + formData.HeadInjuryDescription + "hi");
          isValid = false
        }
      }
      if (
        formData.HasAllergy === 'Yes' &&
        (formData.AllergyDescription === null ||
          formData.AllergyDescription.trim() === '')
      ) {
        setAllergyDescriptionError(true)
        isValid = false
      }
      if (
        formData.IsOnMedication === 'Yes' &&
        (formData.MedicationDescription === null ||
          formData.MedicationDescription.trim() === '')
      ) {
        setMedicationDescriptionError(true)
        isValid = false
      }

      return isValid
    }

    const SaveButtonClick = (showMsg = true) => {
      // const isValid = ValidationObject();

      // if (!isValid) {
      //   toast.error("Kindly fill the all required fields.");
      //   return;
      // }
      if (IsSignLock || !isDirty) {
        return
      }
      setIsSaving(true)
      formData.CreatedBy = CreatedBy
      formData.UpdatedBy = CreatedBy
      medicallService
        .insertCHAData(formData)
        .then((response) => {
          triggerRefresh()
          //toast.success(showMsg);
          //alert(showMsg);
          if (showMsg) {
            toast.success(response.MessageDetail)
          }
          setRefreshForm(true)
          //toast.success(response.MessageDetail);
          // Second API call to updateDiagnosisCode
          const episodeUpdatedData = {
            episodeid: formData.EpisodeId,
            diagnosisid2: formData.Diagnosis2,
            diagnosisid3: formData.Diagnosis3,
            diagnosisid4: formData.Diagnosis4,
            onsetdate: formData.Diagnosis1OnsetDate,
            Onsetdate2: formData.Diagnosis2OnsetDate,
            Onsetdate3: formData.Diagnosis3OnsetDate,
            Onsetdate4: formData.Diagnosis4OnsetDate,
          }
          //alert(JSON.stringify(episodeUpdatedData));
          if (formData.EpisodeId > 0) {
            return medicallService.updateDiagnosisCode(episodeUpdatedData)
          }
        })
        .catch((error) => {
          toast.error('Error in API call!')
        })
        .finally(() => {
          setIsSaving(false)
          setIsDirty(false)
        })
    }

    const ddlChange = (controlName, selectedValue) => {
      const updatedFormData = {
        ...formData,
        [controlName]: selectedValue,
      }
      setFormData(updatedFormData)
      setIsDirty(true)
    }

    const handleTextBoxValueChange = (controlName, value) => {
      const valueControl = controlName
      const updatedFormData = {
        ...formData,
        [valueControl]: value,
      }
      setFormData(updatedFormData)
      //setIsDirty(true)
    }
    const handleAutoCompleteChange = (controlName, selectedValue) => {
      const valueOnly = selectedValue.value // Extract the value property
      const valueControl = controlName
      if (valueOnly) {
        // alert("controlName" + JSON.stringify(controlName));
        // alert("value" + JSON.stringify(valueOnly));
        const updatedFormData = {
          ...formData,
          [valueControl]: valueOnly,
        }
        //alert("aaaaaaaaaa"+JSON.stringify(updatedFormData));
        setFormData(updatedFormData)
        setIsDirty(true)
      }
    }

    useEffect(() => {
      //alert("form data");
      ValidationObject()
    }, [formData])

    if (loading) {
      return <Loading />
    }
    return (
      <Container fluid>
        <Row className='mt-2'>
          <Col md={6}>
            <Row>
              <Col style={underlineStyle} md={12}>
                <b>Personal Profile</b>
                {/* <MonthYearPicker defaultDate={'1/2025'} onDateChange={handleDateChange} /> */}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col md={4}>Have you had a prior head injury?</Col>
              <Col md={2}>
                <SelectYesNo
                  defaultValue={formData.HadHeadInjury}
                  onSelect={(selectedValue) =>
                    ddlChange('HadHeadInjury', selectedValue)
                  }
                />
              </Col>
              <Col md={4}>Do you have any allergies? </Col>
              <Col md={2}>
                <SelectYesNo
                  defaultValue={formData.HasAllergy}
                  onSelect={(selectedValue) =>
                    ddlChange('HasAllergy', selectedValue)
                  }
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={6}>
                {formData.HadHeadInjury && formData.HadHeadInjury === 'Yes' && (
                  <TextareaComponent  IsChangeTracking={setIsDirty}
                    showError={HeadInjuryDescriptionError}
                    cols={40}
                    rows={2}
                    maxLength={200}
                    defaultValue={formData.HeadInjuryDescription}
                    onTextChange={(value) =>
                      handleTextValueChange('HeadInjuryDescription', value)
                    }
                    placeholder={'Please describe...'}
                  />
                )}
              </Col>
              <Col md={6}>
                {formData.HasAllergy && formData.HasAllergy === 'Yes' && (
                  <TextareaComponent  IsChangeTracking={setIsDirty}
                    showError={AllergyDescriptionError}
                    cols={40}
                    rows={2}
                    maxLength={200}
                    defaultValue={formData.AllergyDescription}
                    onTextChange={(value) =>
                      handleTextValueChange('AllergyDescription', value)
                    }
                    placeholder={'Please describe...'}
                  />
                )}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col md={4}>Any recent changes to your voice?</Col>
              <Col md={2}>
                <SelectYesNo
                  defaultValue={formData.HasRecentChangeInVoice}
                  onSelect={(selectedValue) =>
                    ddlChange('HasRecentChangeInVoice', selectedValue)
                  }
                />
              </Col>
              {formData.HasRecentChangeInVoice &&
                formData.HasRecentChangeInVoice === 'Yes' && (
                  <>
                    <Col md={4}>When did you first notice a change?</Col>
                    <Col md={2}>
                      <MonthYearPicker
                        defaultDate={formData.NoticeChange}
                        onDateChange={(selectedValue) =>
                          ddlChange('NoticeChange', selectedValue)
                        }
                      ></MonthYearPicker>
                    </Col>
                  </>
                )}
            </Row>
            <Row className='mt-2'>
              <Col md={5}>Are you currently on any medications?</Col>
              <Col md={2}>
                <SelectYesNo
                  defaultValue={formData.IsOnMedication}
                  onSelect={(selectedValue) =>
                    ddlChange('IsOnMedication', selectedValue)
                  }
                />
              </Col>
            </Row>
            {formData.IsOnMedication === 'Yes' && (
              <>
                <Row className='mt-1'>
                  <Col md={12}>
                    <TextareaComponent  IsChangeTracking={setIsDirty}
                      showError={MedicationDescriptionError}
                      controlid='MedicationDescription'
                      cols={40}
                      rows={2}
                      maxLength={5000}
                      defaultValue={formData.MedicationDescription}
                      onTextChange={(value) =>
                        handleTextValueChange('MedicationDescription', value)
                      }
                      placeholder={'Please describe...'}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col md={12}>Outline previous surgeries. (if any)</Col>
            </Row>

            <Row className='mt-1'>
              <Col md={12}>
                <TextareaComponent  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={2}
                  maxLength={5000}
                  defaultValue={formData.PreviousSurgeriesDescription}
                  onTextChange={(value) =>
                    handleTextValueChange('PreviousSurgeriesDescription', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                Is there anything else we should know about your general overall
                health?
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={12}>
                <TextareaComponent  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={2}
                  maxLength={5000}
                  defaultValue={formData.OverallHealthDescription}
                  onTextChange={(value) =>
                    handleTextValueChange('OverallHealthDescription', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>
            <Row>
              <Col className='mt-2' style={underlineStyle} md={12}>
                <b>Screening History</b>
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={6}>
                When was {userFirstName}'s last vision screening?
              </Col>
              <Col md={3}>
                <Datectrl
                  selectedDate={formData.LastVisionScreeningDate}
                  onDateChange={(value) =>
                    handleTextValueChange('LastVisionScreeningDate', value)
                  }
                />
              </Col>
              <Col md={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange('LastVisionScreeningResult', selectedValue)
                  }
                  defaultValue={formData.LastVisionScreeningResult}
                  ddType={3}
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={10}>Does {userFirstName} wear glasses/contacts?</Col>
              <Col md={2}>
                <SelectYesNo
                  defaultValue={formData.DoesWearGlasses}
                  onSelect={(selectedValue) =>
                    ddlChange('DoesWearGlasses', selectedValue)
                  }
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={6}>
                When was {userFirstName}'s last hearing screening?
              </Col>
              <Col md={3}>
                <Datectrl
                  selectedDate={formData.LastHearingScreeningDate}
                  onDateChange={(value) =>
                    handleTextValueChange('LastHearingScreeningDate', value)
                  }
                />
              </Col>
              <Col md={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange('LastHearingScreeningResult', selectedValue)
                  }
                  defaultValue={formData.LastHearingScreeningResult}
                  ddType={3}
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={10}>Does {userFirstName} wear hearing aids?</Col>
              <Col md={2}>
                <SelectYesNo
                  defaultValue={formData.DoesWearHearingAids}
                  onSelect={(selectedValue) =>
                    ddlChange('DoesWearHearingAids', selectedValue)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col style={underlineStyle} md={12}>
                <b>Clinical Diagnoses</b>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col sm={10}>
                When was {userFirstName} first diagnosed with primary diagnosis
                (
                <b>
                  {userDetails && userDetails.Diagnosisid1} -{' '}
                  {primaryDiagnosisDetail}
                </b>
                )?
              </Col>
              <Col sm={2}>
                <Datectrl
                  selectedDate={formData.Diagnosis1OnsetDate || ''}
                  onDateChange={(value) =>
                    handleTextBoxValueChange('Diagnosis1OnsetDate', value)
                  }
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col sm={6}>
                When was {userFirstName} secondary diagnosed{' '}
                {formData.Diagnosis2 && (
                  <>
                    (<b>{formData.Diagnosis2})</b>
                  </>
                )}
                ?
              </Col>
              <Col sm={4}>
                <AsyncAutoComplete
                  fetchedData={icdCode}
                  noOptionsMessage='No options found'
                  //handleButtonClick={handleButtonClick}
                  isRequiredMessageShown={isRequiredMessageShown}
                  setIsRequiredMessageShown={setIsRequiredMessageShown}
                  selectedValue={formData.Diagnosis2}
                  defaultValue={formData.Diagnosis2}
                  isMultiSelect={false}
                  setSelectedValue={(value) =>
                    handleAutoCompleteChange('Diagnosis2', value)
                  }
                  IsChangeTracking={setIsDirty}
                />
              </Col>
              <Col sm={2} style={{ textAlign: 'right' }}>
                <Datectrl
                  selectedDate={formData.Diagnosis2OnsetDate || ''}
                  onDateChange={(value) =>
                    handleTextBoxValueChange('Diagnosis2OnsetDate', value)
                  }
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col sm={6}>
                {' '}
                When was {userFirstName} third diagnosed{' '}
                {formData.Diagnosis3 && (
                  <>
                    (<b>{formData.Diagnosis3})</b>
                  </>
                )}
                ?
              </Col>
              <Col sm={4}>
                {' '}
                {/* {!formData.Diagnosis3 && (
                    <> */}
                <AsyncAutoComplete
                  fetchedData={icdCode}
                  noOptionsMessage='No options found'
                  //   handleButtonClick={handleButtonClick}
                  isRequiredMessageShown={isRequiredMessageShown}
                  setIsRequiredMessageShown={setIsRequiredMessageShown}
                  selectedValue={formData.Diagnosis3}
                  defaultValue={formData.Diagnosis3}
                  isMultiSelect={false}
                  setSelectedValue={(value) =>
                    handleAutoCompleteChange('Diagnosis3', value)
                  }
                  IsChangeTracking={setIsDirty}
                />
                {/* </>
                  )} */}
              </Col>
              <Col sm={2} style={{ textAlign: 'right' }}>
                <Datectrl
                  selectedDate={formData.Diagnosis3OnsetDate || ''}
                  onDateChange={(value) =>
                    handleTextBoxValueChange('Diagnosis3OnsetDate', value)
                  }
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col sm={6}>
                When was {userFirstName} fourth diagnosed
                {formData.Diagnosis4 && (
                  <>
                    (<b>{formData.Diagnosis4})</b>
                  </>
                )}{' '}
                ?
              </Col>
              <Col sm={4}>
                {' '}
                <AsyncAutoComplete
                  fetchedData={icdCode}
                  noOptionsMessage='No options found'
                  //   handleButtonClick={handleButtonClick}
                  isRequiredMessageShown={isRequiredMessageShown}
                  setIsRequiredMessageShown={setIsRequiredMessageShown}
                  selectedValue={formData.Diagnosis4}
                  defaultValue={formData.Diagnosis4}
                  isMultiSelect={false}
                  setSelectedValue={(value) =>
                    handleAutoCompleteChange('Diagnosis4', value)
                  }
                  IsChangeTracking={setIsDirty}
                />
              </Col>
              <Col sm={2} style={{ textAlign: 'right' }}>
                <Datectrl
                  selectedDate={formData.Diagnosis4OnsetDate || ''}
                  onDateChange={(value) =>
                    handleTextBoxValueChange('Diagnosis4OnsetDate', value)
                  }
                />
              </Col>
            </Row>
            <Row style={underlineStyle} md={12}>
              <b>Areas to Assess</b>
            </Row>
            <Row style={{ padding: 5 }}>
              <Col sm={12}>
                <CheckboxListInColumn
                  values={formData.FCM_Ids}
                  onChange={(selectedValues) =>
                    ddlChange('FCM_Ids', selectedValues)
                  }
                  typeOption={6}
                  columns={2}
                  rows={7}
                ></CheckboxListInColumn>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <AlertifyMessage /> */}
        <Row className='mt-3'>
          <Col md={12} className='text-right'>
            <button
              onClick={SaveButtonClick}
              disabled={IsSignLock || !isDirty || isSaving} // Disable the button if IsSignLock is true or isSaving is true
              style={{ width: '150px', marginRight: '20px' }}
              className='btn btn-primary rounded-pill'
            >
              {isSaving ? <b>Saving...</b> : <b>Save</b>}
            </button>
          </Col>
        </Row>
      </Container>
    )
  },
)
