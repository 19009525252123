// LanguageContext.js
import React, { createContext, useContext, useState } from 'react'

///This context provider is used for the
const LanguageContext = createContext()

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState('')

  const userLanguage = (newLanguage) => {
    setLanguage(newLanguage)
  }

  return (
    <LanguageContext.Provider value={{ language, userLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

export function useLanguage() {
  return useContext(LanguageContext)
}
