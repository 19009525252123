import axios from 'axios'
import { Variables } from '../../Variables'
import { AssessSumService } from '../Component/AssessSumService'
import { NomsService } from '../Component/NomsService'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
const nomsService = new NomsService()

export class LanguageExpressionService {
  constructor() {
    this.LanguageExpId = 0
    this.VisitId = null
    this.LanguageTested = 'English'
    this.LanguageOther = null
    this.NonVerbal = null
    this.AACUse = null
    this.WordUse = null
    this.UtteranceLength = null
    this.VocabularySize = null
    this.WordSentencePatterns = null
    this.NotedLanguage = null
    this.ComplexityLanguage = null
    this.ComplexityLanguageOther = 'complex'
    this.ComplexityLanguageDesc = null
    this.ComplexityOther = null
    this.Reading = null
    this.PragmaticLanguage = null
    this.StrengthsWeaknesses = null
    this.OverallRating = 'Within Normal Limits'
    this.IsSkilledTherapyIndicated = false
    this.Result = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []

      // Assuming serviceAssessment.saveData returns a Promise
      const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)

      // Assuming serviceAssessment.saveData returns a valid axios post configuration
      if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
        reject(new Error('Invalid assessment Promise'))
        return
      }

      // Push the assessment promise to the apiCalls array
      apiCalls.push(assessmentPromise)

      // Push the second axios.post call to the apiCalls array

      apiCalls.push(axios.post(apiUrl + 'SLE/InsertSLE', formData))

      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }

      Promise.all(apiCalls)
        .then((responses) => {
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  getGetAssSummary(VisitId) {
    return serviceAssessment
      .getGetAssSummary(VisitId, 'SLE')
      .then((data) => {
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  deleteAssSumData(summId) {
    return serviceAssessment
      .deleteAssSumData(summId)
      .then((data) => {
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getNomsData(VisitId) {
    return nomsService
      .getNomsData(VisitId, 'SLE')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getLanExpData(VisitId) {
    return axios
      .get(apiUrl + 'SLE/GetSLE/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data) {
          this.LanguageExpId = data.LanguageExpId
          this.VisitId = data.VisitId
          this.LanguageTested = data.LanguageTested
          this.LanguageOther = data.LanguageOther
          this.NonVerbal = data.NonVerbal
          this.AACUse = data.AACUse
          this.WordUse = data.WordUse
          this.AnswersQuestions = data.AnswersQuestions
          this.UtteranceLength = data.UtteranceLength
          this.VocabularySize = data.VocabularySize
          this.WordSentencePatterns = data.WordSentencePatterns
          this.NotedLanguage = data.NotedLanguage
          this.ComplexityLanguage = data.ComplexityLanguage
          this.ComplexityLanguageOther = data.ComplexityLanguageOther
          this.ComplexityLanguageDesc = data.ComplexityLanguageDesc
          this.ComplexityOther = data.ComplexityOther
          this.Reading = data.Reading
          this.PragmaticLanguage = data.PragmaticLanguage
          this.StrengthsWeaknesses = data.StrengthsWeaknesses
          this.OverallRating = data.OverallRating
          this.IsSkilledTherapyIndicated = data.IsSkilledTherapyIndicated
          this.Result = data.Result
          this.CreatedBy = data.CreatedBy
          this.UpdatedBy = data.UpdatedBy
          this.CreatedDate = data.CreatedDate

          return this
        }
      })
      .catch((error) => console.log('Error fetching data:', error))
  }
}
