import axios from 'axios'
import { Variables } from '../../Variables'

const CACHE_KEY = 'primaryDiagnosisCache'
const CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000

const CACHE_KEY_CPT = 'CPTCode'
const CACHE_EXPIRATION_CPT = 7 * 24 * 60 * 60 * 1000

export class PocService {
  constructor() {
    this.PocId = 0
    this.VisitId = 0
    this.PrimaryDiagnosis = null
    this.CPTCode = '92523'
    this.SecondryDiagnosis = ''
    this.AreaOfAsses = ''
    this.STIndicated = false
    this.Prognosis = 'Good'
    this.PrognosisDescription = ''
    this.ReqClientObervation = false
    this.Frequency = 1
    this.TimesPerWeek = 26
    this.SessionMinutes = 30
    this.SLPAOk = 'Yes'
    this.POCDiscuss = 'Client'
    this.SummaryRecommend = ''
    this.ReferTo = 'Caregiver Training/Home Exercise Program'
    this.ResonforReferal = null
    //'Caregiver training ongoing. Home program established for identified deficits.'
    this.CreatedDate = ''
    this.InternalService = null
    this.ExternalService = null // 'Caregiver Training/Home Exercise Program'
    this.CreateBy = null
    this.UpdateBy = null
    this.IsClientDischarge=false
    this.IsSTIndicated=false
  }

  getPocData(VisitId) {
    const apiUrl = Variables.API_URL
    return axios
      .get(`${apiUrl}POC/GetPOC/${VisitId}`)
      .then((response) => {
        const data = response.data.Result
        // if (data && data.CPTCode === null) {
        //   data.CPTCode = '10007'
        // }
         //alert(JSON.stringify(data))
        return data
      })
      .catch((error) => {
        console.error('Error fetching PocDTO data:', error)
        throw error
      })
  }

  getPrimaryDiagnosisCode() {
    let primaryDiagnosisCodeCache = null
    let cacheTimestamp = null

    const CACHE_EXPIRATION = 5 * 24 * 60 * 60 * 1000 // 5 days in milliseconds

    const currentTime = new Date().getTime()

    if (primaryDiagnosisCodeCache && cacheTimestamp) {
      if (currentTime - cacheTimestamp < CACHE_EXPIRATION) {
        return Promise.resolve(primaryDiagnosisCodeCache)
      }
    }
    //api/SLPBilling/GetPayorCPTCode/?PayorId={0}&treatmentTypeID={1}&VisitType={2}&IsSplintCodes={3}
    const apiUrl = Variables.USER_API_URL
    return axios
      .get(`${apiUrl}ListData/GetListData/?listType=${5}`)
      .then((response) => {
        const dataFromResponse = response.data.ListResult
        primaryDiagnosisCodeCache = dataFromResponse
        cacheTimestamp = currentTime
        return dataFromResponse
      })
      .catch((error) => {
        console.error('Error fetching PocDTO data:', error)
        throw error
      })
  }


  getCPTCodeCode() {
    const cachedData = localStorage.getItem(CACHE_KEY_CPT)
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_CPT}_timestamp`)
    const currentTime = new Date().getTime()

    if (cachedData && cachedTimestamp) {
      if (currentTime - Number(cachedTimestamp) < CACHE_EXPIRATION_CPT) {
        return Promise.resolve(JSON.parse(cachedData))
      }
    }

    const apiUrl = Variables.USER_API_URL
    return axios
      .get(`${apiUrl}ListData/GetListDataById/?listType=4&id=1`)
      .then((response) => {
        const dataFromResponse = response.data.ListResult
        localStorage.setItem(CACHE_KEY_CPT, JSON.stringify(dataFromResponse))
        localStorage.setItem(
          `${CACHE_EXPIRATION_CPT}_timestamp`,
          currentTime.toString(),
        )
        return dataFromResponse
      })
      .catch((error) => {
        console.error('Error fetching CPT code data:', error)
        throw error
      })
  }

  getAreaOfAss(VisitId) {
    const apiUrl = Variables.API_URL
    return axios
      .get(`${apiUrl}POC/GetAreaOfAss/${VisitId}`)
      .then((response) => response.data.Result)
      .catch((error) => {
        console.error('Error fetching PocDTO data:', error)
        throw error
      })
  }

 

  async checkGoalExist(VisitId) {
    const apiUrl = Variables.API_URL;
    try {
      // Make the GET request and wait for the response
      const response = await axios.get(`${apiUrl}POC/CheckGoalExist/${VisitId}`);
      
      // Return the response data's Result property
      return response.data.Result;
    } catch (error) {
      // Log the error and rethrow it for further handling
      console.error('Error fetching checkGoalExist data:', error);
      throw error;
    }
  }
  
  getAreaOfAssReport(VisitId) {
    const apiUrl = Variables.API_URL
    return axios
      .get(`${apiUrl}POC/GetAreaOfAssReport/${VisitId}`)
      .then((response) => response.data.Result)
      .catch((error) => {
        console.error('Error fetching POC/GetAreaOfAssReport data:', error)
        throw error
      })
  }

  insertPocData(formData) {
    const apiUrl = Variables.API_URL
    return axios
      .post(`${apiUrl}POC/InsertPOC`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting PocDTO data:', error)
        throw error
      })
  }

  GetPastVisits(clientId, visitDate, treatmentTypeId) {
    const apiUrl = Variables.USER_API_URL
    const url = `${apiUrl}Visit/GetPastVisits/?clientId=${clientId}&visitDate=${visitDate}&treatmentTypeId=${treatmentTypeId}`

    return axios
      .get(url)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error('Error fetching Visit/GetPastVisits data:', error.message)
        throw error
      })
  }

  GetUploadedFile(relatedRecordId, relatedModule) {
    const apiUrl = Variables.USER_API_URL
    const url = `${apiUrl}File/GetUploadedFile/?relatedrecordid=${relatedRecordId}&relatedmodule=${relatedModule}`
    //alert(url)
    return axios
      .get(url)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(
          'Error fetching File/GetUploadedFile data:',
          error.message,
        )
        throw error
      })
  }

  GetDownloadFile(fileName, filePath) {
    const apiUrl = Variables.API_URL // Ensure Variables.API_URL is correctly defined

    if (!apiUrl) {
      console.error('API URL is not defined.')
      return Promise.reject('API URL is not defined.')
    }

    const url = `${apiUrl}POC/DownloadPdf?fileUrl=${encodeURIComponent(
      filePath,
    )}&fileName=${encodeURIComponent(fileName)}`

    console.log('Request URL:', url)

    return axios
      .get(url, { responseType: 'blob' }) // Set responseType to 'blob' to handle binary data
      .then((response) => {
        if (response.status !== 200) {
          console.error(
            'Failed to fetch file:',
            response.status,
            response.statusText,
          )
          throw new Error(
            `Failed to fetch file: ${response.status} ${response.statusText}`,
          )
        }

        if (!response.data) {
          console.error('Received null response data')
          throw new Error('Received null response data')
        }

        return response // Return the blob data directly
      })
      .catch((error) => {
        console.error('Error fetching File/DownloadPdf data:', error.message)
        throw error // Re-throw the error to handle it elsewhere if needed
      })
  }
  
}
