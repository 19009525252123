import React, { useState, useEffect, useContext } from 'react'
import { Document, Page, PDFViewer, StyleSheet } from '@react-pdf/renderer'

import ReportHeader from '../ReportHeader'
import ReportFooter from '../ReportFooter'
import ProgressNTSection2 from './ProgressNTSection2'
import ProgressNTSection1 from './ProgressNTSection1'
import ContentSoapNote from './ContentSoapNote'
import DSContentSection1 from './DSContentSection1'
import DSContentSection2 from './DSContentSection2'
import { DischargeSummaryService } from '../../ProgressNote/DischargeSummary/DischargeSummaryService'
import { useSelector } from 'react-redux'
const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },
  image: {
    width: 400, // Adjust the width as needed
    height: 300, // Adjust the height as needed
    marginTop: 10,
  },
  body: {
    paddingTop: 1,
    paddingBottom: 40,
    // paddingHorizontal: 15,
  },
})

//This data which need the value of pn/sn
const PdfGenerateNotes = ({
  userDetails,
  FullName,
  FirstName,
  hisHer,
  HeOrShe,
  clientdata,
  pSummaryData = null,
  soapNoteData = null,
  disSummaryData = null,
  VisitId,
  licenceData=null
}) => {
  const [pdfFilename, setPdfFilename] = useState(`${VisitId}.pdf`)
  const [diagnosis2, setDiagnosis2] = useState(null)
  const [chartImageDataUrl, setChartImageDataUrl] = useState(null)

  const dischargeNoteService = new DischargeSummaryService()
  //const [dsData, setDSData] = useState('')



  const updateDiagnosis2 = (value) => {
    setDiagnosis2(value)
  }
  

  // useEffect(() => {
  //   const fetchData = () => {
  //     dischargeNoteService
  //       .getProgressNotes(VisitId, clientdata.Clientid)
  //       .then((dataFromResponse) => {
  //         // alert(JSON.stringify(dataFromResponse))
  //         if (dataFromResponse.VisitId > 0) {
  //           setDSData(dataFromResponse)
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching PocDTO data:', error)
  //       })
  //   }
  //   if (clientdata && VisitId) {
  //     alert("ds call")
  //     fetchData()
  //   }
  // }, [clientdata, VisitId])

  function capitalizeFirstLetter(string) {
  
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  return (
    <>
      {/* <PDFViewer style={{ width: '100%' }}> */}
      <Document title={pdfFilename}>
        {disSummaryData && (
          <>
            <Page size='A4' style={styles.body}>
              <ReportHeader
                isShowSubTitle={false}
                clientdata={clientdata}
                headerText={'Speech & Language Discharge Report'}
              ></ReportHeader>
              <DSContentSection1
                clientdata={clientdata}
                FirstName={capitalizeFirstLetter(FirstName)}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={VisitId}
                FullName={FullName}
                mainData={disSummaryData}
              ></DSContentSection1>
              <ReportFooter></ReportFooter>
            </Page>
            <Page size='A4' style={styles.body}>
              <ReportHeader
                isShowSubTitle={false}
                clientdata={clientdata}
                headerText={'Speech & Language Discharge Report'}
              ></ReportHeader>
              <DSContentSection2
                clientdata={clientdata}
                FirstName={capitalizeFirstLetter(FirstName)}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={VisitId}
                FullName={FullName}
                mainData={disSummaryData}
              ></DSContentSection2>
              <ReportFooter></ReportFooter>
            </Page>
          </>
        )}
        {pSummaryData && (
          <>
            <Page size='A4' style={styles.body}>
              <ReportHeader
                isShowSubTitle={false}
                clientdata={clientdata}
                headerText={'Speech & Language Progress Report'}
              ></ReportHeader>

              <ProgressNTSection1
                clientdata={clientdata}
                FirstName={capitalizeFirstLetter(FirstName)}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={VisitId}
                FullName={FullName}
                mainData={pSummaryData}
                chartImageUrl={chartImageDataUrl}
              ></ProgressNTSection1>

              <ReportFooter></ReportFooter>
            </Page>
            <Page size='A4' style={styles.body}>
              <ReportHeader
                isShowSubTitle={false}
                clientdata={clientdata}
                headerText={'Speech & Language Progress Report'}
              ></ReportHeader>
              <ProgressNTSection2
                clientdata={clientdata}
                FirstName={capitalizeFirstLetter(FirstName)}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={VisitId}
                FullName={FullName}
                mainData={pSummaryData}
                licenceData={licenceData}
              ></ProgressNTSection2>
              <ReportFooter></ReportFooter>
            </Page>{' '}
          </>
        )}
        {/* {soapNoteData && ( */}
        <Page size='A4' style={styles.body}>
          <ReportHeader
            isShowSubTitle={false}
            clientdata={userDetails}
            headerText={'Speech & Language Follow Up Report'}
          ></ReportHeader>
          <ContentSoapNote
            clientdata={userDetails}
            FirstName={capitalizeFirstLetter(FirstName)}
            HeOrShe={HeOrShe}
            hisHer={hisHer}
            userDetails={userDetails}
            VisitId={VisitId}
            FullName={FullName}
            soapData={soapNoteData}
            pnData={pSummaryData}
          ></ContentSoapNote>
          <ReportFooter></ReportFooter>
        </Page>
        {/* )} */}
      </Document>
      {/* </PDFViewer> */}
    </>
  )
}

export default PdfGenerateNotes
