import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ToastMessage = ({ type, message }) => {
  const showToast = () => {
    switch (type) {
      case 'success':
        toast.success(message)
        break
      case 'error':
        toast.error(message)
        break
      default:
        toast(message)
    }
  }

  return (
    <ToastContainer
      position='top-center'
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='colored'
      style={{
        width: '350px', // Adjust the width as needed
      }}
      bodyStyle={{
        whiteSpace: 'normal',
      }}
      toastStyle={{
        whiteSpace: 'normal',
      }}
    />
  )
}

const NotificationPanel = () => {
  // You can define state or use a state management library to handle notifications

  return (
    <>
      {/* Place success and error notifications anywhere in the app */}
      <ToastMessage type='success' message='Success notification!' />
      <ToastMessage type='error' message='Error notification!' />
    </>
  )
}

const WaitingComponent = () => {
  // You can define a loading state or use a state management library to handle loading

  return (
    <div className='loading-spinner'>
      {/* Your loading spinner or content */}
      <div className='spinner'></div>
      <span>Loading...</span>
    </div>
  )
}

const AlertifyMessage = () => {
  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        style={{
          width: '350px', // Adjust the width as needed
        }}
        bodyStyle={{
          whiteSpace: 'normal',
        }}
        toastStyle={{
          whiteSpace: 'normal',
        }}
      />
      <NotificationPanel />
      {/* You can use WaitingComponent anywhere in the app when needed 
      <WaitingComponent />*/}
    </>
  )
}

export default AlertifyMessage
