import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'

export function SelectYesNo({ onSelect, dropdownName, defaultValue }) {
  const [selectedOption, setSelectedOption] = useState(defaultValue || '')

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue)
    }
  }, [defaultValue])

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value
    setSelectedOption(selectedValue)
    onSelect(selectedValue, dropdownName)
  }

  return (
    <div>
      <select
        className='form-select form-select-sm'
        value={selectedOption}
        onChange={handleSelectChange}
      >
        <option value='No'>No</option>
        <option value='Yes'>Yes</option>
      </select>
    </div>
  )
}
