import React, { useState, useEffect, useContext } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { DDList } from '../../Controls/DDList'
import Container from 'react-bootstrap/Container'
import { CheckboxListInColumn, Checkbox } from '../../Controls/CheckBoxList'
import { TextareaComponent, TextBox } from '../../Controls/TextareaComponent '
import { SPSSumm } from '../../SpeechInt/SPSumm'
import { AssessIntService } from './AssessIntService'
import { VisitProvider, SignLockProvider } from '../../Provider/Visitprovider'
import PercentageSlider from '../../Controls/PercentageSlider'
import { SelectYesNo } from '../../Controls/DDLYesNo'
import { useUser } from '../../Provider/UserContext'
import { CreatedByProvider } from '../../Provider/Visitprovider'
import { toast } from 'react-toastify'
import Loading from '../../Controls/Loading'

export const AssessIntUI = React.forwardRef(
  ({ onDataFetched, triggerRefresh }, ref) => {
    const VisitId = useContext(VisitProvider)
    const { userDetails } = useUser()
    const service = new AssessIntService()
    const [formData, setFormData] = useState(new AssessIntService())
    const [consolidatedRows, setConsolidatedRows] = useState([])
    const IsSignLock = useContext(SignLockProvider)
    const CreatedBy = useContext(CreatedByProvider)
    const [showErrorOther, setshowErrorOther] = useState(false)
    const [isAssSumLoading, setIsAssSumLoading] = useState(false)
    const [AssessmentDescriptionError, setAssessmentDescriptionError] =
      useState(false)
    const [loading, setLoading] = useState(true)
    const [isDirty, setIsDirty] = useState(false)

    React.useImperativeHandle(ref, () => ({
      handleFormSubmit,
    }))

    useEffect(() => {
      if (userDetails) {
        formData.VisitId = VisitId
        formData.CreatedBy = CreatedBy
        formData.UpdatedBy = CreatedBy
        //alert(JSON.stringify(formData));
      }
    }, [formData, userDetails, VisitId, CreatedBy])

    const handleCheckboxListChange = (controlName, value) => {
      const valueControl = controlName
      const updatedFormData = {
        ...formData,
        [valueControl]: value,
      }
      setFormData(updatedFormData)
      setIsDirty(true)
    }
    const handleTextBoxValueChange = (controlName, value) => {
      const valueControl = controlName
      const updatedFormData = {
        ...formData,
        [valueControl]: value,
      }
      setFormData(updatedFormData)
      //setIsDirty(true)
    }
    const ddlChange = (selectedValue, dropdownName) => {
      const valueControl = dropdownName
      const updatedFormData = {
        ...formData,
        [valueControl]: selectedValue,
      }
      setFormData(updatedFormData)
      setIsDirty(true)
    }

    const handleCheckboxChange = (columnName, checked) => {
      const updatedFormData = { ...formData, [columnName]: checked }
      setFormData(updatedFormData)
      triggerRefresh(checked)
      setIsDirty(true)
    }
    const ValidationObject = () => {
      let isValid = true

      if (formData.LanguageTested === 'Other') {
        if (
          formData.LanguageOther === null ||
          formData.LanguageOther.trim() === ''
        ) {
          setshowErrorOther(true)
          isValid = false
        }
      }
      if (
        formData.AssessmentDescription === null ||
        formData.AssessmentDescription.trim() === ''
      ) {
        setAssessmentDescriptionError(true)
        isValid = false
      }

      return isValid
    }
    const handleFormSubmit = (showMsg = true) => {
      // alert(isDirty)
      if (IsSignLock || !isDirty) {
        //alert(isDirty)
        return
      }
      // const isValid = ValidationObject();
      // if (!isValid) {
      //   toast.error("Kindly fill the all required field(s).");
      //   return;
      // }
      //formData.VisitId = VisitId;
      const updateAssmentSummary = consolidatedRows.map((item) => {
        if (item.hasOwnProperty('VisitId')) {
          if (item.VisitId === 0) {
            item.VisitId = VisitId
          }
        }
        return item
      })
      //alert(JSON.stringify(updateAssmentSummary));
      //return;
      service
        .saveData(updateAssmentSummary, formData)
        .then((response) => {
          //console.log(JSON.stringify(response));
          if (showMsg) {
            toast.success('Record has been saved.')
          }
        })
        .catch((error) => {
          console.error('.saveData(updateAssmentSummary,formData)', error)
        })
        .catch((error) => {
          toast.error(
            '.saveData(updateAssmentSummary,formData) Error in API call!',
          )
        })
        .finally(() => {
          setIsDirty(false)
        })
    }

    useEffect(() => {
      //alert(JSON.stringify(formData));
      if (VisitId) {
        const fetchIntData = () => {
          service
            .getSpeechData(VisitId)
            .then((dataFromResponse) => {
              if (dataFromResponse !== undefined) {
                triggerRefresh(dataFromResponse.IsSkilledSpeechTherapy)
                setFormData(dataFromResponse)
              } else {
                // alert("ds");
                //setFormData(service);
              }
            })
            .catch((error) => {
              console.error('Error in CHA general:', error)
            })
            .finally(() => {
              setLoading(false)
              onDataFetched()
            })
        }
        const fetchAssSumdata = () => {
          service
            .getGetAssSummary(VisitId)
            .then((dataFromResponse) => {
              setConsolidatedRows(dataFromResponse)
            })
            .catch((error) => {
              console.error('Error in fetchAssSumdata:', error)
            })
            .finally(() => {
              setLoading(false)
              onDataFetched()
            })
        }

        fetchIntData()
        fetchAssSumdata()
      }
    }, [VisitId])

    const handleRowDeleted = (deletedRow) => {
      if (!deletedRow || typeof deletedRow.RowId === 'undefined') {
        console.error('Invalid deletedRow:', deletedRow)
        return
      }
      if (deletedRow.AssessmentSummaryId > 0) {
        service.deleteAssSumData(deletedRow.AssessmentSummaryId)
      }

      // alert(JSON.stringify(deletedRow));
      // return;
      // setConsolidatedRows((prevRows) =>
      //   prevRows.filter((row) => row.RowId !== deletedRow.RowId)
      // );
    }

    const handleUpdatedRows = (updatedRows) => {
      setConsolidatedRows(updatedRows)
    }

    useEffect(() => {
      //alert("form data");
      ValidationObject()
    }, [formData])

    if (loading) {
      return <Loading />
    }
    return (
      <Container fluid>
        <Row className='mt-2'>
          {' '}
          <Col xs={6}>
            <Row>
              <Col xs={6}>
                <b>Intelligibility Findings</b>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xs={3}>Language Tested</Col>
              <Col>
                <DDList
                  dropdownName='LanguageTested'
                  defaultValue={formData.LanguageTested}
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'LanguageTested')
                  }
                  ddType={18}
                />
              </Col>

              {formData.LanguageTested === 'Other' && (
                <Col md={3}>
                  {' '}
                  <TextBox
                    showError={showErrorOther}
                    maxLength={20}
                    controlid='LanguageOther'
                    defaultValue={formData.LanguageOther}
                    onTextChange={(value) =>
                      handleTextBoxValueChange('LanguageOther', value)
                    }
                    placeholder={'other...'}
                  />{' '}
                </Col>
              )}
            </Row>
            <Row className='mt-2'>
              <Col xs={3}>Highest Level</Col>
              <Col xs={3}>
                <DDList
                  dropdownName='HighestLevel'
                  defaultValue={formData.HighestLevel}
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'HighestLevel')
                  }
                  ddType={30}
                />
              </Col>
              <Col xs={2}>Percentage</Col>
              <Col xs={4}>
                <PercentageSlider
                  value={formData.Percentage}
                  min={0}
                  max={100}
                  step={5}
                  onChange={(selectedValue) =>
                    ddlChange(selectedValue, 'Percentage')
                  }
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <b>Oral Motor Findings</b>
            </Row>
            <Row className='mt-1'>
              <Col xs={6}>
                <Row className='mt-1'>
                  <Col xs={2}>Lingual</Col>
                  <Col xs={10}>
                    <DDList
                      onSelect={(selectedValue) =>
                        ddlChange(selectedValue, 'OralLingual')
                      }
                      defaultValue={formData.OralLingual}
                      ddType={11}
                    />
                  </Col>
                </Row>
                <Row className='mt-1'>
                  <Col xs={2}>Labial</Col>
                  <Col xs={10}>
                    <DDList
                      onSelect={(selectedValue) =>
                        ddlChange(selectedValue, 'OralLabial')
                      }
                      defaultValue={formData.OralLabial}
                      ddType={11}
                    />
                  </Col>
                </Row>
                <Row className='mt-1'>
                  <Col xs={2}>Buccal</Col>
                  <Col xs={10}>
                    <DDList
                      onSelect={(selectedValue) =>
                        ddlChange(selectedValue, 'OralBuccal')
                      }
                      defaultValue={formData.OralBuccal}
                      ddType={11}
                    />
                  </Col>
                </Row>
                <Row className='mt-1'>
                  <Col xs={2}>Facial</Col>
                  <Col xs={10}>
                    <DDList
                      onSelect={(selectedValue) =>
                        ddlChange(selectedValue, 'OralFacial')
                      }
                      defaultValue={formData.OralFacial}
                      ddType={11}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={6} className='mt-1'>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={6}
                  maxLength={500}
                  controlid='OralDescription'
                  defaultValue={formData.OralDescription}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('OralDescription', value)
                  }
                  placeholder={
                    'Describe the strength, tone, movement, and range of motion...'
                  }
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col xs={3}>Diadochokinetic Rate</Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'DiadochokineticRate')
                  }
                  defaultValue={formData.DiadochokineticRate}
                  ddType={11}
                />
              </Col>
              <Col xs={3}>Fluency of Speech</Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'FluencySpeech')
                  }
                  defaultValue={formData.FluencySpeech}
                  ddType={11}
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col xs={3}>Aware of Deficit</Col>
              <Col xs={3}>
                <SelectYesNo
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'AwareDeficit')
                  }
                  defaultValue={formData.AwareDeficit}
                  ddType={11}
                />
              </Col>
              <Col xs={3}>Prosody</Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'Prosody')
                  }
                  defaultValue={formData.Prosody}
                  ddType={11}
                />
              </Col>
            </Row>
            <Row>
              <b>Impact on Daily Life</b>{' '}
            </Row>
            <Row>
              <Col xs={12}>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={2}
                  maxLength={500}
                  controlid='ImpactOnDailyLife'
                  defaultValue={formData.ImpactOnDailyLife}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('ImpactOnDailyLife', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={6}>
                <b>Sound Production Patterns</b>
              </Col>
              <Col xs={6}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'SPP_Level')
                  }
                  defaultValue={formData.SPP_Level}
                  ddType={11}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <CheckboxListInColumn
                  values={formData.SPP_Type}
                  onChange={(selectedValues) =>
                    handleCheckboxListChange('SPP_Type', selectedValues)
                  }
                  typeOption={2}
                  columns={1}
                  rows={3}
                ></CheckboxListInColumn>
              </Col>
              <Col xs={8} style={{ marginTop: '5px' }}>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={3}
                  maxLength={500}
                  controlid='SPP_Description'
                  defaultValue={formData.SPP_Description}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('SPP_Description', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>Stimulability</Col>
              <Col xs={2}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'Stimulability_Rating')
                  }
                  defaultValue={formData.Stimulability_Rating}
                  ddType={12}
                />
              </Col>
            </Row>
            <Row>
              <b>Assessment Summary</b>
            </Row>
            <Row className='mt-1 mb-1'>
              <Col xs={2}>
                <b>Overall Rating</b>
              </Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'OverallIntRating')
                  }
                  defaultValue={formData.OverallIntRating}
                  ddType={11}
                />
              </Col>
              <Col xs={7}>
                <Checkbox
                  label='Skilled Therapy NOT Indicated for INT'
                  checked={formData.IsSkilledSpeechTherapy}
                  onChange={handleCheckboxChange}
                  columnName='IsSkilledSpeechTherapy'
                />
              </Col>
            </Row>
            <Row>
              <SPSSumm
                apiData={consolidatedRows}
                onUpdatedRows={handleUpdatedRows}
                onRowAdded={handleUpdatedRows}
                onRowDeleted={handleRowDeleted}
                FCMType={'INT'}
                isChangeTracked={setIsDirty}
              />
            </Row>
            <Row>
              <CheckboxListInColumn
                values={formData.AssessmentType}
                onChange={(selectedValues) =>
                  handleCheckboxListChange('AssessmentType', selectedValues)
                }
                typeOption={3}
                columns={3}
                rows={1}
              ></CheckboxListInColumn>
            </Row>
            {formData.AssessmentType &&
              formData.AssessmentType.includes('Dysarthria') && (
                <>
                  <Row>
                    <b>Dysarthria Type </b>
                  </Row>
                  <Row className='mt-1'>
                    <CheckboxListInColumn
                      values={formData.DysarthriaType}
                      onChange={(selectedValues) =>
                        handleCheckboxListChange(
                          'DysarthriaType',
                          selectedValues,
                        )
                      }
                      typeOption={9}
                      columns={6}
                      rows={1}
                    ></CheckboxListInColumn>
                  </Row>
                </>
              )}{' '}
            <Row>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                showError={AssessmentDescriptionError}
                cols={40}
                rows={2}
                maxLength={500}
                controlid='AssessmentDescription'
                defaultValue={formData.AssessmentDescription}
                onTextChange={(value) =>
                  handleTextBoxValueChange('AssessmentDescription', value)
                }
                placeholder={'Please describe the result...'}
              />
            </Row>
            {/* <Row>
              <Col xs={6}>
                <b>Overall Intelligibility Rating</b>
              </Col>
              <Col xs={6}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'OverallIntRating')
                  }
                  defaultValue={formData.OverallIntRating}
                  ddType={11}
                />
              </Col>
            </Row> */}
          </Col>
        </Row>

        <Row style={{ textAlign: 'right' }}>
          <Col xs={12}>
            {/* <Button type="submit" className="btn btn-primary rounded-pill" >Preview</Button> */}
            <Button
              disabled={IsSignLock || !isDirty}
              type='submit'
              onClick={handleFormSubmit}
              style={{
                width: '150px',
                marginTop: '10px',
                marginLeft: '10px',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
              className='btn btn-primary rounded-pill'
            >
              <b>Save</b>
            </Button>
            {/* <Button type="submit" style={{ marginLeft: '10px' }} className="btn btn-primary rounded-pill">Sign/Lock</Button>
             */}{' '}
          </Col>
        </Row>
      </Container>
    )
  },
)
