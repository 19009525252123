//Local Env API
// export const Variables = {
// //   API_URL:process.env.REACT_APP_API_URL,
// //    USER_API_URL: process.env.REACT_APP_USER_API_URL,
// //   REACT_APP_API_URL=https://sit-appapi.slptele.com/api/
//   API_URL:'http://localhost:65270/api/',
//   USER_API_URL: 'http://localhost:50930/api/',
// }
//Test Env API'http://localhost:65270/api/',
export const Variables = {
  API_URL: 'https://teststnotesapi.slptele.com/api/',
  USER_API_URL: 'https://testapi.slptele.com/api/',
}

//Test Env API NEW Test server
// export const Variables = {
//   API_URL: 'https://uat-stnotes.api.slptele.com/api/',
//   USER_API_URL: 'https://uat-app.api.slptele.com/api/',
// }
// stage Env API
// export const Variables = {
//   API_URL: 'https://stage-stnotesapi.slptele.com/api/',
//   USER_API_URL: 'https://stage-appapi.slptele.com/api/',
// }

// Production Env API
// export const Variables = {
//   API_URL: 'https://stnotesapi.slptele.com/api/',
//   USER_API_URL: 'https://appapi.slptele.com/api/',
// }
