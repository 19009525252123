import React, { useState, useEffect } from 'react'

export const RadioButtonlist = ({
  typeOption,
  columns,
  selectedValue,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(selectedValue)

  const options = [
    { id: '1', label: 'Add Progress Note' },
    { id: '2', label: 'Discharge Client' },
  ]
  useEffect(() => {
    // alert(selectedValue);
    if (selectedValue && selectedValue.toString() !== '0') {
      setSelectedOption(selectedValue.toString())
    }
  }, [selectedValue])

  const handleOptionChange = (optionId) => {
    if (selectedOption === optionId) {
      // If the same option is clicked, deselect it
      setSelectedOption(0)
      if (typeof onChange === 'function') {
        onChange(0)
      }
    } else {
      // Otherwise, select the clicked option
      setSelectedOption(optionId)
      if (typeof onChange === 'function') {
        onChange(optionId)
      }
    }
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {options.map((option) => (
        <label
          key={option.id}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
        >
          <input
            className='form-check-input'
            type='checkbox' // Use checkboxes
            style={{
              border: '2px solid #007bff',
              borderRadius: '50%',
              verticalAlign: 'middle',
              margin: '0 5px 0 15px',
            }}
            value={option.id}
            checked={selectedOption === option.id}
            onChange={() => handleOptionChange(option.id)}
          />
          <span className='form-check-label' style={{ marginLeft: '35px' }}>
            {option.label}
          </span>
        </label>
      ))}
    </div>
  )
}
