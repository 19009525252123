import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { SignLockProvider } from '../../Provider/Visitprovider'
import { LanguageExpressionUI } from './LanguageExpressionUI'
import { NomsUI } from './NomsUI'
import { useUser } from '../../Provider/UserContext'
import { GoalsUI } from './GoalsUI'

export const LanguageExpression = forwardRef(
  ({ isAdult, onTabChange }, ref) => {
    const { userDetails } = useUser()
    const [refreshData, setRefreshData] = useState(false)
    const [activeGoalTab, setActiveGoalTab] = useState(false)
    const [activeTab, setActiveTab] = useState('AS')
    const [isAdultCHA, setIsAdultCHA] = useState(false)
    const IsSignLock = useContext(SignLockProvider)
    const [refreshGoalTab, setRefreshGoalTab] = useState(false)
    const AssessmentRef = useRef()
    const NOMSRef = useRef()
    const GoalsRef = useRef()
    const [age, setAge] = useState(0)

    const triggerRefreshGoalTab = () => {
      //alert("Data saved in noms");
      setRefreshGoalTab(true)
    }

    useEffect(() => {
      if (userDetails) {
        setAge(userDetails.Age)
      }
    }, [userDetails])

    useEffect(() => {
      setActiveTab('AS')
      onTabChange('AS')
      //alert(JSON.stringify(isAdult));
      setIsAdultCHA(isAdult)
    }, [isAdult, refreshData])

    const triggerRefresh = (refresh) => {
      //alert(refresh);
      setRefreshData(refresh)
    }

    const handleTabChange = (eventKey) => {
      //alert("Active Key: "+ eventKey);
      if (IsSignLock === false) {
        // // alert("Event: "+ eventKey);
        // // alert("Active Key: "+ activeTab);
        if (eventKey === 'AS' && activeTab === 'NM') {
          NOMSRef.current.handleButtonClick(false)
          triggerRefreshGoalTab()
        } else if (eventKey === 'NM' && activeTab === 'AS') {
          AssessmentRef.current.handleFormSubmit(false)
        } else if (eventKey === 'GL' && activeTab === 'NM') {
          NOMSRef.current.handleButtonClick(false)
          //triggerRefreshGoalTab();
        } else if (eventKey === 'NM' && activeTab === 'GL') {
          GoalsRef.current.handleButtonClick(false)
        } else if (eventKey === 'GL' && activeTab === 'AS') {
          AssessmentRef.current.handleFormSubmit(false)
        } else if (eventKey === 'AS' && activeTab === 'GL') {
          GoalsRef.current.handleButtonClick(false)
        }
      }
      // if (eventKey === "GL") {
      //   triggerRefreshGoalTab();
      // }
      setActiveTab(eventKey)
      onTabChange(eventKey)
    }

    const SaveAss = () => {
      if (IsSignLock === false) {
        AssessmentRef.current.handleFormSubmit(false)
      }
    }
    const SaveNOMS = () => {
      if (IsSignLock === false) {
        NOMSRef.current.handleButtonClick(false)
      }
    }
    const SaveGOAL = () => {
      if (IsSignLock === false) {
        GoalsRef.current.handleButtonClick(false)
      }
    }
    useImperativeHandle(ref, () => ({
      SaveAss,
      SaveNOMS,
      SaveGOAL,
    }))

    return (
      <div style={{ display: 'block', width: 'max', padding: '10px' }}>
        <Tabs defaultActiveKey={activeTab} onSelect={handleTabChange}>
          <Tab eventKey='AS' title='Assessment'>
            {activeTab === 'AS' && (
              <LanguageExpressionUI
                ref={AssessmentRef}
                triggerRefresh={triggerRefresh}
                onDataFetched={() => setActiveTab('AS')}
              />
            )}
          </Tab>
          {refreshData === false && (
            <Tab eventKey='NM' title='NOMS'>
              {activeTab === 'NM' && (
                <NomsUI
                  age={age}
                  triggerGoalRefresh={triggerRefreshGoalTab}
                  ref={NOMSRef}
                  onDataFetched={() => setActiveTab('NM')}
                  isActiveGoal={setActiveGoalTab}
                />
              )}
            </Tab>
          )}

          {refreshData === false && (
            <Tab eventKey='GL' title={isAdult ? 'Goal' : 'Goal'} disabled={!activeGoalTab}>
              {activeTab === 'GL' && (
                <GoalsUI
                  IsAdult={isAdultCHA}
                  refreshGoal={refreshGoalTab}
                  ref={GoalsRef}
                  onDataFetched={() => setActiveTab('GL')}
                />
              )}
            </Tab>
          )}
        </Tabs>
      </div>
    )
  },
)
