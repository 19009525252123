import React, { useEffect, useState } from 'react'
import { View, Text } from '@react-pdf/renderer'
import { SoapNoteStyle } from '../../CSS/reportStyle'
import formatDate from '../../Utility/Utility'
import { GoalService } from '../../GoalComponenet/GoalService'

const HeaderText = ({ FullName, clientdata, PocData, Diagnosis2, VisitId }) => {
  const goalService = new GoalService()
  const [headerData, setHeaderData] = useState(null)

  useEffect(() => {
    const fetchHeaderData = () => {
      if(!clientdata)
        return;
      const visitdate = formatDate(
        clientdata.Scheduleddateto,
        false,
        false,
      ).toString()
      goalService
        .getHeaderData(VisitId, clientdata.Clientid, visitdate)
        .then((dataFromResponse) => {
          //alert(JSON.stringify(dataFromResponse))
          setHeaderData(dataFromResponse)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    }
    //alert(JSON.stringify(PocData))
    fetchHeaderData()
  }, [clientdata, VisitId])

  return (
    <View style={SoapNoteStyle.content}>
      <View style={SoapNoteStyle.gridContainer}>
        <View style={SoapNoteStyle.gridRow}>
          <View style={SoapNoteStyle.gridCell}>
            <Text>{FullName?.toUpperCase()}</Text>
          </View>
          <View style={SoapNoteStyle.gridCell}>
            <Text>
              Referred by:{' '}
              {clientdata && clientdata.ReferringPhysician?.toUpperCase()}
            </Text>
          </View>
          <View style={SoapNoteStyle.gridCell}>
            <Text>
              Therapist: {clientdata && clientdata.TherapistName?.toUpperCase()}
            </Text>
          </View>
        </View>
        <View style={SoapNoteStyle.gridRow}>
          <View style={SoapNoteStyle.gridCell}>
            <Text>
              DOB: {clientdata && formatDate(clientdata.DOB, false, true)}{' '}
              {'   '} Age: {clientdata && clientdata.Age}
            </Text>
          </View>
          <View style={SoapNoteStyle.gridCell}>
            <Text>Primary: {clientdata && clientdata.Diagnosisid1}</Text>
          </View>
          <View style={SoapNoteStyle.gridCell}>
            <Text>Treatment: {headerData && headerData.Treatment}</Text>
          </View>
        </View>
        <View style={SoapNoteStyle.gridRow}>
          <View style={SoapNoteStyle.gridCell}>
            <Text>Gender: {clientdata && clientdata.Gender}</Text>
          </View>
          <View style={SoapNoteStyle.gridCell}>
            <Text>
              Secondary: {headerData && headerData.SecondaryDiagnosis}
            </Text>
          </View>
          <View style={SoapNoteStyle.gridCell}>
            <Text>Billing: {headerData && headerData.Billing}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default HeaderText
