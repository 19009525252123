export class CHCGeneralDTO {
  constructor() {
    this.CHC_GeneralId = 0
    this.VisitId = 0
    this.DominantLanguage = 'English'
    this.DominantLanguageOther = ''
    this.AdditionalLanguages = ['English']
    this.CommunicatesThrough = 'Single words'
    this.UnderstandingLevel = '0-25%'
    this.HasFamilyDefict = 'No'
    this.FamilyDefictDescription = ''
    this.DeficitEffect = ''
    this.LivingSiblingDescription = ''
    this.AttendSchool = 'No'
    this.ClassGrade = 'Prek'
    this.CurrentPlan = 'IEP'
    this.HasSocialSkillConcern = 'No'
    this.SocialSkillConcernDescription = ''
    this.SpeechTherapyReason = ''
    this.ExpectedOutcome = ''
    this.HasSpeechTherapyInPast = 'No'
    this.PastSpeechTherapyDescription = ''
    this.ReceivedPastSpeechTherapyReport = 'No'
    this.ReceivedOtherSpecialistsReport = ''
    this.IsReceivingOutsideAgencyService = 'No'
    this.ReceivingOutsideAgencyWhere = ''
    this.ReceivingOutsideAgencyServicedescription = ''
    this.OtherSpecialistsWorkedWith = ['Early Interventionist']
    this.HaveYouReceiveReport = 'No'
    this.SitupAge = null
    this.StandAge = null
    this.SpeakWordAge = null
    this.CrawlAge = null
    this.WalkAge = null
    this.ComWordsAge = null
    this.WhereName = null
    this.CreatedBy = null
    this.UpdatedBy = null
  }
}

export class CHCMedicalDTO {
  constructor() {
    this.CHC_MedicalId = 0
    this.FCM_Ids = ''
    this.BirthHistoryType = 'Unremarkable'
    this.BirthHistoryDescription = null
    this.HadEarInfection = 'No'
    this.EarInfectionFrequency = ''
    this.HasUsedPETubes = ''
    this.IsUsingPacifier = 'No'
    this.PacifierUseFrequency = ''
    this.HadHeadInjury = 'No'
    this.HeadInjuryDescription = ''
    this.HasAllergy = 'No'
    this.AllergyDescription = ''
    this.DevelopmentalDiagnoses = ''
    this.BehavioralDiagnoses = ''
    this.PreviousSurgeriesDescription = ''
    this.OverallHealthDescription = ''
    this.LastVistionScreeningDate = ''
    this.LastVistionScreeningResult = 'Passed'
    this.DoesWearGlasses = 'No'
    this.LastHearingScreeningDate = ''
    this.PrimaryDiagnosisDate = ''
    this.LastHearingScreeningResult = 'Passed'
    this.DoesWearHearingAids = 'No'
    this.IsOnMedication = 'No'
    this.MedicationDescription = ''
    this.EpisodeId = 0
    this.Diagnosis1 = null
    this.onsetdate = null
    this.Diagnosis2 = null
    this.Diagnosis2OnsetDate = null
    this.Diagnosis3 = null
    this.Diagnosis3OnsetDate = null
    this.Diagnosis4 = null
    this.Diagnosis4OnsetDate = null
    this.CreatedBy = null
    this.UpdatedBy = null
  }
}

//   handleChange = (event) => {
//     const { name, value } = event.target;
//     this.setState((prevState) => ({
//       formModel: {
//         ...prevState.formModel,
//         [name]: value,
//       },
//     }));
//   };

//   handleSubmit = (event) => {
//     event.preventDefault();
//     // Access the form model from the state
//     const { formModel } = this.state;

//     // Construct the payload using the form model
//     const payload = {
//       communicates: formModel.communicates,
//       // other form model properties
//     };

//     // Make the API request using the payload
//     // Example:
//     // api.post('/endpoint', payload)
//     //   .then(response => {
//     //     // Handle the response
//     //   })
//     //   .catch(error => {
//     //     // Handle the error
//     //   });
//   };

//   render() {
//     const { formModel } = this.state;
