import axios from 'axios'
import { Variables } from '../../Variables'

const apiUrl = Variables.API_URL
export class CHAGeneralService {
  constructor() {
    this.CHA_GeneralId = 0
    this.VisitId = 0
    this.DominantLanguage = 'English'
    this.DominantLanguageOther = null
    this.AdditionalLanguages = null
    this.DeficitEffect = null
    this.CurrentLivingDescription = null
    this.HasFamilyDefict = 'No'
    this.FamilyDefictDescription = null
    this.HasDifficultExpression = 'No'
    this.DifficultExpressionDescription = null
    this.HasDifficultFinding = 'No'
    this.DifficultFindingDescription = null
    this.HasDifficultUnderstand = 'No'
    this.DifficultUnderstandDescription = null
    this.DifficultArea = null
    this.DifficultAreaDescription = null
    this.DifficultDailyActivity = null
    this.DifficultDailyActivityDescription = null
    this.SeekingSpeachTherapy = null
    this.SeekingSpeachTherapyOutcome = null
    this.HasSpeechTherapyPast = 'No'
    this.HasSpeechTherapyPastDescription = null
    this.HasReceivedReport = 'Yes'
    this.IsReceivingOutsideAgencyService = 'No'
    this.ReceivingOutsideAgencyWhere = null
    this.OtherSpecialistsWorkedWith = null
    this.IsReceivedReportOther = 'Yes'
    this.IsEntReport = 'No'
    this.HighestEducation = 'High School'
    this.HighestEducationOther = null
    this.IsCurrentlyWorking = 'No'
    this.Profession = null
    this.Hobbies = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
  }

  getCHAData(VisitId) {
    return axios
      .get(`${apiUrl}CHA/General/${VisitId}`)
      .then((response) => {
        const dataFromResponse = response.data.Result

        if (dataFromResponse) {
          this.CHA_GeneralId = dataFromResponse.CHA_GeneralId
          this.VisitId = dataFromResponse.VisitId
          this.DominantLanguage = dataFromResponse.DominantLanguage
          this.DominantLanguageOther = dataFromResponse.DominantLanguageOther
          this.AdditionalLanguages = dataFromResponse.AdditionalLanguages
            ? dataFromResponse.AdditionalLanguages.split(',')
            : null
          this.DeficitEffect = dataFromResponse.DeficitEffect
          this.CurrentLivingDescription =
            dataFromResponse.CurrentLivingDescription
          this.HasFamilyDefict = dataFromResponse.HasFamilyDefict
          this.FamilyDefictDescription =
            dataFromResponse.FamilyDefictDescription
          this.HasDifficultExpression = dataFromResponse.HasDifficultExpression
          this.DifficultExpressionDescription =
            dataFromResponse.DifficultExpressionDescription
          this.HasDifficultFinding = dataFromResponse.HasDifficultFinding
          this.DifficultFindingDescription =
            dataFromResponse.DifficultFindingDescription
          this.HasDifficultUnderstand = dataFromResponse.HasDifficultUnderstand
          this.DifficultUnderstandDescription =
            dataFromResponse.DifficultUnderstandDescription
          this.DifficultArea = dataFromResponse.DifficultArea
            ? dataFromResponse.DifficultArea.split(',')
            : null
          this.DifficultAreaDescription =
            dataFromResponse.DifficultAreaDescription
          this.DifficultDailyActivity = dataFromResponse.DifficultDailyActivity
            ? dataFromResponse.DifficultDailyActivity.split(',')
            : null
          this.DifficultDailyActivityDescription =
            dataFromResponse.DifficultDailyActivityDescription
          this.SeekingSpeachTherapy = dataFromResponse.SeekingSpeachTherapy
          this.SeekingSpeachTherapyOutcome =
            dataFromResponse.SeekingSpeachTherapyOutcome
          this.HasSpeechTherapyPast = dataFromResponse.HasSpeechTherapyPast
          this.HasSpeechTherapyPastDescription =
            dataFromResponse.HasSpeechTherapyPastDescription
          this.HasReceivedReport = dataFromResponse.HasReceivedReport
          this.IsReceivingOutsideAgencyService =
            dataFromResponse.IsReceivingOutsideAgencyService
          this.ReceivingOutsideAgencyWhere =
            dataFromResponse.ReceivingOutsideAgencyWhere
          this.OtherSpecialistsWorkedWith =
            dataFromResponse.OtherSpecialistsWorkedWith
              ? dataFromResponse.OtherSpecialistsWorkedWith.split(',')
              : null
          this.IsReceivedReportOther = dataFromResponse.IsReceivedReportOther
          this.IsEntReport = dataFromResponse.IsEntReport
          this.HighestEducation = dataFromResponse.HighestEducation
          // this.HighestEducation = dataFromResponse.HighestEducation;
          this.HighestEducationOther = dataFromResponse.HighestEducationOther
          this.IsCurrentlyWorking = dataFromResponse.IsCurrentlyWorking
          this.Profession = dataFromResponse.Profession
          this.Hobbies = dataFromResponse.Hobbies
          ///alert(JSON.stringify(this));
          return this
        } else {
          return new CHAGeneralService()
        }
      })
      .catch((error) => {
        console.error('Error fetching SoapNote data:', error)
        throw error
      })
  }

  insertCHAData(formData) {
    return axios
      .post(`${apiUrl}CHA/CreateGeneral`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting CHA/CreateGeneral data:', error)
        throw error
      })
  }
}
