import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { SpeechIntNoms } from './SpeechIntNoms'
import { SpeechIntGoal } from './SpeechIntGoal'
import { SpeechIntAss } from './SpeechIntAss'
import { AssessIntUI } from '../CasehistoryAdult/SpeechInt/AssessIntUI'
import { SignLockProvider } from '../Provider/Visitprovider'

export const SpeechIntSubTab = forwardRef(({ isAdult, onTabChange }, ref) => {
  const [refreshData, setRefreshData] = useState(false)
  const [activeGoalTab, setActiveGoalTab] = useState(false)
  const [activeTab, setActiveTab] = useState('AS')
  const [isAdultCHA, setIsAdultCHA] = useState(false)
  const IsSignLock = useContext(SignLockProvider)
  const [refreshGoalTab, setRefreshGoalTab] = useState(false)
  const AssessmentCHARef = useRef()
  const AssessmentCHCRef = useRef()
  const NOMSRef = useRef()
  const GoalsRef = useRef()

  const triggerRefreshGoalTab = () => {
    //alert("Data saved in noms");
    setRefreshGoalTab(true)
  }
  // useEffect(() => {
  //   setRefreshGoalTab(true);
  // }, [refreshGoalTab]);

  useEffect(() => {
    setActiveTab('AS')
    onTabChange('AS')
    //alert(JSON.stringify(isAdult));
    setIsAdultCHA(isAdult)
  }, [isAdult, refreshData])

  const triggerRefresh = (refresh) => {
    //alert(refresh);
    setRefreshData(refresh)
  }

  const handleTabChange = (eventKey) => {
    //alert("Active Key: "+ eventKey);
    if (IsSignLock === false) {
      // alert("Event: "+ eventKey);
      // alert("Active Key: "+ activeTab);
      if (eventKey === 'AS' && activeTab === 'NM') {
        NOMSRef.current.handleButtonClick(false)
        //triggerRefreshGoalTab();
      } else if (eventKey === 'NM' && activeTab === 'AS') {
        if (isAdultCHA === true) {
          AssessmentCHARef.current.handleFormSubmit(false)
        } else {
          //alert("Active Key: "+ isAdult);
          AssessmentCHCRef.current.handleFormSubmit(false)
        }
      } else if (eventKey === 'GL' && activeTab === 'NM') {
        NOMSRef.current.handleButtonClick(false)
        //triggerRefreshGoalTab();
      } else if (eventKey === 'NM' && activeTab === 'GL') {
        GoalsRef.current.handleButtonClick(false)
      } else if (eventKey === 'GL' && activeTab === 'AS') {
        if (isAdultCHA === true) {
          // alert("as");
          AssessmentCHARef.current.handleFormSubmit(false)
        } else {
          AssessmentCHCRef.current.handleFormSubmit(false)
        }
      } else if (eventKey === 'AS' && activeTab === 'GL') {
        GoalsRef.current.handleButtonClick(false)
      }
    }
    // if (eventKey === "GL") {
    //   triggerRefreshGoalTab();
    // }
    setActiveTab(eventKey)
    onTabChange(eventKey)
  }

  const SaveAss = () => {
    if (IsSignLock === false) {
      if (isAdultCHA === true) {
        AssessmentCHARef.current.handleFormSubmit(false)
      } else {
        AssessmentCHCRef.current.handleFormSubmit(false)
      }
    }
  }
  const SaveNOMS = () => {
    if (IsSignLock === false) {
      NOMSRef.current.handleButtonClick(false)
    }
  }
  const SaveGOAL = () => {
    if (IsSignLock === false) {
      GoalsRef.current.handleButtonClick(false)
    }
  }
  useImperativeHandle(ref, () => ({
    SaveAss,
    SaveNOMS,
    SaveGOAL,
  }))
  return (
    <div style={{ display: 'block', width: 'max', padding: '10px' }}>
      <Tabs defaultActiveKey={activeTab} onSelect={handleTabChange}>
        <Tab eventKey='AS' title='Assessment'>
          {activeTab === 'AS' &&
            (isAdultCHA === true ? (
              <AssessIntUI
                onDataFetched={() => setActiveTab('AS')}
                ref={AssessmentCHARef}
                triggerRefresh={triggerRefresh}
              />
            ) : (
              <SpeechIntAss
                onDataFetched={() => setActiveTab('AS')}
                ref={AssessmentCHCRef}
                triggerRefresh={triggerRefresh}
              />
            ))}
        </Tab>
        {refreshData === false && (
          <Tab eventKey='NM' title='NOMS'>
            {activeTab === 'NM' && (
              <SpeechIntNoms
                onDataFetched={() => setActiveTab('NM')}
                triggerGoalRefresh={triggerRefreshGoalTab}
                isActiveGoal={setActiveGoalTab}
                ref={NOMSRef}
              />
            )}
          </Tab>
        )}

        {refreshData === false && (
          <Tab eventKey='GL' title={isAdult ? 'Goal - Adult' : 'Goal - Child'} disabled={!activeGoalTab}>
            {activeTab === 'GL' && (
              <SpeechIntGoal
                onDataFetched={() => setActiveTab('GL')}
                IsAdult={isAdultCHA}
                refreshGoal={refreshGoalTab}
                ref={GoalsRef}
              />
            )}
          </Tab>
        )}
      </Tabs>
    </div>
  )
})
