const ProgressNotes = {
  SoapNote: 'SN',
  ProgressNote: 'PN',
  DischargeSummary: 'DS',
}

const VisitTypeId = {
  Eval: '1',
  SoapNote: '2',
  ReEval: '3',
  Discharge: '4',
  OneOnOne: '16',
  ProgressNote: '17',
}

//This will be used in the sign and lock table
const NoteType = {
  Eval: 1, //0
  SoapNote: 2, //1
  ReEval: 3, //
  DischargeSummary: 4, //3
  ProgressNote: 5, //2
  DischargeNoms: 6, //4
}

const DisaplyNoteTab = {
  Normal_Client: 0,
  ADD_PROGRESS_NOTE: 1,
  DISCHARGE_CLIENT: 2,
}
export { ProgressNotes, VisitTypeId, NoteType, DisaplyNoteTab }
