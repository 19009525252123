import axios from 'axios'
import { Variables } from '../../Variables'
import formatDate from '../../Utility/Utility'

const apiUrl = Variables.API_URL
export class CHAMedicalService {
  constructor() {
    this.CHA_MedicalId = 0
    this.VisitId = 0
    this.HadHeadInjury = 'No'
    this.HeadInjuryDescription = null
    this.HasAllergy = 'No'
    this.AllergyDescription = null
    this.HasRecentChangeInVoice = 'No'
    this.NoticeChange = null
    this.IsOnMedication = 'No'
    this.MedicationDescription = null
    this.PreviousSurgeriesDescription = null
    this.OverallHealthDescription = null
    this.LastVisionScreeningDate = null
    this.LastVisionScreeningResult = 'Passed'
    this.DoesWearGlasses = 'No'
    this.LastHearingScreeningDate = null
    this.LastHearingScreeningResult = 'Passed'
    this.DoesWearHearingAids = 'No'
    this.Diagnosis1 = null
    this.Diagnosis1OnsetDate = null
    this.Diagnosis2 = null
    this.Diagnosis2OnsetDate = null
    this.Diagnosis3 = null
    this.Diagnosis3OnsetDate = null
    this.Diagnosis4 = null
    this.Diagnosis4OnsetDate = null
    this.EpisodeId = ''
    this.FCM_Ids = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
  }

  getCHAData(VisitId) {
    return axios
      .get(`${apiUrl}CHA/Medical/${VisitId}`)
      .then((response) => {
        const dataFromResponse = response.data.Result
        if (dataFromResponse) {
          this.CHA_MedicalId = dataFromResponse.CHA_MedicalId
          this.VisitId = dataFromResponse.VisitId
          this.HadHeadInjury = dataFromResponse.HadHeadInjury
          this.HeadInjuryDescription = dataFromResponse.HeadInjuryDescription
          this.HasAllergy = dataFromResponse.HasAllergy
          this.AllergyDescription = dataFromResponse.AllergyDescription
          this.HasRecentChangeInVoice = dataFromResponse.HasRecentChangeInVoice
          this.NoticeChange = formatDate(dataFromResponse.NoticeChange, false)
          this.IsOnMedication = dataFromResponse.IsOnMedication
          this.MedicationDescription = dataFromResponse.MedicationDescription
          this.PreviousSurgeriesDescription =
            dataFromResponse.PreviousSurgeriesDescription
          this.OverallHealthDescription =
            dataFromResponse.OverallHealthDescription
          this.LastVisionScreeningDate = formatDate(
            dataFromResponse.LastVisionScreeningDate,
            false,
          )

          this.LastVisionScreeningResult =
            dataFromResponse.LastVisionScreeningResult
          this.DoesWearGlasses = dataFromResponse.DoesWearGlasses
          this.LastHearingScreeningDate = formatDate(
            dataFromResponse.LastHearingScreeningDate,
            false,
          )

          this.LastHearingScreeningResult =
            dataFromResponse.LastHearingScreeningResult
          this.DoesWearHearingAids = dataFromResponse.DoesWearHearingAids
          this.FCM_Ids = dataFromResponse.FCM_Ids
          this.Diagnosis1 = dataFromResponse.Diagnosis1
          this.Diagnosis1OnsetDate = formatDate(
            dataFromResponse.Diagnosis1OnsetDate,
            false,
          )
          this.Diagnosis2 = dataFromResponse.Diagnosis2
          this.Diagnosis2OnsetDate = formatDate(
            dataFromResponse.Diagnosis2OnsetDate,
            false,
          )
          this.Diagnosis3 = dataFromResponse.Diagnosis3
          this.Diagnosis3OnsetDate = formatDate(
            dataFromResponse.Diagnosis3OnsetDate,
            false,
          )
          this.Diagnosis4 = dataFromResponse.Diagnosis4
          this.Diagnosis4OnsetDate = formatDate(
            dataFromResponse.Diagnosis4OnsetDate,
            false,
          )
          this.CreatedBy = dataFromResponse.CreatedBy
          this.UpdatedBy = dataFromResponse.UpdatedBy
          return this
        }
      })
      .catch((error) => {
        console.error('Error fetching SoapNote data:', error)
        throw error
      })
  }

  insertCHAData(formData) {
    return axios
      .post(`${apiUrl}CHA/CreateMedical`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting CHA/CreateMedical data:', error)
        throw error
      })
  }

  updateDiagnosisCode(episodeUpdatedData) {
    return axios
      .put(
        Variables.USER_API_URL + 'Episode/UpdateEpisodeDiagnosis',
        episodeUpdatedData,
      )
      .then((response) => {
        // Handle the response from the server
        console.log('Update successful:', response.data)
      })
      .catch((error) => {
        // Handle errors
        console.error('Error updating data:', error)
      })
  }
}
