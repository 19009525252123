import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Variables } from '../Variables'
import { max } from 'date-fns'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { TextareaComponent } from '../Controls/TextareaComponent '
import { MultiSelectDropdown } from '../Controls/MultiSelectDropdown'
import { CHCGeneralDTO } from '../Model/CasehistoryModel'
import { VisitProvider, SignLockProvider } from '../Provider/Visitprovider'
import { DDList } from '../Controls/DDList'
import { TextBox } from '../Controls/TextareaComponent '
import { SelectYesNo } from '../Controls/DDLYesNo'
import { useUser } from '../Provider/UserContext'
import { useLanguage } from '../Provider/LanguageContext'
import Loading from '../Controls/Loading'
import { CreatedByProvider } from '../Provider/Visitprovider'
import { create } from 'lodash'

export const CasehistoryGeneral = React.forwardRef(({ onDataFetched }, ref) => {
  const { userFirstName } = useUser()
  const VisitId = useContext(VisitProvider)
  const { userDetails } = useUser()
  const { userLanguage } = useLanguage()
  const [formData, setFormData] = useState(new CHCGeneralDTO())
  const [refreshForm, setRefreshForm] = useState(false)
  const [showValidationError, setShowValidationError] = useState(false)
  const [hasPastTherapyError, sethasPastTherapyError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [
    ReceivingOutsideAgencyWhereError,
    setReceivingOutsideAgencyWhereError,
  ] = useState(false)
  const [socialError, setsocialError] = useState(false)
  const IsSignLock = useContext(SignLockProvider)
  const CreatedBy = useContext(CreatedByProvider)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    if (userDetails) {
      //formData.VisitId = VisitId
      formData.CreatedBy = CreatedBy
      formData.UpdatedBy = CreatedBy
    }
  }, [userDetails, VisitId, CreatedBy])

  const handleChange = (controlName, selectedValue) => {
    //alert(controlName);alert(selectedValue);
    setFormData((prevData) => ({ ...prevData, [controlName]: selectedValue }))
    if (controlName === 'DominantLanguage') {
      userLanguage(selectedValue)
    }
    setIsDirty(true)
  }
  React.useImperativeHandle(ref, () => ({
    handleSubmit,
  }))
  // const handleSave = () => {
  //  alert("sassss");
  // };
  // useEffect(() => {
  //   //alert(onSave);
  //   //if (onSave===true) {
  //     alert("asas");
  //   //}
  // }, [onSave]);

  const handleTextBoxValueChange = (controlName, value) => {
    const valueControl = controlName
    const updatedFormData = {
      ...formData,
      [valueControl]: value,
    }
    setFormData(updatedFormData)
    //setIsDirty(true)
  }

  const handleMultiSelectChange = (controlName, selectedValues) => {
    const selectedText = selectedValues.map((option) => option.label)
    setFormData((prevData) => ({ ...prevData, [controlName]: selectedText }))
    setIsDirty(true)
  }
  const fetchData = (visitId) => {
    axios
      .get(Variables.API_URL + 'CaseHistory/General/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (response.status === 200) {
          //alert(JSON.stringify(data));
          const caseHistoryChildDTO = mapToCaseHistoryChildDTO(data)
          userLanguage(data.DominantLanguage)
          setFormData(caseHistoryChildDTO)
        } else {
          //alert(JSON.stringify(new CHCGeneralDTO()));
          var formResetData = new CHCGeneralDTO()
          setFormData(new CHCGeneralDTO())
          userLanguage(formResetData.DominantLanguage)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
        onDataFetched()
      })
  }

  useEffect(() => {
    if (VisitId) {
      fetchData(VisitId)
    }
  }, [VisitId, refreshForm])
  const ValidationObject = () => {
    let isValid = true

    if (formData.AttendSchool === 'Yes') {
      if (!formData.WhereName) {
        setShowValidationError(true)
        isValid = false
      }
    }
    if (formData.HasSpeechTherapyInPast === 'Yes') {
      if (!formData.PastSpeechTherapyDescription) {
        sethasPastTherapyError(true)
        isValid = false
      }
    }
    if (formData.IsReceivingOutsideAgencyService === 'Yes') {
      if (!formData.ReceivingOutsideAgencyWhere) {
        setReceivingOutsideAgencyWhereError(true)
        isValid = false
      }
    }

    if (formData.HasSocialSkillConcern === 'Yes') {
      if (!formData.SocialSkillConcernDescription) {
        setsocialError(true)
        isValid = false
      }
    }
    //alert(isValid);
    return isValid
  }
  useEffect(() => {
    ValidationObject()
  }, [formData])

  const handleSubmit = (showMsg = true) => {
    // const isValid = ValidationObject();
    // //alert(isValid);
    // if (!isValid) {
    //   alert("Kindly fill the all required field(s).");
    //   return;
    // }
    if (IsSignLock || !isDirty) {
      //alert('No changes detected.')
      return
    }
    setRefreshForm(false)
    formData.VisitId = VisitId
    formData.CreatedBy = CreatedBy
    formData.UpdatedBy = CreatedBy
    //alert(JSON.stringify(formData))
    if (Array.isArray(formData.AdditionalLanguages)) {
      formData.AdditionalLanguages = formData.AdditionalLanguages.join(',')
    }
    if (Array.isArray(formData.OtherSpecialistsWorkedWith)) {
      formData.OtherSpecialistsWorkedWith =
        formData.OtherSpecialistsWorkedWith.join(',')
    }
    saveOrUpdateCaseHistory(formData, showMsg)
  }

  const saveOrUpdateCaseHistory = (caseHistoryChild, showMsg) => {
    const apiUrl =
      caseHistoryChild.CHC_GeneralId === 0
        ? 'CaseHistory/CreateGeneral'
        : 'CaseHistory/UpdateGeneral'
    //alert(apiUrl);
    if (caseHistoryChild.CHC_GeneralId === 0) {
      axios
        .post(Variables.API_URL + apiUrl, caseHistoryChild)
        .then((response) => {
          if (showMsg) {
            alert(response.data.MessageDetail)
          }
          setRefreshForm(true)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setIsDirty(false)
        })
    } else {
      axios
        .put(Variables.API_URL + apiUrl, caseHistoryChild)
        .then((response) => {
          setRefreshForm(true)
          if (showMsg) {
            alert(response.data.MessageDetail)
          }
          setRefreshForm(true)
          //console.log(response.data);
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setIsDirty(false)
        })
    }
  }

  const mapToCaseHistoryChildDTO = (item) => {
    const caseHistoryChildDTO = new CHCGeneralDTO()
    caseHistoryChildDTO.CHC_GeneralId = item.CHC_GeneralId
    caseHistoryChildDTO.VisitId = item.VisitId
    caseHistoryChildDTO.DominantLanguage = item.DominantLanguage

    caseHistoryChildDTO.DominantLanguageOther = item.DominantLanguageOther
    if (item.AdditionalLanguages !== undefined) {
      caseHistoryChildDTO.AdditionalLanguages =
        item.AdditionalLanguages.split(',')
    }
    caseHistoryChildDTO.CommunicatesThrough = item.CommunicatesThrough
    caseHistoryChildDTO.UnderstandingLevel = item.UnderstandingLevel
    caseHistoryChildDTO.HasFamilyDefict = item.HasFamilyDefict //===true?1:0;
    caseHistoryChildDTO.FamilyDefictDescription = item.FamilyDefictDescription
    caseHistoryChildDTO.DeficitEffect = item.DeficitEffect
    caseHistoryChildDTO.LivingSiblingDescription = item.LivingSiblingDescription
    caseHistoryChildDTO.AttendSchool = item.AttendSchool // === true ? 1 : 0;
    caseHistoryChildDTO.ClassGrade = item.ClassGrade
    caseHistoryChildDTO.CurrentPlan = item.CurrentPlan
    caseHistoryChildDTO.HasSocialSkillConcern = item.HasSocialSkillConcern
    caseHistoryChildDTO.SocialSkillConcernDescription =
      item.SocialSkillConcernDescription
    caseHistoryChildDTO.HasSpeechTherapyInPast = item.HasSpeechTherapyInPast //=== true ? 1 : 0;
    caseHistoryChildDTO.PastSpeechTherapyDescription =
      item.PastSpeechTherapyDescription
    caseHistoryChildDTO.ReceivedPastSpeechTherapyReport =
      item.ReceivedPastSpeechTherapyReport //=== true ? 1 : 0;
    caseHistoryChildDTO.IsReceivingOutsideAgencyService =
      item.IsReceivingOutsideAgencyService //=== true ? 1 : 0;

    if (item.OtherSpecialistsWorkedWith !== undefined) {
      caseHistoryChildDTO.OtherSpecialistsWorkedWith =
        item.OtherSpecialistsWorkedWith.split(',')
    }

    caseHistoryChildDTO.HaveYouReceiveReport = item.HaveYouReceiveReport
    caseHistoryChildDTO.ReceivingOutsideAgencyWhere =
      item.ReceivingOutsideAgencyWhere

    caseHistoryChildDTO.SpeechTherapyReason = item.SpeechTherapyReason
    caseHistoryChildDTO.ExpectedOutcome = item.ExpectedOutcome
    caseHistoryChildDTO.SitUpAge = item.SitupAge
    caseHistoryChildDTO.StandAge = item.StandAge
    caseHistoryChildDTO.CrawlAge = item.CrawlAge
    caseHistoryChildDTO.SpeakWordAge = item.SpeakWordAge
    caseHistoryChildDTO.WalkAge = item.WalkAge
    caseHistoryChildDTO.ComWordsAge = item.ComWordsAge
    caseHistoryChildDTO.WhereName = item.WhereName

    // Assign other properties as needed
    return caseHistoryChildDTO
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <div style={{ display: 'block', width: max, padding: 10 }}>
      <table style={{ width: '100%', paddind: 5 }}>
        <tr>
          <td style={{ width: '50%', verticalAlign: 'top' }}>
            <h6>Personal Profile</h6>
            <Form.Group as={Row} className='mb-3'>
              <Form.Label size='sm' column lg={4}>
                {userFirstName}'s dominant language?
              </Form.Label>
              <Col sm={3}>
                <DDList
                  dropdownName='DominantLanguage'
                  defaultValue={formData.DominantLanguage}
                  onSelect={(selectedValue) =>
                    handleChange('DominantLanguage', selectedValue)
                  }
                  ddType={18}
                />
              </Col>{' '}
              {formData.DominantLanguage == 'Other' && (
                <Col sm={3}>
                  <TextBox
                    IsChangeTracking={setIsDirty}
                    maxLength={20}
                    controlid='DominantLanguageOther'
                    defaultValue={formData.DominantLanguageOther}
                    onTextChange={(value) =>
                      handleTextBoxValueChange('DominantLanguageOther', value)
                    }
                    placeholder={'other...'}
                  />
                </Col>
              )}
            </Form.Group>
            <Form.Group as={Row} className='mb-2'>
              <Form.Label size='sm' column sm={6}>
                {userFirstName}'s additional language(s) spoken at home?
              </Form.Label>
              <Col sm={6}>
                <MultiSelectDropdown
                  closeMenuOnSelect={false}
                  optionType={4}
                  isMultiSelect={true} //['English','Arabic']
                  setDefaultValues={formData.AdditionalLanguages}
                  selectedOptions={formData.AdditionalLanguages}
                  onChange={(values) =>
                    handleMultiSelectChange('AdditionalLanguages', values)
                  }
                ></MultiSelectDropdown>
              </Col>
              {/* <Col sm={2}>
                   <Form.Control size="sm" type="text" placeholder="other" />
                   </Col> */}
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label size='sm' column sm={6}>
                {userFirstName}'s communicates wants and needs through?
              </Form.Label>
              <Col sm={2}>
                <DDList
                  dropdownName='CommunicatesThrough'
                  defaultValue={formData.CommunicatesThrough}
                  onSelect={(selectedValue) =>
                    handleChange('CommunicatesThrough', selectedValue)
                  }
                  ddType={19}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label size='sm' column sm={6}>
                How much of what {userFirstName} says do you understand?
              </Form.Label>
              <Col sm={2}>
                <DDList
                  dropdownName='UnderstandingLevel'
                  defaultValue={formData.UnderstandingLevel}
                  onSelect={(selectedValue) =>
                    handleChange('UnderstandingLevel', selectedValue)
                  }
                  ddType={20}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} style={{ marginBottom: '2px' }}>
              <Col sm={9}>
                Is there a family history of speech and language deficits or
                learning difficulties?
              </Col>
              <Col sm={2}>
                <SelectYesNo
                  defaultValue={formData.HasFamilyDefict}
                  onSelect={(selectedValue) =>
                    handleChange('HasFamilyDefict', selectedValue)
                  }
                />
              </Col>
            </Form.Group>
            {formData.HasFamilyDefict == 'Yes' && (
              <Form.Group as={Row}>
                <Col sm={12}>
                  <TextareaComponent
                    IsChangeTracking={setIsDirty}
                    controlid='FamilyDefictDescription'
                    maxLength={5000}
                    defaultValue={formData.FamilyDefictDescription}
                    onTextChange={(value) =>
                      handleTextBoxValueChange('FamilyDefictDescription', value)
                    }
                    cols={40}
                    rows={2}
                    placeholder={'Please describe...'}
                  />
                </Col>
              </Form.Group>
            )}
            <Row>
              <Form.Group as={Col}>
                <Form.Label>
                  How does deficit affect {userFirstName}’s life?
                </Form.Label>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  maxLength={5000}
                  controlid='DeficitEffect'
                  defaultValue={formData.DeficitEffect}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('DeficitEffect', value)
                  }
                  placeholder={'Please describe...'}
                  cols={40}
                  rows={2}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>
                  Describe {userFirstName}’s current living situation?
                </Form.Label>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  maxLength={5000}
                  cols={40}
                  rows={2}
                  controlid='LivingSiblingDescription'
                  defaultValue={formData.LivingSiblingDescription}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('LivingSiblingDescription', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Form.Group>
            </Row>
            <Row style={{ padding: 5 }}>
              <b>Social / Education</b>
            </Row>
            <Form.Group as={Row}>
              <Form.Label size='sm' column lg={5}>
                Does {userFirstName} attend school/daycare?
              </Form.Label>
              <Col sm={2}>
                <SelectYesNo
                  defaultValue={formData.AttendSchool}
                  onSelect={(selectedValue) =>
                    handleChange('AttendSchool', selectedValue)
                  }
                />{' '}
              </Col>
              <Col sm={5}>
                {formData.AttendSchool == 'Yes' && (
                  <TextBox
                    IsChangeTracking={setIsDirty}
                    showError={showValidationError}
                    controlid='WhereName'
                    maxLength={50}
                    defaultValue={formData.WhereName}
                    onTextChange={(value) =>
                      handleTextBoxValueChange('WhereName', value)
                    }
                    placeholder={'...where'}
                  />
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} style={{ marginBottom: '3px' }}>
              <Col sm={1}>{formData.AttendSchool === 'Yes' && 'Grade:'}</Col>
              <Col sm={2}>
                {formData.AttendSchool == 'Yes' && (
                  <DDList
                    dropdownName='ClassGrade'
                    defaultValue={formData.ClassGrade}
                    onSelect={(selectedValue) =>
                      handleChange('ClassGrade', selectedValue)
                    }
                    ddType={21}
                  />
                )}
              </Col>
              <Col sm={2}>{formData.AttendSchool == 'Yes' && 'On a Plan?'}</Col>
              <Col sm={2}>
                {formData.AttendSchool == 'Yes' && (
                  <DDList
                    dropdownName='CurrentPlan'
                    defaultValue={formData.ClassGrade}
                    onSelect={(selectedValue) =>
                      handleChange('CurrentPlan', selectedValue)
                    }
                    ddType={22}
                  />
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label size='sm' column sm={6}>
                Do you have concerns about {userFirstName}’s social skills?
              </Form.Label>
              <Col sm={2}>
                <SelectYesNo
                  defaultValue={formData.HasSocialSkillConcern}
                  onSelect={(selectedValue) =>
                    handleChange('HasSocialSkillConcern', selectedValue)
                  }
                />
              </Col>
            </Form.Group>
            {formData.HasSocialSkillConcern === 'Yes' && (
              <Form.Group as={Row}>
                <Col sm={12}>
                  <TextareaComponent
                    IsChangeTracking={setIsDirty}
                    showError={socialError}
                    maxLength={5000}
                    cols={40}
                    rows={2}
                    defaultValue={formData.SocialSkillConcernDescription}
                    onTextChange={(value) =>
                      handleTextBoxValueChange(
                        'SocialSkillConcernDescription',
                        value,
                      )
                    }
                    placeholder={'Please describe...'}
                  />
                </Col>
              </Form.Group>
            )}
          </td>
          <td style={{ width: '50%', verticalAlign: 'top', paddingLeft: 15 }}>
            <h6>Therapy Profile</h6>
            {/* <Form>                        */}
            <Row className='mb-12'>
              {/* <Form.Group as={Col}>
                <Form.Label>Why are you seeking speech therapy?</Form.Label>
                <TextareaComponent IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={2}
                  maxLength={5000}
                  defaultValue={formData.SpeechTherapyReason}
                  onTextChange={(value) =>
                    handleTextBoxValueChange("SpeechTherapyReason", value)
                  }
                  placeholder={"Please describe..."}
                />
              </Form.Group> */}

              <Form.Group as={Col}>
                <Form.Label>What is your expected outcome?</Form.Label>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={2}
                  maxLength={5000}
                  defaultValue={formData.ExpectedOutcome}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('ExpectedOutcome', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Form.Group>
            </Row>

            <Form.Group as={Row} style={{ paddingTop: 5 }}>
              <Form.Label size='sm' column sm={5}>
                Has {userFirstName} had speech therapy in the past?
              </Form.Label>
              <Col sm={2}>
                <SelectYesNo
                  defaultValue={formData.HasSpeechTherapyInPast}
                  onSelect={(selectedValue) =>
                    handleChange('HasSpeechTherapyInPast', selectedValue)
                  }
                />
              </Col>
              <Col sm={5}>
                {formData.HasSpeechTherapyInPast == 'Yes' && (
                  <TextBox
                    IsChangeTracking={setIsDirty}
                    maxLength={50}
                    showError={hasPastTherapyError}
                    defaultValue={formData.PastSpeechTherapyDescription}
                    onTextChange={(value) =>
                      handleTextBoxValueChange(
                        'PastSpeechTherapyDescription',
                        value,
                      )
                    }
                    placeholder={'where...'}
                  />
                )}
              </Col>
            </Form.Group>

            {formData.HasSpeechTherapyInPast == 'Yes' && (
              <Form.Group as={Row}>
                <Form.Label size='sm' column sm={5}>
                  Have you received a report?
                </Form.Label>
                <Col sm={2}>
                  <SelectYesNo
                    defaultValue={formData.ReceivedPastSpeechTherapyReport}
                    onSelect={(selectedValue) =>
                      handleChange(
                        'ReceivedPastSpeechTherapyReport',
                        selectedValue,
                      )
                    }
                  />
                </Col>
              </Form.Group>
            )}
            {formData.HasSpeechTherapyInPast == 'Yes' && (
              <Form.Group as={Row} className='mb-12' style={{ paddingTop: 5 }}>
                <Form.Label size='sm' column sm={9}>
                  Is {userFirstName} currently receiving speech therapy services
                  through outside agency?
                </Form.Label>
                <Col sm={2}>
                  <SelectYesNo
                    defaultValue={formData.IsReceivingOutsideAgencyService}
                    onSelect={(selectedValue) =>
                      handleChange(
                        'IsReceivingOutsideAgencyService',
                        selectedValue,
                      )
                    }
                  />
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row}>
              <Form.Label size='sm' column sm={6}></Form.Label>
              <Col sm={6}>
                {formData.HasSpeechTherapyInPast == 'Yes' && (
                  <>
                    {formData.IsReceivingOutsideAgencyService == 'Yes' && (
                      <TextBox
                        IsChangeTracking={setIsDirty}
                        maxLength={50}
                        showError={ReceivingOutsideAgencyWhereError}
                        defaultValue={formData.ReceivingOutsideAgencyWhere}
                        onTextChange={(value) =>
                          handleTextBoxValueChange(
                            'ReceivingOutsideAgencyWhere',
                            value,
                          )
                        }
                        placeholder={'where...'}
                      />

                      // <Form.Control
                      //   maxLength={50}
                      //   size="sm"
                      //   type="text"
                      //   value={formData.ReceivingOutsideAgencyWhere}
                      //   onChange={(event) =>
                      //     handleTextBoxValueChange(
                      //       "ReceivingOutsideAgencyWhere",
                      //       event.target.value
                      //     )
                      //   }
                      //   placeholder="...where?"
                      // />
                    )}
                  </>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-1'>
              <Form.Label size='sm' column sm={7}>
                Has {userFirstName} worked with any other specialists?
              </Form.Label>
              <Col sm={5}>
                <MultiSelectDropdown
                  closeMenuOnSelect={false}
                  selectedOptions={formData.OtherSpecialistsWorkedWith}
                  setDefaultValues={formData.OtherSpecialistsWorkedWith}
                  onChange={(values) =>
                    handleMultiSelectChange(
                      'OtherSpecialistsWorkedWith',
                      values,
                    )
                  }
                  optionType={2}
                  isMultiSelect={true}
                ></MultiSelectDropdown>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label size='sm' column sm={5}>
                Have you received a report?
              </Form.Label>
              <Col sm={2}>
                <SelectYesNo
                  defaultValue={formData.HaveYouReceiveReport}
                  onSelect={(selectedValue) =>
                    handleChange('HaveYouReceiveReport', selectedValue)
                  }
                />
              </Col>
            </Form.Group>
            <Row>
              <b>Development History</b>
            </Row>
            <Row style={{ paddingLeft: 20, marginBottom: '3px' }}>
              Indicate {userFirstName}'s age (in months) when {userFirstName}{' '}
              could:
            </Row>

            <Form.Group as={Row}>
              <Col sm={2} style={{ textAlign: 'right' }}>
                Sit Up:
              </Col>
              <Col sm={2}>
                <TextBox
                  IsChangeTracking={setIsDirty}
                  numericOnly={true}
                  controlid='SitUpAge'
                  defaultValue={formData.SitUpAge}
                  maxLength={2}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('SitUpAge', value)
                  }
                  placeholder={'age'}
                />
              </Col>
              <Col sm={2} style={{ textAlign: 'right' }}>
                Stand:
              </Col>
              <Col sm={2}>
                <TextBox
                  IsChangeTracking={setIsDirty}
                  numericOnly={true}
                  controlid='StandAge'
                  defaultValue={formData.StandAge}
                  maxLength={2}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('StandAge', value)
                  }
                  placeholder={'age'}
                />
              </Col>
              <Col sm={2} style={{ textAlign: 'right' }}>
                Speak words:
              </Col>
              <Col sm={2}>
                <TextBox
                  IsChangeTracking={setIsDirty}
                  numericOnly={true}
                  controlid='SpeakWordAge'
                  defaultValue={formData.SpeakWordAge}
                  maxLength={2}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('SpeakWordAge', value)
                  }
                  placeholder={'age'}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} style={{}}>
              <Col sm={2} style={{ textAlign: 'right' }}>
                Crawl:
              </Col>
              <Col sm={2}>
                <TextBox
                  IsChangeTracking={setIsDirty}
                  numericOnly={true}
                  controlid='CrawlAge'
                  defaultValue={formData.CrawlAge}
                  maxLength={2}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('CrawlAge', value)
                  }
                  placeholder={'age'}
                />
              </Col>
              <Col sm={2} style={{ textAlign: 'right' }}>
                Walk:
              </Col>
              <Col sm={2}>
                <TextBox
                  IsChangeTracking={setIsDirty}
                  numericOnly={true}
                  controlid='WalkAge'
                  defaultValue={formData.WalkAge}
                  maxLength={2}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('WalkAge', value)
                  }
                  placeholder={'age'}
                />
              </Col>
              <Col sm={2} style={{ textAlign: 'right' }}>
                Com. Words:
              </Col>
              <Col sm={2}>
                <TextBox
                  IsChangeTracking={setIsDirty}
                  numericOnly={true}
                  controlid='ComWordsAge'
                  defaultValue={formData.ComWordsAge}
                  maxLength={2}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('ComWordsAge', value)
                  }
                  placeholder={'age'}
                />
              </Col>
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td colspan={2} style={{ textAlign: 'right' }}>
            <div style={{ marginTop: '10px' }}>
              {/* <Button type="button" className="btn btn-primary  rounded-pill" >Preview</Button> */}
              <button
                disabled={IsSignLock || !isDirty}
                onClick={handleSubmit}
                style={{ width: '150px' }}
                className='btn btn-primary rounded-pill'
              >
                <b> Save</b>{' '}
              </button>
              {/*  <Button type="button" style={{marginLeft: '10px'}} onClick={handleSubmit} className="btn btn-primary rounded-pill">Save</Button> 
      <Button type="button" style={{marginLeft: '10px'}} className="btn btn-primary rounded-pill">Sign/Lock</Button> */}
            </div>
          </td>
        </tr>
      </table>
    </div>
  )
})
