import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { AutocompleteDropdown } from '../Controls/AutoCompleteDropdown'
import { DDList } from '../Controls/DDList'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Delete, Add } from '../Icon/DeleteIcon'
import data from '../data/goalquestion.json'
import { allSoundOptions } from '../SpeechInt/SoundGrid'
import axios from 'axios'
import { Variables } from '../Variables'
import { GoalsService } from '../FCM/Component/GoalsService'

const apiUrl = Variables.API_URL

export const GoalTemplatePhonology = ({
  onTableDataChange,
  VisitId,
  reFreshGrid,
  fcmType,
  hasData = false,
  CreatedBy = null,
  IsChangeTracking = false,
}) => {
  const initialRow = {
    GoalId: 0,
    RowId: 1,
    FCMType: fcmType,
    VisitId: VisitId,
    GoalType: 'Phonology',
    Duration: 6,
    Units: 'mth(s)',
    GoalCode: 'ST',
    Given: 'no cues',
    AtLevel: 'sentence',
    InSessions: '4 of 5',
    Accuracy: '10%',
    GoalDescription: '',
    CreatedBy: CreatedBy,
    UpdatedBy: CreatedBy,
  }

  const [rows, setRows] = useState([initialRow])
  const [jsonQuestionData, setQuestionData] = useState([])
  const goalService = new GoalsService()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (reFreshGrid === true) {
      setIsLoading(true)
      //alert(reFreshGrid);
      goalService
        .getGoalsData(VisitId, fcmType)
        .then((response) => {
          const updatedRows = response.filter(
            (item) => item.GoalType === 'Phonology',
          )
          if (updatedRows.length === 0) {
            hasData(false)
            setRows([initialRow])
          } else {
            hasData(true)
            setRows(updatedRows)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [reFreshGrid])

  const removeDuplicateRows = (rows) => {
    const uniqueRowIds = new Set()
    return rows.filter((row) => {
      if (uniqueRowIds.has(row.RowId)) {
        return false
      }
      uniqueRowIds.add(row.RowId)
      return true
    })
  }
  useEffect(() => {
    onTableDataChange(rows)
  }, [rows, onTableDataChange])

  useEffect(() => {
    fetch('../data/goalPhonology.json')
      .then((response) => response.json())
      .then((jsonData) => setQuestionData(jsonData))
      .catch((error) => console.log(error))
  }, [])

  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      { ...initialRow, RowId: prevRows.length + 1 },
    ])
    //setRows(prevRows => removeDuplicateRows(prevRows));
  }

  const handleDeleteRow = (id, goalId) => {
    if (goalId > 0) {
      goalService.deleteGoal(goalId)
    }
    setRows((prevRows) => prevRows.filter((row) => row.RowId !== id))
    IsChangeTracking(true)
  }

  const handleTextboxChange = (rowId, columnName, value) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.RowId === rowId ? { ...row, [columnName]: value } : row,
      ),
    )
    IsChangeTracking(true)
  }

  const handleOptionSelect = (rowId, controlName, value) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.RowId === rowId ? { ...row, [controlName]: value } : row,
      ),
    )
    IsChangeTracking(true)
  }

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <table className='table'>
          <thead className='thead-light'>
            <tr>
              {/* <td style={{ width: "150px" }}>
                <b>Phonology</b>
              </td> */}
              <tr>
                <th style={{ width: '250px' }}>Phonology</th>
              </tr>
            </tr>
            <tr>
              <th style={{ width: '2%' }}>Goal</th>
              <th style={{ width: '10%' }}>Within</th>
              <th style={{ width: '10%' }}>given</th>
              <th style={{ width: '50%' }}>the client</th>
              <th style={{ width: '8%' }}>at the &#123;&#125; level</th>
              <th style={{ width: '9%' }}>with &#123;&#125; accuracy</th>
              <th style={{ width: '9%' }}>in &#123;&#125; sessions</th>
              <th style={{ width: '2%' }}>
                <button onClick={handleAddRow} title='Add New Goal'>
                  <Add></Add>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={row.RowId}>
                <td style={{ width: '2%' }}>
                  {row.GoalCode}-{index + 1}
                </td>
                <td style={{ width: '10%' }}>
                  <Form>
                    <Form.Group as={Row}>
                      <Col sm={4}>
                        <input
                          type='text'
                          maxLength={2}
                          className='form-control form-control-sm'
                          value={row.Duration}
                          onChange={(e) =>
                            handleTextboxChange(
                              row.RowId,
                              'Duration',
                              e.target.value,
                            )
                          }
                        />
                      </Col>
                      <Col sm={8}>
                        <DDList
                          ddType={4}
                          defaultValue={row.Units}
                          onSelect={(e) =>
                            handleOptionSelect(row.RowId, 'Units', e)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </td>

                <td style={{ width: '10%' }}>
                  <DDList
                    ddType='5'
                    defaultValue={row.Given}
                    onSelect={(e) => handleOptionSelect(row.RowId, 'Given', e)}
                  />
                </td>
                <td style={{ width: '50%' }}>
                  <Form>
                    <Form.Group controlId='autocomplete'>
                      <AutocompleteDropdown
                        options={jsonQuestionData}
                        initialValue={row.GoalDescription}
                        onSelect={(option) =>
                          handleOptionSelect(
                            row.RowId,
                            'GoalDescription',
                            option,
                          )
                        }
                      />
                    </Form.Group>
                  </Form>
                </td>

                <td style={{ width: '8%' }}>
                  <DDList
                    ddType={6}
                    defaultValue={row.AtLevel}
                    onSelect={(e) =>
                      handleOptionSelect(row.RowId, 'AtLevel', e)
                    }
                  />
                </td>
                <td style={{ width: '9%' }}>
                  <DDList
                    ddType={8}
                    defaultValue={row.Accuracy}
                    onSelect={(e) =>
                      handleOptionSelect(row.RowId, 'Accuracy', e)
                    }
                  />
                </td>
                <td style={{ width: '9%' }}>
                  <DDList
                    ddType={7}
                    defaultValue={row.InSessions}
                    onSelect={(e) =>
                      handleOptionSelect(row.RowId, 'InSessions', e)
                    }
                  />
                </td>
                <td style={{ width: '2%' }}>
                  {index === rows.length - 1 && rows.length < 5 ? (
                    <button
                      onClick={() => handleDeleteRow(row.RowId, row.GoalId)}
                    >
                      <Delete />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleDeleteRow(row.RowId, row.GoalId)}
                    >
                      <Delete />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
