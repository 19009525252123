import React, { useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import { OptionsforLanguage } from '../data/options'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { faGlasses } from '@fortawesome/free-solid-svg-icons'

const animatedComponents = makeAnimated()

export function MultiSelectDropdown({
  optionType,
  isMultiSelect,
  moreOptions,
  closeMenuOnSelect,
  onChange,
  setDefaultValues,
}) {
  const [selectedOptions, setSelectedOptions] = useState([])
  var [options, setOptions] = useState([])
  const defaultValue = options[0]

  useEffect(() => {
    console.log('setDefaultValues:', selectedOptions)
    //alert(JSON.stringify(setDefaultValues))
    if (Array.isArray(setDefaultValues)) {
      const defaultValues = setDefaultValues
        .filter((option) => option.length > 0) // Exclude items with length zero
        .map((option) => ({
          value: option,
          label: option,
        }))

      setSelectedOptions(defaultValues)
    } else {
      //alert(JSON.stringify(selectedOptions))
      setSelectedOptions(defaultValue)
    }
  }, [setDefaultValues])

  // useEffect(() => {
  //   console.log('setDefaultValues:', setDefaultValues)
  //   if (Array.isArray(setDefaultValues)) {
  //     const defaultValues = setDefaultValues?.map((option) => ({
  //       value: option,
  //       label: option,
  //     }))
  //     setSelectedOptions(defaultValues)
  //   } else {
  //     setSelectedOptions(defaultValue)
  //   }
  // }, [setDefaultValues])

  // useEffect(() => {
  //   if (setDefaultValues) {
  //       const defaultValues=setDefaultValues.map(option => (
  //           { value: option, label: option }));
  //         setSelectedOptions(defaultValues);
  //   }
  // }, [setDefaultValues]);

  if (optionType === 2) {
    options = [
      { value: 'Early Interventionist', label: 'Early Interventionist' },
      { value: 'Occupational Therapist', label: 'Occupational Therapist' },
      { value: 'Physical Therapist', label: 'Physical Therapist' },
      { value: 'Behavioral Therapist', label: 'Behavioral Therapist' },
      // { value: "Physical Therapist", label: "Physical Therapist" },
    ]
  } else if (optionType === 3) {
    options = moreOptions
  } else if (optionType === 4) {
    options = OptionsforLanguage.map((option) => ({
      value: option.value,
      label: option.value,
    }))
  } else if (optionType === 5) {
    options = moreOptions
  } else if (optionType === 6) {
    options = moreOptions
  } else if (optionType === 7) {
    options = [
      { value: 'Audiologist', label: 'Audiologist' },
      { value: 'Otolaryngologist', label: 'Otolaryngologist' },
      { value: 'Neurologist', label: 'Neurologist' },
    ]
  } else if (optionType === 8) {
    options = [
      { value: 'Memory', label: 'Memory' },
      { value: 'Attention', label: 'Attention' },
      { value: 'Reading', label: 'Reading' },
      { value: 'Writing', label: 'Writing' },
      { value: 'Speaking', label: 'Speaking' },
      {
        value: 'Understanding Spoken Language',
        label: 'Understanding Spoken Language',
      },
      { value: 'Problem Solving', label: 'Problem Solving' },
      { value: 'Math', label: 'Math' },
      { value: 'Other', label: 'Other' },
    ]
  } else if (optionType === 9) {
    options = [
      { value: 'Phone Calls', label: 'Phone Calls' },
      { value: 'Bathing/Showering', label: 'Bathing/Showering' },
      { value: 'Dressing', label: 'Dressing' },

      { value: 'Personal Hygiene', label: 'Personal Hygiene' },
      { value: 'Toileting', label: 'Toileting' },
      { value: 'Shopping', label: 'Shopping' },
      { value: 'Cooking', label: 'Cooking' },
      { value: 'Bathing/Showering', label: 'Bathing/Showering' },
      { value: 'Telling Time', label: 'Telling Time' },
      { value: 'Appointments', label: 'Appointments' },
      { value: 'Walking', label: 'Walking' },
      { value: 'Transportation', label: 'Transportation' },
      { value: 'Driving', label: 'Driving' },
      { value: 'Other', label: 'Other' },
    ]
  }

  const handleCreateOption = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue }
    const updatedOptions = [...options, newOption]

    // Update the options and set the newly created option as selected
    setOptions(updatedOptions)
    setSelectedOptions([...selectedOptions, newOption])
    // Invoke the onChange callback with the updated selected values
    if (onChange) {
      onChange([...selectedOptions, newOption])
    }
  }
  const handleChange = (selected) => {
    //setSelectedOptions([...selectedOptions, selected])
    setSelectedOptions(selected)
    if (onChange) {
      onChange(selected)
    }
  }
  // const handleChange = (selected) => {
  //   // alert(selected)
  //   if (selected !== '') {
  //     setSelectedOptions([...selectedOptions, selected])
  //     if (onChange) {
  //       onChange(selected)
  //     }
  //   }
  // }

  return (
    <CreatableSelect
      closeMenuOnSelect={closeMenuOnSelect}
      components={animatedComponents}
      isMulti={isMultiSelect}
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      onCreateOption={handleCreateOption}
      classNamePrefix='custom-select-sm'
    />
  )
}

export const AsyncAutoComplete = ({
  fetchedData,
  noOptionsMessage,
  handleButtonClick,
  selectedValue,
  setSelectedValue,
  isRequiredMessageShown,
  setIsRequiredMessageShown,
  defaultValue,
  isMultiSelect,
  IsChangeTracking = false,
  isDisabled = false, // Add the isDisabled prop with a default value
}) => {
  const [loading, setLoading] = useState(true)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])

  const filterOptions = (inputValue, options) => {
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    )
  }

  const promiseOptions = (inputValue, fetchedData) =>
    new Promise((resolve) => {
      setTimeout(() => {
        const filteredOptions = filterOptions(inputValue, fetchedData)
        resolve(filteredOptions)
      }, 1000)
    })

  useEffect(() => {
    try {
      //alert(JSON.stringify(setIsRequiredMessageShown));
      //alert("value:" + JSON.stringify(defaultValue));
      //console.log('defaultValue:', defaultValue)
      if (
        fetchedData &&
        fetchedData.length > 0 &&
        (defaultValue !== null || defaultValue !== '')
      ) {
        setIsDataLoaded(true)
        setLoading(false)
        if (defaultValue) {
          const defaultValuesArray = defaultValue.split(',')
          const selectedItems = defaultValuesArray
            .map((defaultValue) =>
              fetchedData.find(
                (item) => item.value.trim() === defaultValue.trim(),
              ),
            )
            .filter((item) => item !== undefined)
          //alert("icd:" +JSON.stringify(selectedItems));
          setSelectedOptions(selectedItems)
          setSelectedValue(selectedItems)
        } else {
          setIsDataLoaded(true)
          setLoading(false)
          //alert("from code"+defaultValue);
        }
      }
    } catch (error) {
      // Handle the error here, e.g., log it or display a message to the user
      console.error('An error occurred Multiselect dropdown useeffect:', error)
    }
  }, [fetchedData, defaultValue])

  const handleInputChange = (newValue) => {
    setSelectedOptions(newValue)
    setSelectedValue(newValue)
    setIsRequiredMessageShown(false)
    IsChangeTracking(true)
  }
  const handleButtonClickInternal = () => {
    if (!selectedValue) {
      setIsRequiredMessageShown(true)
    } else {
      // Perform the desired action when the button is clicked and the option is selected
      handleButtonClick()
    }
  }

  return (
    <>
      {isDataLoaded ? (
        <div
          style={isRequiredMessageShown ? { border: '1px solid red' } : null}
        >
          <AsyncCreatableSelect
            cacheOptions
            defaultOptions
            loadOptions={(inputValue) =>
              promiseOptions(inputValue, fetchedData)
            }
            noOptionsMessage={() => noOptionsMessage || 'No options found'}
            value={selectedOptions}
            onChange={handleInputChange}
            isMulti={isMultiSelect === null ? true : isMultiSelect}
            isDisabled={isDisabled}
            isValidNewOption={() => false}//using this option to disable the creation of new option
          />
        </div>
      ) : (
        <p>Loading...</p>
      )}
      {isRequiredMessageShown && (
        <p style={{ color: 'red' }}>This field is required.</p>
      )}
      {/* <button onClick={handleButtonClickInternal}>Submit</button> */}
    </>
  )
}
