import React from 'react'

export const Datectrl = ({ selectedDate, onDateChange }) => {
  const handleDateChange = (event) => {
    const dateValue = event.target.value
    onDateChange(dateValue)
  }

  return (
    <input
      style={{ width: '130px' }}
      type='date'
      className='form-control form-control-sm'
      value={selectedDate}
      onChange={handleDateChange}
    />
  )
}
