import axios from 'axios'
import { Variables } from '../../Variables'

const apiUrl = Variables.API_URL

export class AssessSumService {
  saveData(updateAssmentSummary) {
    return new Promise((resolve, reject) => {
      const apiCalls = []
      //alert(formData.SpeechelligibilityId);
      apiCalls.push(
        axios.post(apiUrl + 'AssessmentSummary/CreateAssSummary', updateAssmentSummary),
      )
      if (apiCalls.length === 0) {
        console.error('No promises to resolve.')
        reject(new Error('No promises to resolve.'))
      }
      Promise.all(apiCalls)
        .then((responses) => {
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  getGetAssSummary(VisitId, fcmType) {
    return axios
      .get(`${apiUrl}AssessmentSummary/GetAssSummary/${VisitId}/${fcmType}`)
      .then((response) => {
        const data = response.data.Result
        return data
      })
      .catch((error) => console.log('Error fetching data:', error))
  }

  deleteAssSumData(summId) {
    return axios
      .delete(apiUrl + 'AssessmentSummary/DeleteAssSummary/' + summId)
      .then((response) => {
        return response.data // Return the data if needed
      })
      .catch((error) => {
        console.log('Error deleting data:', error)
      })
  }
}
