import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const headerData = createSlice({
  name: 'headerTabData',
  initialState,
  reducers: {
    HeaderData(state, action) {
      return {
        ...state,
        ...action.payload,
      }
      //state.headerTabData = action.payload;  // Set the POC data when action is dispatched
    },
    clearHeaderData(state) {
      return null
      //state.pocData = null;  // Clear POC data when action is dispatched
    },
  },
})

export const { HeaderData, clearHeaderData } = headerData.actions // Export the actions
export default headerData.reducer // Export the reducer

// import { createSlice } from '@reduxjs/toolkit'
// const initialState = {
//     headerTabData: null,  // Initialize the POC data
// };
// const headerTabDataSlice = createSlice({
//     name: 'headertab',
//     initialState,
//     reducers: {
//         User(state, action) {
//             return {
//                 ...state,
//                 ...action.payload,
//             };
//         },
//         UpdateSelectedClient(state, action) {
//             return {
//                 ...state,
//                 selectedClient: {...state.selectedClient,...action.payload},
//             };
//         }
//     },
// });

// export const { User,UpdateSelectedClient } = headerTabDataSlice.actions
// // this is not in used right now
// export const selectUser = (state: { persistedReducers: { user: any; }; }) =>
//     state.persistedReducers.user

// export default userSlice.reducer
