import React, { useState, useEffect } from 'react'
//import { TextBox } from '../Controls/TextBoxComponent'
//import { MultiSelectDDL } from '../Controls/MultiSelectDDL'

import MultiSelectDDL from './MultiSelectDDL'
export const TreatmentAimTarget = ({
  defaultRow,
  onRowSelected,
  visitId,
  FCMType,
}) => {
  const [gridData, setGridData] = useState([])
  useEffect(() => {
    var dataFile = null
    if (FCMType === 'INT') {
      dataFile = 'data/disINTNoms18PlusTAT.json'
    } else if (FCMType === 'SLE') {
      dataFile = 'data/disSLENomsTAT.json'
    }
    else if (FCMType === 'SLC') {
      dataFile = 'data/disSLCNomsTAT.json'
    }
    else if (FCMType === 'COG') {
      dataFile = 'data/disCOGNomsTAT.json'
    }
    else if (FCMType === 'SWAL') {
      dataFile = 'data/disSWALNomsTAT.json'
    }
    else if (FCMType === 'VOIC') {
      dataFile = 'data/disVOICNomsTAT.json'
    }
    
    

    fetch(dataFile)
      .then((response) => response.json())
      .then((data) => {
        const updatedData = data.map((item) => ({
          ...item,
          VisitId: visitId,
          FCMType: FCMType,
        }))
        //alert(JSON.stringify(updatedData));
        setGridData(updatedData)
      })
      .catch((error) => {
        console.error('Error reading data:', error)
      })
  }, [FCMType, visitId])

  const [selectedRow, setSelectedRow] = useState(null)
  const [defaultRowProcessed, setDefaultRowProcessed] = useState(false)
  const [dropdownOptions, setDropdownOptions] = useState([
    { value: '0-25% of the time', text: '0-25% of the time' },
    { value: '26-49% of the time', text: '26-49% of the time' },
    { value: '50-75% of the time', text: '50-75% of the time' },
    { value: '76-90% of the time', text: '76-90% of the time' },
    { value: '91-100% of the time', text: '91-100% of the time' },
  ])

  useEffect(() => {
    if (!defaultRowProcessed && defaultRow && defaultRow.length > 0) {
      const updatedData = gridData.map((row) => {
        const matchingDefaultRow = defaultRow.find(
          (defaultRowItem) =>
            String(defaultRowItem.RowId) === String(row.RowId),
        )

        if (matchingDefaultRow) {
          return {
            ...row,
            Discharge_NomsId: matchingDefaultRow.Discharge_NomsId,
            Answer: matchingDefaultRow.Answer,
            QuestionCode: matchingDefaultRow.QuestionCode,
            //IsLongTermGoal:matchingDefaultRow.IsLongTermGoal
          }
        }

        return row
      })
      //console.log(JSON.stringify(updatedData));
      setGridData(updatedData)
      setSelectedRow(null) // Clear the selected row, as multiple rows might have changed
      onRowSelected(updatedData) // Notify parent about the updated rows
      setDefaultRowProcessed(true)
    }
  }, [defaultRow, defaultRowProcessed])

  const handleDropdownChange = (id, value) => {
    const selectedValue = value.trim() === '' ? dropdownOptions[0].value : value
    //alert(JSON.stringify(selectedValue))
    const updatedData = gridData.map((row) => {
      if (row.RowId === id) {
        return { ...row, Answer: selectedValue }
      }
      return row
    })
    //alert(JSON.stringify(updatedData))
    setGridData(updatedData)
    setSelectedRow(updatedData.find((row) => row.RowId === id))
    onRowSelected(updatedData)
  }

  const getDropdownOptionsForRow = () => {
    //alert(JSON.stringify(dropdownOptions));
    return dropdownOptions
  }

  return (
    <table className='table table-striped'>
      <thead>
        <tr>
          <th style={{ width: '2%' }}>#</th>
          <th style={{ width: 'auto' }}>Treatment Aims and Targets</th>
          <th style={{ width: 'auto' }}>Response values</th>
        </tr>
      </thead>
      <tbody>
        {gridData.map((row) => (
          <tr key={row.RowId}>
            <td>{row.RowId}</td>
            <td>{row.Question}</td>
            <td>
              {/* {row.ResponseType === 0 && (
                <>
                  <select
                    className='form-select form-select-sm'
                    value={row.Answer}
                    onChange={(event) =>
                      handleDropdownChange(row.RowId, event.target.value)
                    }
                  >
                    {getDropdownOptionsForRow().map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </>
              )} */}
              {row.ResponseType === 1 && (
                <>
                  <input
                    type='text'
                    size='sm'
                    value={row.Answer}
                    name={row.RowId}
                    className={`form-control form-control-sm`}
                    onChange={(event) =>
                      handleDropdownChange(row.RowId, event.target.value)
                    }
                    placeholder={row.Question}
                    maxLength={100}
                  />
                </>
              )}
              {row.ResponseType === 2 && (
                <>
                  <MultiSelectDDL
                    FCMType={FCMType}
                    type={row.RowId}
                    closeMenuOnSelect={false}
                    value={row.Answer}
                    onChange={(e) => handleDropdownChange(row.RowId, e)}
                  />{' '}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
