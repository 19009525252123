import React, { createContext, useContext, useState, useEffect } from 'react'
import { Variables } from '../Variables'
import axios from 'axios'
import { VisitProvider } from '../Provider/Visitprovider'

const TabContext = createContext()
export default TabContext

const apiUrl = Variables.USER_API_URL
const UserContext = createContext()

export function useUser() {
  const context = useContext(UserContext)
  if (!context) {
    console.log('useUser error')
    //throw new Error('');
  }
  return context
}

export function UserProvider({ children }) {
  const VisitId = useContext(VisitProvider)
  const [userFirstName, setUserFirstName] = useState('')
  const [userDetails, setUserDetails] = useState(null)

  const fetchData = async () => {
    if (VisitId) {
      try {
        // alert(VisitId)
        const response = await axios.get(
          apiUrl + 'Client/GetVisitDetailForClinicalNotes?visitId=' + VisitId,
        )
        const data = response.data
        //alert(JSON.stringify(data))
        if (data) {
          const clientData = await axios.get(
            apiUrl + 'Client/GetClientById?clientID=' + data.Clientid,
          )
          const dataClient = clientData.data.Result
          //alert(JSON.stringify(dataClient))
          data.BrandName = dataClient.BrandName
          data.BrandCode = dataClient.BrandCode
          data.BrandLogo = dataClient.BrandLogo
        }

        //alert(VisitId)
        if (!data) {
          alert('This VisitID=' + VisitId + ' does not exist.')
          return
        }

        // Capitalize FullName
        if (data && data.FullName) {
          const formattedFullName =
            data.FullName.charAt(0).toUpperCase() +
            data.FullName.slice(1).toLowerCase()
          // Assign to userDetails
          data.FullName = formattedFullName
        }

        // Ensure Age is a number and assign to userDetails
        if (data && data.Age && !isNaN(data.Age)) {
          data.Age = parseInt(data.Age, 10)
        }
        //alert(JSON.stringify(data))
        setUserDetails(data)

        if (data && data.FirstName) {
          const formattedFirstName =
            data.FirstName.charAt(0).toUpperCase() +
            data.FirstName.slice(1).toLowerCase()
          setUserFirstName(formattedFirstName)
        }
      } catch (error) {
        console.log('Error fetching user details:', error)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [VisitId])

  const userContextValue = {
    userFirstName,
    userDetails,
  }

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  )
}
