import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { DDList } from '../Controls/DDList'
import axios from 'axios'
import { Variables } from '../Variables'
import Loading from '../Controls/Loading'

const apiUrl = Variables.API_URL

export const GTDefault = ({
  VisitId,
  onRowSelected,
  IsrefreshGoal,
  fcmType,
  CreatedBy = null,
  IsChangeTracking = false,
}) => {
  const [rows, setRows] = useState([
    {
      GoalId: 0,
      VisitId: VisitId,
      GoalType: 'LT',
      FCMType: fcmType,
      Duration: 6,
      Units: 'mth(s)',
      GoalCode: 'LTG',
      Accuracy: '0-25% of the time',
      GoalDescription: '',
      RowId: 1,
      CreatedBy: CreatedBy,
    },
  ])

  const [loading, setLoading] = useState(true) // Initialize loading state
  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${apiUrl}SpeechInt/GetLTGoal/${VisitId}/${fcmType}`,
      )
      if (response.status === 200) {
        const data = response.data.Result
        const filteredRows = data.filter((item) => item.GoalType === 'LT')

        const updatedRows = filteredRows.map((item) => ({
          GoalCode: item.GoalCode || rows[0].GoalCode,
          GoalDescription: item.GoalDescription,
          Accuracy: item.Accuracy,
          VisitId: item.VisitId,
          GoalType: item.GoalType,
          RowId: item.RowId,
          GoalId: item.GoalId,
          FCMType: item.FCMType,
          Duration: item.Duration || rows[0].Duration,
          Units: item.Units || rows[0].Units,
          CreatedBy: CreatedBy,
          UpdatedBy: CreatedBy,
        }))

        setRows(updatedRows)
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      // Code to run regardless of success or failure
      setLoading(false)
    }
  }

  useEffect(() => {
    if (VisitId) {
      //fetchData();
      const timer = setTimeout(fetchData, 4000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [VisitId, IsrefreshGoal])

  useEffect(() => {
    onRowSelected(rows)
  }, [rows])

  const [dropdownOptions, setDropdownOptions] = useState([
    { value: 1, text: '0-25% of the time' },
    { value: 2, text: '26-49% of the time' },
    { value: 3, text: '50-75% of the time' },
    { value: 4, text: '76-90% of the time' },
    { value: 5, text: '91-100% of the time' },
  ])

  const handleDropdownChange = (rowId, columnName, value) => {
    const updatedRows = rows.map((row) => {
      if (row.RowId === rowId) {
        return { ...row, [columnName]: value }
      }
      return row
    })
    setRows(updatedRows)
    onRowSelected(updatedRows)
    IsChangeTracking(true)
    //alert(JSON.stringify(updatedRows));
  }

  const handleTextboxChange = (rowId, columnName, value) => {
    const updatedRows = rows.map((row) => {
      if (row.RowId === rowId) {
        return { ...row, [columnName]: value }
      }
      return row
    })
    setRows(updatedRows)
    onRowSelected(updatedRows)
    IsChangeTracking(true)
    // alert(JSON.stringify(updatedRows));
  }

  const getDropdownOptionsForRow = () => {
    return dropdownOptions
  }
  const handleOptionSelect = (option) => {
    //console.log('Selected option:', option);
  }
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Goal</th>
              <th>Within</th>
              <th>the client</th>
              <th>with &#123;&#125; accuracy</th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((row, index) => (
                <tr key={row.RowId}>
                  <td style={{ width: '5%' }}>{row.GoalCode}</td>
                  <td style={{ width: '12%' }}>
                    <Form>
                      <Form.Group as={Row}>
                        <Col sm={4}>
                          <input
                            type='text'
                            maxLength={2}
                            className='form-control form-control-sm'
                            value={row.Duration}
                            onChange={(e) =>
                              handleTextboxChange(
                                row.RowId,
                                'Duration',
                                e.target.value,
                              )
                            }
                          />
                        </Col>
                        <Col sm={8}>
                          <DDList
                            defaultValue={row.Units}
                            ddType={4}
                            onSelect={(e) =>
                              handleDropdownChange(row.RowId, 'Units', e)
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </td>
                  <td style={{ width: '72%' }}>{row.GoalDescription}</td>
                  <td style={{ width: '11%' }}>
                    <select
                      className='form-select form-select-sm'
                      value={row.Accuracy}
                      onChange={(event) =>
                        handleDropdownChange(
                          row.RowId,
                          'Accuracy',
                          event.target.value,
                        )
                      }
                    >
                      {getDropdownOptionsForRow(row.RowId).map((option) => (
                        <option key={option.text} value={option.text}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
