import React, { Component } from 'react'
import axios from 'axios'
import { Variables } from '../../Variables'
import { AssessSumService } from '../Component/AssessSumService'
import { NomsService } from '../Component/NomsService'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
const nomsService = new NomsService()

export class FluencyService {
  constructor() {
    this.FluencyId = 0
    this.VisitId = null
    this.FamilyHistory = 'No'
    this.FamilyHistoryDesc = null
    this.DysfluencyType = null
    this.PhysicalPresentations = null
    this.PhysicalPresentationsDesc = null
    this.Cluttering = null
    this.ClutteringDesc = null
    this.AssessmentObservations = null
    this.ImpactDailyActivities = null
    this.OverallRating = 'Within Normal Limits'
    this.IsSkilledTherapyIndicated = false
    this.Result = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []
      const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)
      if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
        reject(new Error('Invalid assessment Promise'))
        return
      }
      apiCalls.push(assessmentPromise)
      apiCalls.push(axios.post(apiUrl + 'FLU/InsertFLU', formData))
      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }
      Promise.all(apiCalls)
        .then((responses) => {
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  getGetAssSummary(VisitId) {
    return serviceAssessment
      .getGetAssSummary(VisitId, 'FLU')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getNomsData(VisitId) {
    return nomsService
      .getNomsData(VisitId, 'FLU')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  deleteAssSumData(summId) {
    return serviceAssessment
      .deleteAssSumData(summId)
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  getFLUData(VisitId) {
    return axios
      .get(apiUrl + 'FLU/GetFLU/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data) {
          this.FluencyId = data.FluencyId
          this.VisitId = data.VisitId
          this.FamilyHistory = data.FamilyHistory
          this.FamilyHistoryDesc = data.FamilyHistoryDesc
          this.DysfluencyType = data.DysfluencyType
          this.PhysicalPresentations = data.PhysicalPresentations
          this.PhysicalPresentationsDesc = data.PhysicalPresentationsDesc
          this.Cluttering = data.Cluttering
          this.ClutteringDesc = data.ClutteringDesc
          this.AssessmentObservations = data.AssessmentObservations
          this.ImpactDailyActivities = data.ImpactDailyActivities
          this.OverallRating = data.OverallRating
          this.IsSkilledTherapyIndicated = data.IsSkilledTherapyIndicated
          this.Result = data.Result
          this.CreatedBy = data.CreatedBy
          this.UpdatedBy = data.UpdatedBy
          this.CreatedDate = data.CreatedDate

          return this
        }
      })
      .catch((error) => console.log('Error getSpeechData data:', error))
  }
}
