import React, { useState, useEffect } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
//import { AACService } from '../FCM/AAC/AACService'
import { AACService } from '../FCM/AAC/AACService'
import CheckedBoxIcon from './Component/CheckBoxIcon'
import { UnCheckedBoxIcon } from './Component/CheckBoxIcon'
import {
  VerbalCommOptions,
  NonVerbalCommOptions,
  SocialInteractionOptions,
  PragVoiceOptions,
} from '../data/options'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const ContentAAC = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  HeaderFcm,
}) => {
  const [assSumdata, setAssSumdata] = useState([])
  const [rptData, setRptData] = useState(null)
  const [nomsData, setNomsData] = useState([])
  const service = new AACService()

  useEffect(() => {
    if (!VisitId) return

    const fetchData = async () => {
      try {
        const [assSummary, aacData, nomsData] = await Promise.all([
          service.getGetAssSummary(VisitId),
          service.getAACData(VisitId),
          service.getNomsData(VisitId),
        ])

        setAssSumdata(assSummary)
        if (aacData) {
          //alert(aacData.IsSkilledTherapyIndicated)
          setRptData(aacData)
        }
       
        setNomsData(nomsData)
      } catch (error) {
        console.log('Error fetching data AAC Report:', error)
      }
    }

    fetchData()
  }, [VisitId])

  // useEffect(() => {
  //   if (!VisitId) return
  //   service
  //     .getGetAssSummary(VisitId)
  //     .then((response) => {
  //       setAssSumdata(response)
  //       //alert(JSON.stringify(data));
  //     })
  //     .catch((error) => console.log('Error fetching data:', error))

  //   service
  //     .getAACData(VisitId)
  //     .then((response) => {
  //       //const data = response.data.Result
  //       //alert(JSON.stringify(response))
  //       if (response) {
  //         setRptData(response)
  //       }
  //     })
  //     .catch((error) => console.log('Error fetching data:', error))

  //     service
  //     .getNomsData(VisitId)
  //     .then((response) => {
  //       //alert(JSON.stringify(response));
  //       setNomsData(response);
  //     })
  //     .catch((error) => {
  //       //alert('Error fetching data: ' + error.message);
  //       console.log('Error fetching data:', error);
  //     });

  // }, [VisitId,FirstName])

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <>
      <View style={reportStyle.pageSpace}>
        {HeaderFcm === true && (
          <>
            <View style={reportStyle.TextHeaderTop}>
              <Text style={reportStyle.TextHeaderText}>
                Clinical Assessment
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName} was assessed against the following AHSA Functional
                Communication Measures (FCM):
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextHeaderText}>AAC</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Based on the assessment & observations outlined herein, I found{' '}
            {FirstName}'s overall deficit to be{' '}
            {rptData && rptData.OverallRating}, and I believe Skilled Speech
            Therapy{' '}
            {rptData && rptData.IsSkilledTherapyIndicated ? 'is not' : 'is'}{' '}
            indicated for AAC.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I scored the following assessments when evaluating {hisHer} current
            AAC proficiency:
          </Text>
        </View>
        <View style={reportStyle.container}>
          <View style={reportStyle.tblheaderRow}>
            <Text style={reportStyle.tbldataCellAssessment}>Assessment</Text>
            <Text style={reportStyle.tblheaderCell}>Raw Score</Text>
            <Text style={reportStyle.tblheaderCell}>Std Score</Text>
            <Text style={reportStyle.tblheaderCell}>%ile Rank</Text>
          </View>
          {assSumdata.map((row, index) => (
            <View style={reportStyle.tbldataRow} key={row.RowId}>
              <Text style={reportStyle.tbldataCellAssessment}>
                {row.AssessmentSummaryDesc}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Score}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Code}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Rank}
              </Text>
            </View>
          ))}
        </View>
        {/* <View style={reportStyle.paragraph}>
          <Text>
            {FirstName}{' '}
            {rptData && rptData.FamilyHistory === 'Yes'
              ? 'has a family history of AAC deficits'
              : 'does not have a family history of AAC deficits'}{' '}
            {hisHer}{' '}
            {rptData && rptData.FamilyHistoryDesc
              ? rptData.FamilyHistoryDesc
              : ''}
            .
          </Text>
        </View> */}

        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Current AAC Method:</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {rptData && rptData.CurrentMethod
              ? `${FirstName} is currently using ${rptData.CurrentMethod}`
              : `${FirstName} is not currently using an AAC method`}
            .
          </Text>
        </View>
        {rptData && rptData.CurrentMethodSuccess && (
          <View style={reportStyle.paragraph}>
            <Text>
              Success with Current Method:{' '}
              {rptData && rptData.CurrentMethodSuccess}
            </Text>
          </View>
        )}
        {rptData && rptData.CurrentMethodBarriers && (
          <View style={reportStyle.paragraph}>
            <Text>
              Barriers to Current Method:{' '}
              {rptData && rptData.CurrentMethodBarriers}
            </Text>
          </View>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>
            AAC Device Being Considered:
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {rptData && rptData.ConsideredDevice
              ? `${FirstName} is considering ${rptData.ConsideredDevice}`
              : `${FirstName} is not currently considering a new AAC device`}
            .
          </Text>
        </View>
        {rptData && rptData.OtherAACTrials && (
          <View style={reportStyle.paragraph}>
            <Text>Other Trials: {rptData.OtherAACTrials}</Text>
          </View>
        )}

        {rptData && rptData.ImpactDailyActivities && (
          <>
            <View style={reportStyle.TextHeader}>
              <Text style={reportStyle.TextSubHeader}>
                Impact on Daily Activities :{' '}
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>{rptData && rptData.ImpactDailyActivities}</Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Observations</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Overall, I found {FirstName}'s {rptData && rptData.Result}
          </Text>
        </View>
        {rptData?.IsSkilledTherapyIndicated === false && (
          <>
            {nomsData && nomsData.length > 0 && (
              <>
                <View style={reportStyle.paragraph}>
                  <Text>{FirstName} was able to:</Text>
                  <View style={[reportStyle.tblnoms, { paddingLeft: 10 }]}>
                    {nomsData.map((row, index) => (
                      <View style={reportStyle.dataRownoms} key={row.RowId}>
                        <Text style={[reportStyle.indexCell]}>
                          {index + 1})
                        </Text>
                        <Text style={[reportStyle.questionCell97]}>
                          {`${
                            row.Question &&
                            row.Question.replace(/\?/g, '')
                              .charAt(0)
                              .toUpperCase()
                          }${
                            row.Question.endsWith('?')
                              ? row.Question.slice(1, -1)
                              : row.Question.slice(1)
                          } ${row.Answer}.`}
                        </Text>
                      </View>
                    ))}
                  </View>{' '}
                </View>
              </>
            )}
          </>
        )}
      </View>
    </>
  )
}

export default ContentAAC
