import axios from 'axios'
import { Variables } from '../../Variables'

const apiUrl = Variables.API_URL

export class GoalsService {
  saveData(data) {
    return new Promise((resolve, reject) => {
      const apiCalls = []
      apiCalls.push(axios.post(apiUrl + 'SpeechInt/CreateGoal', data))
      if (apiCalls.length === 0) {
        console.error('No promises to resolve.')
        reject(new Error('No promises to resolve.'))
      }
      Promise.all(apiCalls)
        .then((responses) => {
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }
  deleteGoal = (goalId) => {
    axios
      .delete(`${apiUrl}SpeechInt/DeleteGoal/${goalId}`)
      .then((response) => {
        //console.log('Goal deleted successfully');
        // You can update state or perform other actions after deletion if needed
      })
      .catch((error) => {
        console.error('Error deleting goal:', error)
        // Handle error if necessary
      })
  }

  getGoalsData(VisitId, fcmType) {
    return axios
      .get(`${apiUrl}SpeechInt/GetGoal/${VisitId}/${fcmType}`)
      .then((response) => {
        const data = response.data.Result
//alert(JSON.stringify(data))
        //const filteredRows = data.filter((item) => item.GoalType === "General");

        const updatedRows = data?.map((item) => ({
          GoalCode: item.GoalCode,
          GoalDescription: item.GoalDescription,
          FCMType: item.FCMType,
          GoalCategory: item.GoalCategory,
          Accuracy: item.Accuracy,
          VisitId: item.VisitId,
          GoalType: item.GoalType,
          RowId: item.RowId,
          GoalId: item.GoalId,
          Duration: item.Duration,
          Units: item.Units,
          Given: item.Given,
          Sound: item.Sound,
          AtLevel: item.AtLevel,
          InSessions: item.InSessions,
          GoalMET: item.GoalMET,
          GoalMetDate: item.GoalMetDate,
          CreatedBy: item.CreatedBy,
          UpdatedBy: item.UpdatedBy,
        }))

        return updatedRows
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error for further handling, if needed
      })
  }

  //
  // axios
  // .get(apiUrl + "SpeechInt/GetGoal/" + VisitId)
  // .then((response) => {
  //   if (response.status === 200) {
  //     const data = response.data.Result;
  //     const goalTypesCsv = data.map((item) => item.GoalType).join(", ");
  //     const filterText = goalTypesCsv.replace(/ /g, "");
  //     // alert(filterText);
  //     setSelectedValues(filterText);
  //   }
  // })
  // .catch((error) => {
  //   console.log(error);
  // })
  // .finally(() => {
  //   //alert(refreshGoal);
  // });
}
