import React, { useState, useEffect } from 'react'
export const SoundOption1 = [
  {
    id: 1,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'p',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 2,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'm',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 3,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'n',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 4,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'w',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 5,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'h',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 6,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'b',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 7,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'g',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 8,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'k',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 9,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'd',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 10,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'f',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 11,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'ŋ',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 12,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'j',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 13,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'ʃ',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 14,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 't',
    Initial: false,
    Medial: false,
    Final: false,
  },
]
export const SoundOption2 = [
  {
    id: 15,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'tʃ',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 16,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'l',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 17,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'ʤ',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 18,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'θ',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 29,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'v',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 20,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 's',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 21,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'z',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 22,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'δ',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 23,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'r',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 24,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'L-blends',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 25,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'R-blends',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 26,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'S-blends',
    Initial: false,
    Medial: false,
    Final: false,
  },
]
const spanishSoundItems = [
  {
    id: 27,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'ñ',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 28,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'll',
    Initial: false,
    Medial: false,
    Final: false,
  },
  {
    id: 29,
    VisitId: 0,
    SI_SoundAssessmentId: 0,
    Sound: 'rr',
    Initial: false,
    Medial: false,
    Final: false,
  },
]

export const allSoundOptions = (userLanguage) => {
  //alert(userLanguage);
  if (userLanguage === 'Spanish') {
    return [...SoundOption1, ...SoundOption2, ...spanishSoundItems]
  } else {
    return [...SoundOption1, ...SoundOption2]
  }
}

export const SoundGrid = ({
  userLanguage,
  SoundGroup,
  data,
  onDataChange,
  isChangeTracked = false,
}) => {
  const [gridData, setGridData] = useState([])

  useEffect(() => {
    let initialData = []

    if (data && data.length > 0) {
      if (SoundGroup === '1') {
        initialData = SoundOption1.map((soundOption) => {
          const matchingData = data.find(
            (item) => item.Sound === soundOption.Sound,
          )
          if (matchingData) {
            return {
              ...soundOption, // Create a shallow copy of the soundOption
              SI_SoundAssessmentId: matchingData.SI_SoundAssessmentId,
              VisitId: matchingData.VisitId,
              Initial: Boolean(matchingData.Initial),
              Medial: Boolean(matchingData.Medial),
              Final: Boolean(matchingData.Final),
            }
          }
          // If there is no matching data in 'data' for the current soundOption, return the original soundOption
          return soundOption
        })
      }
      if (SoundGroup === '2') {
        let mergeSounds = []
        //alert(userLanguage);
        if (userLanguage === 'Spanish') {
          //alert(userLanguage);
          mergeSounds = [...SoundOption2, ...spanishSoundItems]
        } else {
          mergeSounds = SoundOption2
        }
        // alert(JSON.stringify(mergeSounds));

        initialData = mergeSounds.map((soundOption) => {
          const matchingData = data.find(
            (item) => item.Sound === soundOption.Sound,
          )
          if (matchingData) {
            return {
              ...soundOption, // Create a shallow copy of the soundOption
              SI_SoundAssessmentId: matchingData.SI_SoundAssessmentId,
              VisitId: matchingData.VisitId,
              Initial: Boolean(matchingData.Initial),
              Medial: Boolean(matchingData.Medial),
              Final: Boolean(matchingData.Final),
            }
          }
          // If there is no matching data in 'data' for the current soundOption, return the original soundOption
          return soundOption //[...soundOption, ...spanishSoundItems];
        })
      }
      isChangeTracked(false)
      //console.log("dataSG : "+JSON.stringify(data));
    } else {
      // Otherwise, set the 'initialData' based on 'SoundGroup'
      if (SoundGroup === '1') {
        initialData = SoundOption1
      } else if (SoundGroup === '2') {
        if (userLanguage === 'spanish') {
          //alert(userLanguage);
          initialData = [...SoundOption2, ...spanishSoundItems]
        } else {
          initialData = SoundOption2
        }
      }
    }

    setGridData(initialData)
  }, [data, SoundGroup])

  const handleCheckboxChange = (sound, position) => {
    const updatedData = gridData.map((item) => {
      if (item.Sound === sound) {
        return {
          ...item,
          [position]: !item[position],
        }
      }
      return item
    })
    isChangeTracked(true)
    setGridData(updatedData)
  }

  useEffect(() => {
    // Notify the parent component about the updated data
    onDataChange(gridData)
  }, [gridData, onDataChange])

  return (
    <div>
      <table className='table'>
        <thead>
          <tr>
            <th>Sound</th>
            <th>Initial</th>
            <th>Medial</th>
            <th>Final</th>
          </tr>
        </thead>
        <tbody>
          {gridData.map((item) => (
            <tr key={item.Sound}>
              <td>
                <b>{item.Sound}</b>
              </td>
              <td>
                <input
                  type='checkbox'
                  checked={item.Initial}
                  onChange={() => handleCheckboxChange(item.Sound, 'Initial')}
                />
              </td>
              <td>
                <input
                  type='checkbox'
                  checked={item.Medial}
                  onChange={() => handleCheckboxChange(item.Sound, 'Medial')}
                />
              </td>
              <td>
                <input
                  type='checkbox'
                  checked={item.Final}
                  onChange={() => handleCheckboxChange(item.Sound, 'Final')}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
