import React, { Component } from 'react'
import axios from 'axios'
import { Variables } from '../../Variables'
import { AssessSumService } from '../Component/AssessSumService'
import { NomsService } from '../Component/NomsService'

const apiUrl = Variables.API_URL
const serviceAssessment = new AssessSumService()
const nomsService = new NomsService()

export class PragmaticService {
  constructor() {
    this.PragmaticId = 0
    this.VisitId = null
    this.VerbalComm = null
    this.VerbalCommDesc = null
    this.NonVerbalComm = null
    this.NonVerbalCommDesc = null
    //this.PhysicalPresentationsDesc = null
    this.SocialInteraction = null
    this.SocialInteractionDesc = null
    this.Voice = null
    this.VoiceDesc = null
    this.ImpactDailyActivities = null
    this.AssessmentObservations = null
    this.OverallRating = 'Within Normal Limits'
    this.IsSkilledTherapyIndicated = false
    //this.Result = 'Complex'
    this.Result = null
    this.CreatedBy = 0
    this.UpdatedBy = 0
    this.CreatedDate = null
  }

  saveData(updateAssmentSummary, formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []

      // Assuming serviceAssessment.saveData returns a Promise
      const assessmentPromise = serviceAssessment.saveData(updateAssmentSummary)

      // Assuming serviceAssessment.saveData returns a valid axios post configuration
      if (!assessmentPromise || typeof assessmentPromise.then !== 'function') {
        reject(new Error('Invalid assessment Promise'))
        return
      }

      // Push the assessment promise to the apiCalls array
      apiCalls.push(assessmentPromise)

      // Push the second axios.post call to the apiCalls array
      apiCalls.push(axios.post(apiUrl + 'PRAG/InsertPRAG', formData))

      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }

      // Use Promise.all to wait for both promises to resolve
      Promise.all(apiCalls)
        .then((responses) => {
          // alert(JSON.stringify(responses))
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  getGetAssSummary(VisitId) {
    return serviceAssessment
      .getGetAssSummary(VisitId, 'PRAG')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  getNomsData(VisitId) {
    return nomsService
      .getNomsData(VisitId, 'PRAG')
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }
  deleteAssSumData(summId) {
    return serviceAssessment
      .deleteAssSumData(summId)
      .then((data) => {
        //console.log("Data received:", data);
        return data
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  getPRAGData(VisitId) {
    return axios
      .get(apiUrl + 'PRAG/GetPRAG/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data) {
          //alert(JSON.stringify(data));
          this.PragmaticId = data.PragmaticId
          this.VisitId = data.VisitId
          this.VerbalComm = data.VerbalComm
          this.VerbalCommDesc = data.VerbalCommDesc
          this.NonVerbalComm = data.NonVerbalComm
          this.NonVerbalCommDesc = data.NonVerbalCommDesc
          this.SocialInteraction = data.SocialInteraction
          this.SocialInteractionDesc = data.SocialInteractionDesc
          this.Voice = data.Voice
          this.VoiceDesc = data.VoiceDesc
          this.ImpactDailyActivities = data.ImpactDailyActivities
          this.AssessmentObservations = data.AssessmentObservations
          this.OverallRating = data.OverallRating
          this.IsSkilledTherapyIndicated = data.IsSkilledTherapyIndicated
          this.Result = data.Result
          this.CreatedBy = data.CreatedBy
          this.UpdatedBy = data.UpdatedBy
          this.CreatedDate = data.CreatedDate

          return this
        }
      })
      .catch((error) => console.log('Error getSpeechData data:', error))
  }
}
