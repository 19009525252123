import axios from 'axios'
import { Variables } from '../../Variables'

const API_URL = Variables.API_URL // Replace with your actual API URL
const CORE_API_URL = Variables.USER_API_URL // Replace with your actual API URL
class SignLockService {
  // Properties
  SignLockId = 0
  VisitId = 0
  ClientId = 0
  VisitTypeId = ''
  TherepistId = 0
  VisitStartDateTime = null
  VisitendDateTime = null
  IsSignedAndLocked = false
  SignAndLockDate = null
  SignedBy = null
  HasNomsBasedNotes = true
  IsProgressNote = false
  NoteType = 0

  async getSignLockData(visitId) {
    try {
      const response = await axios.get(
        `${API_URL}SignLock/GetIsLockDetails/${visitId}`,
      )
      const dataFromResponse = response.data.Result
      if (dataFromResponse) {
        // Update class properties with fetched data
        this.SignLockId = dataFromResponse.SignLockId
        this.VisitId = dataFromResponse.VisitId
        this.ClientId = dataFromResponse.ClientId
        this.VisitTypeId = dataFromResponse.VisitTypeId
        this.VisitStartDateTime = dataFromResponse.VisitStartDateTime
        this.VisitendDateTime = dataFromResponse.VisitendDateTime
        this.IsSignedAndLocked = dataFromResponse.IsSignedAndLocked
        this.SignAndLockDate = dataFromResponse.SignAndLockDate
        this.IsProgressNote = dataFromResponse.IsProgressNote
        this.NoteType = dataFromResponse.NoteType
        // this.SignedBy = dataFromResponse.SignedBy;
        const classProperties = {
          SignLockId: this.SignLockId,
          VisitId: this.VisitId,
          ClientId: this.ClientId,
          VisitTypeId: this.VisitTypeId,
          VisitStartDateTime: this.VisitStartDateTime,
          VisitendDateTime: this.VisitendDateTime,
          IsSignedAndLocked: this.IsSignedAndLocked,
          SignAndLockDate: this.SignAndLockDate,
          IsProgressNote: this.IsProgressNote,
          NoteType: this.NoteType,
          // SignedBy: this.SignedBy, // Uncomment this if needed
        }
        //alert("api response:" + JSON.stringify(dataFromResponse));
        return classProperties
      }
    } catch (error) {
      console.error('Error fetching getSignLockData data:', error)
      throw error
    }
  }

  async getSignLockDataByNoteType(visitId, noteType) {
    try {
      const response = await axios.get(
        `${API_URL}SignLock/GetIsLockDetailByNoteType/${visitId}/${noteType}`,
      )
      const dataFromResponse = response.data.Result
      if (dataFromResponse) {
        // Update class properties with fetched data
        this.SignLockId = dataFromResponse.SignLockId
        this.VisitId = dataFromResponse.VisitId
        this.ClientId = dataFromResponse.ClientId
        this.VisitTypeId = dataFromResponse.VisitTypeId
        this.VisitStartDateTime = dataFromResponse.VisitStartDateTime
        this.VisitendDateTime = dataFromResponse.VisitendDateTime
        this.IsSignedAndLocked = dataFromResponse.IsSignedAndLocked
        this.SignAndLockDate = dataFromResponse.SignAndLockDate
        this.IsProgressNote = dataFromResponse.IsProgressNote
        this.NoteType = dataFromResponse.NoteType
        // this.SignedBy = dataFromResponse.SignedBy;
        const classProperties = {
          SignLockId: this.SignLockId,
          VisitId: this.VisitId,
          ClientId: this.ClientId,
          VisitTypeId: this.VisitTypeId,
          VisitStartDateTime: this.VisitStartDateTime,
          VisitendDateTime: this.VisitendDateTime,
          IsSignedAndLocked: this.IsSignedAndLocked,
          SignAndLockDate: this.SignAndLockDate,
          IsProgressNote: this.IsProgressNote,
          NoteType: this.NoteType,
          // SignedBy: this.SignedBy, // Uncomment this if needed
        }
        //alert("api response:" + JSON.stringify(dataFromResponse));
        return classProperties
      }
    } catch (error) {
      console.error('Error fetching getSignLockDataByNoteType data:', error)
      throw error
    }
  }

  async getAssessmentList(clientId) {
    try {
      const response = await axios.get(
        `${API_URL}SignLock/GetAssessmentList/${clientId}`,
        { timeout: 10000 },
      )
      const dataFromResponse = response.data.Result
      if (dataFromResponse) {
        const classPropertiesList = dataFromResponse.map((item) => ({
          value: item.VisitId,
          text: item.Assessment,
          visitTypeId: item.VisitTypeId,
          isProgressNote: item.IsProgressNote,
        }))
        //console.log(classPropertiesList);
        return classPropertiesList
      }
    } catch (error) {
      console.error('Error fetching getAssessmentList data:', error)
      throw error
    }
  }
  getAuthorizedSLPBillingSoapNoteCPTCodes(VisitId) {
    /// alert(VisitId);

    //let authId = 124172;
    let authId =0;
    return this.GetVisitDetail(VisitId)
      .then((dataFromResponse) => {
      authId = dataFromResponse.AuthId
        //alert(JSON.stringify(dataFromResponse));
        return axios.get(
          `${CORE_API_URL}Episode/GetAuthorizedCPTCodes?authId=${authId}`,
        )
      })
      .then((response) => {
        const dataFromResponse2 = response.data
        //alert(JSON.stringify(dataFromResponse2));
        return dataFromResponse2.ListResult
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  GetSLPBilling(VisitId) {
    /// alert(VisitId);

    let payorId = 0
    let treatmentTypeID = 0
    let VisitType = 0

    return this.GetVisitDetail(VisitId)
      .then((dataFromResponse) => {
        payorId = dataFromResponse.PayorId
        treatmentTypeID = dataFromResponse.TreatmentTypeId
        VisitType = dataFromResponse.VisitTypeId

        //alert(`${payorId}-${treatmentTypeID}-${VisitType}`);

        return axios.get(
          `${CORE_API_URL}SLPBilling/GetPayorCPTCode/?PayorId=${payorId}&treatmentTypeID=${treatmentTypeID}&VisitType=${VisitType}&IsSplintCodes=false`,
        )
      })
      .then((response) => {
        const dataFromResponse2 = response.data
        //alert(JSON.stringify(dataFromResponse2));
        return dataFromResponse2.ListResult
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        throw error
      })
  }

  GetVisitByID(visitId) {
    //alert(JSON.stringify(clientId))
    return axios
      .get(`${CORE_API_URL}Visit/GetVisitByID?id=${visitId}`)
      .then((response) => {
        const dataFromResponse = response.data
        //alert(JSON.stringify(dataFromResponse))
        return dataFromResponse
      })
      .catch((error) => {
        console.error('Error fetching GetVisitByID data:', error)
        throw error
      })
  }

  GetVisitDetail(visitId) {
    //alert(JSON.stringify(clientId))
    return axios
      .get(`${CORE_API_URL}Visit/GetVisitDetail/?visitId=${visitId}`)
      .then((response) => {
        const dataFromResponse = response.data
        //alert(JSON.stringify(dataFromResponse))
        //if (dataFromResponse) {
        // const classPropertiesList = dataFromResponse.map((item) => ({
        //   line: item.ServiceLine,
        // }))
        // alert(JSON.stringify(classPropertiesList))
        //console.log(classPropertiesList);
        //}
        //alert(JSON.stringify(dataFromResponse))
        return dataFromResponse
      })
      .catch((error) => {
        console.error('Error fetching GetVisitDetail data:', error)
        throw error
      })
  }

  async GetClientServiceLines(clientId) {
    //alert(JSON.stringify(clientId))
    try {
      const response = await axios.get(
        `${CORE_API_URL}Episode/GetClientServiceLines/?clientId=${clientId}`,
      )
      const dataFromResponse = response.data
      //alert(JSON.stringify(dataFromResponse))
      //if (dataFromResponse) {
      // const classPropertiesList = dataFromResponse.map((item) => ({
      //   line: item.ServiceLine,
      // }))
      // alert(JSON.stringify(classPropertiesList))
      //console.log(classPropertiesList);
      //}
      //alert(JSON.stringify(dataFromResponse))
      return dataFromResponse
    } catch (error) {
      console.error('Error fetching GetClientServiceLines data:', error)
      throw error
    }
  }

  // Function to insert SignLock data
  async insertSignLockData(formData) {
    try {
      const response = await axios.post(
        `${API_URL}SignLock/InsertSingLock`,
        formData,
      )
      // alert(response.data);
      return response.data
    } catch (error) {
      console.error('Error inserting SignLock data:', error)
      throw error
    }
  }
  // Function to insert SignLock data
  insertSignLockDataSLPCore(formData) {
    return axios
      .post(`${CORE_API_URL}Visit/SignAndLockVisit`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting SignLock data:', error)
        throw error
      })
  }
  // async insertSignLockDataSLPCore(formData) {
  //   try {
  //     const response = await axios.post(
  //       `${CORE_API_URL}Visit/SignAndLockVisit`,
  //       formData,
  //     )
  //     return response.data
  //   } catch (error) {
  //     console.error('Error inserting SignLock data:', error)
  //     throw error
  //   }
  // }

  async insertSaveClientSetup(formData) {
    try {
      const response = await axios.post(
        `${CORE_API_URL}Client/SaveClientSetup`,
        formData,
      )
      return response.data
    } catch (error) {
      console.error('Error SaveClientSetup data:', error)
      throw error
    }
  }

  async UpdateClientQueueFromNotes(formData) {
    try {
      const response = await axios.post(
        `${CORE_API_URL}Visit/UpdateClientQueueFromNotes`,
        formData,
      )
      return response.data
    } catch (error) {
      console.error('Error SaveClientSetup data:', error)
      throw error
    }
  }

  async SaveLog(formData) {
    try {
      const response = await axios.post(
        `${CORE_API_URL}Log/SaveLog`,
        formData,
      )
      return response.data
    } catch (error) {
      console.error('Error SaveLog data:', error)
      throw error
    }
  }


  async DischargeClient(visitid, therapistId, dateDischarge) {
    //alert(Client/DischargeClient?visitid=${visitid}&dischargedBy=${therapistId}&dischargeDate=${dateDischarge}&dateToContactPotentialClient={null}`);
    // console.log(
    //   `${CORE_API_URL}Client/DischargeClient?visitid=${encodeURIComponent(
    //     visitid,
    //   )}&dischargedBy=${encodeURIComponent(
    //     therapistId,
    //   )}&dischargeDate=${encodeURIComponent(
    //     dateDischarge,
    //   )}&dateToContactPotentialClient=${null}&NextAnnualCheckupDate=${null}`,
    // )
    try {
      const response = await axios.get(
        `${CORE_API_URL}Client/DischargeClient?visitid=${encodeURIComponent(
          visitid,
        )}&dischargedBy=${encodeURIComponent(
          therapistId,
        )}&dischargeDate=${encodeURIComponent(
          dateDischarge,
        )}&dateToContactPotentialClient=${null}&NextAnnualCheckupDate=${null}`,
      )
      const dataFromResponse = response.data.Result
      return dataFromResponse;
      // alert(JSON.stringify(dataFromResponse))
     
    } catch (error) {
      console.error('Error fetching DischargeClient data:', error)
      throw error
    }
  }
}

// Export an instance of the SignLockService class
const signLockService = new SignLockService()
export default signLockService
