import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Delete, Add } from '../Icon/DeleteIcon'

export const SPSSumm = ({
  onRowAdded,
  onRowDeleted,
  onUpdatedRows,
  apiData,
  FCMType,
  isChangeTracked = false,
  VisitId = 0,
}) => {
  const [rows, setRows] = useState([
    {
      RowId: 1,
      AssessmentSummaryId: 0,
      VisitId: VisitId,
      FCMType: FCMType,
      AssessmentSummaryDesc: '',
      AssessmentSummary_Code: '',
      AssessmentSummary_Rank: '',
    },
  ])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let initialData = []
    // Check if apiData is not undefined before using it
    if (apiData && apiData.length > 0) {
      //alert('apidata:' + JSON.stringify(apiData))
      initialData = apiData.map((assOption) => {
        // const matchingData = rows.find((item) => item.id === soundOption.id);
        // if (matchingData) {
        return {
          ...assOption, // Create a shallow copy of the soundOption
          SoundAssessmentId: assOption.SoundAssessmentId,
          RowId: assOption.RowId,
          VisitId: assOption.VisitId,
          FCMType: assOption.FCMType,
          AssessmentSummaryDesc: assOption.AssessmentSummaryDesc,
          AssessmentSummary_Code: assOption.AssessmentSummary_Code,
          AssessmentSummary_Rank: assOption.AssessmentSummary_Rank,
        }
        //}
        // If there is no matching data in 'data' for the current soundOption, return the original soundOption
        //return soundOption;
      })
      //alert("hidaat"+JSON.stringify(initialData));
      setRows(initialData)
      setLoading(false)
    } else {
      //alert('hidaat' + JSON.stringify(rows))
      //onUpdatedRows(rows)
      setRows(rows)
      setLoading(false)
    }
    //alert("uiu");
    // alert("hidaat"+JSON.stringify(initialData));
    // console.log("dtaaspsp:"+JSON.stringify(initialData));
  }, [apiData])

  const maxRowId = rows.reduce((maxId, row) => {
    return Math.max(maxId, row.RowId)
  }, 0)

  const handleAddRow = () => {
    const newRow = {
      RowId: maxRowId + 1,
      AssessmentSummaryId: 0,
      VisitId: VisitId,
      FCMType: FCMType,
      AssessmentSummaryDesc: '',
      AssessmentSummary_Score: '',
      AssessmentSummary_Code: '',
      AssessmentSummary_Rank: '',
    }
    setRows((prevRows) => [...prevRows, newRow])
    onRowAdded(newRow) // Notify the parent about the added row
    isChangeTracked(true)
  }

  const handleDeleteRow = (id) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this row?',
    )

    if (confirmed) {
      const deletedRow = rows.find((row) => row.RowId === id)

      const updatedRows = rows.filter((row) => row.RowId !== id)
      //alert(JSON.stringify(updatedRows));
      //return;
      setRows(updatedRows)
      onUpdatedRows(updatedRows)
      onRowDeleted(deletedRow) // Notify the parent about the deleted row
      isChangeTracked(true)
    }
  }

  const handleTextboxChange = (rowId, columnName, value, isNumeric) => {
    const valueFilter = value

    // Allow only numeric characters (0-9) and limit to 3 characters
    const getvalue = isNumeric
      ? valueFilter.replace(/[^0-9]/g, '')
      : valueFilter
    //alert(columnName)
    const updatedRows = rows.map((row) => {
      if (row.RowId === rowId) {
        return { ...row, [columnName]: getvalue }
      }
      return row
    })
    setRows(updatedRows)
    onUpdatedRows(updatedRows)
    isChangeTracked(true)
  }

  return (
    <div>
      {loading ? (
        'Loading...'
      ) : (
        <table className='table '>
          <thead>
            <tr>
              <th>Assessment</th>
              <th>Raw Score</th>
              <th>Std Score</th>
              <th>%ile Rank</th>
              <th>
                <button onClick={handleAddRow} title='Add new record'>
                  <Add></Add>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={row.RowId}>
                <td style={{ width: '55%' }}>
                  <Form>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <input
                          type='text'
                          maxLength={100}
                          className='form-control form-control-sm'
                          value={row.AssessmentSummaryDesc}
                          onChange={(e) =>
                            handleTextboxChange(
                              row.RowId,
                              'AssessmentSummaryDesc',
                              e.target.value,
                              false,
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </td>
                <td style={{ width: '15%' }}>
                  <input
                    type='text'
                    maxLength={8}
                    className='form-control form-control-sm'
                    value={row.AssessmentSummary_Score}
                    onChange={(e) =>
                      handleTextboxChange(
                        row.RowId,
                        'AssessmentSummary_Score',
                        e.target.value,
                        false,
                      )
                    }
                  />
                </td>
                <td style={{ width: '15%' }}>
                  <input
                    type='text'
                    maxLength={8}
                    className='form-control form-control-sm'
                    value={row.AssessmentSummary_Code}
                    onChange={(e) =>
                      handleTextboxChange(
                        row.RowId,
                        'AssessmentSummary_Code',
                        e.target.value,
                        false,
                      )
                    }
                  />
                </td>
                <td style={{ width: '15%' }}>
                  <input
                    type='text'
                    maxLength={8}
                    className='form-control form-control-sm'
                    value={row.AssessmentSummary_Rank}
                    onChange={(e) =>
                      handleTextboxChange(
                        row.RowId,
                        'AssessmentSummary_Rank',
                        e.target.value,
                        false,
                      )
                    }
                  />
                </td>
                <td>
                  <button onClick={() => handleDeleteRow(row.RowId)}>
                    <Delete />
                  </button>
                  {/* {index === rows.length - 1 && rows.length < 5 ? (
                  <button onClick={handleAddRow}>
                    <Add></Add>
                  </button>
                ) : (
                  <button onClick={() => handleDeleteRow(row.RowId)}>
                    <Delete />
                  </button>
                )} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

// const AssementSummaryView = (apiData) => {
//   const [rows, setRows] = useState([
//     {
//       RowId: 1,
//       AssessmentSummaryId: 0,
//       VisitId: 0,
//       AssessmentSummaryDesc: '',
//       AssessmentSummary_Code: '',
//       AssessmentSummary_Rank: '',
//     },
//   ])
//   useEffect(() => {
//     let initialData = []
//     if (apiData && apiData.length > 0) {
//       initialData = apiData.map((assOption) => {
//         return {
//           ...assOption,
//           SoundAssessmentId: assOption.SoundAssessmentId,
//           RowId: assOption.RowId,
//           VisitId: assOption.VisitId,
//           AssessmentSummaryDesc: assOption.AssessmentSummaryDesc,
//           AssessmentSummary_Code: assOption.AssessmentSummary_Code,
//           AssessmentSummary_Rank: assOption.AssessmentSummary_Rank,
//         }
//       })
//       setRows(initialData)
//     } else {
//       setRows(rows)
//     }
//   }, [apiData])

//   return (
//     <table className='table '>
//       <thead>
//         <tr>
//           <th>Assessment</th>
//           <th>Raw Score</th>
//           <th>Standard Score</th>
//           <th>%File rank</th>
//         </tr>
//       </thead>
//       <tbody>
//         {rows.map((row, index) => (
//           <tr key={row.RowId}>
//             <td style={{ width: '55%' }}>
//               <Form>
//                 <Form.Group as={Row}>
//                   <Col sm={12}>{row.AssessmentSummaryDesc}</Col>
//                 </Form.Group>
//               </Form>
//             </td>
//             <td style={{ width: '15%' }}>{row.AssessmentSummary_Score}</td>
//             <td style={{ width: '15%' }}>{row.AssessmentSummary_Code}</td>
//             <td style={{ width: '15%' }}>{row.AssessmentSummary_Rank}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   )
// }

// export default AssementSummaryView
