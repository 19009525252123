import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
import formatDate from '../Utility/Utility'

const ReportFooter = (chcGeneral) => {
  const currentDate = new Date()
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`

  return (
    <View
      style={{
        ...reportStyle.footer,
        fontSize: '10px',
        position: 'absolute',
        bottom: 20,
      }}
      fixed
    >
      <Text style={reportStyle.footerTextLeft}>
        All Care Therapies ((T) 877-757-8353 (F) 877-753-3009)
      </Text>
      <Text
        style={reportStyle.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
      <Text style={reportStyle.footerTextRight} fixed>
        Created Date: {formattedDate}
      </Text>
    </View>
  )
}

export default ReportFooter
