import React, { useState, useEffect, useContext } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { DDList } from '../../Controls/DDList'
import Container from 'react-bootstrap/Container'
import { CheckboxListInColumn, Checkbox } from '../../Controls/CheckBoxList'
import { TextareaComponent, TextBox } from '../../Controls/TextareaComponent '
import { LanguageComService } from './LanguageComService'
import { VisitProvider, SignLockProvider } from '../../Provider/Visitprovider'
import PercentageSlider from '../../Controls/PercentageSlider'
import { SelectYesNo } from '../../Controls/DDLYesNo'
import { useUser } from '../../Provider/UserContext'
import { CreatedByProvider } from '../../Provider/Visitprovider'
import { toast } from 'react-toastify'
import { SPSSumm } from '../../SpeechInt/SPSumm'
import Loading from '../../Controls/Loading'

export const LanguageComUI = React.forwardRef(
  ({ onDataFetched, triggerRefresh }, ref) => {
    const VisitId = useContext(VisitProvider)
    const { userDetails } = useUser()
    const service = new LanguageComService()
    const [formData, setFormData] = useState(new LanguageComService())
    const [consolidatedRows, setConsolidatedRows] = useState([])
    const IsSignLock = useContext(SignLockProvider)
    const CreatedBy = useContext(CreatedByProvider)
    const [showErrorOther, setshowErrorOther] = useState(false)
    const [ResultError, setResultError] = useState(false)
    const [StrengthsWeaknessesError, setStrengthsWeaknessesError] =
      useState(false)
    const [loading, setLoading] = useState(true)
    const [isDirty, setIsDirty] = useState(false)

    React.useImperativeHandle(ref, () => ({
      handleFormSubmit,
    }))

    useEffect(() => {
      if (userDetails) {
        formData.VisitId = VisitId
        formData.CreatedBy = CreatedBy
        formData.UpdatedBy = CreatedBy
        //alert(JSON.stringify(formData));
      }
    }, [formData, userDetails, VisitId, CreatedBy])

    const handleTextBoxValueChange = (controlName, value) => {
      const valueControl = controlName
      const updatedFormData = {
        ...formData,
        [valueControl]: value,
      }
      setFormData(updatedFormData)
      //setIsDirty(true)
    }
    const ddlChange = (selectedValue, dropdownName) => {
      const valueControl = dropdownName
      const updatedFormData = {
        ...formData,
        [valueControl]: selectedValue,
      }
      //alert(valueControl);
      setFormData(updatedFormData)
      setIsDirty(true)
    }

    const handleCheckboxChange = (columnName, checked) => {
      const updatedFormData = { ...formData, [columnName]: checked }
      setFormData(updatedFormData)
      if (columnName === 'SkilledTherapyIndicated') {
        triggerRefresh(checked)
      }
      setIsDirty(true)
    }
    const ValidationObject = () => {
      let isValid = true

      if (formData.LanguageTested === 'Other') {
        if (
          formData.LanguageOther === null //|| formData.LanguageOther.trim() === ""
        ) {
          setshowErrorOther(true)
          isValid = false
        }
      }
      if (
        formData.StrengthsWeaknesses === null // || formData.Result.trim() === ""
      ) {
        setStrengthsWeaknessesError(true)
        isValid = false
      }
      if (
        formData.Result === null // || formData.Result.trim() === ""
      ) {
        setResultError(true)
        isValid = false
      }

      return isValid
    }
    const handleFormSubmit = (showMsg = true) => {
      //alert(isDirty)
      if (IsSignLock || !isDirty) {
        //alert(isDirty)
        return
      }
      // const isValid = ValidationObject();
      // if (!isValid) {
      //   toast.error("Kindly fill the all required field(s).");
      //   return;
      // }
      //formData.VisitId = VisitId;
      const updateAssmentSummary = consolidatedRows.map((item) => {
        if (item.hasOwnProperty('VisitId')) {
          if (item.VisitId === 0) {
            item.VisitId = VisitId
          }
        }
        return item
      })
      //alert(JSON.stringify(updateAssmentSummary))
      //return
      service
        .saveData(updateAssmentSummary, formData)
        .then((response) => {
          //console.log(JSON.stringify(response));
          //toast.success("Record has been saved.");
          if (showMsg) {
            toast.success('Record has been saved.')
          }
        })
        .catch((error) => {
          console.error(
            'Error : .saveData(updateAssmentSummary,formData)',
            error,
          )
        })
        .catch((error) => {
          toast.error(
            '.saveData(updateAssmentSummary,formData) Error in API call!',
          )
        })
        .finally(() => {
          setIsDirty(false)
        })
    }

    useEffect(() => {
      //alert(JSON.stringify(formData));
      if (VisitId) {
        const fetchIntData = () => {
          service
            .getLanComData(VisitId)
            .then((dataFromResponse) => {
              //alert("Data : "+JSON.stringify(dataFromResponse));
              if (dataFromResponse !== undefined) {
                triggerRefresh(dataFromResponse.SkilledTherapyIndicated)
                setFormData(dataFromResponse)
              } else {
                // alert("ds");
                //setFormData(service);
              }
            })
            .catch((error) => {
              console.error('Error in CHA general:', error)
            })
            .finally(() => {
              onDataFetched()
              setLoading(false)
            })
        }
        const fetchAssSumdata = () => {
          service
            .getGetAssSummary(VisitId)
            .then((dataFromResponse) => {
              setConsolidatedRows(dataFromResponse)
            })
            .catch((error) => {
              console.error('Error in fetchAssSumdata:', error)
            })
        }

        fetchIntData()
        fetchAssSumdata()
      }
    }, [VisitId])

    const handleRowDeleted = (deletedRow) => {
      //alert(JSON.stringify(deletedRow.AssessmentSummaryId));
      if (!deletedRow || typeof deletedRow.RowId === 'undefined') {
        console.error('Invalid deletedRow:', deletedRow)
        return
      }
      if (deletedRow.AssessmentSummaryId > 0) {
        service.deleteAssSumData(deletedRow.AssessmentSummaryId)
      }

      // alert(JSON.stringify(deletedRow));
      // return;
      // setConsolidatedRows((prevRows) =>
      //   prevRows.filter((row) => row.RowId !== deletedRow.RowId)
      // );
    }

    const handleUpdatedRows = (updatedRows) => {
      //alert(JSON.stringify(updatedRows))
      setConsolidatedRows(updatedRows)
    }

    useEffect(() => {
      //alert("form data");
      ValidationObject()
    }, [formData])

    if (loading) {
      return <Loading />
    }
    return (
      <Container fluid>
        <Row className='mt-2'>
          {' '}
          <Col xs={6}>
            <Row className='mt-2'>
              <Col xs={3}>Language Tested</Col>
              <Col xs={2}>
                <DDList
                  dropdownName='LanguageTested'
                  defaultValue={formData.LanguageTested}
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'LanguageTested')
                  }
                  ddType={18}
                />
              </Col>

              {formData.LanguageTested === 'Other' && (
                <Col xs={3}>
                  <TextBox
                    IsChangeTracking={setIsDirty}
                    showError={showErrorOther}
                    maxLength={20}
                    controlid='LanguageOther'
                    defaultValue={formData.LanguageOther}
                    onTextChange={(value) =>
                      handleTextBoxValueChange('LanguageOther', value)
                    }
                    placeholder={'other...'}
                  />{' '}
                </Col>
              )}
              <Col xs={3}>
                <Checkbox
                  label='Non-Verbal'
                  checked={formData.NonVerbal}
                  onChange={handleCheckboxChange}
                  columnName='NonVerbal'
                />{' '}
              </Col>
              <Col xs={3}>
                <Checkbox
                  label='AAC-Use'
                  checked={formData.AACUse}
                  onChange={handleCheckboxChange}
                  columnName='AACUse'
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={2}>
                <b>Comprehends</b>
              </Col>
              <Col xs={5} style={{ fontStyle: 'italic', color: 'red' }}>
                Select noted abilities
              </Col>
            </Row>
            <Row>
              <CheckboxListInColumn
                values={formData.Comprehend}
                onChange={(selectedValues) =>
                  ddlChange(selectedValues, 'Comprehend')
                }
                typeOption={10}
                columns={4}
                rows={5}
              />
            </Row>
            <Row>
              <b>Answers Questions</b>{' '}
            </Row>
            <Row>
              <Col xs={12}>
                <CheckboxListInColumn
                  values={formData.AnswersQuestions}
                  onChange={(selectedValues) =>
                    ddlChange(selectedValues, 'AnswersQuestions')
                  }
                  typeOption={11}
                  columns={4}
                  rows={2}
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={3}>
                <b>Language Complexity</b>
              </Col>
              <Col xs={5}>
                <div style={{ fontStyle: 'italic', color: 'red' }}>
                  Select the highest level proficiency
                </div>{' '}
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col xs={3}>Overall Complexity</Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'OverallComplexity')
                  }
                  defaultValue={formData.OverallComplexity}
                  ddType={14}
                />
              </Col>
              <Col xs={3}>Following Directions</Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'FollowingDirection')
                  }
                  defaultValue={formData.FollowingDirection}
                  ddType={16}
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col xs={3}>Repeating Words</Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'RepeatingWord')
                  }
                  defaultValue={formData.RepeatingWord}
                  ddType={14}
                />
              </Col>

              <Col xs={3}>Sequencing</Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'Sequencing')
                  }
                  defaultValue={formData.Sequencing}
                  ddType={16}
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col xs={3}>Repeating Sentences</Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'RepeatingSentence')
                  }
                  defaultValue={formData.RepeatingSentence}
                  ddType={14}
                />
              </Col>

              <Col xs={3}>Speech Understanding</Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'SpeechUnderstanding')
                  }
                  defaultValue={formData.SpeechUnderstanding}
                  ddType={15}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row className='mt-1'>
              <b>Verbal Comprehension </b>{' '}
            </Row>
            <Row>
              <Col xs={12}>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={1}
                  maxLength={1000}
                  controlid='VerbalComprehension'
                  defaultValue={formData.VerbalComprehension}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('VerbalComprehension', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>
            <Row>
              <b>Reading Comprehension</b>{' '}
            </Row>
            <Row>
              <Col xs={12}>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={1}
                  maxLength={1000}
                  controlid='ReadingComprehension'
                  defaultValue={formData.ReadingComprehension}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('ReadingComprehension', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>
            <Row>
              <b>Retelling Information</b>{' '}
            </Row>
            <Row>
              <Col xs={12}>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={1}
                  maxLength={1000}
                  controlid='RetellingInformation'
                  defaultValue={formData.RetellingInformation}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('RetellingInformation', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>

            <Row>
              <b>Comprehension Details</b>{' '}
            </Row>
            <Row>
              <Col xs={12}>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={1}
                  maxLength={5000}
                  controlid='ComprehensionDetails'
                  defaultValue={formData.ComprehensionDetails}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('ComprehensionDetails', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>
            <Row>
              <b>Strengths/Weaknesses</b>{' '}
            </Row>
            <Row>
              <Col xs={12}>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  cols={40}
                  rows={1}
                  maxLength={5000}
                  showError={StrengthsWeaknessesError}
                  controlid='StrengthsWeaknesses'
                  defaultValue={formData.StrengthsWeaknesses}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('StrengthsWeaknesses', value)
                  }
                  placeholder={'Please describe...'}
                />
              </Col>
            </Row>

            <Row>
              <b>Assessment Summary</b>
            </Row>
            <Row className='mt-1'>
              <Col xs={2}>
                <b>Overall Rating</b>
              </Col>
              <Col xs={3}>
                <DDList
                  onSelect={(selectedValue) =>
                    ddlChange(selectedValue, 'OverallRating')
                  }
                  defaultValue={formData.OverallRating}
                  ddType={11}
                />
              </Col>
              <Col xs={7}>
                <Checkbox
                  label='Skilled Therapy NOT Indicated for SLC'
                  checked={formData.SkilledTherapyIndicated}
                  onChange={handleCheckboxChange}
                  columnName='SkilledTherapyIndicated'
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <SPSSumm
                apiData={consolidatedRows}
                onUpdatedRows={handleUpdatedRows}
                onRowAdded={handleUpdatedRows}
                onRowDeleted={handleRowDeleted}
                FCMType={'SLC'}
                isChangeTracked={setIsDirty}
              />
            </Row>

            <Row className='mt-1'>
              <Col xs={12}>
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  showError={ResultError}
                  cols={40}
                  rows={2}
                  maxLength={5000}
                  controlid='Result'
                  defaultValue={formData.Result}
                  onTextChange={(value) =>
                    handleTextBoxValueChange('Result', value)
                  }
                  placeholder={'Please describe the result...'}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ textAlign: 'right' }}>
          <Col xs={12}>
            {/* <Button type="submit" className="btn btn-primary rounded-pill" >Preview</Button> */}
            <Button
              disabled={IsSignLock || !isDirty}
              type='submit'
              onClick={handleFormSubmit}
              style={{
                width: '150px',
                marginTop: '10px',
                marginLeft: '10px',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
              className='btn btn-primary rounded-pill'
            >
              <b>Save</b>
            </Button>
            {/* <Button type="submit" style={{ marginLeft: '10px' }} className="btn btn-primary rounded-pill">Sign/Lock</Button>
             */}{' '}
          </Col>
        </Row>
      </Container>
    )
  },
)
