import React, { useState, useEffect } from 'react'

export const ServiceDeliveryComp = ({
  defaultRow,
  onRowSelected,
  visitId,
  FCMType,
  Age,
}) => {
  const [gridData, setGridData] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [defaultRowProcessed, setDefaultRowProcessed] = useState(false)

  useEffect(() => {
    var dataFile = null

    if (FCMType === 'INT') {
      dataFile = 'data/disINTNomsSD.json'
    } else if (FCMType === 'SLE') {
      if (Age > 5) {
        dataFile = 'data/disSLENomsSD6.json'
      } else if (Age <= 5) {
        dataFile = 'data/disSLENomsSD35.json'
      }
    } else if (FCMType === 'SLC') {
      if (Age >= 6) {
        dataFile = 'data/disSLCNomsSD6.json'
      } else if (Age < 6) {
        dataFile = 'data/disSLCNomsSD35.json'
      }
    } else if (FCMType === 'COG') {
      // alert(FCMType)
      if (Age >= 6) {
        dataFile = 'data/disCOGNomsSD6.json'
      } else if (Age < 6) {
        dataFile = 'data/disCOGNomsSD35.json'
      }
    } else if (FCMType === 'SWAL') {
      dataFile = 'data/disSWALNomsSD.json'
    }
    else if (FCMType === 'FEED') {
      dataFile = 'data/disFEEDNomsSD.json'
    }
    else if (FCMType === 'FLU') {
      dataFile = 'data/disFLUNomsSD.json'
    }
    else if (FCMType === 'AAC') {
      dataFile = 'data/disAACNomsSD.json'
    }else if (FCMType === 'PRAG') {
      // alert(FCMType)
      if (Age >= 6) {
        dataFile = 'data/disPRAGNomsSD6.json'
      } else if (Age < 6) {
        dataFile = 'data/disPRAGNomsSD35.json'
      }
    }
    else if (FCMType === 'VOIC') {
      dataFile = 'data/disVOICNomsSD.json'
    }
    
    
    fetch(dataFile)
      .then((response) => response.json())
      .then((data) => {
        const updatedData = data.map((item) => ({
          ...item,
          VisitId: visitId,
          FCMType: FCMType,
        }))
        //alert(JSON.stringify(updatedData));
        setGridData(updatedData)
      })
      .catch((error) => {
        console.error('Error reading data:', error)
      })
  }, [FCMType, visitId])

  useEffect(() => {
    if (!defaultRowProcessed && defaultRow && defaultRow.length > 0) {
      const updatedData = gridData.map((row) => {
        const matchingDefaultRow = defaultRow.find(
          (defaultRowItem) =>
            String(defaultRowItem.RowId) === String(row.RowId),
        )

        if (matchingDefaultRow) {
          return {
            ...row,
            Discharge_NomsId: matchingDefaultRow.Discharge_NomsId,
            Answer: matchingDefaultRow.Answer,
            // IsLongTermGoal: matchingDefaultRow.IsLongTermGoal,
          }
        }

        return row
      })

      setGridData(updatedData)
      setSelectedRow(null) // Clear the selected row, as multiple rows might have changed
      onRowSelected(updatedData) // Notify parent about the updated rows
      setDefaultRowProcessed(true)
    }
  }, [defaultRow, defaultRowProcessed, gridData])

  const handleDropdownChange = (id, value) => {
    const selectedValue = value.trim() === '' ? '' : value
    const updatedData = gridData.map((row) => {
      if (row.RowId === id) {
        return { ...row, Answer: selectedValue }
      }
      return row
    })
    setGridData(updatedData)
    setSelectedRow(updatedData.find((row) => row.RowId === id))
    onRowSelected(updatedData)
  }

  const PercetageNos = () => {
    const options = []
    for (let i = 0; i <= 100; i += 5) {
      options.push({ value: i + '%', text: i + '%' })
    }
    return options
  }

  const getDropdownOptionsForRow = (rowId) => {
    var options = []
    if (rowId === 1) {
      options = PercetageNos()
    } else if (rowId === 2) {
      options = [
        { value: 'Individual', text: 'Individual' },
        { value: 'Group', text: 'Group' },
        { value: 'Training/Consultation', text: 'Training/Consultation' },
        { value: 'Self-contained classroom', text: 'Self-contained classroom' },
        { value: 'Classroom-based', text: 'Classroom-based' },
      ]
    } else if (rowId === 3 || rowId === 5) {
      options = [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' },
      ]
    } else if (rowId === 4) {
      options = [
        { value: 'Yes, all', text: 'Yes, all' },
        { value: 'Yes, some', text: 'Yes, some' },
        { value: 'Did not complete', text: 'Did not complete' },
        {
          value: 'Home program not established',
          text: 'Home program not established',
        },
      ]
    }
    return options
  }

  return (
    <table className='table table-striped'>
      <thead>
        <tr>
          <th style={{ width: '2%' }}>#</th>
          <th style={{ width: '75%' }}>Service Delivery</th>
          <th style={{ width: '15%' }}>Response values</th>
        </tr>
      </thead>
      <tbody>
        {gridData.map((row) => (
          <tr key={row.RowId}>
            <td>{row.RowId}</td>
            <td>{row.Question}</td>
            <td>
              <select
                className='form-select form-select-sm'
                value={row.Answer}
                onChange={(event) =>
                  handleDropdownChange(row.RowId, event.target.value)
                }
              >
                {getDropdownOptionsForRow(row.ResponseType).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
