import React, { useState, useEffect } from 'react'
import { GoalService } from '../../GoalComponenet/GoalService'
import { Table } from 'react-bootstrap'
export const HeaderUI = ({ firstName, VisitId, ClientId }) => {
  const goalService = new GoalService()
  const [goalData, setGoalData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = () => {
      setLoading(true)
      goalService
        .getPNGoals(ClientId, VisitId)
        .then((dataFromResponse) => {
          if (dataFromResponse) {
            //alert(JSON.stringify(dataFromResponse))
            setGoalData(dataFromResponse)
          }
        })
        .catch((error) => {
          console.error('Error fetching goalService.getPNGoals:', error)
        })
        .finally(() => {
          setLoading(false) // Set loading to false when data fetching ends
        })
    }
    if (VisitId !== null && ClientId != null) {
      // Fetch data when both VisitId and ClientId are not null
      fetchData()
    }
  }, [VisitId, ClientId])

  const LTgoalCounters = {}
  const STgoalCounters = {}

  const getGoalCounter = (GoalCode) => {
    //alert(JSON.stringify(LTgoalCounters));
    if (GoalCode === 'LTG') {
      // LT goals: Use the index + 1
      const value = (LTgoalCounters[GoalCode] || 0) + 1
      LTgoalCounters[GoalCode] = value
      return value
    } else {
      // Other goals: Increment the counter for the specific goal type
      const value = (STgoalCounters[GoalCode] || 0) + 1
      STgoalCounters[GoalCode] = value
      return value
    }
  }
  return (
    <div className='responsive-container'>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className='table-wrapper'>
          <Table size="sm"
            // responsive
            // borderless={true}
            // style={{
            //   width: 'auto',
            // }}
            className="custom-table"
          >
            <tbody>
              {goalData.map((row, index) => (
                <tr key={row.GoalId} >
                  <td
                    style={{padding: '0px',
                      width: '100%',
                      paddingLeft: row.GoalType !== 'LT' ? '10px' : '0',
                    }}
                  >
                    {row.GoalType === 'LT' ? (
                      <>
                        LTG<span> - {getGoalCounter(row.GoalCode)}: </span>
                        Within{' '}
                        <b>
                          {row.Duration} {row.Units}, {firstName}{' '}
                          {row.GoalDescription} {row.Accuracy}
                        </b>
                        .{' '}
                      </>
                    ) : row.GoalType === 'Articulation' ? (
                      <>
                        STG
                        <span>
                          {' '}
                          - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}:{' '}
                        </span>
                        Within{' '}
                        <b>
                          {row.Duration} {row.Units}
                        </b>
                        , given <b>{row.Given}</b>, {firstName}{' '}
                        <b>{row.GoalDescription}</b> in <b>{row.Sound}</b>{' '}
                        sound(s) at the <b>{row.AtLevel}</b> level with{' '}
                        <b>{row.Accuracy}</b> accuracy in{' '}
                        <b>{row.InSessions}</b> sessions.
                      </>
                    ) : row.GoalType === 'Phonology' ? (
                      <>
                        STG
                        <span>
                          {' '}
                          - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}:{' '}
                        </span>
                        Within{' '}
                        <b>
                          {row.Duration} {row.Units}
                        </b>
                        , given <b>{row.Given}</b>, {firstName}{' '}
                        <b>{row.GoalDescription}</b> with <b>{row.Accuracy}</b>{' '}
                        accuracy in <b>{row.InSessions}</b> sessions.
                      </>
                    ) : row.GoalType === 'General' ? (
                      <>
                        STG
                        <span>
                          {' '}
                          - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}:{' '}
                        </span>
                        Within{' '}
                        <b>
                          {row.Duration} {row.Units}
                        </b>
                        , given <b>{row.Given}</b>, {firstName}{' '}
                        <b>{row.GoalDescription}</b> with <b>{row.Accuracy}</b>{' '}
                        accuracy in <b>{row.InSessions}</b> sessions.
                      </>
                    ) : (
                      <>
                        {' '}
                        STG
                        <span>
                          {' '}
                          - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}:{' '}
                        </span>
                        Within{' '}
                        <b>
                          {row.Duration} {row.Units}
                        </b>
                        , given <b>{row.Given}</b>, {firstName}{' '}
                        <b>{row.GoalDescription}</b> with <b>{row.Accuracy}</b>{' '}
                        accuracy in <b>{row.InSessions}</b> sessions.
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  )
}
