import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Variables } from '../Variables'
import { format, max } from 'date-fns'
import 'bootstrap/dist/css/bootstrap.css'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { CasehistoryMedical } from './CaseHistoryMedical'
import { CasehistoryGeneral } from './CaseHistoryGeneral'
import { useUser } from '../Provider/UserContext'
import { CHAGeneralUI } from '../CasehistoryAdult/General/CHAGeneralUI'
import { CHAMedicalUI } from '../CasehistoryAdult/Medical/CHAMedicalUI'
import { SignLockProvider } from '../Provider/Visitprovider'

export const CasehistorysubTab = forwardRef(
  ({ triggerRefresh, isAdult, onTabChange }, ref) => {
    const defaultTab = 'general' // Set your default tab here
    const [activeTab, setActiveTab] = useState(defaultTab)
    //const [activeTab, setActiveTab] = useState("general");
    const { userFirstName, setUserFirstName } = useUser()
    const IsSignLock = useContext(SignLockProvider)
    const caseHistoryGeneralRef = useRef()
    const caseHistoryMedicalRef = useRef()

    const handleTabSelect = (selectedTab) => {
      if (IsSignLock === false) {
        //alert(IsSignLock);
        if (selectedTab === 'general' && activeTab === 'medical') {
          if (isAdult === true) {
            caseHistoryMedicalRef.current.SaveButtonClick(false)
          } else {
            caseHistoryMedicalRef.current.handleFormSubmit(false)
          }
        } else if (selectedTab === 'medical' && activeTab === 'general') {
          if (isAdult === true) {
            caseHistoryGeneralRef.current.SaveButtonClick(false)
          } else {
            caseHistoryGeneralRef.current.handleSubmit(false)
          }
        }
      }
      setActiveTab(selectedTab)
      onTabChange(selectedTab)
    }

    // useEffect(() => {
    //   alert(isAdult);
    // }, [isAdult]);
    // const childRefGeneral = useRef(null);
    // const childRefMedical = useRef(null);
    const SaveGeneral = () => {
      if (IsSignLock === false) {
        if (isAdult === true) {
          caseHistoryGeneralRef.current.SaveButtonClick(false)
        } else {
          caseHistoryGeneralRef.current.handleSubmit(false)
        }
      }
    }
    const SaveMedical = () => {
      if (IsSignLock === false) {
        if (isAdult === true) {
          caseHistoryMedicalRef.current.SaveButtonClick(false)
        } else {
          caseHistoryMedicalRef.current.handleFormSubmit(false)
        }
      }
    }
    useImperativeHandle(ref, () => ({
      SaveGeneral,
      SaveMedical,
    }))
    // useEffect(() => {
    //   childRefGeneral.current=SaveGeneral;
    //   childRefMedical.current = SaveMedical;
    // }, []);

    return (
      <div style={{ display: 'block', width: max, padding: 10 }}>
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabSelect}
          id='caseHistoryTabs'
        >
          <Tab eventKey='general' title='General'>
            {activeTab === 'general' &&
              (isAdult === true ? (
                <CHAGeneralUI
                  onDataFetched={() => setActiveTab('general')}
                  ref={caseHistoryGeneralRef}
                />
              ) : (
                <CasehistoryGeneral
                  onDataFetched={() => setActiveTab('general')}
                  ref={caseHistoryGeneralRef}
                />
              ))}
          </Tab>
          <Tab eventKey='medical' title='Medical'>
            {activeTab === 'medical' &&
              (isAdult === true ? (
                <CHAMedicalUI
                  onDataFetched={() => setActiveTab('medical')}
                  ref={caseHistoryMedicalRef}
                  triggerRefresh={triggerRefresh}
                />
              ) : (
                <CasehistoryMedical
                  onDataFetched={() => setActiveTab('medical')}
                  ref={caseHistoryMedicalRef}
                  triggerRefresh={triggerRefresh}
                />
              ))}
          </Tab>
        </Tabs>
      </div>
    )
  },
)
