import React, { useState, useEffect, useContext } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
import formatDate from '../Utility/Utility'
import axios from 'axios'
import { Variables } from '../Variables'
import HeaderTextContent from './HeaderTextContent'
import { AssessIntService } from '../CasehistoryAdult/SpeechInt/AssessIntService'
import { formatTime } from '../Utility/Utility'
import { NomsService } from '../FCM/Component/NomsService'
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
// const styles = {
//   container: {
//     paddingTop: '0px',
//     paddingLeft: '20px', // Padding from the left
//     paddingRight: '20px', // Padding from the right
//   },
//   headerRow: {
//     flexDirection: 'row',
//     borderBottom: '1px solid #FAFAFA', // Light gray border
//     backgroundColor: '#FAFAFA', // Very light gray background
//     padding: '2px', // Padding for cells
//   },
//   headerCell: {
//     flex: 1,
//     fontWeight: 'bold',
//     fontSize: '8pt', // Font size 11
//   },
//   dataRow: {
//     flexDirection: 'row',
//     borderBottom: '1px solid #FAFAFA',
//     padding: '2px', // Padding for cells
//   },
//   dataCell: {
//     flex: 1,
//     fontSize: '8pt', // Font size 11
//   },
//   soundRow: {
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//   },
// }
// const stylesTable = {
//   page: {
//     padding: 10,
//   },
//   container: {
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//     paddingLeft: '20px', // Padding from the left
//     paddingRight: '20px',
//   },
//   cell: {
//     width: '25%',
//     padding: 1,
//     fontSize: '8pt', // Font size 11
//   },
//   customText: {
//     fontFamily: 'Roboto',
//     fontWeight: 'bold',
//     fontSize: 8,
//   },
//   boldText: {
//     fontFamily: 'Roboto-Bold',
//     fontWeight: 'bold',
//     fontSize: 8,
//   },
//   doulosText: {
//     fontFamily: 'Doulos SIL',
//     fontWeight: 'bold',
//     fontSize: 9,
//   },
//   containerQ: {
//     flexDirection: 'column',
//     flexWrap: 'wrap',
//     // paddingLeft: "10px", // Padding from the left
//     // paddingRight: "10px",
//   },
//   dataRow: {
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     borderBottom: '0px solid #FAFAFA',
//     padding: 1,
//   },
//   indexCell: {
//     width: '3%',
//     fontSize: 8,
//   },
//   questionCell: {
//     width: '82%',
//     fontSize: 8,
//   },
//   answerCell: {
//     width: '15%',
//     fontSize: 8,
//   },
// }
const nomsService = new NomsService()
const ContentSIA = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  clientdata,
  pocData,
  Diagnosis2,
  HeaderFcm,
}) => {
  const [isYes] = useState('Yes')
  const [assSumdata, setAssSumdata] = useState([])
  const [coreSidata, setCoreSiData] = useState(null)
  const [nomsData, setNomsData] = useState([])
  const service = new AssessIntService()

  useEffect(() => {
      if (!VisitId) return
    const fetchIntData = () => {
      service
        .getSpeechData(VisitId)
        .then((dataFromResponse) => {
          if (dataFromResponse !== undefined) {
            //console.log(dataFromResponse)
            setCoreSiData(dataFromResponse)
          }
        })
        .catch((error) => {
          console.error('Error in CHA general:', error)
        })
    }
    const fetchAssSumdata = () => {
      //alert(VisitId);
      service
        .getGetAssSummary(VisitId)
        .then((dataFromResponse) => {
          //alert(JSON.stringify(dataFromResponse))
          setAssSumdata(dataFromResponse)
        })
        .catch((error) => {
          console.error('Error in fetchAssSumdata:', error)
        })
    }
    nomsService
      .getNomsData(VisitId, 'INT')
      .then((response) => {
        setNomsData(response)
      })
      .catch((error) => {})
    // axios
    //   .get(Variables.API_URL + 'SpeechInt/GetNoms/' + VisitId)
    //   .then((response) => {
    //     const data = response.data.Result
    //     setNomsData(data)
    //   })
    //   .catch((error) => {})
    fetchIntData()
    fetchAssSumdata()
  }, [VisitId])

  return (
    <>
      <View style={reportStyle.pageSpace}>
        {/* <View style={reportStyle.headerTextContainerText}>
        <Text style={reportStyle.headerText}>
          Date of Evaluation:{' '}
          {coreSidata && formatDate(coreSidata.CreatedDate, false, true)}
          {'   '}
          <Text style={reportStyle.headerTextSpace}> </Text>
          Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
          {'   '}
          <Text style={reportStyle.headerTextSpace}> </Text>
          End: {clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
        </Text>
      </View> 
      <HeaderTextContent
        clientdata={clientdata}
        FullName={FullName}
        PocData={pocData}
        Diagnosis2={Diagnosis2}
      ></HeaderTextContent>*/}
        {/* <View style={reportStyle.TextHeaderTop}>
        <Text style={reportStyle.TextHeaderText}>Clinical Assessment</Text>
      </View>
     <View style={reportStyle.paragraph}>
        <Text>
          {FirstName} was assessed against the following AHSA Functional
          Communication Measures (FCM):
        </Text>
      </View> */}
        {HeaderFcm === true && (
          <>
            <View style={reportStyle.TextHeaderTop}>
              <Text style={reportStyle.TextHeaderText}>
                Clinical Assessment
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName} was assessed against the following AHSA Functional
                Communication Measures (FCM):
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextHeaderText}>Speech Intelligibility</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Based on the assessment & observations outlined herein, I found{' '}
            {FirstName}'s overall deficit to be{' '}
            {coreSidata && coreSidata.OverallIntRating}, and I believe Skilled
            Speech Therapy{' '}
            {coreSidata && coreSidata.IsSkilledSpeechTherapy ? 'is not' : 'is'}{' '}
            indicated for Speech Intelligibility.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I scored the following assessments when evaluating {FirstName}'s
            current speech & language proficiency:
          </Text>
        </View>
        <View style={reportStyle.container}>
          <View style={reportStyle.tblheaderRow}>
            <Text style={reportStyle.tbldataCellAssessment}>Assessment</Text>
            <Text style={reportStyle.tblheaderCell}>Raw Score</Text>
            <Text style={reportStyle.tblheaderCell}>Standard Score</Text>
            <Text style={reportStyle.tblheaderCell}>%File rank</Text>
          </View>
          {assSumdata.map((row, index) => (
            <View style={reportStyle.tbldataRow} key={row.RowId}>
              <Text style={reportStyle.tbldataCellAssessment}>
                {row.AssessmentSummaryDesc}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Score}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Code}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Rank}
              </Text>
            </View>
          ))}
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I conducted the assessment in{' '}
            {coreSidata && coreSidata.LanguageTested
              ? coreSidata.LanguageTested === 'Other'
                ? coreSidata.LanguageOther
                : coreSidata.LanguageTested
              : ''}{' '}
            and {hisHer} highest level of communication was{' '}
            {coreSidata && coreSidata.HighestLevel} at{' '}
            {coreSidata && coreSidata.Percentage}%.{' '}
            {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} over
            Intelligibility rating is currently{' '}
            {coreSidata && coreSidata.OverallIntRating}.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Overall, I found {FirstName}{' '}
            {coreSidata && coreSidata.AssessmentDescription}.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {coreSidata && coreSidata.AssessmentType && (
              <>
                Indication of {coreSidata.AssessmentType.replace(/,/g, ', ')} of
                Prosody is suspected.{' '}
                {coreSidata &&
                  coreSidata.AssessmentType.includes('Dysarthria') && (
                    <>
                      The type of Dysarthria appears to be{' '}
                      {coreSidata.AssessmentType.replace(/,/g, ', ')}.
                    </>
                  )}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {HeOrShe} appears to{' '}
            {coreSidata && coreSidata.AwareDeficit === isYes ? 'be' : 'be not'}{' '}
            aware of {hisHer} deficit and I would describe the impact of the
            deficit on {hisHer} daily life as{' '}
            {coreSidata && coreSidata.ImpactOnDailyLife}.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>{FirstName} was able to:</Text>
        </View>
        <View style={reportStyle.container}>
          <View style={reportStyle.tblheaderRow}>
            <Text style={reportStyle.tbldataCell}>Lingual</Text>
            <Text style={reportStyle.tbldataCell}>Labial</Text>
            <Text style={reportStyle.tbldataCell}>Buccal</Text>
            <Text style={reportStyle.tbldataCell}>Facial</Text>
          </View>

          <View style={reportStyle.tbldataRow}>
            <Text style={reportStyle.tbldataCell}>
              {coreSidata && coreSidata.OralLingual}
            </Text>
            <Text style={reportStyle.tbldataCell}>
              {coreSidata && coreSidata.OralLabial}
            </Text>
            <Text style={reportStyle.tbldataCell}>
              {coreSidata && coreSidata.OralBuccal}
            </Text>
            <Text style={reportStyle.tbldataCell}>
              {coreSidata && coreSidata.OralFacial}
            </Text>
          </View>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I found {hisHer} strength, tone, movement, and range of motion to be{' '}
            {coreSidata && coreSidata.OralDescription}.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} Diadochokinetic
            Rate was {coreSidata && coreSidata.DiadochokineticRate}, Fluency of
            Speech was {coreSidata && coreSidata.FluencySpeech}, and Prosody was{' '}
            {coreSidata && coreSidata.Prosody}.
          </Text>
        </View>

        <View style={reportStyle.paragraph}>
          <Text>
            {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} Sound Production
            Patterns was {coreSidata && coreSidata.SPP_Level}{' '}
            {coreSidata && coreSidata.SPP_Type && (
              <>with {coreSidata.SPP_Type}</>
            )}
            {coreSidata && coreSidata.SPP_Description && (
              <>
                {' '}
                and the following sounds in error and/or production patterns:{' '}
                {coreSidata.SPP_Description}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I also found {hisHer} Stimulability to be{' '}
            {coreSidata && coreSidata.Stimulability_Rating}.
          </Text>
        </View>
        {coreSidata && coreSidata.IsSkilledSpeechTherapy === false && (
          <View style={reportStyle.paragraph}>
            <Text>{FirstName} was able to:</Text>
            <View style={reportStyle.tblnoms}>
              {nomsData.map((row, index) => (
                <View
                  style={[reportStyle.dataRownoms, { paddingLeft: 10 }]}
                  key={row.RowId}
                >
                  <Text style={[reportStyle.indexCell]}>{index + 1})</Text>
                  <Text style={[reportStyle.questionCell]}>
                    {row.Question.endsWith('?')
                      ? row.Question.replace(/\?/g, '')
                          .charAt(0)
                          .toUpperCase() + row.Question.slice(0, -1)
                      : row.Question.replace(/\?/g, '')
                          .charAt(0)
                          .toUpperCase() + row.Question.slice(1)}{' '}
                    {row.Answer}.
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}
        {/*<View style={reportStyle.paragraph}>
        <Text>
          {coreSidata && coreSidata.AssessmentType && (
            <>
              Indication of {coreSidata.AssessmentType} of Prosody is suspected.
            </>
          )}
        </Text>
      </View>
      <View style={reportStyle.paragraph}>
        <Text>
          {coreSidata && coreSidata.AssessmentType && (
            <>
              Indication of {coreSidata.AssessmentType} of Prosody is suspected.
            </>
          )}
        </Text>
      </View> */}

        {/* {coreSidata && coreSidata.IsSkilledSpeechTherapy === true ? (
        <View style={reportStyle.paragraph}>
          <Text>
            Skilled Speech Therapy is not indicated for Speech Intelligibility.
          </Text>
        </View>
      ) : (
        <View style={reportStyle.paragraph}>
          <Text>
            Skilled Speech Therapy is indicated for Speech Intelligibility.
          </Text>
        </View>
      )} */}
      </View>
    </>
  )
}

export default ContentSIA
