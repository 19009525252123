import React, { useEffect, useState } from 'react'

const customSliderStyle = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: '16px',
  },
  input: {
    width: '100%',
    marginTop: '2px',
    height: '4px',
  },
  value: {
    fontSize: '16px',
    fontWeight: 'bold',
    alignSelf: 'flex-end', // Align to the right side
    marginTop: '-2px', // Add some margin for spacing
  },
  thumb: {
    width: '50px', // Adjust the width and height to your desired size
    height: '50px',
    borderRadius: '20%',
    backgroundColor: '#3a7bec',
    cursor: 'pointer',
    border: 'none',
  },
}

const PercentageSlider = ({ value, min, max, step, label, onChange }) => {
  const [displayValue, setValue] = useState(value)

  useEffect(() => {
    if (value) {
      setValue(value)
    }
  }, [value])

  const handleChange = (event) => {
    onChange(event.target.value)
    setValue(event.target.value)
  }

  return (
    <div style={customSliderStyle.container}>
      <input
        type='range'
        id='slider'
        name='slider'
        defaultValue={value}
        value={displayValue}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        style={{
          ...customSliderStyle.input,
          '--thumb-size': customSliderStyle.thumb.width, // Set custom property for thumb size
        }}
      />
      <span style={customSliderStyle.value}>{displayValue}%</span>
    </div>
  )
}

export default PercentageSlider
