import React, { useState, useEffect,useContext  } from 'react'
import logo from './logo.svg'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css'
import { UserDetail } from './Controls/UserDeails'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { MainTab } from './casehistory/MainTab'
import { UserProvider,useUser  } from './Provider/UserContext'
import { PNTabs } from './ProgressNote/PNTabs'
import TabContext from './Provider/UserContext'
import { LanguageProvider, useLanguage } from './Provider/LanguageContext'
import EvalPDFDownlaod from './PDFGenerator/EvalPDFDownlaod'

document.body.style.zoom = '100%'

function App({ pdfMode, refreshSignLock }) {
  const [activeTab, setActiveTab] = useState('CHC') // Set the default active tab
  const [visitType, setVisitType] = useState(null)
  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey)
  }
  const user = useContext(useUser);
 // alert(JSON.stringify(user))
  const brandLogo = user?.BrandLogo;
  return (
    <UserProvider>
      <Container fluid>
        <Row >
          {/* <Col
            style={{
              verticalAlign: 'center',
              paddingTop: '20px',
              marginLeft: '-50px',
            }}
            md='auto'
          >
           <img
              alt='AllcareTherapies'
              style={{ widht: '180px', height: '70px' }}
              //src='./logoImage/logo.png'
              src={brandLogo ? brandLogo : './logoImage/logo.png'}
            />
          </Col> */}
          <Col xs={12} style={{ paddingTop: '10px' }}>
            <TabContext.Provider value={activeTab}>
              <UserDetail />
            </TabContext.Provider>
        </Col> 
        </Row> 
        <Row>
          <Col xs={12}>
            <UserProvider>
              <LanguageProvider>
                {/* {pdfMode === 'd' ? (
                <EvalPDFDownlaod />
               
              ) : ( */}
                <MainTab
                  refreshSignLock={refreshSignLock}
                  activeTab={activeTab}
                  handleTabChange={handleTabChange}
                />
                {/* )} */}
              </LanguageProvider>
            </UserProvider>
          </Col>
        </Row>
      </Container>
    </UserProvider>
  )
}

export default App
