import React, { useState, useEffect, useContext } from 'react'
import jsPDF from 'jspdf'
import {
  Document,
  Page,
  Text,
  Image,
  View,
  PDFViewer,
  Svg,
  Polygon,
  Rect,
  StyleSheet,
} from '@react-pdf/renderer'
import Modal from 'react-modal'
import { useUser } from '../../Provider/UserContext'
import axios from 'axios'
import { Variables } from '../../Variables'
import { VisitProvider } from '../../Provider/Visitprovider'
import ReportHeader from '../ReportHeader'
import ReportFooter from '../ReportFooter'
import DSContentSection1 from './DSContentSection1'
import DSContentSection2 from './DSContentSection2'
import ContentSoapNote from './ContentSoapNote'
//import { CreatedByProvider } from '../../Provider/Visitprovider'

const styles = StyleSheet.create({
  body: {
    paddingTop: 1,
    paddingBottom: 40,
    // paddingHorizontal: 15,
  },
})
const customModalStyles = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    padding: '20px',
    maxWidth: '1200px',
    width: '100%',
    maxHeight: '1000px',
    height: '80%',
    overflow: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}

const apiCoreUrl = Variables.USER_API_URL
const DischargeSumReEvalPDF = ({ data, isShowButton = true, newVisitId }) => {

  const [modalOpen, setModalOpen] = useState(false)
  const [generatedPDF, setGeneratedPDF] = useState(null)
  const [pdfFilename, setPdfFilename] = useState('DischargeSummary.pdf')
  const { userDetails } = useUser()
  const [clientdata, setClientdata] = useState(null)
  const [FullName, setFullName] = useState(null)
  const VisitId = useContext(VisitProvider)
  const [diagnosis2, setDiagnosis2] = useState(null)
  const [dynamicVisitId, setDynamicVisitId] = useState(null)

  const [FirstName, setFirstName] = useState('')
  const [isYes] = useState('Yes')
  const [HeOrShe, setHeOrShe] = useState('')
  const [hisHer, sethisHer] = useState('')

  useEffect(() => {
    if (userDetails) {
      setClientdata(userDetails)
      setFullName(userDetails.LastName + ', ' + userDetails.FirstName)
      setFirstName(userDetails.FirstName)
      setHeOrShe(userDetails.Sex === 1 ? 'He' : 'She')
      sethisHer(userDetails.Sex === 1 ? 'his' : 'her')
      setDynamicVisitId(VisitId)
    }
  }, [userDetails])

  const fetchData = async (visitId) => {
    if (visitId) {
      try {
        //alert(VisitId);
        const response = await axios.get(
          apiCoreUrl + 'Client/GetVisitDetailForClinicalNotes?visitId=' + visitId,
        ) // Replace with the correct visitId or get it from somewhere else
        const data = response.data

        setClientdata(data)
        setHeOrShe(data.Sex === 1 ? 'He' : 'She')
        sethisHer(data.Sex === 1 ? 'his' : 'her')

        setFullName(data.LastName + ', ' + data.FirstName)
        setFirstName(data.FirstName)
      } catch (error) {
        console.log('Error fetching user details:', error)
      }
    }
  }

  useEffect(() => {
    //alert(isShowButton);
    if (!isShowButton) {
      fetchData(newVisitId)
      setDynamicVisitId(newVisitId)
      generatePDF()
    }
  }, [isShowButton, newVisitId])

  const generatePDF = () => {
    const doc = new jsPDF()
    setGeneratedPDF(doc.output('datauristring'))
    setModalOpen(true)
  }

  const handleDownload = () => {
    setPdfFilename('ProgressNote.pdf')
  }
  const updateDiagnosis2 = (value) => {
    setDiagnosis2(value)
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  return (
    <div>
      {isShowButton && (
        <button
          className='btn btn-primary rounded-pill'
          style={{ width: '150px', marginRight: '10px' }}
          onClick={generatePDF}
        >
          <b>Preview</b>
        </button>
      )}
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customModalStyles}
      >
        <PDFViewer style={{ width: '100%', height: 'calc(100% - 50px)' }}>
          <Document title={pdfFilename}>
            <Page size='A4' style={styles.body}>
              <ReportHeader
                isShowSubTitle={false}
                clientdata={clientdata}
                headerText={'Speech & Language Discharge Report'}
              ></ReportHeader>
              <DSContentSection1
                clientdata={clientdata}
                FirstName={capitalizeFirstLetter(FirstName)}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={dynamicVisitId}
                FullName={FullName}
                mainData={data}
              ></DSContentSection1>
              <ReportFooter></ReportFooter>
            </Page>
            <Page size='A4' >
              <ReportHeader
                isShowSubTitle={false}
                clientdata={clientdata}
                headerText={'Speech & Language Discharge Report'}
              ></ReportHeader>
              <DSContentSection2
                clientdata={clientdata}
                FirstName={capitalizeFirstLetter(FirstName)}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={dynamicVisitId}
                FullName={FullName}
                mainData={data}
              ></DSContentSection2>
              <ReportFooter></ReportFooter>
            </Page>{' '}
            {/* {isShowButton && (
              <Page size='A4'>
                <ReportHeader
                  isShowSubTitle={false}
                  headerText={'Speech & Language Progress Report'}
                ></ReportHeader>
                <ContentSoapNote
                  clientdata={clientdata}
                  FirstName={capitalizeFirstLetter(FirstName)}
                  HeOrShe={HeOrShe}
                  hisHer={hisHer}
                  userDetails={userDetails}
                  VisitId={dynamicVisitId}
                  FullName={FullName}
                  pnData={data}
                ></ContentSoapNote>
                <ReportFooter></ReportFooter>
              </Page>
            )} */}
          </Document>
        </PDFViewer>

        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            padding: '10px',
            textAlign: 'center',
            backgroundColor: '#f0f0f0',
          }}
        >
          {/* <a
            href={generatedPDF}
            download={pdfFilename}
            onClick={handleDownload}
          >
            Download PDF
          </a> */}
        </div>
      </Modal>
    </div>
  )
}

export default DischargeSumReEvalPDF
