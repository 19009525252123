import React from 'react';
import { View, Svg, Path } from '@react-pdf/renderer';

const CheckedBoxIcon = () => {
  return (
    <View
      style={{
        width: 10,
        height: 10,
        borderWidth: 1,
        borderColor: 'black',
        justifyContent: 'center', // Center vertically
        alignItems: 'center',     // Center horizontally
        marginRight: '5px',
        paddingBottom: 1, 
      }}
    >
      <Svg width={8} height={8}>
        {/* Add an SVG checkmark icon */}
        <Path
          d='M0.25 3.5L2.75 6L7.75 1'
          fill='none'
          stroke='black'
          strokeWidth='1.5'
        />
      </Svg>
    </View>
  );
};

export default CheckedBoxIcon;


export const UnCheckedBoxIcon = () => {
  return (
    <View
      style={{
        width: 10,
        height: 10,
        borderWidth: 1,
        borderColor: 'black',
        marginRight: '5px',
      }}
    >
     </View>
  )
}


