class PNGoal {
  constructor() {
    this.PN_GoalsId = 0
    this.SI_GoalsId = 0
    this.VisitId = 0
    this.ClientId = 0
    this.IsIndicated = false
    this.RowId = null
    this.PN_Goal_Type = ''
    this.GoalType = ''
    this.GoalCode = ''
    this.GoalDescription = ''
    this.Duration = null
    this.Units = ''
    this.Given = ''
    this.Sound = ''
    this.AtLevel = ''
    this.Accuracy = ''
    this.InSessions = ''
    this.GoalMET = false
    this.GoalMetDate = null
  }
}

export default PNGoal
